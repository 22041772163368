/* eslint-disable @typescript-eslint/no-explicit-any */
class IntercomService {
  openChat(): void {
    (window as any)?.Intercom?.("show");
  }

  closeChat(): void {
    console.log((window as any)?.Intercom);
    (window as any)?.Intercom?.("hide");
  }

  shutdown(): void {
    (window as any)?.Intercom?.("shutdown");
  }

  hideLauncher(): void {
    (window as any)?.Intercom?.("update", {
      hide_default_launcher: true,
    });
  }

  showLauncher(): void {
    (window as any)?.Intercom?.("update", {
      hide_default_launcher: false,
    });
  }
}

export default new IntercomService();

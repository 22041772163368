import clsx from "clsx";
import User from "../../../../../models/entities/user";
import { CheckCircleIcon, NoSymbolIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon } from "@heroicons/react/24/solid";

interface UserStatusProps {
  user: User;
}

export default function UserStatus({ user }: UserStatusProps) {
  return (
    <span
      className={clsx(
        user.status == "Inactive" && "bg-red-50 text-red-700 ring-red-600/20",
        user.status == "Invited" && "bg-blue-50 text-blue-700 ring-blue-600/20",
        user.status == "Active" && "bg-green-50 text-green-700 ring-green-600/20",
        "inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset"
      )}
    >
      {user.status == "Inactive" && <NoSymbolIcon className="h-4 w-4 text-red-500" />}
      {user.status == "Invited" && <EnvelopeIcon className="h-3.5 w-3.5 text-blue-500" />}
      {user.status == "Active" && <CheckCircleIcon className="h-4 w-4 text-green-500" />}
      &nbsp;
      {user.status}
    </span>
  );
}

import ChannelAccountTypes from "../../data/channel-account-types";
import MetaAccount from "../../models/meta/account";
import ApiService from "../core/api-service";

class FacebookService {
  getAuthorizationUrl(state: string): Promise<string> {
    return ApiService.get<string>(`external-providers/facebook/authorization-url`, {
      params: {
        state: state,
      },
    });
  }

  exchangeCodeForLongLivedToken(code: string): Promise<string> {
    return ApiService.post<string>(
      `external-providers/facebook/exchange-code`,
      {},
      {
        disableGlobalErrorReporting: true,
        params: {
          code: code,
          longLived: true,
        },
      }
    );
  }

  getLongLivedUserAccessToken(token: string): Promise<string> {
    return ApiService.get<string>(`external-providers/facebook/token`, {
      params: {
        exchangeToken: token,
      },
    });
  }

  getFacebookAccounts(userId: string, userAccessToken: string): Promise<MetaAccount[]> {
    return ApiService.get<MetaAccount[]>(`external-providers/facebook/accounts`, {
      disableGlobalErrorReporting: true,
      params: {
        userId,
        userAccessToken,
      },
    }).then((metaAccounts) => {
      metaAccounts.forEach((x) => {
        x.channelAccountType = ChannelAccountTypes.FacebookPage;
      });

      return metaAccounts;
    });
  }
}

export default new FacebookService();

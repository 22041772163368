import ListAllQueuedCountResponse from "../../models/api-responses/get-channel-queue-counters";
import GetSingleQueueCountersResponse from "../../models/api-responses/get-single-queue-counters";
import Post, { PostStatus } from "../../models/entities/post";
import ApiService from "../core/api-service";

class PostQueueService {
  listQueued(socialSetId: string, channelId?: string): Promise<Post[]> {
    return ApiService.get<Post[]>(`post-queue/queued`, {
      params: {
        socialSetId,
        channelId,
      },
    });
  }

  listByStatus(
    socialSetId: string,
    status: PostStatus,
    channelId?: string
  ): Promise<Post[]> {
    return ApiService.get<Post[]>(`post-queue/by-status`, {
      params: {
        socialSetId,
        channelId,
        status,
      },
    });
  }

  getSingleQueueCounters(
    socialSetId: string,
    channelId?: string
  ): Promise<GetSingleQueueCountersResponse> {
    return ApiService.get<GetSingleQueueCountersResponse>(
      `post-queue/single-queue-counters`,
      {
        params: {
          socialSetId,
          channelId,
        },
      }
    );
  }

  listAllQueuedCount(
    socialSetId: string,
    channelId?: string
  ): Promise<ListAllQueuedCountResponse> {
    return ApiService.get<ListAllQueuedCountResponse>(
      `post-queue/list-queued-count`,
      {
        params: {
          socialSetId,
          channelId,
        },
      }
    );
  }
}

export default new PostQueueService();

import { useNavigate } from "react-router-dom";
import User from "../../../../../models/entities/user";
import UserStatus from "./UserStatus";
import UserAvatar from "../../../../../components/common/UserAvatar";

interface UsersListProps {
  users: User[];
}

export default function UsersList({ users }: UsersListProps) {
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full px-4 sm:px-6 lg:px-0">
        <div className="-mx-4 mt-8 sm:-mx-0">
          <table className="min-w-full divide-y divide-gray-100 overflow-hidden ring-1 ring-black ring-opacity-5 rounded-sm sm:rounded-lg">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                >
                  <div className="pl-4">Name</div>
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-center text-xs font-medium uppercase tracking-wide text-gray-500 lg:table-cell"
                >
                  Permissions
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-center text-xs font-medium uppercase tracking-wide text-gray-500 lg:table-cell"
                >
                  Social sets
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  Status
                </th>
                {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Edit</span>
                </th> */}
              </tr>
            </thead>
            <tbody className="divide-y divide-dashed divide-gray-200 bg-white">
              {users.map((user) => (
                <tr
                  key={user.id}
                  className="hover:bg-gray-50 focus:bg-gray-100 cursor-pointer transition-all"
                  tabIndex={1}
                  onClick={() => navigate(`${user.id}`)}
                >
                  <td className="w-full max-w-0 py-5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0 whitespace-nowrap">
                    <div className="flex items-center pl-4">
                      <UserAvatar picture={user.picture} size="md" />
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">
                          {user.name}{" "}
                          {user.role == "Owner" && (
                            <span className="text-xs text-gray-600">
                              (owner)
                            </span>
                          )}
                        </div>
                        <div className=" text-gray-500">{user.email}</div>

                        <dl className="mt-1 font-normal lg:hidden">
                          <dt className="sr-only">Permissions</dt>
                          <dd className="mt-1 truncate text-gray-700">
                            <span className="inline-flex items-center rounded-full bg-purple-100 px-2 py-1 text-xs font-normal text-purple-700">
                              <svg
                                className="h-1.5 w-1.5 fill-purple-500"
                                viewBox="0 0 6 6"
                              >
                                <circle cx={3} cy={3} r={3} />
                              </svg>{" "}
                              &nbsp;Permissions:{" "}
                              <span className="text-bold">
                                &nbsp;
                                {user.permissions?.length
                                  ? user.permissions.length
                                  : "All"}
                              </span>
                            </span>
                          </dd>
                          <dt className="sr-only">Social sets</dt>
                          <dd className="mt-1 truncate text-gray-700">
                            <span className="inline-flex items-center rounded-full bg-amber-100 px-2 py-1 text-xs font-normal text-amber-700">
                              <svg
                                className="h-1.5 w-1.5 fill-amber-500"
                                viewBox="0 0 6 6"
                              >
                                <circle cx={3} cy={3} r={3} />
                              </svg>
                              &nbsp;Social sets:{" "}
                              <span className="text-bold">
                                &nbsp;
                                {user.socialSetIds?.length
                                  ? user.socialSetIds?.length
                                  : "All"}
                              </span>
                            </span>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </td>

                  <td
                    className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell"
                    align="center"
                  >
                    <span className="inline-flex items-center rounded-full bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
                      {user.permissions?.length
                        ? user.permissions?.length
                        : "All"}
                    </span>
                  </td>
                  <td
                    className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell"
                    align="center"
                  >
                    <span className="inline-flex items-center rounded-full bg-amber-100 px-2 py-1 text-xs font-medium text-amber-700">
                      {user.socialSetIds?.length
                        ? user.socialSetIds?.length
                        : "All"}
                    </span>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-5 text-sm text-gray-500"
                    align="center"
                  >
                    <UserStatus user={user} />
                  </td>
                  {/* <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0" align="center">
                    <a href="#" className="text-primary-600 hover:text-primary-900">
                      Edit<span className="sr-only">, {user.name}</span>
                    </a>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

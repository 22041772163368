import {
  BookmarkIcon,
  ChatBubbleOvalLeftIcon,
  EllipsisHorizontalIcon,
  HeartIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { PostInstance } from "../../models/post-instance";
import clsx from "clsx";
import AttachmentSlot from "../../models/attachment-slot";

export interface TikTokPhotoPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function TikTokPhotoPreview({
  postInstance,
  attachments,
}: TikTokPhotoPreviewProps) {
  const channel = postInstance.channel;
  const title = postInstance?.postConfig?.tikTok?.contentOptions?.title;
  const description =
    postInstance?.postConfig?.tikTok?.contentOptions?.description;
  const commercialContent =
    postInstance?.postConfig?.tikTok?.contentOptions?.commercialContent;
  const dotsCount = Math.min(attachments.length, 6);
  const smallerDot = dotsCount > 1 ? dotsCount - 1 : -1;
  const selectedDot = 0;

  if (!attachments.length) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col justify-center relative rounded-lg w-full overflow-hidden bg-black aspect-[9/16]">
        <div
          className="absolute top-0 right-0 left-0 bottom-0 z-10"
          style={{
            background:
              "linear-gradient(rgba(0, 0, 0, 0.2) 8.17%, rgba(0, 0, 0, 0) 20.79%, rgba(0, 0, 0, 0) 71.68%, rgba(0, 0, 0, 0.2) 83.07%)",
          }}
        ></div>

        <div className="overflow-hidden">
          <div className="grid grid-cols-3 grid-rows-2  h-full">
            <img
              className="col-span-3 row-span-2 w-full h-fit object-cover"
              src={attachments[0].preview.url}
            />
          </div>
        </div>

        <div className="absolute top-4 right-0 z-10 w-full">
          <div className="flex flex-row justify-center gap-6 px-3 py-4">
            <div className="flex gap-4 justify-center">
              <div className="text-white text-xs font-light">Following</div>
              <div className="text-white text-xs border-b-2 border-white pb-2">
                For You
              </div>
            </div>
            <div className="absolute top-4 right-3 rounded-full">
              <MagnifyingGlassIcon className="h-5 w-5 text-white" />
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 right-0 z-10">
          <div className="flex flex-col items-center gap-6 px-3 py-4">
            <div className="relative flex flex-col">
              <img
                referrerPolicy="no-referrer"
                className="h-9 w-9 flex-none rounded-full bg-white outline outline-1 outline-white"
                src={channel.pictureUrl}
              />
            </div>

            <div className="rounded-full">
              <HeartIcon className="h-5 w-5 text-white" />
            </div>
            <div className="rounded-full">
              <ChatBubbleOvalLeftIcon className="w-5 h-5 scale-x-[-1] text-white" />
            </div>
            <div className="rounded-full">
              <BookmarkIcon className="w-5 h-5 text-white" />
            </div>
            <div className="rounded-full">
              <EllipsisHorizontalIcon className="w-5 h-5 text-white" />
            </div>
            <div className="rounded-full mt-2">
              <div
                className="w-8 h-8 rounded-full flex items-center justify-center"
                style={{
                  background:
                    "linear-gradient(310.03deg, rgb(54, 54, 53) 13.36%, rgb(117, 117, 117) 49.18%, rgb(16, 16, 16) 88.11%)",
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-4 right-0 left-2 z-10 mr-8 pr-2 mb-2">
          <div className="flex flex-col items-start p-1 bg-black/35 max-w-[80%] rounded-2xl">
            <div className="flex gap-2">
              <img
                referrerPolicy="no-referrer"
                className="h-9 w-9 flex-none rounded-full bg-white outline outline-1 outline-white"
                src={channel.pictureUrl}
              />
              <div>
                <div className="text-gray-100/80 text-[10px] font-semibold">
                  {channel.name} • now
                </div>

                {title ? (
                  <div className="text-white text-[10px] leading-tight font-normal line-clamp-[6] whitespace-break-spaces">
                    {title}
                  </div>
                ) : null}

                {description ? (
                  <div className="mt-1 text-white text-[10px] leading-tight font-extralight line-clamp-[3] whitespace-break-spaces">
                    {description}
                  </div>
                ) : null}

                {commercialContent && (
                  <span className="inline-flex items-center rounded-md bg-stone-400 p-1 text-[10px] leading-none font-medium text-white">
                    Promotional content
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center gap-1 pt-2">
            {Array(dotsCount)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className={clsx(
                    "rounded-full",
                    index == selectedDot ? "bg-white" : "bg-white/60",
                    index == smallerDot ? "w-1 h-1" : "w-1.5 h-1.5"
                  )}
                ></div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

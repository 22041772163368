import dayjs from "dayjs";
import CalendarDay from "../modules/scheduler/calendar/models/calendar-day";
import Post from "../models/entities/post";

export function generateCalendarMonth(
  year: number,
  month: number,
  timezone: string
): CalendarDay[] {
  // Define the start of the month
  const startOfMonth = dayjs.tz(new Date(year, month, 1), timezone);
  const today = dayjs().tz(timezone);

  // Find the start of the calendar view, ensuring it starts on Monday
  const startOfCalendar = startOfMonth.startOf("isoWeek");

  // Define the end of the month
  const endOfMonth = dayjs(new Date(year, month + 1, 0));

  // Find the end of the calendar view, ensuring it includes the full week
  const endOfCalendar = endOfMonth.endOf("isoWeek");

  const days: CalendarDay[] = [];
  let currentDate = dayjs(startOfCalendar);

  while (
    currentDate.isBefore(endOfCalendar) ||
    currentDate.isSame(endOfCalendar, "day")
  ) {
    // Check if the current date is in the current month
    const isCurrentMonth = currentDate.month() === month;
    const isPast = currentDate.add(1, "day").isBefore(today);

    // Check if the current date is today
    const isToday = currentDate.isSame(dayjs(), "day");

    days.push({
      date: currentDate.format("YYYY-MM-DD"),
      isCurrentMonth: isCurrentMonth,
      dayOfWeek: "",
      events: [],
      isPast: isPast,
      isSelected: false,
      isToday: isToday,
    });

    // Move to the next day
    currentDate = currentDate.add(1, "day");
  }

  return days;
}

export function attachCalendarEvents(
  days: CalendarDay[],
  posts: Post[],
  timezone: string
): CalendarDay[] {
  (posts ?? []).forEach((post) => {
    if (!post.scheduledAt) {
      return;
    }

    const scheduledDate = dayjs(post.scheduledAt).tz(timezone);
    const postDate = scheduledDate.format("YYYY-MM-DD");
    const day = days.find((x) => x.date == postDate);

    if (day) {
      day.events.push({
        id: post.id,
        datetime: scheduledDate.toISOString(),
        name: post.title,
        time: scheduledDate.format("hh:mm A"),
        post: post,
      });
    }
  });

  return days;
}

export function attachCalendarPlaceholders(days: CalendarDay[]): CalendarDay[] {
  (days ?? []).forEach((day) => {
    day.events.push({
      id: "",
      datetime: day.date,
      name: "",
      time: dayjs(day.date).format("YYYY-MM-DD hh:mm A"),
      post: null,
    });
  });

  return days;
}

import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import FacebookVideoFrame from "../facebook/FacebookVideoFrame";
import { PostInstance } from "../../models/post-instance";
import {
  ChatBubbleOvalLeftIcon,
  HeartIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import AttachmentSlot from "../../models/attachment-slot";

export interface InstagramReelPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function InstagramReelPreview({
  postInstance,
  attachments,
}: InstagramReelPreviewProps) {
  const channel = postInstance.channel;
  const instagramPostConfig = postInstance?.postConfig?.instagram;
  const message = instagramPostConfig?.contentOptions?.caption;

  if (!attachments.length) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col relative rounded-lg w-full overflow-hidden aspect-[9/16]">
        <div className="absolute bottom-0 right-0 z-10">
          <div className="flex flex-col items-center gap-6 px-3 py-4">
            <div className="rounded-full">
              <HeartIcon className="h-5 w-5 text-white" />
            </div>
            <div className="rounded-full flex flex-col items-center justify-center gap-1">
              <ChatBubbleOvalLeftIcon className="w-5 h-5 scale-x-[-1] text-white" />
              {instagramPostConfig.firstCommentOptions?.comment && (
                <span className="text-white text-xs text-[10px] font-light">
                  1
                </span>
              )}
            </div>
            <div className="rounded-full">
              <PaperAirplaneIcon className="w-5 h-5 text-white" />
            </div>
            <div className="rounded-full">
              <div></div>
              <div>
                <EllipsisHorizontalIcon className="w-5 h-5 text-white" />
                <div className="mt-2">
                  <img
                    referrerPolicy="no-referrer"
                    className="h-6 w-6 flex-none rounded-sm bg-gray-50 outline outline-1 outline-white"
                    src={channel.pictureUrl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 right-0 left-0 z-10 mr-8 pr-2 mb-4">
          <div className="flex flex-col items-start px-4 py-3">
            <div className="flex items-center gap-2">
              <img
                referrerPolicy="no-referrer"
                className="h-6 w-6 flex-none rounded-full"
                src={channel.pictureUrl}
              />
              <span className="text-[10px] font-semibold leading-3 text-white">
                {channel.name}
              </span>{" "}
            </div>
            {message ? (
              <div className="text-white text-[10px] leading-[14px] font-light line-clamp-1 mt-2 whitespace-break-spaces">
                {message}
              </div>
            ) : null}
          </div>
        </div>

        <FacebookVideoFrame slot={attachments[0]} />
      </div>
    </>
  );
}

import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../queries/query-names";
import stripeService from "../../../services/api/stripe-service";
import Spinner from "../../../components/common/Spinner";
import PlanList from "./components/PlanList";
import { useContext, useEffect } from "react";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import actionTypes from "../../../state/global-state/action-types";
import { invalidateQuery } from "../../../queries/query-utils";
import useAuthorizedAccess from "../../../hooks/useAuthorizedAccess";
import Unauthorized from "../../../components/common/403";

export default function PlanSettings() {
  const { state, dispatch } = useContext(GlobalStateContext);
  const isAuthorized = useAuthorizedAccess(["billing_write"], [], []);

  // Subscription
  const subscription = state.currentTenant?.subscription;

  // Products
  const { data: products, isLoading: isLoadingProducts } = useQuery({
    queryKey: [queryNames.stripeProducts],
    queryFn: stripeService.listProducts,
  });

  // Payment methods
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useQuery(
    {
      queryKey: [queryNames.stripePaymentMethods],
      queryFn: stripeService.getPaymentMethods,
    }
  );

  // Prices
  const { data: prices, isLoading: isLoadingPrices } = useQuery({
    queryKey: [queryNames.stripePrices],
    queryFn: stripeService.listPrices,
  });

  const onSubscriptionUpdated = (): void => {
    invalidateQuery([queryNames.tenants]);
  };

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SETTINGS_BREADCRUMBS,
      payload: [
        {
          name: "Settings",
          to: "/settings",
        },
        {
          name: "Plan Settings",
          to: "/settings/plan",
        },
      ],
    });
  }, [dispatch]);

  return (
    <>
      {!isAuthorized && <Unauthorized flat={true} />}
      {isAuthorized && (
        <div className="flex-1 flex flex-col">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Choose Your Plan
            </h2>
            <p className="text-sm leading-6 text-gray-500">
              Plans built for businesses of all sizes.
            </p>
          </div>

          <div className="my-4 border-t border-gray-200"></div>

          <div className="flex-1 flex flex-col items-center justify-center relative">
            {isLoadingProducts || isLoadingPrices || isLoadingPaymentMethods ? (
              <Spinner size="5" />
            ) : (
              <PlanList
                products={products}
                prices={prices}
                subscription={subscription}
                paymentMethods={paymentMethods}
                onSubscriptionUpdated={onSubscriptionUpdated}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

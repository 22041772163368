import AcceptUserInviteRequest from "../../models/api-requests/accept-user-invite-request";
import User from "../../models/entities/user";
import ApiService from "../core/api-service";

class UserService {
  create(model: object): Promise<User> {
    return ApiService.post<User>(`users/invite`, model);
  }

  acceptInvite(model: AcceptUserInviteRequest): Promise<User> {
    return ApiService.post<User>(`users/accept-invite`, model, {
      disableGlobalErrorReporting: true,
    });
  }

  list(): Promise<User[]> {
    return ApiService.get<User[]>(`users`);
  }

  delete(id: string): Promise<void> {
    return ApiService.delete<void>(`users/${id}`);
  }

  deactivate(id: string): Promise<void> {
    return ApiService.post<void>(`users/${id}/deactivate`, {});
  }

  activate(id: string): Promise<void> {
    return ApiService.post<void>(`users/${id}/activate`, {});
  }

  reinvite(id: string): Promise<void> {
    return ApiService.post<void>(`users/${id}/invite`, {});
  }

  update(id: string, model: object): Promise<User> {
    return ApiService.put<User>(`users/${id}`, model);
  }

  updateProfile(id: string, model: object): Promise<User> {
    return ApiService.put<User>(`users/${id}/profile`, model);
  }

  updateNotificationSettings(id: string, model: object): Promise<User> {
    return ApiService.put<User>(`users/${id}/notification-settings`, model);
  }
}

export default new UserService();

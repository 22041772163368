/* eslint-disable @typescript-eslint/no-unused-vars */
import "@aws-amplify/ui-react/styles.css";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Splash from "../MainLayout/components/Splash";
import AuthService from "../../services/auth/auth-service";
import tenantService from "../../services/api/tenant-service";
import TenantUser from "../../models/entities/tenant-user";
import { queryClient } from "../../queries/query-client";
import queryNames from "../../queries/query-names";
import SocialSet from "../../models/entities/social-set";
import socialSetsService from "../../services/api/social-sets-service";
import inviteService from "../../services/application/invite-service";
import userService from "../../services/api/user-service";
import ToastHolder from "../../components/common/ToastHolder";
import errorReporter from "../../utils/error-reporter";
import GenericError from "../../components/errors/GenericError";
import sessionService from "../../services/application/session-service";
import GlobalStateContext from "../../state/global-state/GlobalStateContext";
import actionTypes from "../../state/global-state/action-types";
import * as Sentry from "@sentry/react";
import Intercom from "@intercom/messenger-js-sdk";
import dayjs from "dayjs";
import couponSignupService from "../../services/application/coupon-signup-service";
import CollectEmail from "./components/CollectEmail";
import { JWT } from "aws-amplify/auth";

function BootstrapperLayout() {
  const [hasBoostraped, setHasBootstraped] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [collectEmail, setCollectEmail] = useState(false);
  const { dispatch } = useContext(GlobalStateContext);
  const navigate = useNavigate();

  const getTenants = (): Promise<TenantUser[]> => {
    return queryClient.fetchQuery({
      queryKey: [queryNames.tenants],
      queryFn: tenantService.list,
    });
  };

  const getSocialSets = (): Promise<SocialSet[]> => {
    return queryClient.fetchQuery({
      queryKey: [queryNames.socialSets],
      queryFn: socialSetsService.list,
    });
  };

  const parsePictureUrl = (picture: string): string => {
    try {
      return JSON.parse(picture).data.url;
    } catch (e) {
      return picture;
    }
  };

  const getReferralId = (): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rewardful = (window as any).Rewardful;
    console.log("ReferralId", rewardful.referral);
    return rewardful.referral;
  };

  const setCurrentSocialSet = (socialSets: SocialSet[]) => {
    dispatch({
      type: actionTypes.SET_SOCIAL_SETS,
      payload: socialSets,
    });

    const current = sessionService.getSocialSet(socialSets) ?? socialSets?.[0];

    dispatch({ type: actionTypes.SET_CURRENT_SOCIAL_SET, payload: current });
  };

  const parseToken = (
    idToken: JWT
  ): { email: string; name: string; identityId: string; picture: string } => {
    const email = idToken.payload["email"]?.toString() ?? "";

    const name =
      idToken.payload["name"]?.toString() ??
      idToken.payload["given_name"]?.toString() ??
      email;

    const identityId = idToken.payload.sub;
    let picture = idToken.payload["picture"]?.toString();
    picture = parsePictureUrl(picture);

    return {
      email,
      name,
      identityId,
      picture,
    };
  };

  const setTenantData = (tenants: TenantUser[], currentTenant: TenantUser) => {
    dispatch({ type: actionTypes.SET_TENANTS, payload: tenants });
    dispatch({
      type: actionTypes.SET_CURRENT_TENANT,
      payload: currentTenant,
    });

    sessionService.setTenant(currentTenant);
  };

  const bootstrap = async () => {
    if (hasBoostraped) {
      return;
    }

    queryClient.invalidateQueries();

    try {
      // If not logged-in: Cancel bootstrapping
      const { idToken } = await AuthService.getTokens();
      if (!idToken) {
        Sentry.setUser(null);
        // User is not logged-in, cancel bootstrapping
        navigate("/sign-out");
        return;
      }

      const { email, name, identityId, picture } = parseToken(idToken);

      if (!email) {
        setCollectEmail(true);
        return;
      }

      // Get tenants list
      const tenants = await getTenants();

      Sentry.setUser({ email: email, sub: identityId, name: name });

      const inviteCode = inviteService.getInviteCode();
      let currentTenant: TenantUser = null;

      // If invite code found: Accept user invitation to join a Workspace
      if (inviteCode) {
        inviteService.clearInviteCode();

        const invitedUser = await userService.acceptInvite({
          code: inviteCode,
          name: name,
          email: email,
          picture: picture,
        });

        const [_, tenants] = await Promise.all([
          await AuthService.getTokens(true),
          getTenants(),
        ]);

        currentTenant = tenants.find((x) => x.user.id == invitedUser.id);
        setTenantData(tenants, currentTenant);
      }
      // If no invitation found and no tenants found: It's a new user so create a brand new account
      else if (!tenants.length) {
        const couponId = couponSignupService.getCouponId();
        const tenant = await tenantService.createTenant({
          name: name,
          email: email,
          picture: picture,
          referralId: getReferralId(),
          couponId: couponId,
        });

        const [_, tenants] = await Promise.all([
          await AuthService.getTokens(true),
          getTenants(),
        ]);

        couponSignupService.clearCouponId();

        currentTenant = tenants.find((x) => x.tenant.id == tenant.tenant.id);
        setTenantData(tenants, currentTenant);
      } else {
        const currentTenantId = sessionService.currentTenantId();

        currentTenant =
          tenants.find((x) => x.tenant.id == currentTenantId) ?? tenants[0];
        setTenantData(tenants, currentTenant);
      }

      Intercom({
        app_id: "bae4dgki",
        user_id: identityId,
        name: name,
        email: email,
        hide_default_launcher: false,
        avatar: picture,
        created_at: dayjs(currentTenant.user.createdAt).unix(),
      });

      const socialSets = await getSocialSets();
      setCurrentSocialSet(socialSets);

      setHasBootstraped(true);

      return true;
    } catch (e) {
      console.error("Bootstrapping error:", e);
      errorReporter.alertErrors(e);
      setHasError(true);
    }
  };

  const onEmailVerified = async () => {
    await AuthService.getTokens(true);

    setCollectEmail(false);
    bootstrap();
  };

  useEffect(() => {
    bootstrap();
  }, [dispatch, hasBoostraped]);

  return (
    <>
      {!hasBoostraped && (
        <>
          {!collectEmail && (
            <>
              {!hasError && <Splash />}
              {hasError && <GenericError />}
            </>
          )}
          {collectEmail && <CollectEmail onCompleted={onEmailVerified} />}

          <ToastHolder />
        </>
      )}

      {hasBoostraped && <Outlet />}
    </>
  );
}

export default BootstrapperLayout;

import { useContext } from "react";
import planQuotaService from "../../../../services/application/plan-quota-service";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import Spinner from "../../../../components/common/Spinner";

export interface TwitterQuotaProps {
  postCount: number;
  date: Date;
  loading: boolean;
}

export default function TwitterQuota({
  postCount,
  date,
  loading,
}: TwitterQuotaProps) {
  const { state } = useContext(GlobalStateContext);

  const subscription = state.currentTenant.subscription;
  const { usagePercentage, remainingUsage, tooltipLabel } =
    planQuotaService.calculateTwitterPostQuotaInfo(
      subscription,
      date,
      postCount
    );

  return (
    <>
      <div
        className="relative size-8 cursor-pointer group hover:scale-125 transition-all"
        data-tooltip-id="tooltip-placeholder"
        data-tooltip-content={tooltipLabel}
      >
        <svg
          className="size-full -rotate-90 transition-all"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="stroke-current text-gray-200"
            strokeWidth="2"
          ></circle>
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="stroke-current text-gray-400"
            strokeWidth="2"
            strokeDasharray="100"
            strokeDashoffset={usagePercentage}
            strokeLinecap="round"
          ></circle>
        </svg>

        {!loading && (
          <div className="flex items-center justify-center absolute top-0 right-0 bottom-0 left-0 p-1.5 transition-all">
            <div className="relative w-full">
              <span className="bg-white rounded-full flex items-center justify-center text-center text-[10px] font-medium font-mono text-gray-500 group-hover:text-gray-700 group-hover:text-xs group-hover:mb-1 transition-all">
                {remainingUsage}
              </span>

              <img
                src="/images/social-platforms/x.svg"
                className="w-3.5 absolute left-[0.26rem] -bottom-2"
              />
            </div>
          </div>
        )}

        {loading && (
          <div className="top-0 left-0 absolute bg-white">
            <Spinner size="8" />
            <img
              src="/images/social-platforms/x.svg"
              className="w-6 absolute left-1 top-1"
            />
          </div>
        )}
      </div>
    </>
  );
}

import Lottie from "react-lottie-player";
import comingSoonAnimation from "./../../assets/animations/coming-soon.json";

interface ComingSoonProps {
  title?: string;
  description?: string;
}

export default function ComingSoon({ title, description }: ComingSoonProps) {
  return (
    <div className="flex flex-col gap-4 items-center justify-center">
      <div className="text-lg/7 font-medium tracking-tight text-gray-950">
        {title ?? "Coming soon"}
      </div>
      <Lottie
        loop
        animationData={comingSoonAnimation}
        play
        style={{ width: 250 }}
      />

      <div className="text-sm/6 text-gray-600 max-w-lg">
        {description ??
          "This feature is coming soon. Stay tuned for more updates."}
      </div>
    </div>
  );
}

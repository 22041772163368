import { AuthTokens, fetchAuthSession, signOut, updateUserAttributes } from "aws-amplify/auth";
import CurrentUser from "./current-user";

class AuthService {
  static async logout(): Promise<void> {
    await signOut();
  }

  static async getTokens(refresh: boolean = false): Promise<AuthTokens | undefined> {
    try {
      const session = await fetchAuthSession({ forceRefresh: refresh });
      return session.tokens;
    } catch (e) {
      console.error("Error getting tokens", e);
      return { idToken: null, accessToken: null };
    }
  }

  static async getUser(): Promise<CurrentUser> {
    const { idToken } = await this.getTokens();

    const user: CurrentUser = {
      email: idToken?.payload?.email?.toString(),
      id: idToken?.payload?.email?.toString(),
      name:
        idToken?.payload?.name?.toString() ??
        idToken?.payload?.given_name?.toString() ??
        idToken?.payload?.email?.toString(),
      picture: idToken?.payload?.picture?.toString(),
    };

    return user;
  }

  static async updateUser(data: object): Promise<void> {
    await updateUserAttributes({
      userAttributes: data,
    });

    const refresh = true;
    await AuthService.getTokens(refresh);
  }
}

export default AuthService;

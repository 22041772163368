import visa from "./../assets/images/cards/dark/1.png";
import mastercard from "./../assets/images/cards/dark/2.png";
import amex from "./../assets/images/cards/dark/22.png";
import discover from "./../assets/images/cards/dark/14.png";
import diners from "./../assets/images/cards/dark/10.png";
import jcb from "./../assets/images/cards/dark/16.png";
import unknown from "./../assets/images/cards/dark/24.png";

const cardMappings = {
  visa: { name: "Visa", image: visa },
  mastercard: { name: "MasterCard", image: mastercard },
  amex: { name: "American Express", image: amex },
  discover: { name: "Discover", image: discover },
  diners: { name: "Diners Club", image: diners },
  jcb: { name: "JCB", image: jcb },
  unknown: { name: "Unknown", image: unknown },
};

export default cardMappings;

import { useContext, useMemo } from "react";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import SocialSet from "../models/entities/social-set";

const useCurrentSocialSet = (): SocialSet | null => {
  const { state } = useContext(GlobalStateContext);

  const socialSet = useMemo(() => {
    return state?.currentSocialSet;
  }, [state.currentSocialSet]);

  return socialSet;
};

export default useCurrentSocialSet;

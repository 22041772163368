/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from "react";
import Post from "../../../models/entities/post";
import DeleteDialog from "../../../components/dialogs/DeleteDialog";
import PostPreviewTileContent from "../../scheduler/calendar/components/PostPreviewTileContent";
import postService from "../../../services/api/post-service";
import CreatePostDialog, {
  OpenComposerParams,
} from "../../../components/dialogs/CreatePostDialog";
import queryNames from "../../../queries/query-names";
import useCurrentSocialSet from "../../../hooks/useCurrentSocialSet";
import dayjs from "dayjs";
import { ScheduleAction } from "../../../models/api-requests/create-post-request";
import { queryClient } from "../../../queries/query-client";

interface PostActionCallbackProps {
  deletePost: (post: Post) => void;
  moveToScheduled: (post: Post) => void;
  publishNow: (post: Post) => void;
  moveToDraft: (post: Post) => void;
  editPost: (post: Post) => void;
  clonePost: (post: Post) => void;
  isDeleting: boolean;
  deletingPost: Post;
  isUpdating: boolean;
}

interface PostActionProps {
  composerRef?: React.RefObject<any>;
  onDeleted?: (post: Post) => void;
  onScheduled?: (post: Post) => void;
  onPublished?: (post: Post) => void;
  onDrafted?: (post: Post) => void;
  onComposerClosed?: () => void;
  onSubmitted?: (posts: Post[]) => void;
  children?: (callbackProps: PostActionCallbackProps) => void | null;
}

const queryKeysToInvalidate = [
  queryNames.queuedPosts,
  queryNames.postsByStatus,
  queryNames.getSingleQueueCounters,
  queryNames.listAllQueuedCount,
  queryNames.postQuotas,
  queryNames.postsByDateRange,
];

export default function PostActions({
  composerRef,
  onDeleted,
  onScheduled,
  onPublished,
  onDrafted,
  onComposerClosed,
  onSubmitted,
  children,
}: PostActionProps) {
  const socialSet = useCurrentSocialSet();
  const [deletingPost, setDeletingPost] = useState<Post>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const deleteDialogRef = useRef(null);

  const deletePost = (post: Post) => {
    setDeletingPost(post);

    deleteDialogRef.current.openDialog();
  };

  const deleteConfirmed = async () => {
    try {
      setIsDeleting(true);

      await postService.delete(deletingPost.id);

      onDeleted && onDeleted(deletingPost);
      invalidateQueries();
    } catch {
      // ignore
    } finally {
      setIsDeleting(false);
    }
  };

  const updatePost = async (
    post: Post,
    scheduleAction: ScheduleAction
  ): Promise<Post> => {
    return postService.update(post.id, {
      title: post.title,
      channelId: post.channel.id,
      config: post.config,
      scheduleAction: scheduleAction,
      postAttachments: post.postAttachments.map((x) => ({
        id: x.id,
        attachmentId: x.attachment.id,
      })),
      scheduledAt: post.scheduledAt,
    });
  };

  const moveToScheduled = async (post: Post) => {
    try {
      setIsUpdating(true);
      await updatePost(post, "Schedule");

      onScheduled && onScheduled(post);
      invalidateQueries();
    } catch {
      // ignore
    } finally {
      setIsUpdating(false);
    }
  };

  const publishNow = async (post: Post) => {
    try {
      setIsUpdating(true);
      await updatePost(post, "PublishNow");

      onPublished && onPublished(post);
      invalidateQueries();
    } catch {
      // ignore
    } finally {
      setIsUpdating(false);
    }
  };

  const moveToDraft = async (post: Post) => {
    try {
      setIsUpdating(true);
      await updatePost(post, "SaveDraft");

      onDrafted && onDrafted(post);
      invalidateQueries();
    } catch {
      // ignore
    } finally {
      setIsUpdating(false);
    }
  };

  const editPost = (post: Post) => {
    openComposer({
      editingPost: post,
    });
  };

  const clonePost = (post: Post) => {
    openComposer({
      cloningPost: post,
      scheduledAt: dayjs()
        .tz(socialSet.timezone)
        .startOf("day")
        .add(1, "day")
        .hour(9)
        .toDate(),
    });
  };

  const openComposer = (params: OpenComposerParams) => {
    composerRef?.current?.openDialog(params);
  };

  const onPostSubmitted = (posts: Post[]) => {
    onSubmitted && onSubmitted(posts);
    invalidateQueries();
  };

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      predicate: (query) =>
        queryKeysToInvalidate.includes(query.queryKey[0] as string),
    });
  };

  return (
    <>
      {children &&
        children({
          deletePost,
          isDeleting,
          deletingPost,
          moveToScheduled,
          publishNow,
          moveToDraft,
          isUpdating,
          editPost,
          clonePost,
        })}

      <CreatePostDialog
        ref={composerRef}
        onClosed={onComposerClosed}
        onPostSubmitted={onPostSubmitted}
      ></CreatePostDialog>

      <DeleteDialog
        title="Delete post"
        message="Are you sure you want to delete the post?"
        ref={deleteDialogRef}
        onConfirm={deleteConfirmed}
      >
        <div className="text-sm mb-4">
          <span>You are about to delete the following post:</span>
        </div>
        {deletingPost && <PostPreviewTileContent post={deletingPost} />}
      </DeleteDialog>
    </>
  );
}

import { useState, useContext, useMemo, useEffect } from "react";
import ToggleBox from "../../../../../components/common/ToggleBox";
import SocialSet from "../../../../../models/entities/social-set";
import GlobalStateContext from "../../../../../state/global-state/GlobalStateContext";
import { UseFormReturn } from "react-hook-form";
import UserUpdateFormInput from "../../../../../models/form-inputs/user-update-input";

interface SocialSetsSectionProps {
  form: UseFormReturn<UserUpdateFormInput, void, UserUpdateFormInput>;
  input: UserUpdateFormInput;
  validate: () => void;
}

export default function SocialSetsSection({ form, input, validate }: SocialSetsSectionProps) {
  const [allSocialSets, setAllSocialSets] = useState(input == null ? false : input?.socialSetIds === null);
  const { state } = useContext(GlobalStateContext);
  const socialSets = useMemo(() => state.socialSets ?? [], [state.socialSets]);
  const socialSetsMap = useMemo(
    () =>
      socialSets.reduce((accumulator, current: SocialSet) => {
        accumulator[current.id] =
          input == null
            ? false
            : input?.socialSetIds == null
            ? true
            : input?.socialSetIds?.includes(current.id) ?? false;
        return accumulator;
      }, {} as Record<string, boolean>),
    [input, socialSets]
  );

  useEffect(() => {
    if (input == null) {
      socialSets.forEach((socialSet) => {
        socialSetsMap[socialSet.id] = false;
      });
      form.setValue("socialSetIds", []);

      return;
    }

    if (input?.socialSetIds?.length > 0) {
      socialSets.forEach((socialSet) => {
        socialSetsMap[socialSet.id] = input.socialSetIds?.includes(socialSet.id) ?? false;
      });

      form.setValue(
        "socialSetIds",
        socialSets.filter((x) => socialSetsMap[x.id]).map((x) => x.id)
      );
    } else {
      socialSets.forEach((socialSet) => {
        socialSetsMap[socialSet.id] = true;
      });

      form.setValue("socialSetIds", null);
    }
  }, [form, input?.socialSetIds, socialSets, socialSetsMap, input]);

  const onAllSocialSetsChanged = (value: boolean) => {
    setAllSocialSets(value);
    Object.keys(socialSetsMap).forEach((key) => (socialSetsMap[key] = value));

    form.setValue("socialSetIds", value ? null : []);
    validate();
  };

  return (
    <ul role="list" className="divide-y divide-gray-100 border border-gray-100 rounded-md mt-6">
      <li className="flex items-center justify-between py-4 px-8 text-sm leading-6">
        <ToggleBox
          value={allSocialSets}
          onChanged={onAllSocialSetsChanged}
          label="All"
          description="Grant access to all current and future social sets."
        />
      </li>
      {socialSets.map((socialSet) => (
        <li key={socialSet.id} className="flex items-center justify-between text-sm leading-6 px-8">
          <ToggleBox
            label={socialSet.name}
            description={`${socialSet.channels.length} channels`}
            disabled={allSocialSets}
            value={socialSetsMap[socialSet.id]}
            onChanged={(value) => {
              socialSetsMap[socialSet.id] = value;
              const existing = form.getValues()?.socialSetIds ?? [];
              const updated = value ? [...existing, socialSet.id] : existing.filter((x) => x !== socialSet.id);

              form.setValue("socialSetIds", updated);
              validate();
            }}
            leading={
              <div className="p-3 pl-0">
                <div
                  style={{ backgroundColor: socialSet.colorHex }}
                  className="flex flex-shrink-0 items-center justify-center text-sm font-medium text-white w-11 h-11 rounded-full mr-4"
                >
                  {socialSet.code}
                </div>
              </div>
            }
          />
        </li>
      ))}
    </ul>
  );
}

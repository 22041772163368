import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import queryNames from "../../../../queries/query-names";
import { invalidateQuery } from "../../../../queries/query-utils";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import EditForm from "./components/EditForm";
import UserProfileFormInput from "../../../../models/form-inputs/user-update-input";
import userService from "../../../../services/api/user-service";

export default function EditProfile() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const user = state.currentTenant.user;

  let content = null;

  const onSubmit = async (input: UserProfileFormInput) => {
    try {
      setIsSubmitting(true);

      await userService.updateProfile(user.id, {
        name: input.name,
        picture: input.picture,
      });

      invalidateQuery([queryNames.tenants]);

      navigate(-1);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (user) {
    const input: UserProfileFormInput = {
      name: user.name,
      email: user.email,
      picture: user.picture,
    };

    content = (
      <EditForm input={input} onSubmit={onSubmit} isSubmitting={isSubmitting} />
    );
  }

  return (
    <>
      <div>
        <div className="flex items-center">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Profile
            </h2>
            <p className="text-sm leading-6 text-gray-500">
              Edit profile settings and picture.
            </p>
          </div>
        </div>

        <div className="my-4 border-t border-gray-200"></div>

        <div className="flex flex-col min-h-[215px] space-y-6 divide-y divide-gray-100 text-sm leading-6">
          {content}
        </div>
      </div>
    </>
  );
}

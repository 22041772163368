import {
  Fragment,
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import Button from "../../../../components/common/Button";
import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import Lottie from "react-lottie-player";
import confettiAnimation from "../../../../assets/animations/confetti.json";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";

export type PlanActivatedType = "SubscriptionActivated" | "PlanChanged";

const ChannelConnectedDialog = forwardRef((_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [planActivatedType, setPlanActivatedType] =
    useState<PlanActivatedType>(null);
  const { state } = useContext(GlobalStateContext);
  const subscription = state.currentTenant?.subscription;

  // Expose the openDialog and closeDialog methods to parent components
  useImperativeHandle(ref, () => ({
    openDialog: openDialog,
    closeDialog: closeDialog,
  }));

  const closeDialog = () => {
    setShowConfetti(false);
    setIsOpen(false);
  };

  const openDialog = (planActivatedType: PlanActivatedType) => {
    setPlanActivatedType(planActivatedType);
    setShowConfetti(true);
    setIsOpen(true);
  };

  return (
    <Transition as={Fragment} show={isOpen}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <TransitionChild
          as="div"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80 z-50 transition-opacity" />

          {showConfetti && (
            <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center z-[60] pointer-events-none">
              <Lottie
                className="w-screen sm:max-w-lg"
                loop={false}
                speed={1.5}
                animationData={confettiAnimation}
                play
                onComplete={() => setShowConfetti(false)}
              />
            </div>
          )}
        </TransitionChild>

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 z-50">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
            <div className="flex justify-end -mt-2">
              <span
                className="cursor-pointer font-extrabold text-xl leading-none text-gray-800 hover:text-gray-600"
                onClick={closeDialog}
              >
                &times;
              </span>
            </div>
            <div className="flex justify-center gap-6 mb-8 mt-2">
              <img
                src="/logo/viraly-logo-square-dense.png"
                className="h-8 w-auto"
              />
            </div>
            <div className="sm:flex sm:items-start mb-8">
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                <DialogTitle
                  as="h3"
                  className="text-center font-semibold leading-6 text-gray-900"
                >
                  {planActivatedType == "SubscriptionActivated" &&
                    "You've activated your subscription"}
                  {planActivatedType == "PlanChanged" &&
                    "Your plan has been successfully updated!"}
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-center text-sm text-gray-500">
                    {planActivatedType == "SubscriptionActivated" &&
                      "You've successfully completed your trial and started your subscription."}
                    {planActivatedType == "PlanChanged" &&
                      `Your plan has been updated to the ${
                        subscription?.items?.[0]?.plan?.product?.name
                      } ${
                        subscription?.items?.[0]?.plan?.interval == "year"
                          ? "yearly"
                          : "monthly"
                      }  plan.`}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 flex justify-end gap-4">
              <Button
                variant="solid"
                color="brand"
                text="Got it!"
                onClick={closeDialog}
              />
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  );
});

export default ChannelConnectedDialog;

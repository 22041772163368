export function delay(timeoutMs: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(resolve, timeoutMs));
}

export function splitPascalCase(data: string): string {
  return data.replace(/([A-Z])/g, " $1").trim();
}

export function isValidUrl(url?: string): boolean {
  if (!url) {
    return false;
  }

  // if (!url.includes("http://") && !url.includes("https://")) {
  //   url = `https://${url}`;
  // }

  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

export function uniqueArray<T>(
  array: T[],
  comparator: (item: T) => unknown
): T[] {
  const seen = new Set();
  return array.filter((item) => {
    const key = comparator(item);
    if (seen.has(key)) {
      return false;
    } else {
      seen.add(key);
      return true;
    }
  });
}

import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import couponSignupService from "../../../services/application/coupon-signup-service";

export default function SignupCoupon() {
  const params = useParams();

  useEffect(() => {
    const couponId = params["couponId"];
    console.log(couponId);

    if (couponId) {
      couponSignupService.setCouponId(couponId);
    }
  }, []);

  return <Navigate to="/signup" replace />;
}

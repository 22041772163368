class ScopeValidatorService {
  private readonly requiredGoogleScopes: string[] = [
    "https://www.googleapis.com/auth/youtube",
    "https://www.googleapis.com/auth/youtube.upload",
  ];
  private readonly requiredPinterestScopes: string[] = [
    "boards:read",
    "user_accounts:read",
    "pins:write",
    "pins:read",
    "boards:write",
  ];

  private readonly requiredTikTokScopes: string[] = ["user.info.basic", "video.publish"];

  validateGoogleScopes(scopes: string[]): void {
    const scopesValid = this.requiredGoogleScopes.every((x) => scopes.includes(x));
    this.throwIfInvlaid(scopesValid);
  }

  validatePinterestScopes(scopes: string[]): void {
    const scopesValid = this.requiredPinterestScopes.every((x) => scopes.includes(x));
    this.throwIfInvlaid(scopesValid);
  }

  validateTikTokScopes(scopes: string[]): void {
    const scopesValid = this.requiredTikTokScopes.every((x) => scopes.includes(x));
    this.throwIfInvlaid(scopesValid);
  }

  private throwIfInvlaid(scopesValid: boolean): void {
    if (!scopesValid) {
      throw new Error("Invalid scopes");
    }
  }
}

export default new ScopeValidatorService();

import dayjs from "dayjs";
import ViewRange from "../modules/scheduler/calendar/models/view-range";
import { ViewType } from "../modules/scheduler/calendar/models/view-type";

export default function todayViewRange(
  timezone: string,
  viewType?: ViewType
): ViewRange {
  const today = dayjs().tz(timezone);

  // For the monthly view
  const startOfMonth = today.startOf("month");
  const endOfMonth = today.endOf("month");
  const startOfMonthFormatted = startOfMonth.format("YYYY-MM-DD");
  const endOfMonthFormatted = endOfMonth.format("YYYY-MM-DD");
  const monthHeaderLabel = today.format("MMM YYYY");

  // For the weekly view
  const startOfWeek = today.startOf("isoWeek");
  const endOfWeek = today.endOf("isoWeek");
  const startOfWeekFormatted = today.startOf("isoWeek").format("YYYY-MM-DD");
  const endOfWeekFormatted = today.endOf("isoWeek").format("YYYY-MM-DD");
  const weekHeaderLabel = `${startOfWeek.format("MMM DD")} - ${endOfWeek.format(
    "MMM DD"
  )}, ${startOfWeek.format("YYYY")}`;

  const result = {
    monthly: {
      year: today.year(),
      month: today.month(),
      startDate: startOfMonthFormatted,
      endDate: endOfMonthFormatted,
      headerLabel: monthHeaderLabel,
    },
    weekly: {
      year: today.year(),
      week: today.isoWeek(),
      startDate: startOfWeekFormatted,
      endDate: endOfWeekFormatted,
      headerLabel: weekHeaderLabel,
    },
    summary: {
      timezoneOffset: today.utcOffset() / 60,
      startDate:
        viewType == "week" ? startOfWeekFormatted : startOfMonthFormatted,
      endDate: viewType == "week" ? endOfWeekFormatted : endOfMonthFormatted,
    },
  };

  return result;
}

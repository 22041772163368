import { ViewType } from "../../modules/scheduler/calendar/models/view-type";
import localBrowserStorage from "../browser-storage/local-browser-storage";

class CalendarViewService {
  getCalendarView(isMedium: boolean): ViewType {
    const value = localBrowserStorage.getItem("calendarView");

    if (value) {
      return (value as ViewType) ?? "month";
    }

    return isMedium ? "month" : "week";
  }

  setCalendarView(view: ViewType): void {
    localBrowserStorage.setItem("calendarView", view);
  }
}

export default new CalendarViewService();

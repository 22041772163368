import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { FaceSmileIcon } from "@heroicons/react/24/outline";
import EmojiPicker, { SkinTonePickerLocation } from "emoji-picker-react";

export interface EmojisProps {
  onEmojiClick: (emojiObject) => void;
}

export default function Emojis({ onEmojiClick }: EmojisProps) {
  return (
    <Popover className="relative">
      <PopoverButton className="flex items-center justify-center gap-1 rounded-full text-gray-400 hover:text-amber-500 group focus:ring-0 focus:outline-none">
        <FaceSmileIcon className="h-5 w-5 " />
        {/* <span className="text-xs font-medium ">Emoji</span> */}
      </PopoverButton>

      <PopoverPanel
        transition
        anchor="top end"
        className="z-50 overflow-hidden rounded-lg shadow-lg transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
      >
        <div className="">
          <div className="relative">
            <EmojiPicker
              onEmojiClick={onEmojiClick}
              autoFocusSearch={false}
              lazyLoadEmojis={true}
              searchDisabled={false}
              skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
              reactionsDefaultOpen={false}
            />
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}

import { Link } from "react-router-dom";
import SocialSetPicker from "./SocialSetPicker";

export interface SidebarContentProps {
  onClose: () => void;
}

export default function SidebarContent({ onClose }: SidebarContentProps) {
  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white shadow p-4 rounded-tr-2xl rounded-br-2xl">
      <nav className="flex flex-1 flex-col h-full">
        <ul role="list" className="flex flex-1 flex-col gap-y-7 h-full">
          <li>
            <Link
              to="/scheduler"
              className="flex h-16 shrink-0 items-center"
              onClick={onClose}
            >
              <img
                className="m-auto"
                width="130"
                src="/logo/viraly-logo-rect.png"
                alt="Viraly"
              />
            </Link>
          </li>

          <li className="flex flex-col flex-1 overflow-y-auto ">
            <SocialSetPicker
              onConnectChannelClick={onClose}
              onCreateSocialSetClick={onClose}
            />
          </li>
        </ul>
      </nav>
    </div>
  );
}

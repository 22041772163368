/* eslint-disable @typescript-eslint/no-explicit-any */
import pinterestService from "../../../../../services/api/pinterest-service";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import Button from "../../../../../components/common/Button";
import { useEffect, useMemo, useRef, useState } from "react";
import PinterestBoard from "../../../../../models/pinterest/pinterest-board";
import InputField from "../../../../../components/form/InputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../../../queries/query-names";
import Spinner from "../../../../../components/common/Spinner";

export interface BoardSelectorProps {
  channelId: string;
  boardId: string;
  onBoardSelected: (board: PinterestBoard) => void;
}

const schema = yup
  .object({
    search: yup.string(),
    boardName: yup.string(),
  })
  .required();

export default function BoardSelector({
  channelId,
  boardId,
  onBoardSelected,
}: BoardSelectorProps) {
  const buttonRef = useRef();
  const [searchValue, setSearchValue] = useState<string>();
  const [boardName, setBoardName] = useState<string>();
  const [creatingBoard, setCreatingBoard] = useState<boolean>();
  // const [refetching, setRefetching] = useState<boolean>();
  const [selectedBoard, setSelectedBoard] = useState<PinterestBoard>();

  const form = useForm({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      search: "",
      boardName: "",
    },
  });

  const { data, refetch } = useQuery({
    queryKey: [queryNames.pinterestBoardsByChannelId, channelId],
    queryFn: () => pinterestService.getBoards(channelId),
    staleTime: Infinity,
  });

  useEffect(() => {
    const board = data?.find((x) => x.id == boardId);
    setSelectedBoard(board);
  }, [boardId, data]);

  const filteredBoards = useMemo(() => {
    const filteredBoards =
      (!searchValue
        ? data
        : data?.filter((x) =>
            x.name.toLocaleLowerCase().includes(searchValue)
          )) ?? [];
    return filteredBoards;
  }, [data, searchValue]);

  const onSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const onBoardNameChanged = (event) => {
    setBoardName(event.target.value);
  };

  const createBoard = async () => {
    setCreatingBoard(true);

    try {
      await pinterestService.createBoard(channelId, boardName);
      await refetchBoards();

      form.setValue("boardName", "");
    } finally {
      setCreatingBoard(false);
    }
  };

  const refetchBoards = async () => {
    const currentBoardsCount = data.length;

    let newBoardsCount = currentBoardsCount;
    let newBoard: PinterestBoard = null;

    while (newBoardsCount <= currentBoardsCount) {
      await delay(1500);
      await refetch().then((x) => {
        newBoardsCount = x.data.length;
        x.data.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
        newBoard = x.data[0];
      });
    }

    if (newBoard) {
      onSelectBoard(newBoard);
    }
  };

  const delay = (ms: number): Promise<NodeJS.Timeout> => {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(null);
      }, ms)
    );
  };

  const onSelectBoard = (board: PinterestBoard) => {
    setSelectedBoard(board);
    onBoardSelected(board);

    if (buttonRef.current) {
      (buttonRef!.current! as any).click();
    }
  };

  // const reloadBoards = async () => {
  //   try {
  //     setRefetching(true);
  //     await refetch();
  //   } finally {
  //     setRefetching(false);
  //   }
  // };

  return (
    <>
      <Popover
        as="div"
        className={clsx(
          "flex items-center gap-1",
          selectedBoard ? "w-full" : null
        )}
      >
        <PopoverButton as="div" className="w-full" ref={buttonRef}>
          {selectedBoard ? (
            <div className="flex gap-2 w-full">
              <div className="relative flex items-center gap-2 w-full rounded-md bg-white px-2 py-2 shadow-xs hover:bg-gray-50 cursor-pointer">
                <div className="flex-shrink-0">
                  {selectedBoard.media.image_cover_url ? (
                    <img
                      className="h-8 w-8 object-cover rounded-md shadow-sm"
                      src={selectedBoard.media.image_cover_url}
                    />
                  ) : (
                    <div className="h-8 w-8 object-cover rounded-md shadow-sm bg-gray-200"></div>
                  )}
                </div>
                <div className="min-w-0 flex-1">
                  <p className="text-sm font-normal text-gray-900 text-ellipsis">
                    {selectedBoard.name}
                  </p>
                </div>

                {creatingBoard && (
                  <div>
                    <Spinner size="4" />
                  </div>
                )}
              </div>
            </div>
          ) : (
            !boardId && (
              <div className="w-full flex justify-end">
                <Button
                  text="Select Pinterest board"
                  variant="solid"
                  color="brand"
                  type="button"
                />
              </div>
            )
          )}
        </PopoverButton>

        <PopoverPanel
          transition
          anchor="top end"
          className="z-50 bg-white overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 p-2 transition duration-200 ease-in-out [--anchor-gap:0] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
        >
          <div className="">
            <div className="flex items-center gap-2">
              <div className="flex-1">
                <InputField
                  label="Search your boards"
                  placeholder="Search your boards"
                  name="title"
                  hideLabel={true}
                  formReturn={form}
                  onChange={onSearch}
                />
              </div>
              {/* <div className="mr-2">
                      <Button
                        icon={<ArrowPathIcon className="w-5 text-gray-500" />}
                        variant="text"
                        type="button"
                        text=""
                        loading={refetching}
                        onClick={reloadBoards}
                      ></Button>
                    </div> */}
            </div>

            <div className="mt-2">
              <ul className="flex flex-col divide-y max-h-60 overflow-auto">
                {filteredBoards.map((board) => (
                  <li
                    key={board.id}
                    onClick={() => onSelectBoard(board)}
                    className="relative flex items-center gap-2 bg-white px-2 py-2 shadow-sm hover:bg-gray-50 cursor-pointer"
                  >
                    <div className="flex-shrink-0">
                      {board.media.image_cover_url ? (
                        <img
                          className="h-8 w-8 object-cover rounded-md shadow-sm"
                          src={board.media.image_cover_url}
                        />
                      ) : (
                        <div className="h-8 w-8 object-cover rounded-md shadow-sm bg-gray-200"></div>
                      )}
                    </div>
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-normal text-gray-900 text-ellipsis">
                        {board.name}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-2 flex gap-2 items-center">
              <InputField
                label="New board name"
                placeholder="New board name"
                name="boardName"
                disabled={creatingBoard}
                hideLabel={true}
                formReturn={form}
                onChange={onBoardNameChanged}
              />

              <Button
                color="brand"
                loading={creatingBoard}
                disabled={!boardName?.length}
                text="Create"
                type="button"
                variant="text"
                onClick={createBoard}
              />
            </div>
          </div>
        </PopoverPanel>
      </Popover>
    </>
  );
}

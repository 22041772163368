import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import userService from "../../../../services/api/user-service";
import queryNames from "../../../../queries/query-names";
import { useQuery } from "@tanstack/react-query";
import User from "../../../../models/entities/user";
import DefaultAvatar from "../../../../components/common/DefaultAvatar";
import useCurrentTenant from "../../../../hooks/useCurrentTenant";

export interface UploadedByPickerProps {
  selection: User[];
  onChanged: (value: User[]) => void;
}

export default function UploadedByPicker({
  selection,
  onChanged,
}: UploadedByPickerProps) {
  const currentUser = useCurrentTenant()?.user;

  const { data: users } = useQuery({
    queryKey: [queryNames.users],
    queryFn: userService.list,
  });

  const toggleUser = (user: User) => {
    let updated = [];

    if (selection.find((x) => x.id === user.id)) {
      updated = selection.filter((x) => x.id != user.id);
    } else {
      updated = [...selection, user];
    }

    onChanged(updated);
  };

  const allUploads = () => {
    onChanged([]);
  };

  const myUploads = () => {
    onChanged([...users.filter((x) => x.id === currentUser?.id)]);
  };

  const othersUploads = () => {
    onChanged([...users.filter((x) => x.id !== currentUser?.id)]);
  };

  return (
    <Menu as="div" className="flex relative text-left">
      {({ open }) => (
        <>
          <MenuButton className="flex w-full justify-start items-center gap-2 rounded-md bg-white px-3 py-2 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50">
            <div className="w-34 overflow-hidden whitespace-nowrap flex-1 text-sm text-gray-900 text-ellipsis text-left">
              {!selection.length ? (
                <div>All Uploads</div>
              ) : (
                <>
                  {selection.length == 1 &&
                    selection[0].id === currentUser?.id && (
                      <span>My Uploads</span>
                    )}
                  {selection.length == 1 &&
                    selection[0].id !== currentUser?.id && (
                      <span>{selection[0].name}</span>
                    )}

                  {selection.length > 1 && (
                    <span>
                      {selection.length == 1
                        ? `${selection[0].name.split(" ")[0]}`
                        : `${selection[0].name.split(" ")[0]} + ${
                            selection.length - 1
                          } others`}
                    </span>
                  )}
                </>
              )}
            </div>

            {open ? (
              <ChevronUpIcon className=" h-5 w-5 text-gray-400" />
            ) : (
              <ChevronDownIcon className=" h-5 w-5 text-gray-400" />
            )}
          </MenuButton>
          <MenuItems
            transition
            anchor="bottom end"
            className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-50 w-72 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <ul className="py-1 px-1 ">
              <li
                className="group text-sm flex items-center justify-between py-2 px-2 rounded-md hover:bg-gray-50 cursor-pointer"
                onClick={allUploads}
              >
                <div className="flex-1 flex items-center justify-between min-w-0 gap-x-4">
                  <div className="text-sm font-normal leading-0 text-gray-800 text-ellipsis">
                    Show All Uploads
                  </div>
                </div>
              </li>
              <li
                className="group text-sm flex items-center justify-between py-2 px-2 rounded-md hover:bg-gray-50 cursor-pointer"
                onClick={myUploads}
              >
                <div className="flex-1 flex items-center min-w-0 gap-x-4">
                  <div className="text-sm font-normal leading-0 text-gray-800 text-ellipsis">
                    Show My Uploads Only
                  </div>
                </div>
              </li>
              {users && users.length > 1 && (
                <li
                  className="group text-sm flex items-center justify-between py-2 px-2 rounded-md hover:bg-gray-50 cursor-pointer"
                  onClick={othersUploads}
                >
                  <div className="flex-1 flex items-center min-w-0 gap-x-4">
                    <div className="text-sm font-normal leading-0 text-gray-800 text-ellipsis">
                      Show Others' Uploads
                    </div>
                  </div>
                </li>
              )}
            </ul>

            <ul className="py-1 px-1">
              {users &&
                users.map((user) => (
                  <div key={user.id}>
                    {
                      <li
                        onClick={() => toggleUser(user)}
                        className={clsx(
                          "group text-sm flex items-center justify-between py-2 px-2 rounded-md hover:bg-gray-50 cursor-pointer"
                        )}
                      >
                        <div className="flex-1 flex items-center min-w-0 gap-x-4">
                          {user.picture && (
                            <img
                              referrerPolicy="no-referrer"
                              className="h-6 w-6 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                              src={user.picture}
                            />
                          )}
                          {!user.picture && <DefaultAvatar />}
                          <div className="max-w-[190px] overflow-hidden whitespace-nowrap flex-1 text-sm leading-0 text-gray-800 text-ellipsis">
                            {user.name}
                          </div>
                          <div className="ml-auto">
                            <input
                              type="checkbox"
                              readOnly={true}
                              checked={
                                !!selection.find((x) => x.id === user.id)
                              }
                              className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600 focus:ring-transparent cursor-pointer"
                            />
                          </div>
                        </div>
                      </li>
                    }
                  </div>
                ))}
            </ul>
          </MenuItems>
        </>
      )}
    </Menu>
  );
}

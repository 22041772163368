/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from "../../../../components/common/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from "../../../../components/form/InputField";
import { useNavigate } from "react-router-dom";
import SocialSetUpdateFormInput from "../../../../models/form-inputs/social-set-update-input";
import { socialSetColors } from "../../../../utils/social-set-utils";
import ColorPickerField from "../../../../components/form/ColorPickerField";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../../queries/query-names";
import timezoneService from "../../../../services/api/timezone-service";
import ComboBoxField, {
  ComboBoxItem,
} from "../../../../components/form/ComboBoxField";

const schema = yup
  .object({
    name: yup.string().required(),
    code: yup.string().required().length(2),
    colorHex: yup.string().required(),
    timezone: yup.string().required(),
  })
  .required();

export default function EditForm({
  input,
  onSubmit,
  isSubmitting,
}: {
  initialColor?: string;
  input?: SocialSetUpdateFormInput;
  onSubmit: (input: SocialSetUpdateFormInput) => void;
  isSubmitting: boolean;
}) {
  const navigate = useNavigate();
  const [codeEdited, setCodeEdited] = useState(false);

  const { data: timezones } = useQuery({
    queryKey: [queryNames.timezones],
    queryFn: timezoneService.list,
    refetchOnMount: false,
    staleTime: Infinity,
  });

  const timezoneOptions = timezones?.map(
    (timezone) =>
      ({
        value: timezone.id,
        label: timezone.displayName,
        caption: timezone.baseUtcOffset.startsWith("-")
          ? timezone.baseUtcOffset.substring(0, 6)
          : `+${timezone.baseUtcOffset.substring(0, 5)}`,
      } as ComboBoxItem)
  );

  const form = useForm<SocialSetUpdateFormInput>({
    resolver: yupResolver(schema) as any,
    mode: "all",
    defaultValues: input,
  });

  const submitForm = () => {
    form.handleSubmit(onSubmit)();
  };

  const generateCode = (text: string): string => {
    const words = text.trim().toUpperCase().split(" ");
    return !text
      ? ""
      : words.length > 1
      ? `${words[0][0]}${words[words.length - 1][0]}`
      : words[0].length > 1
      ? `${words[0][0]}${words[0][1]}`
      : `${words[0][0]}${words[0][0]}`;
  };

  const codeChanged = (event) => {
    const code = event.target?.value?.toUpperCase() ?? "";
    form.setValue("code", code);
    setCodeEdited(!!code);
  };

  const nameChanged = (event) => {
    const name = event.target.value;

    if (!codeEdited) {
      const value = generateCode(name);
      form.setValue("code", value);
    }
  };

  return (
    <>
      <div className="border-b border-gray-200 bg-white pb-5">
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="truncate">
            <h3 className="text-base font-semibold leading-7 text-gray-900 truncate">
              {input?.name ?? "Social set"}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {input?.name
                ? "Update social set details"
                : "Create new social set"}
            </p>
          </div>
        </div>
      </div>

      <form onSubmit={form.handleSubmit(onSubmit)} className="flex-1">
        <div className="md:max-w-sm">
          <div className="py-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <InputField
                  label="Name"
                  name="name"
                  placeholder="Enter a name for the social set"
                  formReturn={form}
                  onChange={nameChanged}
                />
              </div>

              <div className="sm:col-span-6">
                <ComboBoxField
                  placeholder="Select a timezone from the list"
                  label="Timezone"
                  name="timezone"
                  formReturn={form}
                  items={timezoneOptions}
                />
              </div>

              <div className="sm:col-span-3 hidden">
                <InputField
                  label="Code"
                  name="code"
                  formReturn={form}
                  maxLength={2}
                  onChange={codeChanged}
                />
              </div>

              <div className="sm:col-span-6">
                <ColorPickerField
                  label="Color"
                  name="colorHex"
                  colors={socialSetColors.map((x) => x.hex)}
                  form={form}
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="border-t border-gray-200 bg-white pt-5 -mx-4 sm:-mx-6">
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap  px-4 sm:px-6">
          <div className=""></div>
          <div className="flex-shrink-0 gap-2 flex">
            <Button
              variant="text"
              color="brand"
              className="w-24"
              text="Cancel"
              onClick={() => navigate(-1)}
            />
            <Button
              text="Save"
              variant="solid"
              color="brand"
              className="w-24"
              type="button"
              onClick={submitForm}
              loading={isSubmitting}
            />
          </div>
        </div>
      </div>
    </>
  );
}

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";

const data: KeyValuePair[] = [
  { key: "youtube", label: "Standard YouTube License" },
  { key: "creativeCommon", label: "Creative Commons - Attribution" },
];

export interface LicenseSelectorProps {
  value?: string;
  onValueSelected: (value?: string) => void;
}

interface KeyValuePair {
  key: string;
  label: string;
}

export default function LicenseSelector({
  value,
  onValueSelected,
}: LicenseSelectorProps) {
  const [searchValue, setSearchValue] = useState<string>();
  const [selectedValue, setSelectedValue] = useState<KeyValuePair>(null);

  useEffect(() => {
    const item = data.find((x) => x.key == value);
    if (item) {
      setSelectedValue(item);
    }
  }, [value]);

  const filteredItems = useMemo(() => {
    const filteredItems =
      (!searchValue
        ? data
        : data?.filter((x) =>
            x.label.toLocaleLowerCase().includes(searchValue)
          )) ?? [];
    return filteredItems;
  }, [searchValue]);

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const onSelectItem = (item: KeyValuePair) => {
    if (item) {
      setSelectedValue(item);
      onValueSelected(item.key);
    }
  };

  return (
    <>
      <Combobox
        as="div"
        value={selectedValue}
        onChange={(item: KeyValuePair) => {
          onSearch("");
          onSelectItem(item);
        }}
      >
        <div className="relative">
          <ComboboxButton className="w-full">
            <ComboboxInput
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-xs md:text-sm sm:leading-6"
              onChange={(event) => onSearch(event.target.value)}
              onBlur={() => onSearch("")}
              displayValue={(item: KeyValuePair) => item?.label}
            />

            <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-1 focus:outline-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
            </div>
          </ComboboxButton>

          {filteredItems.length > 0 && (
            <ComboboxOptions
              anchor="top"
              transition
              className="w-[var(--input-width)] [--anchor-gap:var(--spacing-1)] empty:invisible transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 z-50  !max-h-60 rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {filteredItems.map((item) => (
                <ComboboxOption
                  key={item.key}
                  value={item}
                  className={({ focus }) =>
                    clsx(
                      "relative cursor-default select-none py-1 pl-2 pr-2 text-xs md:text-sm mx-1 md:mx-2 rounded-md",
                      focus ? "bg-primary-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ focus, selected }) => (
                    <>
                      <span
                        className={clsx(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {item.label}
                      </span>

                      {selected && (
                        <span
                          className={clsx(
                            "hidden md:flex absolute inset-y-0 right-0 items-center pr-2",
                            focus ? "text-white" : "text-primary-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>
    </>
  );
}

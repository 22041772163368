import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Outlet } from "react-router-dom";
import GlobalStateProvider from "../../state/global-state/GlobalStateProvider";
import { queryClient } from "../../queries/query-client";
import Splash from "../MainLayout/components/Splash";
import UnauthenticatedLayout from "../UnauthenticatedLayout/UnauthenticatedLayout";
import clsx from "clsx";
import { useEffect } from "react";
import {
  authenticatorFormFields,
  authenticatorSignupAttributes,
  getComponents,
} from "../../modules/auth/signup/authenticator-config";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
// import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { createIDBPersister } from "../../queries/indexdb-persister";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function AuthenticatorLayout() {
  const { authStatus, route, toSignIn, toSignUp } = useAuthenticator(
    (context) => [
      context.authStatus,
      context.route,
      context.toSignIn,
      context.toSignUp,
    ]
  );

  useEffect(() => {
    if (route == "signUp") {
      toSignIn();
    }
  }, [route, toSignIn]);

  const components = getComponents(toSignIn, toSignUp);
  const persister = createIDBPersister();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#E03058",
      },
    },
  });

  return (
    <>
      {authStatus === "configuring" && <Splash />}
      {authStatus !== "authenticated" ? (
        <UnauthenticatedLayout>
          <div className={clsx(route)}>
            <Authenticator
              hideSignUp={true}
              signUpAttributes={authenticatorSignupAttributes}
              formFields={authenticatorFormFields}
              components={components}
            />
          </div>
        </UnauthenticatedLayout>
      ) : (
        <>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister, maxAge: 1000 * 60 * 60 * 24 * 1 }} // 1 day
          >
            <GlobalStateProvider>
              <ThemeProvider theme={theme}>
                <Outlet />
              </ThemeProvider>
            </GlobalStateProvider>
          </PersistQueryClientProvider>
        </>
      )}
    </>
  );
}

import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import queryNames from "../../../../queries/query-names";
import { invalidateQuery } from "../../../../queries/query-utils";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import EditForm from "./components/EditForm";
import TenantUpdateFormInput from "../../../../models/form-inputs/tenant-form-input";
import tenantService from "../../../../services/api/tenant-service";

export default function EditWorkspace() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const tenant = state.currentTenant;

  let content = null;

  const onSubmit = async (input: TenantUpdateFormInput) => {
    try {
      setIsSubmitting(true);

      await tenantService.updateTenant(tenant.tenant.id, {
        name: input.name,
      });

      invalidateQuery([queryNames.tenants]);

      navigate(-1);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (tenant) {
    const input: TenantUpdateFormInput = {
      name: tenant.tenant.name,
    };

    content = (
      <EditForm input={input} onSubmit={onSubmit} isSubmitting={isSubmitting} />
    );
  }

  return (
    <>
      <div>
        <div className="flex items-center">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Workspace
            </h2>
            <p className="text-sm leading-6 text-gray-500">
              Edit workspace settings.
            </p>
          </div>
        </div>

        <div className="my-4 border-t border-gray-200"></div>

        <div className="flex flex-col min-h-[465px] space-y-6 divide-y divide-gray-100 text-sm leading-6">
          {content}
        </div>
      </div>
    </>
  );
}

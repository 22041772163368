import Lottie from "react-lottie-player";
import notFoundAnimation from "../../assets/animations/404.json";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

export interface UnauthorizedProps {
  flat?: boolean;
}

export default function Unauthorized({ flat }: UnauthorizedProps) {
  const navigate = useNavigate();

  return (
    <div
      className={clsx(
        "flex flex-col gap-8 items-center max-w-md mx-auto p-8 sm:p-14 sm:max-w-lg bg-white rounded-xl relative",
        !flat && "shadow"
      )}
    >
      <div className="text-3xl font-bold tracking-tight text-gray-900">
        Oops! You don’t have access.
      </div>
      <div className="mx-auto mt-6 text-md text-gray-600 text-center">
        It looks like you don’t have permission to view this page. You might
        want to check with the owner of your workspace, or feel free to contact
        us if you think this is a mistake.
      </div>
      <div>
        <Lottie
          loop
          animationData={notFoundAnimation}
          play
          style={{ height: 300 }}
        />
      </div>
      <div>
        <Button
          onClick={() => navigate("/scheduler/calendar")}
          variant="solid"
          color="slate"
          text="Go Home"
        />
      </div>

      <svg
        viewBox="0 0 1024 1024"
        aria-hidden="true"
        className="absolute left-1/2 top-0 -z-10 h-[32rem] w-[32rem] sm:h-[38rem] sm:w-[38rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
      >
        <circle
          r={512}
          cx={512}
          cy={512}
          fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
          fillOpacity="0.7"
        />
        <defs>
          <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#E935C1" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}

import {
  GlobeAsiaAustraliaIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import FacebookVideoFrame from "./FacebookVideoFrame";
import { PostInstance } from "../../models/post-instance";
import AttachmentSlot from "../../models/attachment-slot";

export interface FacebookStoryPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function FacebookStoryPreview({
  postInstance,
  attachments,
}: FacebookStoryPreviewProps) {
  const channel = postInstance.channel;

  if (!attachments.length) {
    return null;
  }

  const attachmentSlot = attachments[0];

  return (
    <>
      <div className="flex flex-col relative rounded-lg w-full overflow-hidden aspect-[9/16]">
        {attachmentSlot.isPhoto ? (
          <img
            className="w-full h-full aspect-[9/16] object-cover"
            src={attachmentSlot.preview.url}
          />
        ) : (
          <FacebookVideoFrame slot={attachmentSlot} />
        )}

        <div className="absolute top-0 left-0 right-0 z-10">
          <div className="bg-white/60 w-11/12 h-[2px] mx-auto mt-2 rounded-sm"></div>
          <div className="flex flex-row items-center gap-2 px-2 py-2">
            <div>
              <img
                referrerPolicy="no-referrer"
                className="h-8 w-8 flex-none rounded-full bg-gray-50"
                src={channel.pictureUrl}
              />
            </div>
            <div className="flex-1 flex flex-row items-center gap-2">
              <div className="text-xs font-bold leading-4 text-white">
                {channel.name}
              </div>
              <span className="text-[13px] leading-4 text-white font-extralight">
                {" "}
                1m
              </span>
              <GlobeAsiaAustraliaIcon className="w-3.5 text-white" />
            </div>
            <div className="text-white flex gap-2">
              <EllipsisHorizontalIcon className="h-5 text-white font-extrabold" />
              <XMarkIcon className="h-5 text-white" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { Description, Field, Label, Switch } from "@headlessui/react";
import DiscloseInfoBox from "./DiscloseInfoBox";
import { useMemo } from "react";

export interface DiscloseContentProps {
  discloseContent: boolean;
  promotionalContent: boolean;
  paidPartnership: boolean;
  privacyStatus: string;
  discloseContentChanged: (discloseContent: boolean) => void;
  promotinalContentChanged: (promotionalContent: boolean) => void;
  paidPartnershipChanged: (paidPartnership: boolean) => void;
}

export default function DiscloseContent({
  discloseContent,
  promotionalContent,
  paidPartnership,
  privacyStatus,
  discloseContentChanged,
  promotinalContentChanged,
  paidPartnershipChanged,
}: DiscloseContentProps) {
  const disabled = useMemo(() => privacyStatus == "SELF_ONLY", [privacyStatus]);

  return (
    <>
      <div>
        <Field className="flex items-center justify-between">
          <div className="flex flex-grow flex-col">
            <div className="flex justify-between items-center">
              <Label as="span" passive className="text-xs font-semibold text-gray-700">
                Disclose video content
              </Label>
              <Switch
                checked={discloseContent}
                onChange={() => discloseContentChanged(!discloseContent)}
                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-primary-600 focus:ring-0 data-[checked]:bg-primary-600"
              >
                <span className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5" />
              </Switch>
            </div>
            {discloseContent && (
              <>
                {promotionalContent && !paidPartnership && (
                  <DiscloseInfoBox text="Your video will be labeled as 'Promotional content'. This cannot be changed once your video is posted." />
                )}
                {((!promotionalContent && paidPartnership) || (promotionalContent && paidPartnership)) && (
                  <DiscloseInfoBox text="Your video will be labeled as 'Paid partnership'. This cannot be changed once your video is posted." />
                )}
              </>
            )}
            <Description as="span" className="text-xs text-gray-500 mt-2">
              Turn on to disclose that this video promotes goods or services in exchange for something of value. Your
              video could promote yourself, a third party, or both.
            </Description>
          </div>
        </Field>

        {discloseContent && (
          <>
            <fieldset className="">
              <div className="">
                <div className="relative flex items-start pb-4 pt-3.5">
                  <div className="min-w-0 flex-1 text-sm leading-6">
                    <label htmlFor="brandOrganic" className="text-xs font-semibold text-gray-700 cursor-pointer">
                      Your brand
                    </label>
                    <p className="text-xs text-gray-500">
                      You are promoting yourself or your own business. This video will be classified as Brand Organic.
                    </p>
                  </div>
                  <div className="ml-3 flex h-6 items-center">
                    <input
                      checked={promotionalContent}
                      onChange={() => promotinalContentChanged(!promotionalContent)}
                      id="brandOrganic"
                      name="brandOrganic"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer"
                    />
                  </div>
                </div>

                <div className="relative flex items-start pb-4">
                  <div className="min-w-0 flex-1 text-sm leading-6">
                    <label htmlFor="brandedContent" className="text-xs font-semibold text-gray-700 cursor-pointer">
                      Branded content
                    </label>
                    <p className="text-xs text-gray-500">
                      You are promoting another brand or a third party. This video will be classified as Branded
                      Content.
                    </p>
                  </div>
                  <div className="ml-3 flex h-6 items-center">
                    <input
                      checked={paidPartnership}
                      onChange={() => paidPartnershipChanged(!paidPartnership)}
                      disabled={disabled}
                      data-tooltip-id="tooltip-placeholder"
                      data-tooltip-content={
                        disabled ? `To disclose your post as branded content,\n your post must be set to "Public".` : ""
                      }
                      id="brandedContent"
                      name="brandedContent"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer disabled:bg-gray-50 disabled:opacity-80 disabled:cursor-not-allowed"
                    />
                  </div>
                </div>

                <div className="text-xs text-gray-500">
                  {promotionalContent && !paidPartnership && (
                    <div>
                      By posting, you agree to TikTok's{" "}
                      <a
                        href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                        target="_blank"
                        className="text-primary-600"
                      >
                        Music Usage Confirmation
                      </a>
                      .
                    </div>
                  )}
                  {paidPartnership && (
                    <div>
                      By posting, you agree to TikTok's{" "}
                      <a
                        href="https://www.tiktok.com/legal/page/global/bc-policy/en"
                        target="_blank"
                        className="text-primary-600"
                      >
                        Branded Content Policy
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                        target="_blank"
                        className="text-primary-600"
                      >
                        Music Usage Confirmation
                      </a>
                      .
                    </div>
                  )}
                </div>
              </div>
            </fieldset>
          </>
        )}
      </div>
    </>
  );
}

import { useContext } from "react";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import { PlanTier } from "../models/stripe/subscription";

export default function useTieredAccess(tiers: PlanTier[]) {
  const {
    state: { currentTenant },
  } = useContext(GlobalStateContext);

  const authorizeTier = () => {
    const currentTier = currentTenant?.subscription?.items?.[0]?.plan?.product
      ?.metadata?.tier as PlanTier;

    if (!tiers.length) {
      return true;
    }

    if (currentTenant?.subscription == null) {
      return false;
    }

    return tiers.includes(currentTier);
  };

  return authorizeTier();
}

import clsx from "clsx";
import { useContext } from "react";
import planQuotaService from "../../../../services/application/plan-quota-service";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";

export interface ChannelsQuotaProps {}

export default function ChannelsQuota() {
  const { state } = useContext(GlobalStateContext);

  const socialSets = state.socialSets ?? [];
  const channels = socialSets.flatMap((x) => x.channels);
  const subscription = state.currentTenant.subscription;
  const channelsQuota = planQuotaService.getChannelsLimit(subscription);
  const channelsCount = channels.length;
  const usagePercentage = Math.min(
    Math.ceil((channelsCount / channelsQuota) * 100),
    100
  );
  const usageLabel =
    usagePercentage <= 34 ? "low" : usagePercentage > 67 ? "high" : "medium";

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <span
            className={clsx(
              "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium",
              usageLabel == "low" && "bg-green-100 text-green-700",
              usageLabel == "medium" && "bg-amber-100 text-amber-700",
              usageLabel == "high" && "bg-red-100 text-red-700"
            )}
          >
            <svg
              viewBox="0 0 6 6"
              aria-hidden="true"
              className={clsx(
                "h-1.5 w-1.5 mr-1.5",
                usageLabel == "low" && "fill-green-500",
                usageLabel == "medium" && "fill-amber-500",
                usageLabel == "high" && "fill-red-500"
              )}
            >
              <circle r={3} cx={3} cy={3} />
            </svg>
            {channelsCount} of {channelsQuota} channels{" "}
            <span className="hidden sm:inline">&nbsp;connected</span>
          </span>
        </div>

        <div className="max-w-[40%] sm:max-w-40 w-full bg-gray-200 rounded-full h-1.5">
          <div
            className={clsx(
              "h-1.5 rounded-full",
              usageLabel == "low" && "bg-green-600",
              usageLabel == "medium" && "bg-amber-600",
              usageLabel == "high" && "bg-red-600"
            )}
            style={{ width: `${usagePercentage}%` }}
          ></div>
        </div>
      </div>
    </>
  );
}

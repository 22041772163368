import { channelConfig } from "../../../../data/channels-config";
import ConnectChannelCard from "./ConnectChannelCard";
import SocialSet from "../../../../models/entities/social-set";
import Button from "../../../../components/common/Button";
import RedirectChannelConnector from "./RedirectChannelConnector";
import { PlusIcon } from "@heroicons/react/20/solid";
import ChannelsQuota from "./ChannelsQuota";
import ChannelsPlan from "./ChannelsPlan";
import { useContext } from "react";
import planQuotaService from "../../../../services/application/plan-quota-service";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";

export interface ChannelsListProps {
  socialSet: SocialSet;
  showHeader: boolean;
}

export default function ChannelsList({
  socialSet,
  showHeader,
}: ChannelsListProps) {
  const { state } = useContext(GlobalStateContext);

  const socialSets = state.socialSets ?? []; // Get all social set channels
  const channels = socialSets.flatMap((x) => x.channels);
  const subscription = state.currentTenant.subscription;
  const channelsQuota = planQuotaService.getChannelsLimit(subscription);
  const channelsCount = channels.length;
  const quotaFilled = channelsQuota == channelsCount;

  return (
    <>
      <div className="bg-white shadow px-4 py-5 sm:px-6">
        {showHeader && (
          <div className="border-b border-gray-200 bg-white pb-5 mb-12">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="flex gap-4 items-center truncate">
                <div
                  className="flex flex-shrink-0 items-center justify-center rounded-full text-sm font-medium text-white w-9 h-9"
                  style={{ backgroundColor: socialSet.colorHex }}
                >
                  {socialSet.code}
                </div>
                <div className="flex flex-col truncate">
                  <h3 className="text-base font-semibold leading-7 text-gray-900 truncate">
                    {socialSet.name}
                  </h3>
                  <p className="text-sm text-gray-500 ">
                    Connect a channel to add it to the current social set
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex-1">
          <div role="list">
            <div className="mx-auto">
              <ul
                role="list"
                className="max-w-screen-sm m-auto flex flex-col gap-4"
              >
                <li className="mb-4">
                  <ChannelsPlan />
                </li>
                <li>
                  <ChannelsQuota />
                </li>

                {channelConfig.map((channelConfig) => (
                  <ConnectChannelCard
                    key={channelConfig.name}
                    channelConfig={channelConfig}
                  >
                    {(canConnect) => {
                      return (
                        <RedirectChannelConnector
                          showInfoPopup={true}
                          channelConfig={channelConfig}
                          socialSetId={socialSet.id}
                        >
                          {({ isLoading, onClick }) => {
                            return (
                              <>
                                {canConnect && !quotaFilled && (
                                  <>
                                    <Button
                                      className="hidden sm:flex"
                                      variant="solid"
                                      color="brand"
                                      text="Connect"
                                      disabled={!canConnect}
                                      loadingText="Redirecting"
                                      loading={isLoading}
                                      onClick={onClick}
                                    />
                                    <Button
                                      className="sm:hidden pr-3 pl-0"
                                      variant="text"
                                      color="slate"
                                      text=""
                                      disabled={!canConnect}
                                      loadingText=""
                                      loading={isLoading}
                                      onClick={onClick}
                                      icon={
                                        <PlusIcon className="w-6 text-primary-600" />
                                      }
                                    />
                                  </>
                                )}
                              </>
                            );
                          }}
                        </RedirectChannelConnector>
                      );
                    }}
                  </ConnectChannelCard>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from "react-router-dom";
import React, { ReactNode } from "react";
import clsx from "clsx";
import Spinner from "./Spinner";

const baseStyles = {
  solid:
    "group inline-flex items-center justify-center rounded-md py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2",
  outline:
    "group inline-flex ring-1 items-center justify-center rounded-md py-2 px-4 text-sm focus:outline-none",
  text: "group inline-flex items-center justify-center rounded-md py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2",
};

const variantStyles = {
  solid: {
    slate: {
      enabled:
        "bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900",
      disabled:
        "bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900",
    },
    gray: {
      enabled:
        "bg-gray-900 text-white hover:bg-gray-700 hover:text-gray-100 active:bg-gray-800 active:text-gray-300 focus-visible:outline-gray-900",
      disabled:
        "bg-gray-900 text-white hover:bg-gray-700 hover:text-gray-100 active:bg-gray-800 active:text-gray-300 focus-visible:outline-gray-900",
    },
    blue: {
      enabled:
        "bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600",
      disabled: "bg-blue-600 text-white bg-opacity-50",
    },
    white: {
      enabled:
        "bg-white text-slate-900 hover:bg-white-50 active:bg-white-200 active:text-slate-600 focus-visible:outline-white",
      disabled: "bg-white text-slate-900 bg-opacity-50",
    },
    brand: {
      enabled:
        "bg-primary-600 text-white hover:text-primary-100 hover:bg-primary-700 active:bg-primary-800 active:text-primary-100 focus-visible:outline-primary-600",
      disabled: "bg-primary-600 text-white bg-opacity-50",
    },
    secondary: {
      enabled:
        "bg-secondary-600 text-white hover:text-secondary-100 hover:bg-secondary-700 active:bg-secondary-800 active:text-secondary-100 focus-visible:outline-secondary-600",
      disabled: "bg-secondary-600 text-white bg-opacity-50",
    },
    red: {
      enabled:
        "bg-red-600 text-white hover:text-slate-100 hover:bg-red-500 active:bg-red-800 active:text-red-100 focus-visible:outline-red-600",
      disabled: "bg-red-600 text-white bg-opacity-50",
    },
    purple: {
      enabled:
        "bg-purple-600 text-white hover:text-slate-100 hover:bg-purple-500 active:bg-purple-800 active:text-purple-100 focus-visible:outline-purple-600",
      disabled: "bg-purple-600 text-white bg-opacity-50",
    },
  },
  outline: {
    slate: {
      enabled:
        "ring-slate-200 text-slate-500 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-slate-600 focus-visible:ring-slate-300",
      disabled: "ring-slate-200 text-slate-500 bg-opacity-50",
    },
    gray: {
      enabled:
        "ring-gray-200 text-gray-500 hover:text-gray-900 hover:ring-gray-300 active:bg-gray-100 active:text-gray-600 focus-visible:outline-grey-600 focus-visible:ring-gray-300",
      disabled: "ring-gray-200 text-gray-500 bg-opacity-50",
    },
    white: {
      enabled:
        "ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white",
      disabled: "ring-slate-700 text-white bg-opacity-50",
    },
    brand: {
      enabled:
        "ring-primary-200 text-primary-600 hover:text-primary-700 hover:ring-primary-300 active:bg-primary-100 active:text-primary-700 focus-visible:outline-primary-600 focus-visible:ring-primary-300",
      disabled: "ring-primary-200 text-primary-400 bg-opacity-50",
    },
    secondary: {
      enabled:
        "ring-secondary-200 text-secondary-600 hover:text-secondary-700 hover:ring-secondary-300 active:bg-secondary-100 active:text-secondary-700 focus-visible:outline-secondary-600 focus-visible:ring-secondary-300",
      disabled: "ring-secondary-200 text-secondary-700 bg-opacity-50",
    },
    blue: {
      enabled:
        "ring-blue-200 text-blue-700 hover:text-blue-900 hover:ring-blue-300 active:bg-blue-100 active:text-blue-600 focus-visible:outline-blue-600 focus-visible:ring-blue-300",
      disabled: "ring-blue-200 text-blue-700 bg-opacity-50",
    },
    red: {
      enabled:
        "ring-red-200 text-red-700 hover:text-red-900 hover:ring-red-300 active:bg-red-100 active:text-red-600 focus-visible:outline-red-600 focus-visible:ring-red-300",
      disabled: "ring-red-200 text-red-700 bg-opacity-50",
    },
    purple: {
      enabled:
        "ring-purple-200 text-purple-700 hover:text-purple-900 hover:ring-purple-300 active:bg-purple-100 active:text-purple-600 focus-visible:outline-purple-600 focus-visible:ring-purple-300",
      disabled: "ring-purple-200 text-purple-700 bg-opacity-50",
    },
  },
  text: {
    slate: {
      enabled:
        "ring-slate-200 text-slate-600 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-slate-600 focus-visible:ring-slate-300",
      disabled: "ring-slate-200 text-slate-700 bg-opacity-50",
    },
    gray: {
      enabled:
        "ring-gray-200 text-gray-500 hover:text-gray-900 hover:ring-gray-300 active:bg-gray-100 active:text-gray-600 focus-visible:outline-gray-600 focus-visible:ring-gray-300",
      disabled: "ring-gray-200 text-gray-700 bg-opacity-50",
    },
    white: {
      enabled:
        "ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white",
      disabled: "ring-slate-700 text-white bg-opacity-50",
    },
    brand: {
      enabled:
        "ring-primary-200 text-primary-600 hover:text-primary-700 hover:ring-primary-300 active:bg-primary-100 active:text-primary-700 focus-visible:outline-primary-600 focus-visible:ring-primary-300",
      disabled: "ring-primary-200 text-primary-900 bg-opacity-50",
    },
    secondary: {
      enabled:
        "ring-secondary-200 text-secondary-600 hover:text-secondary-700 hover:ring-secondary-300 active:bg-secondary-100 active:text-secondary-700 focus-visible:outline-secondary-600 focus-visible:ring-secondary-300",
      disabled: "ring-secondary-200 text-secondary-900 bg-opacity-50",
    },
    blue: {
      enabled:
        "ring-blue-200 text-blue-700 hover:text-blue-900 hover:ring-blue-300 active:bg-blue-100 active:text-blue-600 focus-visible:outline-blue-600 focus-visible:ring-blue-300",
      disabled: "ring-blue-200 text-blue-700 bg-opacity-50",
    },
    red: {
      enabled:
        "ring-red-200 text-red-700 hover:text-red-900 hover:ring-red-300 active:bg-red-100 active:text-red-600 focus-visible:outline-red-600 focus-visible:ring-red-300",
      disabled: "ring-red-200 text-red-700 bg-opacity-50",
    },
    purple: {
      enabled:
        "ring-purple-200 text-purple-700 hover:text-purple-900 hover:ring-purple-300 active:bg-purple-100 active:text-purple-600 focus-visible:outline-purple-600 focus-visible:ring-purple-300",
      disabled: "ring-purple-200 text-purple-700 bg-opacity-50",
    },
  },
};

// Including 'to' in ButtonProps
type ButtonProps = (
  | {
      variant?: "solid";
      color?: keyof typeof variantStyles.solid;
    }
  | {
      variant: "outline";
      color?: keyof typeof variantStyles.outline;
    }
  | {
      variant: "text";
      color?: keyof typeof variantStyles.text;
    }
) &
  (
    | (Omit<React.ComponentPropsWithoutRef<typeof Link>, "color"> & {
        to?: string; // Adding 'to' to accommodate internal routing
      })
    | (Omit<React.ComponentPropsWithoutRef<"button">, "color"> & {
        href?: undefined;
      })
  );

const Button = React.forwardRef(
  (
    {
      className,
      variant = "solid",
      color = "slate",
      loading = false,
      text = "",
      loadingText = "",
      children,
      to,
      icon,
      ...props
    }: ButtonProps & {
      to?: string;
      loading?: boolean;
      loadingText?: string;
      text: string;
      icon?: ReactNode;
    },
    ref
  ) => {
    const disabled = props["disabled"] ? "disabled" : "enabled";

    className = clsx(
      baseStyles[variant],
      variantStyles[variant][color][disabled],
      className
    );

    const content =
      children ??
      (loading ? (
        <>
          {" "}
          {loadingText && <span className="mx-1">{loadingText}&nbsp;</span>}
          <Spinner size="5" />
        </>
      ) : (
        <span>{text}</span>
      ));

    if (to) {
      return (
        <Link
          ref={ref}
          to={disabled == "disabled" ? null : to}
          className={className}
          {...(props as any)}
        >
          {!loading ? icon : null}
          {content}
        </Link>
      );
    } else {
      return (
        <button
          ref={ref}
          className={className}
          {...(props as any)}
          disabled={disabled == "disabled" || loading}
          type="button"
        >
          {!loading ? icon : null}
          {content}
        </button>
      );
    }
  }
);

export default Button;

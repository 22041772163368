import { Outlet } from "react-router-dom";
import useAuthorizedAccess from "../../hooks/useAuthorizedAccess";
import Unauthorized from "../../components/common/403";

export default function SocialSetsRoot() {
  const isAuthorized = useAuthorizedAccess(["social_sets_write"], [], []);

  return (
    <>
      {!isAuthorized && <Unauthorized flat={true} />}
      {isAuthorized && <Outlet />}
    </>
  );
}

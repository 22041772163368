import { useContext, useMemo } from "react";
import GlobalStateContext from "../state/global-state/GlobalStateContext";

export default function useAuthorizedSocialSets() {
  const {
    state: { currentTenant, socialSets },
  } = useContext(GlobalStateContext);

  return useMemo(() => {
    return currentTenant?.user?.socialSetIds == null
      ? socialSets ?? []
      : socialSets?.filter((socialSet) =>
          currentTenant.user.socialSetIds.includes(socialSet.id)
        ) ?? [];
  }, [currentTenant, socialSets]);
}

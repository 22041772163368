import { useAuthenticator } from "@aws-amplify/ui-react";

export default function ToForgotPassword() {
  const { toForgotPassword } = useAuthenticator(() => []);

  return (
    <div className="text-sm text-gray-600 hover:underline cursor-pointer text-center" onClick={toForgotPassword}>
      Forgot your password?
    </div>
  );
}

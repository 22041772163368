import ChannelAccountTypes from "../../data/channel-account-types";
import GoogleTokens from "../../models/google/google-tokens";
import PinterestAccount from "../../models/pinterest/pinterest-account";
import PinterestBoard from "../../models/pinterest/pinterest-board";
import ApiService from "../core/api-service";

class PinterestService {
  getAuthorizationUrl(state: string): Promise<string> {
    return ApiService.get<string>(`external-providers/pinterest/authorization-url`, {
      params: {
        state: state,
      },
    });
  }

  exchangeAuthorizationCode(code: string): Promise<GoogleTokens> {
    return ApiService.get<GoogleTokens>(`external-providers/pinterest/token`, {
      disableGlobalErrorReporting: true,
      params: {
        authorizationCode: code,
      },
    });
  }

  async getUserAccount(token: string): Promise<PinterestAccount> {
    const userAccount = await ApiService.get<PinterestAccount>(`external-providers/pinterest/account`, {
      params: {
        accessToken: token,
      },
    });

    userAccount.channelAccountType =
      userAccount.account_type == "BUSINESS"
        ? ChannelAccountTypes.PinterestBusiness
        : ChannelAccountTypes.PinterestProfile;

    return userAccount;
  }

  getBoards(channelId: string): Promise<PinterestBoard[]> {
    return ApiService.get<PinterestBoard[]>(`external-providers/pinterest/boards`, {
      params: {
        channelId: channelId,
      },
    });
  }

  createBoard(channelId: string, name: string): Promise<PinterestBoard> {
    return ApiService.post<PinterestBoard>(`external-providers/pinterest/boards`, {
      channelId,
      name,
    });
  }
}

export default new PinterestService();

import { forwardRef, useImperativeHandle, useRef, useState } from "react";

function VideoThumb({ src, width, height }, ref) {
  /*
	The only required prop is the "src". 
	The value of this prop can be any valid (not an embed) video url
	A valid video url in this case is any video url that can be loaded by the HTML <video/> element
	If you choose to omit the "width" and "height" props,
		they both will automatically generated from the video src (recommended approach)
	*/
  const canvasRef = useRef();
  const videoRef = useRef();

  const [canvasWidth, setCanvasWidth] = useState(width);
  const [canvasHeight, setCanvasHeight] = useState(height);

  useImperativeHandle(ref, () => ({
    // call this method in your parent component when you're ready to generate a thumbnail
    // by default, the generated thumbnail is based on the first frame of the video
    generateThumbnail() {
      const canvas = canvasRef.current as HTMLCanvasElement;

      if (!canvas) {
        throw new Error("Canvas was found");
      }

      const context = canvas.getContext("2d");
      context.drawImage(videoRef.current, 0, 0, canvasWidth, canvasHeight);
      const blob = canvas.toDataURL();
      const { ext: mimeType } = blob.split(",")[0].match(/\/(?<ext>\w+);/).groups;

      return {
        blob,
        mimeType,
        filename: `image.${mimeType}`,
        width: canvasWidth,
        height: canvasHeight,
      };
    },
  }));

  const onVideoLoad = ({ target }) => {
    if (!(width && height)) {
      setCanvasWidth(target.videoWidth);
      setCanvasHeight(target.videoHeight);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        zIndex: "-1",
        visibility: "hidden",
      }}
    >
      <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
      <video ref={videoRef} src={src} onLoadedData={onVideoLoad} />
    </div>
  );
}

export default forwardRef(VideoThumb);

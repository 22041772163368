import ChannelAccountTypes from "../../data/channel-account-types";
import TikTokAccount from "../../models/tiktok/tiktok-account";
import TikTokCreator from "../../models/tiktok/tiktok-creator";
import TikTokPrivacyOption from "../../models/tiktok/tiktok-privacy-option";
import TikTokTokens from "../../models/tiktok/tiktok-tokens";
import OAuthPopupHandler from "../auth/oauth-popup-handler";
import ApiService from "../core/api-service";

class TikTokService {
  get privacyOptions(): TikTokPrivacyOption[] {
    return [
      { value: "PUBLIC_TO_EVERYONE", label: "Everyone", disabled: false, disabledTooltip: "" },
      { value: "MUTUAL_FOLLOW_FRIENDS", label: "Friends", disabled: false, disabledTooltip: "" },
      { value: "FOLLOWER_OF_CREATOR", label: "Followers", disabled: false, disabledTooltip: "" },
      { value: "SELF_ONLY", label: "Only Me", disabled: false, disabledTooltip: "" },
    ];
  }

  getAuthorizationUrl(state: string): Promise<string> {
    return ApiService.get<string>(`external-providers/tiktok/authorization-url`, {
      params: {
        state: state,
      },
    });
  }

  exchangeCodeForToken(code: string): Promise<TikTokTokens> {
    return ApiService.get<TikTokTokens>(`external-providers/tiktok/exchange-code`, {
      disableGlobalErrorReporting: true,
      params: {
        code: code,
      },
    });
  }

  async getAccount(accessToken: string): Promise<TikTokAccount> {
    const account = await ApiService.get<TikTokAccount>(`external-providers/tiktok/account`, {
      params: {
        accessToken: accessToken,
      },
    });

    account.channelAccountType = ChannelAccountTypes.TikTokAccount;

    return account;
  }

  getCreatorInfo(channelId: string): Promise<TikTokCreator> {
    return ApiService.get<TikTokCreator>(`external-providers/tiktok/creator-info`, {
      params: {
        channelId,
      },
    });
  }

  async getAuthorizationCode(authorizationUrl: string): Promise<string> {
    const { code } = await new OAuthPopupHandler().initiate(authorizationUrl, "TikTokOAuthCallback");

    return code;
  }
}

export default new TikTokService();

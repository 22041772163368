/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { PostInstance } from "../../models/post-instance";
import PrimaryTextBox from "../../../../../components/common/PrimaryTextBox";
import eventBusService from "../../../../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../../../../services/application/event-bus/event-bus-events";
import AttachmentSlot from "../../models/attachment-slot";

interface LinkedInFormProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
  children?: React.ReactNode | (({ primaryForm, secondaryForm }) => void);
}

interface LinkedInFormInput {
  title?: string;
  description?: string;
  link?: string;
  channelId: string;
  firstComment?: string;
}

const schema = yup
  .object({
    description: yup.string(),
    title: yup.string(),
    link: yup.string().notRequired(),
    firstComment: yup.string().max(500),
  })
  .required();

export default function LinkedInForm({
  postInstance,
  attachments,
  onPostInstanceChanged,
  children,
}: LinkedInFormProps) {
  const linkedInConfig = postInstance.postConfig.linkedIn;

  const form = useForm<LinkedInFormInput>({
    mode: "onChange",
    resolver: yupResolver(schema) as any,
    reValidateMode: "onChange",
    defaultValues: {
      description: linkedInConfig.contentOptions?.commentary ?? "",
      firstComment: linkedInConfig.firstCommentOptions?.comment ?? "",
    },
  });

  useEffect(() => {
    const insertAiContent = (content) => {
      form.setValue("description", content);
      onDescriptionChanged(content);
    };

    eventBusService.on(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);

    return () => {
      eventBusService.remove(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);
    };
  });

  useEffect(() => {
    const setSystemPostType = () => {
      let systemPostType = linkedInConfig.contentOptions.systemPostType;

      const initialSystemPostType = systemPostType;

      if (attachments.length == 1) {
        systemPostType = attachments[0].isPhoto
          ? "PhotoPost"
          : attachments[0].isVideo
          ? "VideoPost"
          : "DocumentPost";
      } else if (attachments.length > 1) {
        systemPostType = "MultiPhotoPost";
      } else {
        systemPostType = "TextPost";
      }

      if (systemPostType != initialSystemPostType) {
        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            linkedIn: {
              ...postInstance.postConfig.linkedIn,
              contentOptions: {
                ...linkedInConfig.contentOptions,
                systemPostType: systemPostType,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    setSystemPostType();
  }, [linkedInConfig.contentOptions, postInstance, attachments]);

  const onDescriptionChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      title: text,
      postConfig: {
        ...postInstance.postConfig,
        linkedIn: {
          ...postInstance.postConfig.linkedIn,
          contentOptions: {
            ...linkedInConfig.contentOptions,
            commentary: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onFirstCommentChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        linkedIn: {
          ...postInstance.postConfig.linkedIn,
          firstCommentOptions: {
            ...postInstance.postConfig.linkedIn.firstCommentOptions,
            comment: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const primaryForm = (
    <>
      <div className="flex flex-row">
        <fieldset className="w-full h-6 mt-3 block"></fieldset>
      </div>

      {/* Description */}
      <div>
        <PrimaryTextBox
          onFirstCommentChanged={onFirstCommentChanged}
          onTextChanged={onDescriptionChanged}
          textAreaOptions={{
            hideLabel: true,
            maxLength: 500,
            className: "h-[124px]",
            name: "description",
            label: "Description",
            formHook: form,
            placeholder: "Start writing your post",
          }}
          firstCommentOptions={{
            hideLabel: true,
            rows: 5,
            maxLength: 500,
            name: "firstComment",
            label: "First Comment",
            formHook: form,
            placeholder: "Write your first comment",
          }}
        />
      </div>
    </>
  );

  const secondaryForm = <></>;

  return (
    <form>
      <>
        {typeof children === "function"
          ? children({ primaryForm, secondaryForm })
          : children}{" "}
      </>
    </form>
  );
}

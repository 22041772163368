import { useContext, useMemo } from "react";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import Channel from "../models/entities/channel";
import localBrowserStorage from "../services/browser-storage/local-browser-storage";

const useCurrentChannel = (): Channel | null => {
  const { state } = useContext(GlobalStateContext);

  const currentChanel = useMemo(() => {
    const currentChannelId =
      state?.currentChanel?.id ?? localBrowserStorage.getItem("channelId");

    return state?.currentSocialSet.channels.find(
      (x) => x.id == currentChannelId
    );
  }, [state?.currentChanel?.id, state?.currentSocialSet.channels]);

  return currentChanel;
};

export default useCurrentChannel;

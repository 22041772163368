// import Lottie from "react-lottie-player";
// import birdAnimation from "../../../assets/animations/bird-animation.json";
// import birdAnimation from "../../../assets/images/viraly-bird-animation.gif";
import Lottie from "react-lottie-player";
import birdAnimation from "../../../assets/animations/viraly-bird-animation.json";

import clsx from "clsx";

interface SplashProps {
  message?: string;
}

export default function Splash({ message }: SplashProps) {
  const showLogo = false;

  return (
    <div className="flex flex-col h-[100dvh]">
      {showLogo ? (
        <img
          className="max-w-40 p-6"
          src="/logo/viraly-logo-rect.png"
          alt="Viraly"
        />
      ) : null}
      <div></div>
      <div className="flex-1 flex flex-col justify-center items-center">
        <div
          className={clsx(
            "flex flex-col items-center",
            showLogo ? "-mt-40" : ""
          )}
        >
          <Lottie
            loop
            animationData={birdAnimation}
            play
            style={{ width: 120 }}
          />
          {/* <img src={birdAnimation} /> */}
          <div className="text-sm text-center leading-8 text-gray-600">
            {message ?? "Fluffing our feathers, just a sec :)"}
          </div>
        </div>
      </div>
    </div>
  );
}

export interface CharacterCounterProps {
  counter: number;
}
export default function CharacterCounter({ counter }: CharacterCounterProps) {
  return (
    <div className="flex items-center justify-center absolute top-0 right-0 pointer-events-none px-1 py-1">
      <span className="text-gray-500 bg-white border border-gray-300 rounded-lg leading-3 font-medium px-1 py-1 font-[Inter] text-[10px] ">
        {counter}
      </span>
    </div>
  );
}

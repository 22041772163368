import dayjs from "dayjs";
import StripeSubscription, { PlanTier } from "../../models/stripe/subscription";

class PlanQuotaService {
  private readonly channelLimits: Record<PlanTier, number> = {
    trial: 3,
    low: 5,
    medium: 15,
    high: 50,
  };

  private readonly postLimits: Record<PlanTier, number> = {
    trial: 50,
    low: 200,
    medium: 500,
    high: 10000,
  };

  private readonly twitterPostLimits: Record<PlanTier, number> = {
    trial: 10,
    low: 10,
    medium: 10,
    high: 10,
  };

  getChannelsLimit(subscription: StripeSubscription): number {
    const tier = this.getTier(subscription);
    return this.channelLimits[tier] ?? this.channelLimits["trial"];
  }

  getPostsLimit(subscription: StripeSubscription): number {
    const tier = this.getTier(subscription);
    return this.postLimits[tier] ?? this.postLimits["trial"];
  }

  getTwitterPostsLimit(subscription: StripeSubscription): number {
    const tier = this.getTier(subscription);
    return this.twitterPostLimits[tier] ?? this.twitterPostLimits["trial"];
  }

  getTier(subscription: StripeSubscription): PlanTier {
    return subscription?.status == "trialing"
      ? "trial"
      : (subscription?.items?.[0]?.plan?.product?.metadata?.tier as PlanTier) ??
          "trial";
  }

  calculatePostQuotaInfo(
    subscription: StripeSubscription,
    postsCount?: number
  ): {
    postsQuota: number;
    tier: PlanTier;
    usagePercentage: number;
    remainingUsage: number;
    usageLabel: string;
    tooltipLabel: string;
  } {
    postsCount = postsCount ?? 0;

    const postsQuota = this.getPostsLimit(subscription);
    const tier = this.getTier(subscription);
    const usagePercentage =
      tier == "high"
        ? 0
        : Math.min(Math.ceil((postsCount / postsQuota) * 100), 100);
    const remainingUsage = Math.max(0, postsQuota - postsCount);
    const usageLabel =
      tier == "high"
        ? "unlimited"
        : usagePercentage <= 34
        ? "low"
        : usagePercentage > 67
        ? "high"
        : "medium";
    const tooltipLabel =
      tier == "high"
        ? "Unlimited posts available"
        : `${remainingUsage} posts remaining for this month out of ${postsQuota}`;

    return {
      postsQuota,
      tier,
      usagePercentage,
      remainingUsage,
      usageLabel,
      tooltipLabel,
    };
  }

  calculateTwitterPostQuotaInfo(
    subscription: StripeSubscription,
    date: Date,
    postsCount?: number
  ): {
    postsQuota: number;
    tier: PlanTier;
    usagePercentage: number;
    remainingUsage: number;
    usageLabel: string;
    tooltipLabel: string;
  } {
    postsCount = postsCount ?? 0;

    const postsQuota = this.getTwitterPostsLimit(subscription);
    const tier = this.getTier(subscription);
    const usagePercentage = Math.min(
      Math.ceil((postsCount / postsQuota) * 100),
      100
    );
    const remainingUsage = Math.max(0, postsQuota - postsCount);
    const usageLabel =
      usagePercentage <= 34 ? "low" : usagePercentage > 67 ? "high" : "medium";
    const tooltipLabel = `You can schedule ${remainingUsage} more post${
      remainingUsage > 1 ? "s" : ""
    } to X for ${dayjs(date).format("MMM DD, YYYY")}`;

    return {
      postsQuota,
      tier,
      usagePercentage,
      remainingUsage,
      usageLabel,
      tooltipLabel,
    };
  }
}

export default new PlanQuotaService();

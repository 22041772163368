import { useEffect, useMemo, useState } from "react";
import Channel from "../../../../../../models/entities/channel";
import ChannelSlot, {
  DayOfWeek,
} from "../../../../../../models/entities/channel-slot";
import PostingFrequency from "../../data/posting-frequency";
import FrequencyPicker from "./FrequencyPicker";
import HourPicker from "./HourPicker";
import MinutePicker from "./MinutePicker";
import MeridiemPicker from "./MeridiemPicker";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import channelSlotService from "../../../../../../services/api/channel-slot-service";
import alertService from "../../../../../../services/alert/alert-service";
import Button from "../../../../../../components/common/Button";
import {
  LocalizationProvider,
  MultiSectionDigitalClock,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { refetchQuery } from "../../../../../../queries/query-utils";
import queryNames from "../../../../../../queries/query-names";
import daysOfWeek, {
  weekDays,
  weekEnds,
} from "../../../../../../data/days-of-week";
import { useNavigate } from "react-router-dom";

const dayOrder: { [key in DayOfWeek]: number } = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

interface PostingTimesSettingsProps {
  channel: Channel;
}

export default function PostingTimesSettings({
  channel,
}: PostingTimesSettingsProps) {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [editedTime, setEditedTime] = useState<dayjs.Dayjs>(null);
  const [postingTimes, setPostingTimes] = useState<ChannelSlot[]>([]);
  const [frequency, setFrequency] = useState<PostingFrequency>("Every Day");
  const [hour, setHour] = useState<number>(new Date().getHours() % 12);
  const [minute, setMinutes] = useState<number>(new Date().getMinutes());
  const [meridiem, setMeridiem] = useState<string>(
    new Date().getHours() >= 12 ? "PM" : "AM"
  );

  useEffect(() => {
    const slots = channel?.slots ?? [];
    sortPostingTimes(slots);
    setPostingTimes(slots);
  }, [channel?.slots]);

  const hasChanges = useMemo(
    () =>
      channel.slots.length != postingTimes.length ||
      postingTimes.some(
        (x) => !channel.slots.some((y) => y.day == x.day && y.time == x.time)
      ),
    [channel.slots, postingTimes]
  );

  const goBack = () => {
    navigate(-1);
  };
  const savePostingTimes = async () => {
    setSubmitting(true);

    try {
      const payload = postingTimes.map(
        (x) =>
          ({
            id: x.id,
            day: x.day,
            time: timeToValidString(x.time).split("T")[1],
          } as ChannelSlot)
      );

      const updatedSlots = await channelSlotService.update(channel.id, payload);

      sortPostingTimes(updatedSlots);
      setPostingTimes(updatedSlots);

      await refetchQuery([queryNames.socialSets]);
      alertService.success("Posting slots have successfully updated.");

      goBack();
    } finally {
      setSubmitting(false);
    }
  };

  const addPostingTimes = () => {
    const slots: ChannelSlot[] = [];
    const time = dayjs(
      new Date(1970, 0, 1, meridiem == "AM" ? hour : hour + 12, minute, 0)
    ).format("HH:mm:ss");

    if (frequency == "Every Day") {
      daysOfWeek.forEach((day) => {
        slots.push({
          id: "0",
          day: day,
          time: time,
        });
      });
    } else if (frequency == "Weekdays") {
      weekDays.forEach((day) => {
        slots.push({
          id: "0",
          day: day,
          time: time,
        });
      });
    } else if (frequency == "Weekends") {
      weekEnds.forEach((day) => {
        slots.push({
          id: "0",
          day: day,
          time: time,
        });
      });
    } else {
      slots.push({
        id: "0",
        day: frequency,
        time: time,
      });
    }

    const uniqueSlots = slots.filter(
      (x) => !postingTimes.some((y) => y.day == x.day && y.time == x.time)
    );

    const updatedPostingTimes = [...postingTimes, ...uniqueSlots];

    sortPostingTimes(updatedPostingTimes);
    setPostingTimes(updatedPostingTimes);
  };

  const sortPostingTimes = (slots: ChannelSlot[]) => {
    slots.sort((a, b) => {
      // First, compare by the day of the week using the dayOrder mapping
      if (dayOrder[a.day] < dayOrder[b.day]) return -1;
      if (dayOrder[a.day] > dayOrder[b.day]) return 1;

      // If days are the same, compare by time (Hour:Minute AM/PM)
      const timeA = dayjs(timeToValidString(a.time)).toDate();
      const timeB = dayjs(timeToValidString(b.time)).toDate();

      return timeA.getTime() - timeB.getTime();
    });
  };

  const timeToValidString = (time: string): string => {
    const [timePart, modifier] = time.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);
    let hoursAdjusted = hours;

    if (modifier === "PM" && hours !== 12) {
      hoursAdjusted += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hoursAdjusted = 0;
    }

    return `1970-01-01T${hoursAdjusted.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:00`;
  };

  const removeSlot = (slot: ChannelSlot) => {
    const updatedPostingTimes = postingTimes.filter(
      (x) => x.day !== slot.day || x.time !== slot.time
    );

    setPostingTimes(updatedPostingTimes);
  };

  const updateSlot = (slot: ChannelSlot) => {
    const updatedSlot = {
      ...slot,
      time: editedTime.format("hh:mm A"),
    };

    const updatedPostingTimes = postingTimes.map((x) =>
      x.day === slot.day && x.time === slot.time ? updatedSlot : x
    );

    setPostingTimes(updatedPostingTimes);
  };

  const clearAll = () => {
    setPostingTimes([]);
  };

  return (
    <>
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-8 px-4">
        <div className="flex items-center">
          <div className="flex flex-col flex-1 max-w-2xl">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Posting Slots
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Your posting slots tell Viraly when to send out posts from your
              queue. For example, the next 5 posts you add to your queue will be
              sent out during the next 5 time slots you select below.
            </p>
          </div>
        </div>

        <div className="ml-auto">
          <Button
            text="Clear All"
            color="brand"
            variant="text"
            disabled={!postingTimes.length}
            icon={<TrashIcon className="h-5 w-5 mr-1" />}
            onClick={clearAll}
          />
        </div>

        <div>
          <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-7 rounded divide-x divide-y border-b border-r [&&>*:nth-child(6)]:border-b [&&>*:nth-child(6)]:-mb-px sm:[&&>*:nth-child(6)]:border-b-transparent sm:[&&>*:nth-child(4)]:border-b sm:[&&>*:nth-child(4)]:-mb-px md:[&&>*:nth-child(4)]:border-b-transparent ">
            {daysOfWeek.map((day) => {
              const channelSlots = postingTimes.filter((x) => x.day == day);

              return (
                <div
                  key={day}
                  className="flex flex-col first:rounded-tl first:border-l first:border-t [&&]:last:border-r md:[&&]:last:border-r-transparent [&&]:last:-mr-px"
                >
                  <div className="p-4 text-sm font-medium text-gray-900 text-center border-b">
                    {day}
                  </div>
                  <div className="justify-center items-center gap-1 min-h-12 py-2">
                    {channelSlots.map((slot, index) => {
                      return (
                        <div
                          key={index}
                          className="group flex items-center justify-center"
                        >
                          <Popover>
                            <PopoverButton className="block text-sm/6 font-semibold text-white/50 focus:outline-none  hover:bg-gray-200 py-1 px-2 rounded cursor-pointer data-[active]:text-white data-[hover]:text-white data-[focus]:outline-1 data-[focus]:outline-white">
                              <div className="text-xs leading-normal font-normal text-gray-500">
                                {dayjs(timeToValidString(slot.time)).format(
                                  "hh:mm A"
                                )}
                              </div>
                            </PopoverButton>
                            <PopoverPanel
                              transition
                              anchor="bottom"
                              className="rounded-xl bg-white shadow border transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
                            >
                              <div>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <div className="flex flex-col">
                                    <MultiSectionDigitalClock
                                      timeSteps={{ hours: 1, minutes: 1 }}
                                      views={["hours", "minutes"]}
                                      onChange={(value) => {
                                        console.log(value);
                                        setEditedTime(value);
                                      }}
                                      defaultValue={dayjs(
                                        timeToValidString(slot.time)
                                      )}
                                    />

                                    <div className="flex justify-between py-2 px-2">
                                      <PopoverButton as="div">
                                        <Button
                                          text="Cancel"
                                          color="gray"
                                          variant="text"
                                          onClick={() => {}}
                                        />
                                      </PopoverButton>

                                      <PopoverButton as="div">
                                        <Button
                                          text="Change"
                                          color="brand"
                                          variant="text"
                                          onClick={() => {
                                            updateSlot(slot);
                                          }}
                                        />
                                      </PopoverButton>
                                    </div>
                                  </div>
                                </LocalizationProvider>
                              </div>
                            </PopoverPanel>
                          </Popover>

                          <div className="transition-all -mr-6 lg:-mr-8 lg:ml-1 lg:opacity-0 lg:group-hover:opacity-100">
                            <TrashIcon
                              className="w-6 h-6 text-gray-400 hover:bg-gray-200 p-1 rounded cursor-pointer"
                              onClick={() => removeSlot(slot)}
                            />
                          </div>
                          {/* <div
                            className="items-center justify-center hidden group-hover:flex group-hover:bg-gray-200 w-full max-w-16 leading-normal p-px rounded cursor-pointer"
                            onClick={() => removeSlot(slot)}
                          >
                            <TrashIcon className="w-3.5 h-3.5 text-gray-400" />
                          </div> */}
                        </div>
                      );
                    })}

                    {channelSlots.length == 0 && (
                      <div className="text-sm text-gray-500 text-center">
                        No slots
                      </div>
                    )}
                  </div>
                </div>
              );
            })}

            {/* <div className="flex flex-col ">
              <div className="p-4 text-sm font-medium text-gray-900 text-center">
                &nbsp;
              </div>
              <div className="justify-center items-center gap-1 min-h-12 py-2">
                <div className="text-sm text-gray-500 text-center">&nbsp;</div>
              </div>
            </div> */}
          </div>

          <div className="mt-6">
            <div className="flex flex-col md:flex-row flex-wrap items-start md:items-center gap-2">
              <div className="text-sm font-medium text-gray-900 text-center">
                Add new posting slot(s) for
              </div>

              <div className="flex flex-row items-center gap-2 flex-wrap">
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <FrequencyPicker
                      onChange={setFrequency}
                      value={frequency}
                    />
                  </div>
                  <div>at</div>
                </div>

                <div className="flex flex-row items-center gap-2">
                  <div>
                    <HourPicker onChange={setHour} value={hour} />
                  </div>
                  <div>
                    <MinutePicker onChange={setMinutes} value={minute} />
                  </div>
                  <div>
                    <MeridiemPicker onChange={setMeridiem} value={meridiem} />
                  </div>
                </div>
                <div>
                  <Button
                    text="Add"
                    variant="outline"
                    color="brand"
                    onClick={addPostingTimes}
                    icon={<PlusIcon className="h-5 w-5 mr-1" />}
                  />
                  {/* <PlusIcon
                    className="h-8 w-8 px-2 py-1 rounded hover:bg-gray-100 text-gray-600 cursor-pointer"
                    onClick={addPostingTimes}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4 items-center ml-auto mt-2">
          <Button text="Cancel" color="brand" variant="text" onClick={goBack} />
          <Button
            text="Save"
            color="brand"
            variant="solid"
            loading={submitting}
            disabled={!hasChanges}
            loadingText="Saving..."
            onClick={savePostingTimes}
          />
        </div>
      </div>
    </>
  );
}

interface AiTone {
  tone: string;
  emoji: string;
}

const aiTones: AiTone[] = [
  { tone: "Adventurous", emoji: "🌍" },
  { tone: "Casual", emoji: "👕" },
  { tone: "Confident", emoji: "🦁" },
  { tone: "Educational", emoji: "📚" },
  { tone: "Empathetic", emoji: "💞" },
  { tone: "Excited", emoji: "🎉" },
  { tone: "Formal", emoji: "🎩" },
  { tone: "Humorous", emoji: "😂" },
  { tone: "Inspirational", emoji: "🚀" },
  { tone: "Neutral", emoji: "🙂" },
  { tone: "Nostalgic", emoji: "🎞️" },
  { tone: "Optimistic", emoji: "☀️" },
  { tone: "Persuasive", emoji: "🧲" },
  { tone: "Romantic", emoji: "🌹" },
  { tone: "Sad", emoji: "😢" },
  { tone: "Sassy", emoji: "💁‍♀️" },
  { tone: "Storytelling", emoji: "📝" },
];

export type { AiTone };
export default aiTones;

import { useRef, useEffect, useState } from "react";
import ViewRange from "../../models/view-range";
import dayjs from "dayjs";
import React from "react";
import Post from "../../../../../models/entities/post";
import CalendarDay from "../../models/calendar-day";
import { CalendarEventSlot } from "../../models/calendar-event";
import clsx from "clsx";
import DayHour from "../../models/day-hour";
import {
  generateWeekData,
  generateDayHours,
  sumMaxTotalSlotsInOneDay,
  flattenDayEvents,
  generateSlotsMap,
} from "../../../../../utils/weekly-calendar-utils";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import useCurrentSocialSet from "../../../../../hooks/useCurrentSocialSet";
import CalendarTileV2 from "./CalendarTileV2";

export interface CalendarWeekViewProps {
  viewRange: ViewRange;
  posts: Post[];
  deletingPost?: Post;
  isSubmitting: boolean;
  onDelete: (post: Post) => void;
  onEdit: (post: Post) => void;
  onClone: (post: Post) => void;
  onCreate: (datetime?: Date) => void;
  onPublishNow: (post: Post) => void;
  onMoveToDraft: (post: Post) => void;
  onMoveToScheduled: (post: Post) => void;
}

export default function CalendarWeekView({
  viewRange,
  posts,
  deletingPost,
  isSubmitting,
  onDelete,
  onEdit,
  onClone,
  onCreate,
  onPublishNow,
  onMoveToDraft,
  onMoveToScheduled,
}: CalendarWeekViewProps) {
  const [weekData, setWeekData] = useState<CalendarDay[]>([]);
  const [hourData, setHourData] = useState<DayHour[]>([]);
  const [eventSlots, setEventSlots] = useState<CalendarEventSlot[]>([]);
  const [placeholderSlots, setPlaceholderSlots] = useState<CalendarEventSlot[]>(
    []
  );
  const [totalSlots, setTotalSlots] = useState<number>(0);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const socialSet = useCurrentSocialSet();

  useEffect(() => {
    const attachEvents = (days: CalendarDay[]) => {
      (posts ?? []).forEach((post) => {
        if (!post.scheduledAt) {
          return;
        }

        const scheduledDate = dayjs(post.scheduledAt).tz(socialSet.timezone);
        const postDate = scheduledDate.format("YYYY-MM-DD");
        const day = days.find((x) => x.date == postDate);

        if (day) {
          day.events.push({
            id: post.id,
            datetime: scheduledDate.toISOString(),
            name: post.title,
            time: scheduledDate.format("hh:mm A"),
            post: post,
          });
        }
      });
    };

    // Set the container scroll position based on the current time.
    const newWeekData = generateWeekData(
      viewRange.weekly.year,
      viewRange.weekly.week,
      socialSet.timezone
    );

    setWeekData(newWeekData);
    attachEvents(newWeekData);

    const hours = generateDayHours(newWeekData);
    setHourData(hours);

    const totalSlots = sumMaxTotalSlotsInOneDay(hours);
    setTotalSlots(totalSlots);

    const events = flattenDayEvents(newWeekData);
    const slots = generateSlotsMap(events, hours, socialSet.timezone);
    const eventSlots = slots.filter((s) => s.event != null);
    const placeholderSlots = slots.filter((s) => s.event == null);

    setEventSlots(eventSlots);
    setPlaceholderSlots(placeholderSlots);

    if (!hasScrolled) {
      setTimeout(() => {
        if (container.current) {
          const currentMinute = new Date().getHours() * 60;

          container.current.scrollTop =
            ((container.current?.scrollHeight ??
              0 - containerNav.current?.offsetHeight ??
              0 - containerOffset.current?.offsetHeight ??
              0) *
              currentMinute) /
            1440;

          setHasScrolled(true);
        }
      }, 500);
    }
  }, [
    viewRange.weekly.year,
    viewRange.weekly.week,
    posts,
    hasScrolled,
    socialSet?.timezone,
  ]);

  const clickOnCreate = (eventSlot: CalendarEventSlot) => {
    const date = dayjs
      .tz(viewRange.weekly.startDate, socialSet.timezone)
      .add(eventSlot.daySlot - 1, "d")
      .add(eventSlot.slotTimeDecimal, "h")
      .toDate();

    onCreate(date);
  };

  return (
    <>
      <div
        ref={container}
        className="isolate flex flex-auto flex-col overflow-auto bg-white"
      >
        <div
          style={{ width: "165%" }}
          className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
        >
          <div
            ref={containerNav}
            className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
          >
            <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
              {/* Days list - Small screens */}
              {weekData.map((day, index) => (
                <button
                  key={index}
                  type="button"
                  className="flex flex-col items-center pb-3 pt-2"
                >
                  {!day.isToday ? (
                    <>
                      {day.dayOfWeek}{" "}
                      <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">
                        {" "}
                        {dayjs(day.date).format("DD")}
                      </span>
                    </>
                  ) : (
                    <>
                      {day.dayOfWeek}
                      <span className="mt-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 font-semibold text-white">
                        {dayjs(day.date).format("DD")}
                      </span>
                    </>
                  )}
                </button>
              ))}
            </div>

            {/* Days list - Larger screens */}
            <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
              <div className="col-end-1 w-14" />
              {weekData.map((day, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center py-3"
                >
                  {!day.isToday ? (
                    <span className="flex items-center">
                      {day.dayOfWeek}{" "}
                      <span className="flex items-center h-8 w-8 justify-center font-semibold text-gray-900">
                        {dayjs(day.date).format("DD")}
                      </span>
                    </span>
                  ) : (
                    <span className="flex items-baseline">
                      {day.dayOfWeek}{" "}
                      <span className="ml-1.5 flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 font-semibold text-white">
                        {dayjs(day.date).format("DD")}
                      </span>
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-auto text-xs leading-6 text-gray-700">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="relative grid flex-auto grid-cols-1 grid-rows-1">
              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-200 sm:grid sm:grid-cols-7">
                {weekData.map((x, index) => (
                  <div
                    key={x.date}
                    className={clsx(
                      `col-start-${index + 1} row-span-full`,
                      x.isPast ? "bg-slate-50" : "bg-white"
                    )}
                  />
                ))}

                <div className="col-start-8 row-span-full w-8" />
              </div>

              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-200"
                style={{
                  gridTemplateRows: `repeat(${totalSlots}, minmax(4.5rem, 1fr))`,
                }}
              >
                <div ref={containerOffset} className="row-end-1 h-7"></div>
                {hourData.map((hour, index) => (
                  <React.Fragment key={index}>
                    <div>
                      <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                        {hour.hourLabel}
                      </div>
                    </div>
                    {new Array(hour.slots - 1).fill(0).map((_, index) => (
                      <div key={index} className="divide-opacity-0"></div>
                    ))}
                  </React.Fragment>
                ))}
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{
                  gridTemplateRows: `1.75rem repeat(${
                    totalSlots * 12
                  }, minmax(0, 1fr)) auto`,
                }}
              >
                {eventSlots.map((eventSlot) => (
                  <li
                    key={eventSlot.event.id}
                    className={clsx(
                      "relative flex flex-col p-1.5",
                      `sm:col-start-${eventSlot.daySlot}`
                    )}
                    style={{
                      gridRow: `${eventSlot.hourSlot * 12 + 2} / span 12`,
                    }}
                  >
                    <CalendarTileV2
                      event={eventSlot.event}
                      showTitle={false}
                      isSubmitting={isSubmitting}
                      onDelete={() => onDelete(eventSlot.event.post)}
                      onEdit={() => onEdit(eventSlot.event.post)}
                      onClone={() => onClone(eventSlot.event.post)}
                      onMoveToDraft={() => onMoveToDraft(eventSlot.event.post)}
                      onMoveToScheduled={() =>
                        onMoveToScheduled(eventSlot.event.post)
                      }
                      onPublishNow={() => onPublishNow(eventSlot.event.post)}
                      loading={deletingPost?.id == eventSlot.event.post.id}
                    />
                  </li>
                ))}

                {placeholderSlots.map((eventSlot, index) => (
                  <li
                    key={index}
                    className={clsx(
                      "relative mt-px flex",
                      `sm:col-start-${eventSlot.daySlot}`
                    )}
                    style={{
                      gridRow: `${eventSlot.hourSlot * 12 + 2} / span 12`,
                    }}
                  >
                    <div className={clsx("group flex items-center rounded-md")}>
                      <div className="absolute inset-1 text-xs leading-5">
                        <div
                          className="hidden group-hover:flex bg-white border border-gray-200 p-1 rounded-md w-full h-full cursor-pointer flex-col justify-center items-center active:bg-gray-200"
                          onClick={() => clickOnCreate(eventSlot)}
                        >
                          <PlusCircleIcon className="w-5 h-5 text-gray-600" />
                          <div className="text-gray-600 leading-1">
                            {eventSlot.slotTime}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden">
        <span className="sm:col-start-1"></span>
        <span className="sm:col-start-2"></span>
        <span className="sm:col-start-3"></span>
        <span className="sm:col-start-4"></span>
        <span className="sm:col-start-5"></span>
        <span className="sm:col-start-6"></span>
        <span className="sm:col-start-7"></span>
      </div>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import alertService from "../services/alert/alert-service";

class ErrorReporter {
  alertErrors(error: any): void {
    const errors =
      (error?.response?.data?.errors ? error?.response?.data?.errors : null) ??
      error?.errors ??
      error?.data?.errors ??
      (error?.message ? [error.message] : null) ??
      (error?.length ? [error] : null) ??
      [];

    errors.forEach((e) => {
      alertService.error(e);
    });
  }
}

export default new ErrorReporter();

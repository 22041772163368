/* eslint-disable @typescript-eslint/no-explicit-any */
// import { Link } from "react-router-dom";

import { Link } from "react-router-dom";
import ToForgotPassword from "../../../layouts/AuthenticatorLayout/components/ToForgotPassword";
import BackToLogin from "../../../layouts/AuthenticatorLayout/components/BackToLogin";

export const authenticatorFormFields = {
  signUp: {
    name: {
      order: 1,
      label: "Full name",
      placeholder: "Enter your full name",
    },
    email: {
      order: 2,
      placeholder: "Enter your email",
    },
    password: {
      order: 3,
      placeholder: "Enter your password",
    },
    confirm_password: {
      order: 4,
      placeholder: "Please confirm your password",
    },
  },
};

export const authenticatorSignupAttributes: any = ["name", "email"];

// export const authenticatorComponents = {
//   SignUp: {
//     Header() {
//       return (
//         <>
//           <div className="mb-10 text-center">
//             <div className="text-xl font-semibold text-gray-900">Sign up</div>
//             <p className="mt-2 text-sm text-gray-700">
//               Already have an account?{" "}
//               <Link onClick={toSignIn} to="/scheduler" className="font-medium text-primary-600 hover:underline">
//                 Sign in
//               </Link>{" "}
//             </p>
//           </div>
//         </>
//       );
//     },
//   },
// };

export function getComponents(toSignIn, toSignUp) {
  return {
    SignIn: {
      Header() {
        return (
          <>
            <div className="mb-10 text-center">
              <div className="text-xl font-semibold text-gray-900">
                Sign in to your account
              </div>
              <p className="mt-2 text-sm text-gray-700">
                Don’t have an account?{" "}
                <Link
                  onClick={toSignUp}
                  to="/signup"
                  className="font-medium text-primary-600 hover:underline cursor-pointer"
                >
                  Sign up
                </Link>{" "}
                for a free trial.
              </p>
            </div>
          </>
        );
      },
      Footer() {
        return (
          <div className="mt-8">
            <ToForgotPassword />
          </div>
        );
      },
    },
    ForgotPassword: {
      Header() {
        return (
          <>
            <div className="mb-6 text-center">
              <div className="text-xl font-semibold text-gray-900">
                Reset password
              </div>
            </div>
          </>
        );
      },
      Footer() {
        return <BackToLogin />;
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <div className="mb-8">
            <div className="text-xl font-semibold text-gray-900 text-center">
              Reset password
            </div>
          </div>
        );
      },
      Footer() {
        return <BackToLogin />;
      },
    },
    SignUp: {
      Header() {
        return (
          <>
            <div className="mb-10 text-center">
              <div className="text-xl font-semibold text-gray-900">Sign up</div>
              <p className="mt-2 text-sm text-gray-700">
                Already have an account?{" "}
                <Link
                  onClick={toSignIn}
                  to="/login"
                  className="font-medium text-primary-600 hover:underline"
                >
                  Sign in
                </Link>{" "}
              </p>
            </div>
          </>
        );
      },
    },
  };
}

import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import socialSetsService from "../../../services/api/social-sets-service";
import ErrorMessage from "../../../components/common/ErrorMessage";
import Spinner from "../../../components/common/Spinner";
import Breadcrumbs from "../../../components/common/BreadCrumbs";
import SocialSetUpdateFormInput from "../../../models/form-inputs/social-set-update-input";
import EditForm from "./components/EditForm";
import queryNames from "../../../queries/query-names";
import NotFound from "../../../components/common/404";
import { canAccessSocialSet } from "../../../utils/auth-utils";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import Unauthorized from "../../../components/common/403";

export default function EditSocialSet() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    state: { currentTenant },
  } = useContext(GlobalStateContext);

  const query = useQuery({
    queryKey: [queryNames.socialSets],
    queryFn: socialSetsService.list,
  });

  const {
    isLoading,
    isError,
    data: socialSets,
    error,
    refetch: refetchSocialSets,
  } = query;

  const socialSet = socialSets?.find((x) => x.id == id);

  let content = null;

  const onSubmit = async (input: SocialSetUpdateFormInput) => {
    try {
      setIsSubmitting(true);

      await socialSetsService.update(id, input);
      refetchSocialSets();

      navigate(-1);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    content = <Spinner />;
  } else if (isError) {
    content = (
      <ErrorMessage
        error={error}
        onRetry={() => {
          query.refetch();
        }}
      />
    );
  } else if (socialSet) {
    const input: SocialSetUpdateFormInput = {
      name: socialSet.name,
      code: socialSet.code,
      colorHex: socialSet.colorHex,
      timezone: socialSet.timezone,
    };

    content = canAccessSocialSet(currentTenant, socialSet.id) ? (
      <div className="flex flex-col bg-white shadow sm:rounded-lg min-h-[465px] px-4 py-5 sm:px-6">
        <EditForm
          input={input}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
    ) : (
      <Unauthorized />
    );
  } else {
    content = <NotFound />;
  }

  return (
    <>
      <div className="flex flex-col gap-5 mx-auto">
        <Breadcrumbs
          pages={[
            {
              name: "Social Sets",
              to: "/social-sets",
            },
            {
              name: `${socialSet?.name ?? "Not Found"}`,
              to: `/social-sets/${id}`,
            },
            {
              name: `Edit`,
              to: `/social-sets/${id}/edit`,
            },
          ]}
        />
        {content}
      </div>
    </>
  );
}

import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/20/solid";
import TikTokPrivacyOption from "../../../../../models/tiktok/tiktok-privacy-option";
import TikTokCreator from "../../../../../models/tiktok/tiktok-creator";
import { useEffect, useMemo } from "react";
import tiktokService from "../../../../../services/api/tiktok-service";

export interface PrivacyStatusProps {
  creatorInfo: TikTokCreator;
  privacyStatus?: TikTokPrivacyOption;
  paidPartnership: boolean;
  onPrivacyStatusChanged: (value: TikTokPrivacyOption) => void;
}

export default function PrivacyStatus({
  creatorInfo,
  privacyStatus,
  paidPartnership,
  onPrivacyStatusChanged,
}: PrivacyStatusProps) {
  const privacyOptions = useMemo(
    () =>
      !creatorInfo?.privacy_level_options
        ? []
        : tiktokService.privacyOptions
            .filter((x) => creatorInfo?.privacy_level_options.includes(x.value))
            .map((x) => {
              const patched = {
                ...x,
              };

              if (paidPartnership && x.value === "SELF_ONLY") {
                patched.disabled = true;
                patched.disabledTooltip =
                  "Branded content videos can't be private";
              }

              return patched;
            }),
    [paidPartnership, creatorInfo?.privacy_level_options]
  );

  useEffect(() => {
    if (!privacyStatus) {
      const everyone = privacyOptions.find(
        (x) => x.value == "PUBLIC_TO_EVERYONE" && !x.disabled
      );
      const friends = privacyOptions.find(
        (x) => x.value == "MUTUAL_FOLLOW_FRIENDS" && !x.disabled
      );
      const self = privacyOptions.find(
        (x) => x.value == "SELF_ONLY" && !x.disabled
      );

      if (everyone) {
        onPrivacyStatusChanged(everyone);
      } else if (friends) {
        onPrivacyStatusChanged(friends);
      } else if (self) {
        onPrivacyStatusChanged(self);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privacyOptions, privacyStatus]);

  return (
    <div className="flex justify-start items-center gap-4 relative">
      <div className="text-gray-700 font-semibold text-xs w-40">
        Who can view this video
      </div>
      <div className="flex-1 w-full">
        <Listbox value={privacyStatus} onChange={onPrivacyStatusChanged}>
          <div className="relative">
            <ListboxButton className="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-1 sm:text-sm sm:leading-6">
              <span className="block truncate">
                {privacyStatus?.label ?? "Please select"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
              </span>
            </ListboxButton>

            <ListboxOptions
              anchor="bottom"
              transition
              className="w-[var(--button-width)] [--anchor-gap:var(--spacing-1)] focus:outline-none transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 z-50 !max-h-56 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
            >
              {privacyOptions?.map((privacyOption) => (
                <ListboxOption
                  data-tooltip-id="tooltip-placeholder"
                  data-tooltip-content={privacyOption.disabledTooltip}
                  disabled={privacyOption.disabled}
                  key={privacyOption.value}
                  value={privacyOption}
                  className="group relative select-none py-2 pl-3 rounded-md mx-1 cursor-pointer pr-9 text-gray-900 data-[focus]:bg-primary-600 data-[focus]:text-white data-[disabled]:bg-gray-100 data-[disabled]:opacity-50"
                >
                  <span className="block truncate font-normal group-data-[selected]:font-semibold">
                    {privacyOption.label}
                  </span>

                  <span className="hidden md:flex absolute inset-y-0 right-0 items-center pr-2 text-primary-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                    <CheckIcon className="h-5 w-5" />
                  </span>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </Listbox>
      </div>
    </div>
  );
}

import { useContext, useEffect, useMemo, useState } from "react";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import actionTypes from "../../../state/global-state/action-types";
import NotificationToggle from "./components/NotificationToggle";
import {
  UserNotificationCodes,
  UserNotificationSetting,
} from "../../../models/entities/user";
import userService from "../../../services/api/user-service";
import TenantUser from "../../../models/entities/tenant-user";

export default function NotificationsSettings() {
  const { dispatch, state } = useContext(GlobalStateContext);
  const [settingsMap, setSettingsMap] = useState<{
    [key: string]: UserNotificationSetting;
  }>(null);
  const [notifications, setNotifications] = useState<UserNotificationSetting[]>(
    []
  );

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SETTINGS_BREADCRUMBS,
      payload: [
        {
          name: "Settings",
          to: "/settings",
        },
        {
          name: "Notifications",
          to: "/settings/notifications",
        },
      ],
    });
  }, [dispatch]);

  const defaultSettings = (code: string) => ({
    code,
    email: false,
    push: false,
    sms: false,
  });

  useEffect(() => {
    setNotifications(state.currentTenant.user?.notificationSettings);
  }, []);

  useEffect(() => {
    setSettingsMap({
      [UserNotificationCodes.PublishedPost]:
        notifications?.find(
          (x) => x.code === UserNotificationCodes.PublishedPost
        ) ?? defaultSettings(UserNotificationCodes.PublishedPost),
      [UserNotificationCodes.FailedPost]:
        notifications?.find(
          (x) => x.code === UserNotificationCodes.FailedPost
        ) ?? defaultSettings(UserNotificationCodes.FailedPost),
      [UserNotificationCodes.ChannelError]:
        notifications?.find(
          (x) => x.code === UserNotificationCodes.ChannelError
        ) ?? defaultSettings(UserNotificationCodes.ChannelError),
      [UserNotificationCodes.Newsletter]:
        notifications?.find(
          (x) => x.code === UserNotificationCodes.Newsletter
        ) ?? defaultSettings(UserNotificationCodes.Newsletter),
    });
  }, [notifications]);

  const updateTenants = (updatedNotifications: UserNotificationSetting[]) => {
    const updatedTenant: TenantUser = {
      ...state.currentTenant,
      user: {
        ...state.currentTenant.user,
        notificationSettings: updatedNotifications,
      },
    };

    const tenantIndex = state.tenants.findIndex(
      (x) => x.tenant.id == updatedTenant.tenant.id
    );
    const tenants = [...state.tenants];
    tenants[tenantIndex] = updatedTenant;

    dispatch({ type: actionTypes.SET_TENANTS, payload: tenants });
  };

  const getUpdatedNotifications = (
    setting: UserNotificationSetting,
    value: boolean
  ): UserNotificationSetting[] => {
    const updatedNotifications = [...(notifications ?? [])];

    const updatedSetting = { ...setting, email: value };
    const index = updatedNotifications.findIndex(
      (x) => x.code === setting.code
    );

    if (index !== -1) {
      updatedNotifications[index] = updatedSetting;
    } else {
      updatedNotifications.push(updatedSetting);
    }

    return updatedNotifications;
  };

  const onChange = async (setting: UserNotificationSetting, value: boolean) => {
    const updatedNotifications = getUpdatedNotifications(setting, value);

    setNotifications(updatedNotifications);
    updateTenants(updatedNotifications);

    await userService.updateNotificationSettings(state.currentTenant.user.id, {
      notificationSettings: updatedNotifications,
    });
  };

  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Email Notifications
        </h2>
        <p className="text-sm leading-6 text-gray-500">
          Configure how you receive notifications.
        </p>
      </div>

      <div className="my-4 border-t border-gray-200"></div>

      {settingsMap && (
        <div className="flex flex-col gap-0 max-w-2xl">
          <NotificationToggle
            title="Published post"
            description="Get notified every time your queued post is posted."
            settings={settingsMap[UserNotificationCodes.PublishedPost]}
            onChange={(checked) =>
              onChange(
                settingsMap[UserNotificationCodes.PublishedPost],
                checked
              )
            }
          />
          <NotificationToggle
            title="Failed post"
            description="Get notified any time a queued post fails to be published."
            settings={settingsMap[UserNotificationCodes.FailedPost]}
            onChange={(checked) =>
              onChange(settingsMap[UserNotificationCodes.FailedPost], checked)
            }
          />
          <NotificationToggle
            title="Channel connections"
            description="Get notified every time your channel is disconnected or the connection needs to be refreshed."
            settings={settingsMap[UserNotificationCodes.ChannelError]}
            onChange={(checked) =>
              onChange(settingsMap[UserNotificationCodes.ChannelError], checked)
            }
          />

          <NotificationToggle
            title="Viraly Newsletter"
            description="Get notified when a new newsletter is available."
            settings={settingsMap[UserNotificationCodes.Newsletter]}
            onChange={(checked) =>
              onChange(settingsMap[UserNotificationCodes.Newsletter], checked)
            }
          />
        </div>
      )}
    </>
  );
}

import { useContext } from "react";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import { authorizeScope } from "../utils/auth-utils";
import { UserRole } from "../models/entities/user";
import { PermissionCode } from "../services/application/permissions-service";
import { PlanTier } from "../models/stripe/subscription";

export default function useAuthorizedAccess(
  permissions: PermissionCode[],
  roles: UserRole[],
  tiers: PlanTier[]
) {
  const {
    state: { currentTenant },
  } = useContext(GlobalStateContext);

  return authorizeScope(permissions, roles, tiers, currentTenant);
}

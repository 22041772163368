import { formatBytes } from "../../../../utils/file-utils";
import AttachmentSlot from "../../create-post/models/attachment-slot";
import MediaItem from "./MediaItem";

export interface GridViewProps {
  slotsByDate: Record<string, AttachmentSlot[]>;
  selectedAttachments: AttachmentSlot[];
  isSelectionMode: boolean;
  onSlotUpdated: (slot: AttachmentSlot) => void;
  onViewAttachment: (attachmentSlot: AttachmentSlot) => void;
}

export default function GridView({
  slotsByDate,
  selectedAttachments,
  isSelectionMode,
  onSlotUpdated,
  onViewAttachment,
}: GridViewProps) {
  return (
    <ul>
      {Object.keys(slotsByDate).map((groupName) => {
        return (
          <li className="group" key={groupName}>
            <div className="border-b mb-4 text-left text-md font-semibold text-gray-900 pt-8 group-first:pt-0">
              {groupName}
            </div>

            <ul
              role="list"
              className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
            >
              {slotsByDate[groupName].map((slot) => (
                <MediaItem
                  key={slot.id}
                  isGridView={true}
                  attachmentSlot={slot}
                  onSlotUpdated={onSlotUpdated}
                >
                  {({ mediaNode, favoritedNode, fileTypeNode }) => (
                    <li key={slot.id}>
                      <div
                        className="relative cursor-pointer hover:opacity-85"
                        onClick={() => onViewAttachment(slot)}
                      >
                        <div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100">
                          <div className="pointer-events-none">
                            {mediaNode}

                            <div className="absolute left-1 bottom-1">
                              {fileTypeNode}
                            </div>

                            <div className="absolute right-1 bottom-1 px-1 py-0.5 rounded-lg">
                              {favoritedNode}
                            </div>
                          </div>
                        </div>

                        {isSelectionMode && (
                          <input
                            type="checkbox"
                            readOnly={true}
                            checked={selectedAttachments.some(
                              (x) => x.id === slot.id
                            )}
                            style={{
                              boxShadow:
                                "rgb(255, 255, 255) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
                            }}
                            className="absolute top-1.5 right-1.5 border-none rounded h-5 w-5 text-primary-600 focus:ring-transparent cursor-pointer"
                          />
                        )}

                        <div className="flex items-center justify-between gap-2 truncate">
                          <div className="gap-2 items-center flex-1 truncate">
                            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-700">
                              {slot.preview.fileName}
                            </p>
                            <p className="flex justify-start items-center gap-1 pointer-events-none text-sm font-medium text-gray-500">
                              {formatBytes(slot.preview.fileSize)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </MediaItem>
              ))}
            </ul>
          </li>
        );
      })}
    </ul>
  );
}

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

export default function TwitterInfoDialog() {
  return (
    <div className="text-sm text-gray-800 font-light">
      <div>You can connect multiple X profiles to Viraly.</div>
      <div className="mt-5">
        <a
          className="text-primary-600 font-medium inline-flex gap-1 items-center"
          href="https://viraly.io/docs/troubleshooting-facebook-and-instagram-connections#checking-admin-settings"
          target="_blank"
        >
          <div className="flex gap-1 items-center">
            <ArrowTopRightOnSquareIcon className="w-[10px] h-[10px]" />
            X's The Delegate feature
          </div>
        </a>{" "}
        is currently unavailable. To link your X profile to Viraly, please
        ensure you are logged into X.com as the profile owner.
      </div>
      <div className="mt-5">
        For connecting multiple X accounts, Viraly will connect to the account{" "}
        <span className="font-medium">
          currently logged in on your browser.
        </span>{" "}
        We recommend logging into the specific account you want to connect on
        X.com first, then accessing Viraly to complete the connection process.
      </div>
    </div>
  );
}

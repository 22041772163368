import UpdateTimezoneRequest from "../../models/api-requests/update-timezone-request";
import { GetSocialSetStatsResponse } from "../../models/api-responses/get-social-set-stats";
import SocialSet from "../../models/entities/social-set";
import ApiService from "../core/api-service";

class SocialSetsService {
  list(): Promise<SocialSet[]> {
    return ApiService.get<SocialSet[]>(`social-sets`, {
      disableGlobalErrorReporting: true,
    });
  }

  get(id: string): Promise<SocialSet> {
    return ApiService.get<SocialSet>(`social-sets/${id}`, {
      disableGlobalErrorReporting: true,
    });
  }

  create(model: object): Promise<SocialSet> {
    return ApiService.post<SocialSet>(`social-sets`, model);
  }

  update(id: string, model: object): Promise<SocialSet> {
    return ApiService.put<SocialSet>(`social-sets/${id}`, model);
  }

  updateTimezone(id: string, model: UpdateTimezoneRequest): Promise<SocialSet> {
    return ApiService.put<SocialSet>(`social-sets/${id}/timezone`, model);
  }

  delete(id: string): Promise<void> {
    return ApiService.delete<void>(`social-sets/${id}`);
  }

  getStats(id: string): Promise<GetSocialSetStatsResponse> {
    return ApiService.get<GetSocialSetStatsResponse>(`social-sets/${id}/stats`);
  }
}

export default new SocialSetsService();

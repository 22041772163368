import { Outlet } from "react-router-dom";
import useAuthorizedAccess from "../../../hooks/useAuthorizedAccess";
import Unauthorized from "../../../components/common/403";
import useTieredAccess from "../../../hooks/useTieredAccess";
import LockedFeature from "../../../components/common/LockedFeature";

export default function UsersRoot() {
  const isAuthorized = useAuthorizedAccess(["users_write"], [], []);
  const hasTierAccess = useTieredAccess(["medium", "high"]);

  return (
    <>
      {!isAuthorized && <Unauthorized flat={true} />}
      {isAuthorized && !hasTierAccess && <LockedFeature flat={true} />}
      {isAuthorized && hasTierAccess && <Outlet />}
    </>
  );
}

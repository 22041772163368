import FacebookVideoFrame from "../facebook/FacebookVideoFrame";
import { PostInstance } from "../../models/post-instance";
import AttachmentSlot from "../../models/attachment-slot";
import { convertUrlsToLinks } from "../../../../../utils/text-utils";
import { Fragment, useMemo } from "react";
import DOMPurify from "dompurify";
import { ThreadsContentInstance } from "../../../../../models/entities/post";
import {
  ArrowPathRoundedSquareIcon,
  ChatBubbleOvalLeftIcon,
  EllipsisHorizontalIcon,
  HeartIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";

export interface ThreadInstancePreviewProps {
  postInstance: PostInstance;
  threadInstance: ThreadsContentInstance;
  attachments: AttachmentSlot[];
}

export default function ThreadInstancePreview({
  postInstance,
  attachments,
  threadInstance,
}: ThreadInstancePreviewProps) {
  const channel = postInstance.channel;

  const mediaContent = getMediaContent();

  const textFormatted = useMemo(() => {
    return threadInstance.message
      ? convertUrlsToLinks(
          threadInstance.message,
          "color:#5AA7FF;font-weight:500;"
        )
      : "";
  }, [threadInstance.message]);

  function getMediaContent() {
    let mediaContent = null;
    const threadInstanceAttachments = attachments.filter((x) =>
      threadInstance.attachmentIds.includes(x.id)
    );

    if (threadInstance.systemPostType == "PhotoPost") {
      mediaContent = getSingleImagePostContent(threadInstanceAttachments);
    } else if (threadInstance.systemPostType == "VideoPost") {
      mediaContent = getVideoPostContent(threadInstanceAttachments);
    } else if (threadInstance.systemPostType == "CarouselPost") {
      mediaContent = getCarouselPostContent(threadInstanceAttachments);
    }

    return mediaContent;
  }

  function getSingleImagePostContent(attachments: AttachmentSlot[]) {
    attachments = attachments.filter((x) => x.isPhoto);

    if (!attachments.length) {
      return null;
    }

    return (
      <div className="grid grid-cols-3 grid-rows-2 max-h-[55vh] h-full">
        <img
          className="col-span-3 row-span-2 w-full h-fit object-cover"
          src={attachments[0].preview.url}
        />
      </div>
    );
  }

  function getVideoPostContent(attachments: AttachmentSlot[]) {
    attachments = attachments.filter((x) => x.isVideo);

    if (!attachments.length) {
      return null;
    }

    return <FacebookVideoFrame slot={attachments[0]} />;
  }

  function getCarouselPostContent(attachments: AttachmentSlot[]) {
    return (
      <div className="overflow-x-auto py-4">
        <div className="flex gap-2 h-44">
          {attachments.map((attachment) => (
            <Fragment key={attachment.id}>
              {attachment.isPhoto && (
                <img
                  className="w-full h-full object-cover rounded-xl shadow"
                  src={attachment.preview.url}
                />
              )}

              {attachment.isVideo && (
                <FacebookVideoFrame slot={attachments[0]} />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full gap-2">
        <div className="flex flex-col items-center">
          <div className="relative">
            <img
              referrerPolicy="no-referrer"
              className="h-9 w-9 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
              src={channel.pictureUrl}
            />
            <div className="absolute right-0 bottom-0 rounded-full outline outline-white bg-gray-950">
              <PlusIcon className="w-3.5 h-3.5 text-white" />
            </div>
          </div>
          <div className="flex-1 bg-[#e5e5e5] w-0.5 mt-2 rounded-3xl"></div>
        </div>

        <div className="flex-1 flex flex-col">
          <div className="flex flex-row items-start gap-2">
            <div className="flex-1 flex items-center gap-2 text-ellipsis overflow-hidden whitespace-nowrap max-w-[250px]">
              <div className="font-medium text-[15px] leading-[18px] text-[#101318] text-ellipsis overflow-hidden">
                {channel.username}
              </div>
              <div className="font-normal text-sm text-[#636363]">9h</div>
              <div className="ml-auto">
                <EllipsisHorizontalIcon className="w-6" />
              </div>
            </div>
          </div>

          <div className="">
            {threadInstance.message?.length ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(textFormatted),
                }}
                className="font-normal text-[14px] leading-[21px] text-[#101318] whitespace-pre-wrap break-words"
              ></div>
            ) : null}

            {mediaContent && (
              <div className="mt-3 flex flex-col rounded-xl overflow-hidden w-full">
                {mediaContent}
              </div>
            )}
          </div>

          <div className="mt-3 mb-3 flex gap-3.5 ">
            <HeartIcon className="w-6 h-6 text-[#101318] heroicon-sw-2" />
            <ChatBubbleOvalLeftIcon className="w-6 h-6 text-[#101318] -scale-x-90 heroicon-sw-2" />
            <ArrowPathRoundedSquareIcon className="w-6 h-6 text-[#101318] heroicon-sw-2" />
            <PaperAirplaneIcon className="w-6 h-6 text-[#101318] heroicon-sw-2" />
          </div>
        </div>
      </div>
    </>
  );
}

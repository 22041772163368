import {
  TikTokPostConfig,
  TikTokPostType,
} from "../../../../../models/entities/post";

export interface PostTypeSelectorProps {
  tikTokConfig: TikTokPostConfig;
  onPostTypeChanged: (postType: TikTokPostType) => void;
}

export default function PostTypeSelector({
  tikTokConfig,
  onPostTypeChanged,
}: PostTypeSelectorProps) {
  return (
    <fieldset className="w-full mt-0.5">
      <div className="flex items-center space-x-10">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => onPostTypeChanged("Video")}
        >
          <input
            name="postType"
            type="radio"
            readOnly
            checked={tikTokConfig.contentOptions.postType == "Video"}
            className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer"
          />
          <label className="ml-3 block text-sm font-medium leading-6 text-gray-600 cursor-pointer">
            Video
          </label>
        </div>
        <div
          className="flex items-center cursor-pointer"
          onClick={() => onPostTypeChanged("Photo")}
        >
          <input
            name="postType"
            type="radio"
            readOnly
            checked={tikTokConfig.contentOptions.postType == "Photo"}
            className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer"
          />
          <label className="ml-3 block text-sm font-medium leading-6 text-gray-600 cursor-pointer">
            Photos
          </label>
        </div>
        <div className="flex-1"></div>
      </div>
    </fieldset>
  );
}

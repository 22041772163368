import ChannelAccountTypes from "../../data/channel-account-types";
import GoogleTokens from "../../models/google/google-tokens";
import { YouTubeCategory } from "../../models/google/youtube-category";
import YouTubeChannel from "../../models/google/youtube-channel";
import ApiService from "../core/api-service";

class GoogleService {
  getAuthorizationUrl(state: string): Promise<string> {
    return ApiService.get<string>(`external-providers/google/authorization-url`, {
      disableGlobalErrorReporting: true,
      params: {
        state: state,
      },
    });
  }

  exchangeAuthCodeForTokens(code: string): Promise<GoogleTokens> {
    return ApiService.get<GoogleTokens>(`external-providers/google/token`, {
      params: {
        authorizationCode: code,
      },
    });
  }

  getYouTubeCategories(): Promise<YouTubeCategory[]> {
    return ApiService.get<YouTubeCategory[]>(`external-providers/google/youtube-categories`);
  }

  getYouTubeChannels(tokens: GoogleTokens): Promise<YouTubeChannel[]> {
    return ApiService.post<YouTubeChannel[]>(`external-providers/google/youtube-channels`, tokens).then((channels) => {
      return channels.map((channel) => {
        channel.tokens = tokens;
        channel.channelAccountType = ChannelAccountTypes.YouTubeChannel;
        return channel;
      });
    });
  }
  // async getYouTubeChannels(tokens: GoogleTokens): Promise<YouTubeChannel[]> {
  //   await this._load();
  //   await this._init(tokens.access_token);

  //   try {
  //     const getChannelsResponse = await gapi.client.request({
  //       path: "https://youtube.googleapis.com/youtube/v3/channels",
  //       params: {
  //         part: "snippet,status",
  //         mine: "true",
  //       },
  //     });

  //     const channels = (getChannelsResponse.result as YouTubeChannelsResponse)?.items ?? [];
  //     channels.forEach((channel) => {
  //       channel.tokens = tokens;
  //       channel.channelAccountType = ChannelAccountTypes.YouTubeChannel;
  //     });

  //     return channels;
  //   } catch (e) {
  //     console.error(e);
  //     const error = e?.result?.error?.message;
  //     throw error ? new Error(error) : e;
  //   }
  // }
}

export default new GoogleService();

import { useAuthenticator } from "@aws-amplify/ui-react";
import { ArrowLongLeftIcon } from "@heroicons/react/20/solid";

export default function BackToLogin() {
  const { toSignIn } = useAuthenticator(() => []);

  return (
    <div
      className="mt-4 flex items-center justify-start gap-2 text-gray-600 hover:underline cursor-pointer text-center"
      onClick={toSignIn}
    >
      <ArrowLongLeftIcon className="w-4" />
      <span className="text-sm">Back to Sign in</span>
    </div>
  );
}

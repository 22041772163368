import { toast } from "react-toastify";

class AlertService {
  success(message: string) {
    toast.success(message);
  }

  warn(message: string) {
    toast.warn(message);
  }

  error(message: string) {
    toast.error(message);
  }

  info(message: string) {
    toast.info(message);
  }
}

export default new AlertService();

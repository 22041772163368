import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Button from "../../../../components/common/Button";
import AttachmentSlot from "../../create-post/models/attachment-slot";
import createPostHelper from "../../../../services/application/create-post-helper";

export interface UploaderProps {
  onAttached: (slots: AttachmentSlot[]) => void;
}

export default function Uploader({ onAttached }: UploaderProps) {
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const newSlotPromises = acceptedFiles.map((a) =>
      createPostHelper.getSlotFromFile(a)
    );
    const newSlots = await Promise.all(newSlotPromises);

    onAttached(newSlots);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
  });

  return (
    <>
      <div {...getRootProps()}>
        <Button
          className="w-full lg:w-auto text-left"
          variant="solid"
          color="brand"
          text="Create new post"
          style={{ width: "125px", minWidth: "125px" }}
        >
          <div className="flex justify-center">Upload Media</div>
          <input {...getInputProps()} />
        </Button>
      </div>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { PauseCircleIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
import AttachmentSlot from "../../models/attachment-slot";
import { useState } from "react";

export interface FacebookVideoFrameProps {
  slot: AttachmentSlot;
}

export default function FacebookVideoFrame({ slot }: FacebookVideoFrameProps) {
  const id = `video-${slot.id}`;
  const [playing, setPlaying] = useState<boolean>(false);

  return (
    <div className="relative h-full w-full">
      <div
        className="absolute top-0 right-0 left-0 bottom-0 z-10"
        style={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0.2) 8.17%, rgba(0, 0, 0, 0) 20.79%, rgba(0, 0, 0, 0) 71.68%, rgba(0, 0, 0, 0.2) 83.07%)",
        }}
      ></div>
      <video
        id={id}
        src={slot.preview.url}
        muted={true}
        loop={true}
        className="col-span-3 row-span-2 w-full h-full object-cover"
      ></video>

      <div className="group absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center">
        {!playing ? (
          <PlayCircleIcon
            className="text-white hover:text-white/80 w-14 cursor-pointer transition-all"
            onClick={() => {
              const video = document.getElementById(id) as any;

              if (!playing) {
                video?.play();
                video.controls = false;
                setPlaying(true);
              }
            }}
          />
        ) : (
          <PauseCircleIcon
            className="text-white hover:text-white/80 w-14 cursor-pointer transition-all opacity-0 group-hover:opacity-100"
            onClick={() => {
              const video = document.getElementById(id) as any;

              if (playing) {
                video?.pause();
                video.controls = false;
                setPlaying(false);
              }
            }}
          />
        )}
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import Button from "../common/Button";
import Spinner from "../common/Spinner";
import TenantUser from "../../models/entities/tenant-user";

const SwitchWorkspaceDialog = forwardRef(
  (
    {
      onConfirm,
      onCancel,
      title,
      message,
      confirmLabel = "Confirm",
      cancelLabel = "Cancel",
    }: any,
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tenantName, setTenantName] = useState<string>();
    const cancelButtonRef = useRef(null);

    // Expose the openDialog and closeDialog methods to parent components
    useImperativeHandle(ref, () => ({
      openDialog: (tenant: TenantUser) => {
        setTenantName(tenant?.tenant?.name);
        setIsOpen(true);
        setIsLoading(false);
      },
      closeDialog: () => setIsOpen(false),
    }));

    // Handle confirm action
    const handleConfirm = async () => {
      if (onConfirm) {
        setIsLoading(true);
        await onConfirm();
        setIsOpen(false);
      } else {
        setIsOpen(false);
      }
    };

    // Handle cancel action
    const handleCancel = () => {
      setIsOpen(false);

      if (onCancel) {
        onCancel();
      }
    };

    return (
      <Transition as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setIsOpen}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80 z-50 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 z-50">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
              {!isLoading && (
                <>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                      <InformationCircleIcon className="h-6 w-6 text-primary-600" />
                    </div>

                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-7 text-gray-900"
                      >
                        {title ? title : "Confirm"}
                      </DialogTitle>
                      <div className="mt-2">
                        <p
                          className="text-sm text-gray-500"
                          dangerouslySetInnerHTML={{
                            __html: message
                              ? message
                              : "Are you sure you want to proceed?",
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 flex justify-end gap-2">
                    <Button
                      variant="solid"
                      color="white"
                      text={cancelLabel}
                      onClick={handleCancel}
                      ref={cancelButtonRef}
                    />
                    <Button
                      variant="solid"
                      color="red"
                      text={confirmLabel}
                      onClick={handleConfirm}
                    />
                  </div>
                </>
              )}

              {isLoading && (
                <div className="w-full flex flex-col gap-3">
                  <div>
                    <Spinner size="8" />
                  </div>
                  <div className="text-center text-sm font-normal text-gray-700">
                    Preparing{" "}
                    <span className="font-semibold">{tenantName}</span> <br />
                  </div>
                </div>
              )}
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    );
  }
);

export default SwitchWorkspaceDialog;

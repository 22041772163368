import * as yup from "yup";

const maxTagsCount = (
  maxLength?: number,
  message: string = `Maximum ${maxLength} tags are allowed`
) => {
  return yup.string().test("tagsMaxLength", message, function (value) {
    return (
      maxLength == null ||
      (value ?? "")
        .split(" ")
        .map((tag) => tag.trim())
        .filter((tag) => !!tag).length <= maxLength
    );
  });
};

export default maxTagsCount;

import { Link } from "react-router-dom";
import SocialSet from "../../../../models/entities/social-set";
import { getDescription } from "../../../../utils/social-set-utils";

export default function List({ socialSets }: { socialSets: SocialSet[] }) {
  return (
    <>
      <div className="px-4 py-5 sm:p-6">
        <ul role="list" className="mt-3 flex flex-col gap-5">
          {socialSets.map((socialSet) => (
            <li
              key={socialSet.id}
              className="col-span-1 flex rounded-md shadow-xs"
            >
              <Link to={`${socialSet.id}`} className="flex flex-1 truncate">
                <div
                  style={{ backgroundColor: socialSet.colorHex }}
                  className="flex flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white w-16"
                >
                  {socialSet.code}
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white hover:bg-gray-50">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <div className="font-medium text-gray-900 hover:text-gray-600 truncate">
                      {socialSet.name}
                    </div>
                    <p className="text-gray-500">{getDescription(socialSet)}</p>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

import { useEffect, useState } from "react";
import Button from "../../../components/common/Button";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../../components/form/InputField";
import { confirmUserAttribute, updateUserAttribute } from "aws-amplify/auth";
import errorReporter from "../../../utils/error-reporter";
import { useNavigate } from "react-router-dom";

const emailSchema = yup
  .object({
    email: yup.string().required().email(),
  })
  .required();

const codeSchema = yup
  .object({
    code: yup.string().required().length(6),
  })
  .required();

export interface CollectEmailProps {
  onCompleted: () => void;
}

export default function CollectEmail({ onCompleted }: CollectEmailProps) {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<"EMAIL" | "CODE">("EMAIL");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const emailForm = useForm<{ email: string }>({
    resolver: yupResolver(emailSchema) as any,
    mode: "all",
    defaultValues: { email: "" },
  });

  const codeForm = useForm<{ code: string }>({
    resolver: yupResolver(codeSchema) as any,
    mode: "all",
    defaultValues: { code: "" },
  });

  const onSubmitEmail = async (input: { email: string }) => {
    setIsSubmitting(true);

    try {
      const result = await updateUserAttribute({
        userAttribute: {
          attributeKey: "email",
          value: input.email,
        },
      });

      setCurrentStep("CODE");
    } catch (e) {
      errorReporter.alertErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitEmailForm = async () => {
    emailForm.handleSubmit(onSubmitEmail)();
  };

  const onSubmitCode = async (input: { code: string }) => {
    setIsSubmitting(true);

    try {
      const result = await confirmUserAttribute({
        userAttributeKey: "email",
        confirmationCode: input.code,
      });

      onCompleted();
    } catch (e) {
      errorReporter.alertErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitCodeForm = async () => {
    codeForm.handleSubmit(onSubmitCode)();
  };

  return (
    <div className="h-dvh">
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col items-center justify-center gap-0 w-full max-w-md p-4 sm:p-8 space-y-4 bg-white rounded-lg shadow">
          <img
            className="w-14"
            src="/logo/viraly-logo-square-dense.png"
            alt="Viraly"
          />

          {currentStep === "EMAIL" && (
            <>
              <h1 className="text-2xl font-semibold text-gray-800">
                Enter your email
              </h1>
              <p className="text-sm text-center text-gray-600">
                To use Viraly an e-mail address is required. <br /> We'll send
                you a confirmation code to verify your email.
              </p>

              <form
                onSubmit={emailForm.handleSubmit(onSubmitEmail)}
                className="flex flex-col gap-8 items-end w-full space-y-4"
              >
                <div className="w-full mt-4">
                  <InputField
                    label=""
                    name="email"
                    placeholder="Enter your email"
                    formReturn={emailForm}
                  />
                </div>
                <div className="w-full flex items-center justify-between">
                  <Button
                    text="Log out"
                    variant="text"
                    color="slate"
                    className="-ml-4"
                    type="button"
                    onClick={() => navigate("/sign-out")}
                  />
                  <Button
                    text="Next"
                    variant="solid"
                    color="brand"
                    className="w-24"
                    type="button"
                    onClick={submitEmailForm}
                    loading={isSubmitting}
                  />
                </div>
              </form>
            </>
          )}
          {currentStep === "CODE" && (
            <>
              <h1 className="text-2xl font-semibold text-gray-800">
                Enter verification code
              </h1>
              <p className="text-sm text-center text-gray-600">
                We've sent a confirmation code to your email. <br /> Please
                enter the code to verify your account.
              </p>

              <form
                onSubmit={codeForm.handleSubmit(onSubmitCode)}
                className="flex flex-col gap-8 items-end w-full space-y-4"
              >
                <div className="w-full mt-4">
                  <InputField
                    label=""
                    name="code"
                    placeholder="Enter verification code"
                    formReturn={codeForm}
                  />
                </div>
                <Button
                  text="Submit"
                  variant="solid"
                  color="brand"
                  className="w-24"
                  type="button"
                  onClick={submitCodeForm}
                  loading={isSubmitting}
                />
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

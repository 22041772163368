import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";
import User from "../../../../../models/entities/user";
import permissionsService from "../../../../../services/application/permissions-service";
import Button from "../../../../../components/common/Button";

interface PermissionsTabProps {
  user: User;
}
export default function PermissionsTab({ user }: PermissionsTabProps) {
  const userPermissions = useMemo(() => {
    const permissions = permissionsService.getPermissions();
    return user?.permissions?.length
      ? permissions.filter((x) => user.permissions.includes(x.code))
      : permissions;
  }, [user.permissions]);

  return (
    <div>
      <dt className="flex justify-between">
        <div className="flex flex-col justify-center">
          <div className="text-base font-semibold leading-7 text-gray-900">
            Permissions
          </div>
          <div className="text-sm text-gray-500">
            {user.name} has been granted the following permissions:{" "}
          </div>
        </div>

        <div>
          <div className="flex-shrink-0 gap-0 flex">
            <Button
              to="edit"
              variant="outline"
              color="slate"
              text="Edit permissions"
            />
          </div>
        </div>
      </dt>

      <dl className="flex flex-col">
        <div className="py-6">
          <ul
            role="list"
            className="divide-y divide-gray-100 border border-gray-100 rounded-md"
          >
            {userPermissions.map((permission) => (
              <li
                key={permission.code}
                className="flex items-center justify-between py-4 px-8 text-sm leading-6"
              >
                <div className="flex w-0 flex-1 items-center">
                  <div className="flex flex-col min-w-0 flex-1 gap-0">
                    <div className="font-medium text-gray-800">
                      {permission.name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {permission.description}
                    </div>
                  </div>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <CheckCircleIcon className="h-5 w-5 text-primary-600" />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </dl>
    </div>
  );
}

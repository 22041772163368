import { useMemo } from "react";
import { ValidationResult } from "../models/validation-result";
import { uniqueArray } from "../../../../utils/shared-utils";

export interface ValidationProps {
  validationResult: ValidationResult;
  errorOwner?: "form" | "attachment";
}

export default function Validation({
  validationResult,
  errorOwner,
}: ValidationProps) {
  const errors = useMemo(() => {
    const errors = errorOwner
      ? validationResult.errors.filter((error) => error.owner === errorOwner)
      : validationResult.errors;

    return uniqueArray(errors, (error) => error.message);
  }, [validationResult.errors, errorOwner]);

  return !validationResult?.isValid && errors?.length ? (
    <div className="rounded-md bg-yellow-50 px-3 py-1.5 border border-yellow-200">
      <div className="flex">
        <div className="text-xs text-yellow-700">
          <ul role="list" className="list-none">
            {errors.map((error, index) => (
              <li key={index}>{error.message}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ) : null;
}

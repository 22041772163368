import { useReducer } from "react";
import GlobalStateContext from "./GlobalStateContext";
import reducer, { initialState } from "./reducer";

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <GlobalStateContext.Provider value={{ state, dispatch }}>{children}</GlobalStateContext.Provider>;
};

export default GlobalStateProvider;

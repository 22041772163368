import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  DocumentDuplicateIcon,
  PencilIcon,
  TrashIcon,
  RocketLaunchIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Spinner from "../../../../../components/common/Spinner";
import { useState } from "react";
import useAuthorizedAccess from "../../../../../hooks/useAuthorizedAccess";

export interface DraftPostButtonsProps {
  primaryLoading: boolean;
  onClone: () => void;
  onEdit: () => void;
  onPublishNow: () => void;
  onMoveToDraft: () => void;
  onMoveToScheduled: () => void;
  onDelete: () => void;
}

export default function DraftPostButtons({
  primaryLoading,
  onClone,
  onEdit,
  onPublishNow,
  onMoveToScheduled,
  onDelete,
}: DraftPostButtonsProps) {
  const canPublish = useAuthorizedAccess(["schedule_posts_publish"], [], []);
  const [loadingLabel, setLoadingLabel] = useState("");

  const moveToScheduled = () => {
    setLoadingLabel("Scheduling");
    onMoveToScheduled();
  };

  const publishNow = () => {
    setLoadingLabel("Publishing");
    onPublishNow();
  };

  return (
    <>
      {/* For users with "Publish" permission */}
      {canPublish && (
        <>
          {!primaryLoading && (
            <>
              <div className="inline-flex rounded-md shadow-sm">
                <button
                  onClick={onEdit}
                  type="button"
                  className="relative inline-flex items-center rounded-md bg-white px-3 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <div
                    className="flex gap-1 items-center"
                    data-tooltip-id="tooltip-placeholder"
                    data-tooltip-content="Edit Post"
                    data-tooltip-delay-show={300}
                  >
                    <PencilIcon className="w-4" />
                  </div>
                </button>
              </div>
              <div className="inline-flex rounded-md shadow-sm">
                {/* Primary Button */}
                <button
                  onClick={moveToScheduled}
                  type="button"
                  className="gap-2 relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <div className="flex gap-1 items-center">
                    <ClockIcon className="w-4" />
                    Schedule Post
                  </div>
                </button>

                <Menu as="div" className="relative -ml-px block">
                  <MenuButton className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
                  </MenuButton>

                  <MenuItems
                    transition
                    anchor="bottom end"
                    className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                  >
                    <div className="p-1">
                      <MenuItem>
                        <button
                          onClick={publishNow}
                          className="w-full group flex items-center rounded px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                          <RocketLaunchIcon
                            aria-hidden="true"
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          />
                          Publish Now
                        </button>
                      </MenuItem>
                      <MenuItem>
                        <button
                          onClick={onClone}
                          className="w-full group flex items-center rounded px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                          <DocumentDuplicateIcon
                            aria-hidden="true"
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          />
                          Duplicate
                        </button>
                      </MenuItem>
                    </div>

                    <div className="p-1">
                      <MenuItem>
                        <button
                          onClick={onDelete}
                          className="w-full group flex items-center rounded px-4 py-2 text-sm text-red-700 data-[focus]:bg-red-100 data-[focus]:text-red-900"
                        >
                          <TrashIcon
                            aria-hidden="true"
                            className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
                          />
                          Delete
                        </button>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </Menu>
              </div>
            </>
          )}

          {primaryLoading && (
            <>
              <div className="inline-flex rounded-md shadow-sm">
                <button
                  disabled={primaryLoading}
                  type="button"
                  className="gap-2 relative inline-flex items-center rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <div className="flex gap-1 items-center">{loadingLabel}</div>
                  <Spinner size="4" />
                </button>
              </div>
            </>
          )}
        </>
      )}

      {/* For users without "Publish" permission */}
      {!canPublish && (
        <>
          <div className="inline-flex rounded-md shadow-sm">
            {/* Primary Button */}
            <button
              onClick={onEdit}
              type="button"
              className="gap-2 relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <div className="flex gap-1 items-center">
                <PencilIcon className="w-4" />
                Edit Post
              </div>
            </button>

            <Menu as="div" className="relative -ml-px block">
              <MenuButton className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <span className="sr-only">Open options</span>
                <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
              </MenuButton>

              <MenuItems
                transition
                anchor="bottom end"
                className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
              >
                <div className="p-1">
                  <MenuItem>
                    <button
                      onClick={onClone}
                      className="w-full group flex items-center rounded px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      <DocumentDuplicateIcon
                        aria-hidden="true"
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      />
                      Duplicate
                    </button>
                  </MenuItem>
                </div>

                <div className="p-1">
                  <MenuItem>
                    <button
                      onClick={onDelete}
                      className="w-full group flex items-center rounded px-4 py-2 text-sm text-red-700 data-[focus]:bg-red-100 data-[focus]:text-red-900"
                    >
                      <TrashIcon
                        aria-hidden="true"
                        className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
                      />
                      Delete
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
        </>
      )}
    </>
  );
}

import TenantUser from "../../models/entities/tenant-user";
import SocialSet from "../../models/entities/social-set";
import localBrowserStorage from "../browser-storage/local-browser-storage";

class SessionService {
  public getSocialSet(socialSets: SocialSet[]): SocialSet {
    const local = localBrowserStorage.getItem("socialSetId");
    return (socialSets ?? []).find((x) => x.id == local);
  }

  public setSocialSet(socialSet?: SocialSet): void {
    localBrowserStorage.setItem("socialSetId", socialSet?.id);
  }

  public getTenant(tenants: TenantUser[]): TenantUser {
    const local = localBrowserStorage.getItem("tenantId");
    return local && tenants?.find((x) => x.tenant.id == local);
  }

  public setTenant(tenant?: TenantUser): void {
    localBrowserStorage.setItem("tenantId", tenant?.tenant.id);
  }

  public currentTenantId(): string | null {
    return localBrowserStorage.getItem("tenantId");
  }

  public setCurrentChannel(channelId: string): void {
    localBrowserStorage.setItem("channelId", channelId);
  }

  public getCurrentChannel(): string | null {
    return localBrowserStorage.getItem("channelId");
  }
}

export default new SessionService();

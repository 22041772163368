import { GlobeAltIcon } from "@heroicons/react/24/outline";
import useCurrentSocialSet from "../../hooks/useCurrentSocialSet";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export interface TimezoneLabelProps {
  showLocalTime?: boolean;
}

export default function TimezoneLabel({
  showLocalTime = true,
}: TimezoneLabelProps) {
  const socialSet = useCurrentSocialSet();
  const [localTime, setLocalTime] = useState(
    dayjs().tz(socialSet.timezone).format("MMM DD, hh:mm A")
  );

  useEffect(() => {
    if (!showLocalTime) return;

    const interval = setInterval(() => {
      setLocalTime(dayjs().tz(socialSet.timezone).format("MMM DD, hh:mm A"));
    }, 1000);

    return () => clearInterval(interval);
  }, [showLocalTime, socialSet.timezone]);

  return (
    <Link
      to={`/social-sets/${socialSet.id}/edit`}
      className="flex justify-end items-center gap-1 text-xs text-gray-600 font-medium cursor-pointer hover:text-gray-400 "
      data-tooltip-id="tooltip-placeholder"
      data-tooltip-content="Local time of current social set"
    >
      <GlobeAltIcon className="w-5" />

      <div className="flex flex-col justify-start">
        <div>{socialSet.timezone}</div>
        {showLocalTime && <div className="text-xs font-light">{localTime}</div>}
      </div>
    </Link>
  );
}

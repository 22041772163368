import {
  ChatBubbleBottomCenterIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  ShareIcon,
} from "@heroicons/react/20/solid";
import FacebookVideoFrame from "../facebook/FacebookVideoFrame";
import { PostInstance } from "../../models/post-instance";
import AttachmentSlot from "../../models/attachment-slot";

export interface YouTubeShortPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function YouTubeShortPreview({
  postInstance,
  attachments,
}: YouTubeShortPreviewProps) {
  const channel = postInstance.channel;
  const message = postInstance?.postConfig?.instagram?.contentOptions?.caption;

  if (!attachments.length) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col relative rounded-lg w-full overflow-hidden aspect-[9/16]">
        <FacebookVideoFrame slot={attachments[0]} />

        <div className="absolute bottom-0 right-0 z-10">
          <div className="flex flex-col items-center gap-6 px-3 py-4">
            <div className="rounded-full flex flex-col items-center justify-center">
              <HandThumbUpIcon className="h-5 w-5 text-white" />
              <div className="text-white text-[8px] font-normal mt-1 text-center">
                Like
              </div>
            </div>
            <div className="rounded-full flex flex-col items-center justify-center">
              <HandThumbDownIcon className="h-5 w-5 text-white" />
              <div className="text-white text-[8px] font-normal mt-1 text-center">
                Dislike
              </div>
            </div>
            <div className="rounded-full flex flex-col items-center justify-center">
              <ChatBubbleBottomCenterIcon className="h-5 w-5 text-white" />
              <div className="text-white text-[8px] font-normal mt-1 text-center">
                Comment
              </div>
            </div>

            <div className="rounded-full flex flex-col items-center justify-center">
              <ShareIcon className="h-5 w-5 text-white" />
              <div className="text-white text-[8px] font-normal mt-1 text-center">
                Share
              </div>
            </div>

            <div className="rounded-full">
              <div>
                <div className="mt-2">
                  <img
                    referrerPolicy="no-referrer"
                    className="h-[34px] w-[34px] flex-none rounded-sm bg-gray-50 outline outline-1 outline-white"
                    src={channel.pictureUrl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 right-0 left-0 z-10 mr-8 pr-2 mb-4">
          <div className="flex flex-col items-start px-4 py-3">
            {postInstance.postConfig?.youTube?.contentOptions?.title && (
              <div className="mb-4 text-white text-xs font-normal leading-4">
                {postInstance.postConfig.youTube.contentOptions.title}
              </div>
            )}
            <div className="flex items-center gap-2 max-w-[80%]">
              <img
                referrerPolicy="no-referrer"
                className="h-6 w-6 flex-none rounded-full"
                src={channel.pictureUrl}
              />
              <span className="text-[10px] font-semibold leading-3 text-white text-ellipsis whitespace-nowrap overflow-hidden">
                {channel.name}
              </span>{" "}
              <span className="text-[10px] font-medium leading-3 text-white bg-[#b80000] p-1 rounded-sm">
                Subscribe
              </span>{" "}
            </div>
            {message ? (
              <div className="text-white text-[10px] leading-[14px] font-light line-clamp-1 mt-2 whitespace-break-spaces">
                {message}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

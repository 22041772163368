/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import Button from "../common/Button";
import GlobalStateContext from "../../state/global-state/GlobalStateContext";
import Lottie from "react-lottie-player";
import discountUnlockedAnimation from "../../assets/animations/discount-unlocked.json";

const CouponAppliedDialog = forwardRef(({ onClose }: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const { state } = useContext(GlobalStateContext);
  const subscription = state.currentTenant.subscription;

  // Expose the openDialog and closeDialog methods to parent components
  useImperativeHandle(ref, () => ({
    openDialog: () => setIsOpen(true),
    closeDialog: () => setIsOpen(false),
  }));

  const handleClose = () => {
    setIsOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <Transition as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogBackdrop className="fixed inset-0 bg-gray-900/80 z-50 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 z-50">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg ">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-secondary-100 sm:mx-0">
                <Lottie
                  loop
                  animationData={discountUnlockedAnimation}
                  play
                  style={{ height: 300 }}
                />
              </div>
              <div className="flex-1 mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-7 text-gray-900"
                >
                  Congratulations!
                </DialogTitle>
                <div className="mt-2">
                  <div>
                    <div className="text-sm mb-4">
                      <div>
                        <span className="font-semibold">
                          {" "}
                          {
                            subscription?.items?.[0]?.discounts?.[0]?.coupon
                              ?.name
                          }
                        </span>{" "}
                        promo coupon has been applied.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 flex justify-end gap-2">
              <Button
                variant="solid"
                color="brand"
                text="Got it!"
                onClick={handleClose}
              />
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  );
});

export default CouponAppliedDialog;

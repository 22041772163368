/* eslint-disable @typescript-eslint/no-explicit-any */

import { Fragment } from "react";
import User from "../../../../../models/entities/user";
import clsx from "clsx";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/react";
import ProfileTab from "./ProfileTab";
import PermissionsTab from "./PermissionsTab";
import SocialSetsTab from "./SocialSetsTab";
import UserStatus from "../../list/components/UserStatus";

interface UserProfileProps {
  user: User;
  onRefetch;
}

export default function UserProfile({ user }: UserProfileProps) {
  const tabs = [
    { name: "Profile", component: <ProfileTab user={user} /> },
    { name: "Permissions", component: <PermissionsTab user={user} /> },
    { name: "Social sets", component: <SocialSetsTab user={user} /> },
  ];

  return (
    <>
      <div className="bg-white">
        {/* Header section */}
        {/* <div className=" bg-white pb-5">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="flex gap-4 items-center">
              <div className="flex flex-shrink-0 items-center justify-center rounded-full text-sm font-medium text-white w-9 h-9">
                <div className="h-11 w-11 flex-shrink-0">
                  {user.picture && (
                    <img
                      className="h-11 w-11 rounded-full shadow"
                      referrerPolicy="no-referrer"
                      src={user.picture}
                      alt="User picture"
                    />
                  )}
                  {!user.picture && (
                    <span className="inline-block h-11 w-11 overflow-hidden rounded-full bg-slate-200 shadow-sm">
                      <svg className="h-full w-full text-slate-400" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-col">
                <h3 className="text-base font-semibold leading-7 text-gray-900">{user.name}</h3>
                <p className="text-sm text-gray-500 ">View, edit or delete user profile</p>
              </div>
            </div>
          </div>
        </div> */}

        <div>
          <div>
            <div>
              <img
                className="w-full h-24 object-cover rounded-lg shadow-sm"
                src="https://images.unsplash.com/photo-1596387451750-f7bfb51461ef?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                alt=""
              />
            </div>
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="-mt-6 sm:flex sm:items-end sm:space-x-5">
                <div className="flex">
                  {user.picture && (
                    <img
                      className="h-11 w-11 rounded-full shadow-lg ring-2 ring-white"
                      referrerPolicy="no-referrer"
                      src={user.picture}
                      alt="User picture"
                    />
                  )}
                  {!user.picture && (
                    <span className="inline-block h-11 w-11 overflow-hidden rounded-full bg-slate-200 shadow-sm ring-2 ring-white">
                      <svg
                        className="h-full w-full text-slate-400"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center mt-6 min-w-0 flex-1">
                <h1 className="truncate text-2xl font-bold text-gray-900">
                  {user.name}
                </h1>
                <UserStatus user={user} />
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <TabGroup className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <TabList className="-mb-px flex space-x-8 overflow-x-auto border-b border-gray-200">
              {tabs.map((tab) => (
                <Tab key={tab.name} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={clsx(
                        selected && "border-pink-500 text-gray-900",
                        !selected &&
                          "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                      )}
                    >
                      {tab.name}
                    </button>
                  )}
                </Tab>
              ))}
            </TabList>
            <TabPanels className="flex-1 pb-5 mt-8">
              {tabs.map((tab) => (
                <TabPanel key={tab.name}>{tab.component}</TabPanel>
              ))}
            </TabPanels>
          </TabGroup>
        </div>
      </div>
    </>
  );
}

import {
  GlobeAsiaAustraliaIcon,
  EllipsisHorizontalIcon,
  HandThumbUpIcon,
  ChatBubbleOvalLeftIcon,
  ShareIcon,
} from "@heroicons/react/20/solid";
import FacebookVideoFrame from "./FacebookVideoFrame";
import { PauseIcon, SpeakerWaveIcon } from "@heroicons/react/20/solid";
import { PostInstance } from "../../models/post-instance";
import AttachmentSlot from "../../models/attachment-slot";
import { convertUrlsToLinks } from "../../../../../utils/text-utils";
import { useMemo } from "react";
import DOMPurify from "dompurify";

export interface FacebookReelPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function FacebookReelPreview({
  postInstance,
  attachments,
}: FacebookReelPreviewProps) {
  const channel = postInstance.channel;
  const facebookPostConfig = postInstance?.postConfig.facebook;

  const textFormatted = useMemo(() => {
    return attachments.length && facebookPostConfig.contentOptions?.message
      ? convertUrlsToLinks(
          facebookPostConfig.contentOptions.message,
          "color:#FFF;font-weight:600;"
        )
      : "";
  }, [attachments.length, facebookPostConfig.contentOptions.message]);

  if (!attachments.length) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col relative rounded-lg w-full overflow-hidden aspect-[9/16]">
        <FacebookVideoFrame slot={attachments[0]} />

        <div className="absolute top-0 left-0 right-0 z-10">
          <div className="flex flex-row items-center gap-3 px-4 py-3">
            <div>
              <img
                referrerPolicy="no-referrer"
                className="h-10 w-10 flex-none rounded-full bg-gray-50"
                src={channel.pictureUrl}
              />
            </div>
            <div className="flex-1 flex flex-col">
              <div className="font-semibold text-[15px] leading-5 text-white">
                {channel.name}
              </div>
              <div className="flex flex-row items-center gap-1">
                <span className="text-[13px] leading-4 text-white font-normal">
                  Reels {" · "}
                </span>
                <span className="text-[13px] leading-4 text-white font-normal">
                  Just now {" · "}
                </span>
                <GlobeAsiaAustraliaIcon className="w-3.5 text-white" />
              </div>
            </div>
            <div className="text-white flex gap-2">
              <PauseIcon className="h-5 text-white" />
              <SpeakerWaveIcon className="h-5 text-white" />
              <EllipsisHorizontalIcon className="h-5 text-white font-extrabold" />
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 right-0 z-10">
          <div className="flex flex-col items-center gap-4 px-4 py-8">
            <div className="rounded-full bg-black/50 p-2">
              <HandThumbUpIcon className="h-5 text-white" />
            </div>
            <div className="rounded-full bg-black/50 p-2">
              <ChatBubbleOvalLeftIcon className="w-5 text-white scale-x-[-1]" />
            </div>
            <div className="rounded-full bg-black/50 p-2">
              <ShareIcon className="h-5 text-white" />
            </div>
          </div>
        </div>

        {textFormatted ? (
          <div className="absolute bottom-0 right-0 left-0 z-10 mr-8">
            <div className="flex flex-col items-start px-4 py-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(textFormatted),
                }}
                className="text-white text-[15px] leading-5 font-normal line-clamp-3 whitespace-pre-wrap break-words"
              ></div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

import Hashtag from "../../models/entities/hashtag";
import ApiService from "../core/api-service";

class HashtagService {
  list(socialSetId: string): Promise<Hashtag[]> {
    return ApiService.get<Hashtag[]>(`hashtags`, {
      params: {
        socialSetId,
      },
    });
  }

  get(id: string): Promise<Hashtag> {
    return ApiService.get<Hashtag>(`hashtags/${id}`);
  }

  create(model: object): Promise<Hashtag> {
    return ApiService.post<Hashtag>(`hashtags`, model);
  }

  update(id: string, model: object): Promise<Hashtag> {
    return ApiService.put<Hashtag>(`hashtags/${id}`, model);
  }

  delete(id: string): Promise<void> {
    return ApiService.delete<void>(`hashtags/${id}`);
  }
}

export default new HashtagService();

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import inviteService from "../../services/application/invite-service";
import { useNavigate } from "react-router-dom";

export default function Invite() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const inviteCode = new URLSearchParams(search).get("inviteCode");

  useEffect(() => {
    if (inviteCode) {
      console.log("Setting invite code");
      inviteService.setInviteCode(inviteCode);
    }

    navigate("/");
  }, [inviteCode, navigate]);

  return (
    <div>
      <h1>Invite {inviteCode}</h1>
    </div>
  );
}

import FacebookVideoFrame from "../facebook/FacebookVideoFrame";
import { PostInstance } from "../../models/post-instance";
import clsx from "clsx";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import AttachmentSlot from "../../models/attachment-slot";

export interface PinterestPinPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function PinterestPinPreview({
  postInstance,
  attachments,
}: PinterestPinPreviewProps) {
  const channel = postInstance.channel;
  const pinterestPostConfig = postInstance.postConfig.pinterest;

  const mediaContent = getMediaContent();

  function getMediaContent() {
    let mediaContent = null;

    if (attachments.length == 1) {
      if (attachments[0].isPhoto) {
        mediaContent = (
          <div className="grid grid-cols-3 grid-rows-2 h-full max-h-[55vh]">
            <img
              className="col-span-3 row-span-2 w-full h-fit object-cover"
              src={attachments[0].preview.url}
            />
          </div>
        );
      } else if (attachments[0].isVideo) {
        mediaContent = <FacebookVideoFrame slot={attachments[0]} />;
      }
    } else {
      mediaContent = (
        <div className="relative">
          <div className="grid grid-cols-3 grid-rows-2 h-full max-h-[55vh]">
            <img
              className="col-span-3 row-span-2 w-full h-fit object-cover"
              src={attachments[0].preview.url}
            />
          </div>
          <div className="absolute bottom-0 left-0 right-0 flex justify-center mb-4 gap-2">
            {attachments.map((slot, index) => (
              <div key={slot.id}>
                <div
                  className={clsx(
                    "h-2 w-2 flex-none rounded-full",
                    index == 0 ? "bg-white" : "bg-white/70"
                  )}
                ></div>
              </div>
            ))}
          </div>
          <div className="absolute bottom-[50%] mt-4 left-0 right-0 flex justify-between">
            <div className="pt-4">
              <ChevronLeftIcon className="h-8 text-white" />
            </div>
            <div className="pt-4">
              <ChevronRightIcon className="h-8 text-white" />
            </div>
          </div>
        </div>
      );
    }

    return mediaContent;
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col rounded-[1.25rem] overflow-hidden w-full">
          <div className="">{mediaContent}</div>
        </div>

        <div className="flex flex-col z-10">
          <div className="flex flex-row items-center gap-2 px-4 py-3">
            <div>
              <img
                referrerPolicy="no-referrer"
                className="h-8 w-8 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                src={channel.pictureUrl}
              />
            </div>
            <div className="flex-1 flex flex-col">
              <div className="font-semibold text-sm text-gray-950">
                {channel.name}
              </div>
            </div>
          </div>

          <div className="max-w-[18rem] overflow-hidden">
            {pinterestPostConfig?.contentOptions.link && (
              <div className="block mb-2 px-4 underline truncate text-sm font-light">
                {pinterestPostConfig.contentOptions.link}
              </div>
            )}

            {pinterestPostConfig.contentOptions.title ? (
              <div className="px-4 text-[15px] font-semibold leading-5 text-gray-900 whitespace-pre-line break-words">
                {pinterestPostConfig.contentOptions.title}
              </div>
            ) : null}

            {pinterestPostConfig.contentOptions.description ? (
              <div className="px-4 pb-3 text-[15px] font-normal leading-5 text-gray-900 whitespace-pre-line break-words">
                {pinterestPostConfig.contentOptions.description}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

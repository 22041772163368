/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";

const GenericDialog = forwardRef(({ children }: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  // Expose the openDialog and closeDialog methods to parent components
  useImperativeHandle(ref, () => ({
    openDialog: () => setIsOpen(true),
    closeDialog: () => setIsOpen(false),
  }));

  return (
    <Transition as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80 z-50 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 z-50">
          <DialogPanel className="relative transform rounded-lg overflow-hidden bg-white shadow-xl transition-all">
            {children}
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  );
});

export default GenericDialog;

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Button from "../../../../components/common/Button";
import { ChannelType } from "../../../../models/entities/post";
import ChannelHelpLinks from "../../../../data/channel-help-links";
import intercomService from "../../../../services/application/intercom-service";

interface HelpLinksDropdownProps {
  channelType: ChannelType;
}

export default function HelpLinksDropdown({
  channelType,
}: HelpLinksDropdownProps) {
  const links = ChannelHelpLinks[channelType] ?? [];

  const contactSupport = () => {
    intercomService.openChat();
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton as="div">
        <Button variant="outline" text="">
          <QuestionMarkCircleIcon
            aria-hidden="true"
            className="mr-1 -ml-2 h-5 w-5"
          />
          Need Help
        </Button>
      </MenuButton>

      <MenuItems
        transition
        anchor="bottom start"
        className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-50 w-80 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      >
        <div className="py-1">
          {links.map((link, index) => (
            <MenuItem key={index}>
              <a
                href={link.link}
                target="_blank"
                title={link.title}
                className="block px-4 py-2 text-sm leading-4 text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 truncate"
              >
                {link.title}
              </a>
            </MenuItem>
          ))}
          <MenuItem>
            <div
              className="block px-4 py-2 text-sm leading-4 text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer"
              onClick={contactSupport}
            >
              Contact Support
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
}

import { useContext, useMemo } from "react";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import TenantUser from "../models/entities/tenant-user";

const useCurrentTenant = (): TenantUser | null => {
  const { state } = useContext(GlobalStateContext);

  const tenant = useMemo(() => {
    return state?.currentTenant;
  }, [state.currentTenant]);

  return tenant;
};

export default useCurrentTenant;

import sessionBrowserStorage from "../browser-storage/session-browser-storage";

class CouponSignupService {
  private static SIGNUP_COUPON_ID: string = "SIGNUP_COUPON_ID";

  public getCouponId(): string {
    return sessionBrowserStorage.getItem("signupCouponId");
  }

  public setCouponId(couponId: string): void {
    sessionBrowserStorage.setItem("signupCouponId", couponId);
  }

  public clearCouponId(): void {
    sessionBrowserStorage.removeItem("signupCouponId");
  }
}

export default new CouponSignupService();

export default function TikTokInfoDialog() {
  return (
    <div className="text-sm text-gray-800 font-light">
      <p>
        You can connect multiple{" "}
        <span className="font-medium">Business or Creator TikTok</span> accounts
        to Viraly.
      </p>

      <p className="mt-5">
        To connect a TikTok account, first log into the TikTok account you want
        to link to Viraly. Then, grant Viraly permission to access this account.
      </p>
    </div>
  );
}

import { useMemo } from "react";
import { LinkedInPostConfig } from "../../../../../models/entities/post";
import { convertUrlsToLinks } from "../../../../../utils/text-utils";
import DOMPurify from "dompurify";
import Channel from "../../../../../models/entities/channel";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

interface LinkedInCommentProps {
  linkedInPostConfig: LinkedInPostConfig;
  channel: Channel;
}

export default function LinkedInComment({
  linkedInPostConfig,
  channel,
}: LinkedInCommentProps) {
  const commentFormatted = useMemo(() => {
    return linkedInPostConfig.firstCommentOptions?.comment
      ? convertUrlsToLinks(
          linkedInPostConfig.firstCommentOptions?.comment,
          "color:#5AA7FF;font-weight:500;"
        )
      : "";
  }, [linkedInPostConfig.firstCommentOptions?.comment]);

  return commentFormatted ? (
    <div className="flex flex-row gap-2 px-4 py-2 border-t">
      <div>
        <img
          referrerPolicy="no-referrer"
          className="h-7 w-7 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
          src={channel.pictureUrl}
        />
      </div>
      <div className="flex-1 flex flex-col bg-gray-100 rounded-lg p-3 truncate">
        <div className="flex items-center gap-2 truncate">
          <div className="font-medium text-xs text-gray-950 truncate">
            {channel.name}
          </div>

          <span className="inline-flex items-center rounded-sm bg-gray-600/90 px-1.5 py-px text-[9px] font-medium text-white">
            Author
          </span>

          <EllipsisHorizontalIcon className="h-4 w-4 text-gray-700 ml-auto" />
        </div>
        <div
          className="text-xs font-normal text-gray-900 whitespace-pre-wrap overflow-hidden line-clamp-5 break-words"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(commentFormatted),
          }}
        ></div>
      </div>
    </div>
  ) : null;
}

import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ValidationResult } from "../models/validation-result";
import clsx from "clsx";
import { ReactNode, useMemo } from "react";
import { Tooltip } from "react-tooltip";

export interface ErrorsAlertContainerProps {
  validation: ValidationResult;
  showTooltip?: boolean;
  disabled?: boolean;
  size: number;
  children?: ReactNode | ((alertIcon: ReactNode) => ReactNode);
}
export default function ErrorsAlertContainer({
  validation,
  size,
  children,
  showTooltip = true,
  disabled = false,
}: ErrorsAlertContainerProps) {
  const icon = (
    <>
      {validation && !validation.isValid && (
        <div className="bg-red-400 rounded-full p-0.5 border border-red-200">
          <span className="hidden w-2.5"></span>
          <ExclamationTriangleIcon className={clsx("text-red-100", `w-${size}`)} />
        </div>
      )}
    </>
  );

  const id = useMemo(() => {
    return `errors-alert-container-${Math.random().toString(36).substring(7)}`;
  }, []);

  return (
    <>
      <div
        data-tooltip-id={showTooltip && validation.errors.length ? id : undefined}
        data-tooltip-html={`<ul class="list-disc pl-3">${validation.errors
          .map((e) => `<li>${e.message}</li>`)
          .join("")}</ul>`}
      >
        {typeof children === "function" ? children(disabled ? null : icon) : children}
      </div>

      <Tooltip id={id} className="viraly-tooltip" />
    </>
  );
}

import UpdateMediaRequest from "../../models/api-requests/update-media-request";
import Attachment, { AttachmentType } from "../../models/entities/attachment";
import ApiService from "../core/api-service";

class MediaLibraryService {
  getAttachments(
    socialSetId: string,
    type?: AttachmentType,
    search?: string,
    favorite?: boolean
  ): Promise<Attachment[]> {
    const params = new URLSearchParams();

    params.append("socialSetId", socialSetId);

    if (type) params.append("type", type);
    if (search) params.append("search", search);
    if (favorite !== undefined) params.append("favorite", String(favorite));

    const queryString = params.toString() ? `?${params.toString()}` : "";

    return ApiService.get<Attachment[]>(
      `media-library/attachments${queryString}`
    );
  }

  deleteAttachment(id: string): Promise<void> {
    return ApiService.delete(`media-library/attachments/${id}`);
  }

  addToFavorites(id: string): Promise<Attachment> {
    return ApiService.put<Attachment>(
      `media-library/attachments/${id}/favourite`,
      {}
    );
  }

  removeFromFavorites(id: string): Promise<Attachment> {
    return ApiService.put<Attachment>(
      `media-library/attachments/${id}/unfavourite`,
      {}
    );
  }

  updateAttachment(id: string, model: UpdateMediaRequest): Promise<Attachment> {
    return ApiService.put<Attachment>(`media-library/attachments/${id}`, model);
  }
}

export default new MediaLibraryService();

import {
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import CreatePost from "../../modules/scheduler/create-post/CreatePost";
import Post from "../../models/entities/post";
import DeleteDialog from "./DeleteDialog";
import useCurrentSocialSet from "../../hooks/useCurrentSocialSet";
import { useNavigate } from "react-router-dom";
import AlertDialog from "./AlertDialog";
import Button from "../common/Button";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import intercomService from "../../services/application/intercom-service";

export interface CreatePostDialogProps {
  onPostSubmitted: (posts: Post[]) => void;
  onClosed?: () => void;
}

export interface OpenComposerParams {
  editingPost?: Post;
  cloningPost?: Post;
  channelIds?: string[];
  scheduledAt?: Date;
}

const CreatePostDialog = forwardRef(
  ({ onPostSubmitted, onClosed }: CreatePostDialogProps, ref) => {
    const socialSet = useCurrentSocialSet();
    const navigate = useNavigate();
    const { isMd } = useBreakpoint("md");

    const [isOpen, setIsOpen] = useState(false);
    const [editingPost, setEditingPost] = useState<Post>(null);
    const [cloningPost, setCloningPost] = useState<Post>(null);
    const [channelIds, setChannelIds] = useState<string[]>([]);
    const [scheduledAt, setScheduledAt] = useState<Date>(null);

    const cancelButtonRef = useRef(null);
    const discardChangesDialogRef = useRef(null);
    const noChannelsDialogRef = useRef(null);

    // Expose the openDialog and closeDialog methods to parent components
    useImperativeHandle(ref, () => ({
      openDialog: ({
        editingPost,
        cloningPost,
        channelIds,
        scheduledAt,
      }: OpenComposerParams) => {
        if (!socialSet.channels.length) {
          noChannelsDialogRef.current.openDialog();
          navigate(`/social-sets/${socialSet?.id}`);
          return;
        }

        setEditingPost(editingPost);
        setCloningPost(cloningPost);
        setChannelIds(channelIds);
        setScheduledAt(scheduledAt);

        if (!isMd) {
          intercomService.hideLauncher();
        }

        setIsOpen(true);
      },
      closeDialog: () => {
        intercomService.showLauncher();
        setIsOpen(false);
      },
    }));

    const handleOnClose = async () => {
      discardChangesDialogRef.current.openDialog();
    };

    const onConfirmDiscardChanges = (): void => {
      setIsOpen(false);
      intercomService.showLauncher();
      onClosed && onClosed();
    };

    const handleOnPostSubmitted = (posts: Post[]): void => {
      setIsOpen(false);
      onPostSubmitted && onPostSubmitted(posts);
    };

    return (
      <>
        <Transition as={Fragment} show={isOpen}>
          <Dialog
            as="div"
            className="relative z-50"
            initialFocus={cancelButtonRef}
            onClose={handleOnClose}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80 z-50 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 flex w-screen items-center justify-center z-50">
              <DialogPanel className="relative transform overflow-hidden w-auto">
                <CreatePost
                  onPostSubmitted={handleOnPostSubmitted}
                  post={editingPost}
                  clonedPost={cloningPost}
                  dateTime={scheduledAt}
                  preselectedChannelIds={channelIds}
                  onClose={handleOnClose}
                />
              </DialogPanel>
            </div>
          </Dialog>
        </Transition>

        <DeleteDialog
          title="Discard Changes?"
          message="You'll lose all your changes, this can't be undone."
          confirmLabel="Discard Changes"
          cancelLabel="Keep Editing"
          ref={discardChangesDialogRef}
          onConfirm={onConfirmDiscardChanges}
        />

        <AlertDialog
          title="Woops!"
          message="You haven't connected any social channels yet to your currently selected social set."
          ref={noChannelsDialogRef}
          actions={
            <>
              <Button
                to={`/social-sets/${socialSet?.id}/channels`}
                variant="solid"
                color="brand"
                text="Connect channel"
              />
            </>
          }
        />
      </>
    );
  }
);

export default CreatePostDialog;

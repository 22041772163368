import { InformationCircleIcon, SparklesIcon } from "@heroicons/react/20/solid";
import { ChannelType } from "../../../../../models/entities/post";
import { useEffect, useState } from "react";
import eventBusService from "../../../../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../../../../services/application/event-bus/event-bus-events";
import {
  ArrowPathIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import TextAreaField from "../../../../../components/form/TextAreaField";
import Button from "../../../../../components/common/Button";
import generatingAnimation from "../../../../../assets/animations/ai-generating-stars.json";
import Lottie from "react-lottie-player";
import generativeAiService from "../../../../../services/api/generative-ai-service";
import { GenerateCaptionCommand } from "../../../../../models/api-requests/generate-post-caption-request";
import alertService from "../../../../../services/alert/alert-service";
import shortenIcon from "../../../../../assets/images/ai-icons/shorten.svg";
import expandIcon from "../../../../../assets/images/ai-icons/expand.svg";
import rephraseIcon from "../../../../../assets/images/ai-icons/rephrase.svg";
import AiAssistantHeader from "./Header";
import TonePicker from "./TonePicker";
import generatingPhrases from "../../../../../data/ai-assistant-generating-phrases";

type AiAssistantMode = "WriteNew" | "ModifyContent";

export interface AiAssistantProps {
  channelType?: ChannelType;
  onClose: () => void;
}

export interface AiAssistantUpdateData {
  content?: string;
}

export default function AiAssistant({
  channelType,
  onClose,
}: AiAssistantProps) {
  const [data, setData] = useState<AiAssistantUpdateData>({});
  const [caption, setCaption] = useState<string>(null);
  const [result, setResult] = useState<string>(null);
  const [generating, setGenerating] = useState<boolean>(false);
  const [mode, setMode] = useState<AiAssistantMode>("WriteNew");
  const [lastCommand, setLastCommand] = useState<GenerateCaptionCommand>(null);
  const [lastContent, setLastContent] = useState<string>(null);
  const [generatingPhrase, setGeneratingPhrase] = useState<string>("");
  const [tone, setTone] = useState<string>("Neutral");

  const form = useForm({
    defaultValues: {
      caption: "",
    },
  });

  const pickPhrase = () => {
    return generatingPhrases[
      Math.floor(Math.random() * generatingPhrases.length)
    ];
  };

  const updateContent = (data: AiAssistantUpdateData) => {
    setData(data);
    setMode(data.content?.length ? "ModifyContent" : "WriteNew");
    setLastContent(data.content);
  };

  useEffect(() => {
    eventBusService.on(
      EventBusEvents.UPDATE_AI_ASSISTANT_CONTENT,
      updateContent
    );

    return () => {
      eventBusService.remove(
        EventBusEvents.UPDATE_AI_ASSISTANT_CONTENT,
        updateContent
      );
    };
  }, []);

  useEffect(() => {
    setResult(null);
    // setLastCommand(null);
    // setLastContent(null);
  }, [mode]);

  useEffect(() => {
    setGeneratingPhrase(pickPhrase());
  }, [generating]);

  const runCommand = async (command: GenerateCaptionCommand) => {
    setGenerating(true);
    setLastCommand(command);

    try {
      const response = await generativeAiService.generatePostCaption(
        command,
        tone,
        lastContent,
        channelType
      );
      setResult(response);
      setLastContent(response);
    } finally {
      setGenerating(false);
    }
  };

  const retry = async () => {
    setGenerating(true);

    try {
      const response = await generativeAiService.generatePostCaption(
        lastCommand,
        tone,
        lastContent,
        channelType
      );
      setResult(response);
    } finally {
      setGenerating(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(result);
    alertService.success("Copied to clipboard");
  };

  const insertContent = () => {
    setResult(null);
    eventBusService.dispatch(EventBusEvents.INSERT_AI_CONTENT, result);

    resetForm();
  };

  const resetForm = () => {
    setCaption(null);
    setLastContent(null);
    setTone("Neutral");
    updateContent({ content: "" });
    form.setValue("caption", "");
  };

  const onToneChanged = (tone: string) => {
    setTone(tone);
  };

  return (
    <div className="flex flex-col gap-4 h-full">
      {/* Header */}
      <div className="flex flex-row gap-2 px-4 pt-4 items-center">
        <AiAssistantHeader channelType={channelType} onClose={onClose} />
      </div>

      {/* Main body */}
      <div className="flex-1 p-4 overflow-auto">
        {generating && (
          <div className="flex flex-col items-center py-6">
            <Lottie
              loop
              speed={1.25}
              animationData={generatingAnimation}
              play
              style={{ width: 50 }}
            />
            <div className="mt-6 font-semibold text-transparent bg-clip-text bg-gradient-to-r from-primary-500 via-primary-500 to-secondary-500">
              {generatingPhrase}
            </div>
          </div>
        )}

        {!generating && (
          <>
            {/* Starting a new search from scratch */}
            {mode == "WriteNew" && (
              <>
                <div className="flex flex-col gap-2">
                  <div className="text-sm font-medium leading-6 text-gray-900">
                    What do you want to write about?
                  </div>
                  {!result && (
                    <>
                      <div>
                        <TextAreaField
                          label=""
                          placeholder="Eg. Get people to attend our graphic design course. 50% off to all new participants. Registrations close in a week."
                          name="caption"
                          formHook={form}
                          onChange={(e) => {
                            setCaption(e.target.value);
                            setLastContent(e.target.value);
                          }}
                          rows={7}
                          hideLabel={true}
                        />
                      </div>
                      <div className="text-xs text-gray-500">
                        <span className="font-semibold">Quick Tip: </span>
                        Be sure to cover the essential points, clearly define
                        your audience, and have a clear goal in mind for the
                        purpose of your post.
                      </div>

                      <div className="mt-4">
                        <TonePicker onToneChanged={onToneChanged} tone={tone} />
                      </div>
                      <div className="flex justify-end mt-4">
                        <Button
                          className="mt-2"
                          variant="solid"
                          color="brand"
                          text="Generate"
                          disabled={!caption?.length}
                          onClick={() => runCommand("WriteNew")}
                        >
                          <div className="flex items-center gap-2">
                            <SparklesIcon className="w-3 h-3" /> Generate
                          </div>
                        </Button>
                      </div>
                    </>
                  )}
                  {result && (
                    <div className="flex flex-col gap-2">
                      <div className="inline-flex items-center rounded-md px-2 py-1 text-sm font-light text-gray-600 ring-1 ring-inset ring-gray-500/10">
                        {caption}
                      </div>
                      <div className="mt-4 text-sm inline-flex items-center rounded-md whitespace-break-spaces bg-purple-50 p-4 font-light text-gray-900 ring-1 ring-inset ring-purple-700/10">
                        <div className="flex flex-col">
                          <div>{result}</div>
                          <div className="flex mt-2 gap-4 items-center">
                            <DocumentDuplicateIcon
                              onClick={copyToClipboard}
                              data-tooltip-id="tooltip-placeholder"
                              data-tooltip-content="Copy text"
                              className="w-5 h-5 text-gray-800 cursor-pointer hover:text-gray-500"
                            />

                            <div
                              onClick={retry}
                              className="ml-auto flex gap-1 font-medium text-gray-800 cursor-pointer hover:text-gray-500"
                            >
                              <ArrowPathIcon className="w-5 h-5" />
                              <span>Retry</span>
                            </div>

                            <Button
                              variant="solid"
                              color="purple"
                              text="Insert"
                              onClick={insertContent}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <TonePicker onToneChanged={onToneChanged} tone={tone} />
                      </div>

                      <div className="text-sm font-medium leading-6 text-gray-900 mt-4">
                        What should I do next?
                      </div>

                      <div className="flex flex-col justify-end gap-3 flex-wrap">
                        <span
                          onClick={() => runCommand("Rephrase")}
                          className="flex gap-2 items-center rounded-md px-2 py-2 text-sm font-medium text-gray-600 ring-1 ring-inset ring-purple-700/30 cursor-pointer hover:bg-purple-50"
                        >
                          <img src={rephraseIcon} className="w-4 h-4" />{" "}
                          Rephrase
                        </span>
                        <span
                          onClick={() => runCommand("Shorten")}
                          className="flex gap-2 items-center rounded-md px-2 py-2 text-sm font-medium text-gray-700 ring-1 ring-inset ring-purple-700/30 cursor-pointer hover:bg-purple-50"
                        >
                          <img src={shortenIcon} className="w-4 h-4" /> Shorten
                        </span>
                        <span
                          onClick={() => runCommand("Expand")}
                          className="flex gap-2 items-center rounded-md px-2 py-2 text-sm font-medium text-gray-700 ring-1 ring-inset ring-purple-700/30 cursor-pointer hover:bg-purple-50"
                        >
                          <img src={expandIcon} className="w-4 h-4" /> Expand
                        </span>
                        {/* <span
                          onClick={() => runCommand("Casual")}
                          className="flex gap-2 items-center rounded-md px-2 py-2 text-sm font-medium text-gray-700 ring-1 ring-inset ring-purple-700/30 cursor-pointer hover:bg-purple-50"
                        >
                          <img src={casualIcon} className="w-4 h-4" /> More
                          Casual
                        </span>
                        <span
                          onClick={() => runCommand("Formal")}
                          className="flex gap-2 items-center rounded-md px-2 py-2 text-sm font-medium text-gray-700 ring-1 ring-inset ring-purple-700/30 cursor-pointer hover:bg-purple-50"
                        >
                          <img src={formalIcon} className="w-4 h-4" /> More
                          Formal
                        </span> */}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {/* Modifying existing user-provided content */}
            {mode == "ModifyContent" && (
              <>
                <div className="flex flex-col gap-2">
                  <div className="text-sm font-medium leading-6 text-gray-900">
                    I will help you modify this content:
                  </div>
                  {result && (
                    <div className="flex flex-col gap-2 mb-4">
                      <div className="inline-flex items-center rounded-md px-2 py-1 text-sm font-light text-gray-600 ring-1 ring-inset ring-gray-500/10">
                        {data.content}
                      </div>
                      <div className="mt-4 text-sm inline-flex items-center rounded-md whitespace-break-spaces bg-purple-50 p-4 font-light text-gray-900 ring-1 ring-inset ring-purple-700/10">
                        <div className="flex flex-col w-full">
                          <div>{result}</div>
                          <div className="flex mt-4 gap-4 items-center">
                            <DocumentDuplicateIcon
                              onClick={copyToClipboard}
                              data-tooltip-id="tooltip-placeholder"
                              data-tooltip-content="Copy text"
                              className="w-5 h-5 text-gray-800 cursor-pointer hover:text-gray-500"
                            />

                            <div
                              onClick={retry}
                              className="ml-auto flex gap-1 font-medium text-gray-800 cursor-pointer hover:text-gray-500"
                            >
                              <ArrowPathIcon className="w-5 h-5" />
                              <span>Retry</span>
                            </div>

                            <Button
                              variant="solid"
                              color="purple"
                              text="Insert"
                              onClick={insertContent}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!result && (
                    <>
                      <div className="mb-4 inline-flex items-center rounded-md px-2 py-1 text-sm font-light text-gray-600 ring-1 ring-inset ring-gray-500/10">
                        {data.content}
                      </div>
                    </>
                  )}

                  <div>
                    <TonePicker onToneChanged={onToneChanged} tone={tone} />
                  </div>

                  <div className="text-sm font-medium leading-6 text-gray-900 mt-4">
                    How can I help with this post?
                  </div>

                  <div className="flex flex-col gap-2">
                    <span
                      onClick={() => runCommand("Rephrase")}
                      className="flex gap-2 items-center rounded-md px-2 py-2 text-sm font-medium text-gray-600 ring-1 ring-inset ring-purple-700/30 cursor-pointer hover:bg-purple-50"
                    >
                      <img src={rephraseIcon} className="w-4 h-4" /> Rephrase
                      for {channelType}
                    </span>
                    <span
                      onClick={() => runCommand("Shorten")}
                      className="flex gap-2 items-center rounded-md px-2 py-2 text-sm font-medium text-gray-700 ring-1 ring-inset ring-purple-700/30 cursor-pointer hover:bg-purple-50"
                    >
                      <img src={shortenIcon} className="w-4 h-4" /> Shorten for{" "}
                      {channelType}
                    </span>
                    <span
                      onClick={() => runCommand("Expand")}
                      className="flex gap-2 items-center rounded-md px-2 py-2 text-sm font-medium text-gray-700 ring-1 ring-inset ring-purple-700/30 cursor-pointer hover:bg-purple-50"
                    >
                      <img src={expandIcon} className="w-4 h-4" /> Expand for{" "}
                      {channelType}
                    </span>

                    {/* <span
                      onClick={() => runCommand("Casual")}
                      className="flex gap-2 items-center rounded-md px-2 py-2 text-sm font-medium text-gray-700 ring-1 ring-inset ring-purple-700/30 cursor-pointer hover:bg-purple-50"
                    >
                      <img src={casualIcon} className="w-4 h-4" /> More Casual
                      for {channelType}
                    </span>
                    <span
                      onClick={() => runCommand("Formal")}
                      className="flex gap-2 items-center rounded-md px-2 py-2 text-sm font-medium text-gray-700 ring-1 ring-inset ring-purple-700/30 cursor-pointer hover:bg-purple-50"
                    >
                      <img src={formalIcon} className="w-4 h-4" /> More Formal
                      for {channelType}
                    </span> */}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {/* Footer */}
      <div className="flex items-center justify-start gap-2 p-4 border-t">
        <InformationCircleIcon className="w-5 h-5 text-gray-400" />
        <div className="text-xs text-gray-400">
          AI-generated responses may contain errors or be misleading. Be sure to
          check before using.
        </div>
      </div>
    </div>
  );
}

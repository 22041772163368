import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ExclamationCircleIcon, PlusIcon } from "@heroicons/react/20/solid";
import { useContext, useRef, useState } from "react";
import SocialSet from "../../../models/entities/social-set";
import clsx from "clsx";
import { getDescription } from "../../../utils/social-set-utils";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  ArrowsUpDownIcon,
  PlusCircleIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import Spinner from "../../../components/common/Spinner";
import actionTypes from "../../../state/global-state/action-types";
import queryNames from "../../../queries/query-names";
import { invalidateQuery } from "../../../queries/query-utils";
import useAuthorizedSocialSets from "../../../hooks/useAuthorizedSocialSets";
import { findChannelConfig } from "../../../data/channels-config";
import useCurrentChannel from "../../../hooks/useCurrentChannel";
import useCurrentSocialSet from "../../../hooks/useCurrentSocialSet";
import Channel from "../../../models/entities/channel";
import { useQuery } from "@tanstack/react-query";
import postQueueService from "../../../services/api/post-queue-service";
import PostActions from "../../../modules/shared/post-actions/PostActions";
import { OpenComposerParams } from "../../../components/dialogs/CreatePostDialog";
import sessionService from "../../../services/application/session-service";

export interface SocialSetPickerProps {
  onConnectChannelClick: () => void;
  onCreateSocialSetClick: () => void;
}

export default function SocialSetPicker({
  onConnectChannelClick,
  onCreateSocialSetClick,
}: SocialSetPickerProps) {
  const confirmDialogRef = useRef(null);
  const postComposerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [nextSocialSet, setNextSocialSet] = useState<SocialSet>(null);
  const { dispatch } = useContext(GlobalStateContext);
  const currentSocialSet = useCurrentSocialSet();
  const socialSets = useAuthorizedSocialSets();
  const currentChannel = useCurrentChannel();

  const { data: counters } = useQuery({
    queryKey: [queryNames.listAllQueuedCount, currentSocialSet.id],
    queryFn: () => postQueueService.listAllQueuedCount(currentSocialSet.id),
    staleTime: 1000 * 60 * 5, // Cache counters for 5 minutes
  });

  const selectSocialSet = (socialSet: SocialSet) => {
    if (socialSet.id == currentSocialSet?.id) {
      return;
    }

    setNextSocialSet(socialSet);
    confirmDialogRef.current.openDialog();
  };

  const onConfirm = () => {
    invalidateQuery([queryNames.postQuotas]);

    dispatch({
      type: actionTypes.SET_CURRENT_SOCIAL_SET,
      payload: nextSocialSet,
    });

    selectChannel();
  };

  const selectChannel = (channel?: Channel) => {
    dispatch({ type: actionTypes.SET_CURRENT_CHANNEL, payload: channel });
    sessionService.setCurrentChannel(channel?.id);

    if (!location.pathname.startsWith("/scheduler/")) {
      navigate(`/scheduler/calendar`);
    }
  };

  const openComposer = (params: OpenComposerParams) => {
    postComposerRef.current?.openDialog(params);
  };

  return (
    <>
      {currentSocialSet == null ? (
        <Spinner size="6" />
      ) : (
        <>
          <ul role="list" className="flex flex-col gap-2 mt-2 py-2">
            <li className="border-b pb-2 group">
              <div
                onClick={() => selectChannel()}
                className={clsx(
                  "flex items-center justify-between py-2 px-2 rounded-md cursor-pointer text-gray-800",
                  currentChannel == null
                    ? "bg-primary-50 text-primary-600"
                    : "hover:bg-gray-50"
                )}
              >
                <div className="flex items-center min-w-0 gap-x-4 w-full">
                  <div className="flex items-center justify-center w-8 h-8">
                    <RectangleStackIcon className="h-6 w-6 flex-none " />
                  </div>
                  <div className="max-w-[190px] overflow-hidden whitespace-nowrap flex-1 text-sm leading-0 text-ellipsis">
                    All Channels
                  </div>

                  <div className="ml-auto">
                    <span className="group-hover:hidden inline-flex items-center rounded-full max-h-6 py-0.5 text-sm font-medium">
                      {counters?.["all"]?.queued ?? 0}
                    </span>

                    <span
                      className="hidden group-hover:inline-flex items-center rounded-full max-h-6 py-0.5 text-sm font-medium"
                      onClick={() =>
                        openComposer({
                          channelIds: currentSocialSet.channels.map(
                            (x) => x.id
                          ),
                        })
                      }
                    >
                      <PlusCircleIcon className="h-6 w-6 -mr-1 hover:bg-primary-600 hover:text-white rounded-full" />
                    </span>
                  </div>
                </div>
              </div>
            </li>

            {currentSocialSet.channels.map((channel) => (
              <li
                onClick={() => selectChannel(channel)}
                key={channel.id}
                className={clsx(
                  "flex items-center justify-between py-2 px-2 rounded-md group",
                  currentChannel?.id == channel.id
                    ? "bg-primary-50 text-primary-600"
                    : "hover:bg-gray-50 text-gray-800 cursor-pointer"
                )}
              >
                <div className="w-full flex items-center min-w-0 gap-x-4">
                  <div className="relative">
                    <img
                      referrerPolicy="no-referrer"
                      className="h-8 w-8 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                      src={
                        channel.pictureUrl ??
                        findChannelConfig(channel.type).imageUrl
                      }
                    />
                    <img
                      className="h-5 w-5 flex-none rounded-full border border-white bg-gray-50 absolute top-3.5 right-0 -mr-2"
                      src={findChannelConfig(channel.type).imageUrl}
                    />
                  </div>
                  <div className="max-w-[190px] overflow-hidden whitespace-nowrap flex-1 text-sm leading-0 text-ellipsis">
                    {channel.name}
                  </div>

                  {channel.status == "Expired" && (
                    <Link
                      to={`social-sets/${currentSocialSet.id}`}
                      className="flex items-center gap-1 rounded-full bg-red-100 p-0.5 text-xs text-red-700 truncate"
                      data-tooltip-id="tooltip-placeholder"
                      data-tooltip-content={`Connection Expired - ${channel.errorMessage}`}
                    >
                      <ExclamationCircleIcon className="w-4" />
                    </Link>
                  )}

                  {channel.status != "Expired" && (
                    <div className="ml-auto">
                      <span className="group-hover:hidden inline-flex items-center rounded-full max-h-6 py-0.5 text-sm font-medium">
                        {counters?.[channel.id]?.queued ?? 0}
                      </span>

                      <span
                        className="hidden group-hover:inline-flex items-center rounded-full max-h-6 py-0.5 text-sm font-medium"
                        onClick={() =>
                          openComposer({
                            channelIds: [channel?.id].filter((x) => !!x),
                          })
                        }
                      >
                        <PlusCircleIcon className="h-6 w-6 -mr-1 hover:bg-primary-600 hover:text-white rounded-full" />
                      </span>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>

          <div className="sticky bottom-0 bg-white flex flex-col gap-2 mt-auto">
            <div className="flex items-center justify-between border rounded-md hover:bg-gray-50 cursor-pointer">
              <Link
                to={`social-sets/${currentSocialSet.id}/channels`}
                className="flex items-center gap-x-4 p-2 w-full"
                onClick={onConnectChannelClick}
              >
                <div className="relative">
                  <PlusIcon className="h-5 w-5 flex-none text-gray-400" />
                </div>
                <div className="max-w-[190px] overflow-hidden whitespace-nowrap flex-1 text-sm leading-0 text-gray-800 text-ellipsis">
                  Connect New Channel
                </div>
              </Link>
            </div>

            <Popover className="border-t">
              <PopoverButton className="inline-flex items-center w-full gap-x-1 text-sm font-semibold leading-1 outline-none text-gray-900 hover:bg-gray-50 px-2 mt-2 rounded-md">
                <div className="flex items-center w-full">
                  <div
                    style={{ backgroundColor: currentSocialSet.colorHex }}
                    className="flex flex-shrink-0 items-center justify-center rounded-full text-sm font-medium text-white w-9 h-9"
                  >
                    {currentSocialSet.code}
                  </div>
                  <div className="flex flex-1 items-start justify-start truncate text-start">
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                      <div className="font-medium text-gray-900 hover:text-gray-600 truncate">
                        {currentSocialSet.name}
                      </div>
                      <p className="text-gray-500 font-normal">
                        Switch social set
                      </p>
                    </div>
                  </div>
                  <ArrowsUpDownIcon className="h-5 w-5" />
                </div>
              </PopoverButton>

              <PopoverPanel
                transition
                anchor="top end"
                className="transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0 mx-4 z-50 flex overflow-hidden rounded-lg bg-white text-sm shadow-lg ring-1 ring-gray-900/5"
              >
                <div className="w-72 sm:w-xs flex-auto">
                  <div className="selection:flex flex-col max-h-[60vh] overflow-auto">
                    {socialSets.map((socialSet) => (
                      <PopoverButton
                        key={socialSet.id}
                        as="div"
                        onClick={() => selectSocialSet(socialSet)}
                        className={clsx(
                          socialSet.id == currentSocialSet.id
                            ? "bg-gray-50"
                            : "",
                          "group relative flex gap-x-3 items-center rounded-lg p-2 m-2 cursor-pointer hover:bg-gray-50"
                        )}
                      >
                        <div
                          style={{ backgroundColor: socialSet.colorHex }}
                          className="flex flex-shrink-0 items-center justify-center rounded-full text-sm font-medium text-white w-8 h-8"
                        >
                          {socialSet.code}
                        </div>
                        <div className="truncate">
                          <div className="font-semibold text-gray-900 truncate">
                            {socialSet.name}
                          </div>
                          <p className="text-gray-600">
                            {getDescription(socialSet)}
                          </p>
                        </div>
                      </PopoverButton>
                    ))}
                  </div>

                  <div className="grid grid-cols-1 divide-x divide-gray-900/5 bg-gray-50">
                    {
                      <PopoverButton
                        as={Link}
                        to="/social-sets"
                        onClick={onCreateSocialSetClick}
                        className="flex items-center justify-center gap-x-2.5 p-3 font-medium text-gray-800 hover:bg-gray-100"
                      >
                        Manage Social Sets
                      </PopoverButton>
                    }
                  </div>
                </div>
              </PopoverPanel>
            </Popover>
          </div>
        </>
      )}
      <ConfirmDialog
        title="Switch social set"
        message="After switching you will only manage channels from to the newly selected social set"
        ref={confirmDialogRef}
        onConfirm={onConfirm}
      />

      <PostActions composerRef={postComposerRef} />
    </>
  );
}

import { useEffect } from "react";

export interface OAuthRelayCallbackProps {
  to: string;
}

export default function OAuthRelayCallback({ to }: OAuthRelayCallbackProps) {
  useEffect(() => {
    window.location.href = `${to}${window.location.search}`;
  }, [to]);

  return null;
}

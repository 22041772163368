const actionTypes = {
  SET_CURRENT_SOCIAL_SET: "SET_CURRENT_SOCIAL_SET",
  SET_SOCIAL_SETS: "SET_SOCIAL_SETS",
  SET_TENANTS: "SET_TENANTS",
  SET_CURRENT_TENANT: "SET_CURRENT_TENANT",
  SET_SETTINGS_BREADCRUMBS: "SET_SETTINGS_BREADCRUMBS",
  SET_CURRENT_CHANNEL: "SET_CURRENT_CHANNEL",
};

export default actionTypes;

import clsx from "clsx";

export interface FavoritesToggleProps {
  activeTab: TabId;
  onTabChange: (tab: TabId) => void;
}

export type TabId = "All" | "Favouried";

const tabs: { name: string; value: TabId }[] = [
  { name: "All", value: "All" },
  { name: "Liked", value: "Favouried" },
];

export default function FavoritesToggle({
  activeTab,
  onTabChange,
}: FavoritesToggleProps) {
  const onTabClick = (tab: TabId) => {
    onTabChange(tab);
  };

  return (
    <nav className="w-full flex-1 flex rounded-md  border border-gray-200 gap-2 p-1">
      {tabs.map((tab) => (
        <div
          key={tab.name}
          onClick={() => onTabClick(tab.value)}
          className={clsx(
            activeTab == tab.value
              ? "text-gray-900 bg-gray-100"
              : "text-gray-600 hover:text-primary-600 hover:bg-gray-50",
            "flex-1 relative inline-flex items-center rounded px-2 py-[calc(0.26rem-1px)] text-sm font-normal transition-all cursor-pointer"
          )}
        >
          {tab.name}
        </div>
      ))}
    </nav>
  );
}

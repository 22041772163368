export const featureSections = [
  {
    id: "s:post-scheduling",
    name: "Post Scheduling",
    features: [
      { id: "f:instagram_schedule", name: "Instagram Scheduling" },
      { id: "f:facebook_schedule", name: "Facebook Scheduling" },
      { id: "f:twitter_schedule", name: "X Scheduling" },
      { id: "f:youtube_schedule", name: "YouTube Scheduling" },
      { id: "f:tiktok_schedule", name: "TikTok Scheduling" },
      { id: "f:pinterest_schedule", name: "Pinterest Scheduling" },
      { id: "f:channels_count", name: "Channels" },
      { id: "f:posts_count", name: "Posts per month" },
    ],
  },
  // {
  //   id: "s:analytics-reporting",
  //   name: "Analytics & Reporting",
  //   features: [
  //     { id: "f:influencer_analytics", name: "Influencer analytics (likes, shares, comments)" },
  //     { id: "f:advanced_analytics", name: "Advanced analytics (engagement rates, best posting times)" },
  //     { id: "f:custom_report_builder", name: "Custom report builder & export options" },
  //   ],
  // },
  {
    id: "s:team-collaboration",
    name: "Team Collaboration",
    features: [
      { id: "f:team_access", name: "Team access" },
      { id: "f:team_roles", name: "Role assignments & permissions" },
      { id: "f:realtime_collaboration", name: "Real-time collaboration" },
    ],
  },
  // {
  //   id: "s:post-sentiment-analysis",
  //   name: "Post Sentiment Analysis",
  //   features: [
  //     { id: "f:sentiment_analysis", name: "Sentiment analysis" },
  //     { id: "f:post_reaction_tracking", name: "Post reaction tracking" },
  //     { id: "f:post_comments_auto_respond", name: "Auto respond to comments" },
  //     { id: "f:post_comments_auto_like", name: "Auto like comments" },
  //     { id: "f:post_comments_auto_delete", name: "Auto delete comments" },
  //   ],
  // },
  {
    id: "s:support",
    name: "Support",
    features: [
      { id: "f:email_support", name: "Email support" },
      { id: "f:priority_support", name: "Priority support" },
      { id: "f:zoom_support", name: "Zoom support" },
      { id: "f:dedicated_account_manager", name: "Dedicated account manager" },
    ],
  },
];

export const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "per month", stripeInterval: "month" },
  { value: "yearly", label: "Yearly", priceSuffix: "per year", stripeInterval: "year" },
];

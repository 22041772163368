import clsx from "clsx";
import StripeSubscription from "../../../models/stripe/subscription";

interface SubscriptionPillProps {
  subscription?: StripeSubscription;
}

export default function SubscriptionPill({
  subscription,
}: SubscriptionPillProps) {
  if (!subscription) {
    return null;
  }

  const productName = subscription.items?.[0]?.plan?.product?.name;
  return (
    <>
      <span
        className={clsx(
          "flex justify-center rounded-full px-2 py-0.5 text-xs font-medium",
          productName == "Influencer" ? "bg-teal-100 text-teal-700" : null,
          productName == "Agency" ? "bg-purple-100 text-purple-700" : null,
          productName == "Business" ? "bg-blue-100 text-blue-700" : null
        )}
      >
        {subscription.items?.[0]?.plan?.product?.name}
      </span>
    </>
  );
}

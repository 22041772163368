/* eslint-disable @typescript-eslint/no-explicit-any */
import "./config/sentry.js";
import "./config/dayjs";
import "./config/amplify";
import "./config/dom-purity";
import "./index.css";

import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./routes";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <RouterProvider router={router} />
);

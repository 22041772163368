import dayjs from "dayjs";
import StripeSubscription from "../../../models/stripe/subscription";
import { Link } from "react-router-dom";

export interface TrialBannerProps {
  subscription?: StripeSubscription;
}

export default function TrialBanner({ subscription }: TrialBannerProps) {
  const days = !subscription
    ? 0
    : dayjs(subscription.trialEnd).diff(dayjs(), "days");

  return (
    <div className="flex items-center gap-4">
      <p className="text-sm leading-6 text-gray-900">
        <strong className="font-semibold">
          {days} day{days === 1 ? "" : "s"}
        </strong>{" "}
        <span className="hidden sm:inline">remaining in your trial</span>
        <span className="inline sm:hidden">left</span>
      </p>
      <Link
        to="/settings/plan"
        className="flex-none rounded-full bg-gray-800 px-3.5 py-1.5 text-sm font-normal text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
      >
        Activate Subscription
      </Link>
    </div>
  );
}

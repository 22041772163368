import { Fragment, forwardRef, useImperativeHandle, useState } from "react";
import { ArrowsRightLeftIcon } from "@heroicons/react/20/solid";
import Button from "../../../../components/common/Button";
import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import Lottie from "react-lottie-player";
import confettiAnimation from "../../../../assets/animations/confetti.json";
import { ChannelType } from "../../../../models/entities/post";
import { findChannelConfig } from "../../../../data/channels-config";

export interface ChannelRefreshedDialogProps {
  onCreatePost?: () => void;
}

const ChannelRefreshedDialog = forwardRef(
  ({ onCreatePost }: ChannelRefreshedDialogProps, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [channelType, setChannelType] = useState<ChannelType>(null);

    // Expose the openDialog and closeDialog methods to parent components
    useImperativeHandle(ref, () => ({
      openDialog: openDialog,
      closeDialog: closeDialog,
    }));

    const closeDialog = () => {
      setShowConfetti(false);
      setIsOpen(false);
    };

    const openDialog = (channelType: ChannelType) => {
      console.log("Open dialog");
      setChannelType(channelType);
      setShowConfetti(true);
      setIsOpen(true);
    };

    const handleCreatePost = () => {
      closeDialog();
      if (onCreatePost) {
        onCreatePost();
      }
    };

    return (
      <Transition as={Fragment} show={isOpen}>
        <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80 z-50 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 z-50">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
              <div className="flex justify-end -mt-2">
                <span
                  className="cursor-pointer font-extrabold text-xl leading-none text-gray-800 hover:text-gray-600"
                  onClick={closeDialog}
                >
                  &times;
                </span>
              </div>
              <div className="flex justify-center gap-6 mb-8 mt-2">
                <img
                  src="/logo/viraly-logo-square-dense.png"
                  className="h-8 w-auto"
                />
                <ArrowsRightLeftIcon className="w-5" />
                <img
                  src={findChannelConfig(channelType)?.imageUrl}
                  className="h-8"
                />
              </div>
              <div className="sm:flex sm:items-start mb-8">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="text-center font-semibold leading-6 text-gray-900"
                  >
                    Great! You've refreshed the connection
                  </DialogTitle>
                  <div className="mt-2">
                    {showConfetti && (
                      <div className="absolute top-0 left-0 right-0 flex justify-center bottom-9 pointer-events-none">
                        <Lottie
                          loop={0}
                          speed={1.5}
                          animationData={confettiAnimation}
                          play
                          onComplete={() => setShowConfetti(false)}
                        />
                      </div>
                    )}

                    <p className="text-center text-sm text-gray-500">
                      You've successfully refreshed the channel connection. Now
                      you can continue scheduling posts to it.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 flex justify-end gap-4">
                <Button
                  variant="solid"
                  color="brand"
                  text="Create a post"
                  onClick={handleCreatePost}
                />
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    );
  }
);

export default ChannelRefreshedDialog;

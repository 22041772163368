import { ReactNode, useContext } from "react";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import { Navigate } from "react-router-dom";

export default function ActiveSubscriptionGuard({
  children,
}: {
  children: ReactNode;
}) {
  const { state } = useContext(GlobalStateContext);
  return state.currentTenant?.subscription?.status === "canceled" ? (
    <>
      <Navigate to="/settings/plan" />
    </>
  ) : (
    children
  );
}

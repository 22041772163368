import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SocialSetProfile from "./components/SocialSetDetails";
import Breadcrumbs from "../../../components/common/BreadCrumbs";
import queryNames from "../../../queries/query-names";
import { refetchQuery } from "../../../queries/query-utils";
import { useContext, useEffect, useRef, useState } from "react";
import ChannelConnectedDialog from "./components/ChannelConnectedDialog";
import OAuthConstants from "../../../constants/oauth";
import ChannelRefreshedDialog from "./components/ChannelRefreshedDialog";
import QueryParamConstants from "../../../constants/query-params";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import NotFound from "../../../components/common/404";
import { canAccessSocialSet } from "../../../utils/auth-utils";
import Unauthorized from "../../../components/common/403";

export default function ViewSocialSet() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryData, setQueryData] = useState(null);

  const channelConnectedDialogRef = useRef(null);
  const channelRefreshedDialogRef = useRef(null);

  const { state } = useContext(GlobalStateContext);
  const socialSets = state.socialSets ?? [];
  const socialSet = socialSets?.find((x) => x.id == id);

  // Open Channel Connected/Refreshed dialog if required
  useEffect(() => {
    const channelType = searchParams.get(
      QueryParamConstants.ConnectedChannelType
    );
    const channelId = searchParams.get(QueryParamConstants.ConnectedChannelId);
    const action = searchParams.get(QueryParamConstants.Action);
    const refreshChannelId = searchParams.get(
      QueryParamConstants.RefreshChannelId
    );

    setQueryData({ channelType, channelId, action, refreshChannelId });

    if (channelType && channelId) {
      if (action == OAuthConstants.ConnectChannel) {
        channelConnectedDialogRef?.current?.openDialog(channelType);
        setSearchParams({});
      } else if (action == OAuthConstants.RefreshChannel) {
        channelRefreshedDialogRef?.current?.openDialog(channelType);
        setSearchParams({});
      }
    } else if (refreshChannelId) {
      setSearchParams({});
    }
    // No dependencies required, execute only once on load
  }, []);

  let content = null;

  if (socialSet) {
    content = canAccessSocialSet(state.currentTenant, socialSet.id) ? (
      <SocialSetProfile
        socialSet={socialSet}
        socialSets={socialSets}
        refreshChannelId={queryData?.refreshChannelId}
        onRefetch={() => {
          refetchQuery([queryNames.socialSets]);
        }}
      />
    ) : (
      <Unauthorized />
    );
  } else {
    content = <NotFound />;
  }

  return (
    <>
      <div className="flex flex-col gap-5 mx-auto">
        <Breadcrumbs
          pages={[
            {
              name: "Social Sets",
              to: "/social-sets",
            },
            {
              name: `${socialSet?.name ?? "Not Found"}`,
              to: `/social-sets/${id}`,
            },
          ]}
        />

        <div className="flex flex-col">
          {
            <>
              {content}
              <ChannelConnectedDialog
                ref={channelConnectedDialogRef}
                onConnectAnother={() => {
                  navigate(`/social-sets/${id}/channels`);
                }}
                onCreatePost={() => {
                  navigate(
                    `/scheduler/calendar/view?${QueryParamConstants.AutoOpenChannelId}=${queryData.channelId}`
                  );
                }}
              />
              <ChannelRefreshedDialog
                ref={channelRefreshedDialogRef}
                onCreatePost={() => {
                  navigate(
                    `/scheduler/calendar/view?${QueryParamConstants.AutoOpenChannelId}=${queryData.channelId}`
                  );
                }}
              />
            </>
          }
        </div>
      </div>
    </>
  );
}

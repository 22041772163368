import ComingSoon from "../../components/common/ComingSoon";

export default function Analytics() {
  const description = `Analytics is a new feature which we are actively working on and will be released in early December 2024. Stay tuned!`;

  return (
    <div>
      <div className="flex-1 flex items-center justify-center px-8 py-6 h-full">
        <ComingSoon description={description} />
      </div>
    </div>
  );
}

class InviteService {
  public getInviteCode(): string | null {
    return localStorage.getItem("ViralyInviteCode");
  }

  public setInviteCode(code: string): void {
    localStorage.setItem("ViralyInviteCode", code);
  }

  public clearInviteCode(): void {
    localStorage.removeItem("ViralyInviteCode");
  }
}

export default new InviteService();

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ArrowTopRightOnSquareIcon,
  LinkIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import Post from "../../../../../models/entities/post";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import alertService from "../../../../../services/alert/alert-service";

export interface PublishedPostButtonsProps {
  post: Post;
  onClone: () => void;
}

export default function PublishedPostButtons({
  post,
  onClone,
}: PublishedPostButtonsProps) {
  const copyLink = () => {
    const link = post.externalUrl;

    if (link) {
      navigator.clipboard.writeText(link);
      alertService.success("Link copied to clipboard");
    } else {
      alertService.error("Link not available");
    }
  };

  return (
    <>
      <div className="inline-flex rounded-md shadow-sm">
        <Link
          to={post.externalUrl}
          target="_blank"
          type="button"
          className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <div className="flex gap-1 items-center">
            <ArrowTopRightOnSquareIcon className="w-4" />
            View Post
          </div>
        </Link>
        <Menu as="div" className="relative -ml-px block">
          <MenuButton className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <span className="sr-only">Open options</span>
            <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
          </MenuButton>

          <MenuItems
            transition
            anchor="bottom end"
            className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div className="p-1">
              <MenuItem>
                <button
                  onClick={copyLink}
                  className="w-full group flex items-center rounded px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                  <LinkIcon
                    aria-hidden="true"
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  Copy Link
                </button>
              </MenuItem>
              <MenuItem>
                <button
                  onClick={onClone}
                  className="w-full group flex items-center rounded px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                  <DocumentDuplicateIcon
                    aria-hidden="true"
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  Duplicate
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </div>
    </>
  );
}

import StripeCheckoutSession from "../../models/stripe/checkout-session";
import StripeCustomerPortal, {
  CustomerPortalFlow,
} from "../../models/stripe/customer-portal";
import StripeInvoice from "../../models/stripe/invoice";
import StripePaymentMethod from "../../models/stripe/payment-method";
import StripePrice from "../../models/stripe/price";
import StripeProduct from "../../models/stripe/product";
import StripeSubscription from "../../models/stripe/subscription";
import ApiService from "../core/api-service";

class StripeService {
  listProducts(): Promise<StripeProduct[]> {
    return ApiService.get<StripeProduct[]>(`stripe/products`);
  }

  listPrices(): Promise<StripePrice[]> {
    return ApiService.get<StripePrice[]>(`stripe/prices`);
  }

  getCheckoutSession(
    priceId: string,
    trial: boolean = false,
    clientReferenceId: string = null
  ): Promise<StripeCheckoutSession> {
    return ApiService.get<StripeCheckoutSession>(`stripe/checkout-session`, {
      params: {
        priceId,
        trial,
        clientReferenceId,
      },
    });
  }

  getSetupCheckoutSession(
    successUrl: string,
    cancelUrl: string
  ): Promise<StripeCheckoutSession> {
    return ApiService.get<StripeCheckoutSession>(
      `stripe/checkout-session-setup`,
      {
        params: {
          successUrl,
          cancelUrl,
        },
      }
    );
  }

  getCustomerPortal(
    returnUrl: string,
    flow?: CustomerPortalFlow,
    newPriceId?: string
  ): Promise<StripeCustomerPortal> {
    return ApiService.get<StripeCustomerPortal>(`stripe/customer-portal`, {
      params: {
        returnUrl,
        flow,
        newPriceId,
      },
    });
  }

  getCurrentSubscription(): Promise<StripeSubscription> {
    return ApiService.get<StripeSubscription>(`stripe/subscription`);
  }

  getPaymentMethods(): Promise<StripePaymentMethod[]> {
    return ApiService.get<StripePaymentMethod[]>(`stripe/payment-methods`);
  }

  getInvoices(): Promise<StripeInvoice[]> {
    return ApiService.get<StripeInvoice[]>(`stripe/invoices`);
  }

  cancelSubscription(): Promise<StripeSubscription> {
    return ApiService.post<StripeSubscription>(
      `stripe/cancel-subscription`,
      {}
    );
  }

  restoreSubscription(): Promise<StripeSubscription> {
    return ApiService.post<StripeSubscription>(
      `stripe/restore-subscription`,
      {}
    );
  }

  endTrialEarly(paymentMethodId: string): Promise<StripeSubscription> {
    return ApiService.post<StripeSubscription>(
      `stripe/end-trial`,
      {},
      {
        params: {
          paymentMethodId,
        },
      }
    );
  }
}

export default new StripeService();

import { useEffect, useMemo, useState } from "react";
import ToggleBox from "../../../../../components/common/ToggleBox";
import permissionsService, { Permission } from "../../../../../services/application/permissions-service";
import { UseFormReturn } from "react-hook-form";
import UserUpdateFormInput from "../../../../../models/form-inputs/user-update-input";

interface PermissionsSectionProps {
  form: UseFormReturn<UserUpdateFormInput, void, UserUpdateFormInput>;
  input: UserUpdateFormInput;
  validate: () => void;
}

export default function PermissionsSection({ form, input, validate }: PermissionsSectionProps) {
  const [allPermissions, setAllPermissions] = useState(input == null ? false : input?.permissions === null);
  const permissions = useMemo(() => permissionsService.getPermissions(), []);

  const permissionsMap = useMemo(
    () =>
      permissions.reduce((accumulator, current: Permission) => {
        accumulator[current.code] =
          input == null
            ? false
            : input.permissions == null
            ? true
            : input?.permissions?.includes(current.code) ?? false;
        return accumulator;
      }, {} as Record<string, boolean>),
    [input, permissions]
  );

  const onAllPermissionsChanged = (value: boolean) => {
    setAllPermissions(value);
    Object.keys(permissionsMap).forEach((key) => (permissionsMap[key] = value));

    form.setValue("permissions", value ? null : []);
    validate();
  };

  useEffect(() => {
    if (input == null) {
      permissions.forEach((permission) => {
        permissionsMap[permission.code] = false;
      });
      form.setValue("permissions", []);

      return;
    }

    if (input?.permissions?.length > 0) {
      permissions.forEach((permission) => {
        permissionsMap[permission.code] = input.permissions?.includes(permission.code) ?? false;
      });

      form.setValue(
        "permissions",
        permissions.filter((x) => permissionsMap[x.code]).map((x) => x.code)
      );
    } else {
      permissions.forEach((permission) => {
        permissionsMap[permission.code] = true;
      });

      form.setValue("permissions", null);
    }
  }, [form, input, input?.permissions, permissions, permissionsMap]);

  return (
    <ul role="list" className="divide-y divide-gray-100 border border-gray-100 rounded-md mt-6">
      <li className="flex items-center justify-between py-4 px-8 text-sm leading-6">
        <ToggleBox
          value={allPermissions}
          label="All"
          description="Grant access to all current and future permissions."
          onChanged={onAllPermissionsChanged}
        />
      </li>
      {permissions.map((permission) => (
        <li key={permission.code} className="flex items-center justify-between py-4 px-8 text-sm leading-6">
          <ToggleBox
            value={permissionsMap[permission.code]}
            label={permission.name}
            disabled={allPermissions}
            description={permission.description}
            onChanged={(value) => {
              permissionsMap[permission.code] = value;

              const existing = form.getValues()?.permissions ?? [];
              form.setValue(
                "permissions",
                value ? [...existing, permission.code] : existing.filter((x) => x !== permission.code)
              );

              validate();
            }}
          />
        </li>
      ))}
    </ul>
  );
}

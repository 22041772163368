import localBrowserStorage from "../browser-storage/local-browser-storage";

class OnboardingService {
  public isOnboardingCompleted(tenantId: string): boolean {
    let onboardingCompleted = localBrowserStorage.getItem(
      "onboardingCompleted"
    );

    if (!onboardingCompleted) {
      onboardingCompleted = {};
    }

    return onboardingCompleted[tenantId] ?? false;
  }

  public setOnboardingCompleted(tenantId: string): void {
    let onboardingCompleted = localBrowserStorage.getItem(
      "onboardingCompleted"
    );

    if (!onboardingCompleted) {
      onboardingCompleted = {};
    }

    onboardingCompleted[tenantId] = true;
    localBrowserStorage.setItem("onboardingCompleted", onboardingCompleted);
  }
}

export default new OnboardingService();

import FacebookVideoFrame from "../facebook/FacebookVideoFrame";
import { PostInstance } from "../../models/post-instance";
import AttachmentSlot from "../../models/attachment-slot";
import { convertUrlsToLinks } from "../../../../../utils/text-utils";
import { useMemo } from "react";
import DOMPurify from "dompurify";

export interface TwitterTweetPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function TwitterTweetPreview({
  postInstance,
  attachments,
}: TwitterTweetPreviewProps) {
  const channel = postInstance.channel;
  const twitterPostConfig = postInstance.postConfig.twitter;

  const mediaContent = getMediaContent();

  const textFormatted = useMemo(() => {
    return twitterPostConfig.contentOptions?.caption
      ? convertUrlsToLinks(
          twitterPostConfig.contentOptions.caption,
          "color:#5AA7FF;font-weight:500;"
        )
      : "";
  }, [twitterPostConfig.contentOptions?.caption]);

  function getMediaContent() {
    let mediaContent = null;

    if (twitterPostConfig.contentOptions.systemPostType == "ImagePost") {
      mediaContent = getSingleImagePostContent(attachments);
    } else if (twitterPostConfig.contentOptions.systemPostType == "VideoPost") {
      mediaContent = getVideoPostContent(attachments);
    } else if (twitterPostConfig.contentOptions.systemPostType == "Carousel") {
      mediaContent = getCarouselPostContent(attachments);
    }

    if (attachments.length == 1) {
      if (attachments[0].isPhoto) {
        mediaContent = (
          <div className="grid grid-cols-3 grid-rows-2 h-full max-h-[55vh]">
            <img
              className="col-span-3 row-span-2 w-full h-fit object-cover"
              src={attachments[0].preview.url}
            />
          </div>
        );
      } else if (attachments[0].isVideo) {
        mediaContent = <FacebookVideoFrame slot={attachments[0]} />;
      }
    }

    return mediaContent;
  }

  function getSingleImagePostContent(attachments: AttachmentSlot[]) {
    attachments = attachments.filter((x) => x.isPhoto);

    if (!attachments.length) {
      return null;
    }

    return (
      <div className="grid grid-cols-3 grid-rows-2 max-h-[55vh] h-full">
        <img
          className="col-span-3 row-span-2 w-full h-fit object-cover"
          src={attachments[0].preview.url}
        />
      </div>
    );
  }

  function getVideoPostContent(attachments: AttachmentSlot[]) {
    attachments = attachments.filter((x) => x.isVideo);

    if (!attachments.length) {
      return null;
    }

    return <FacebookVideoFrame slot={attachments[0]} />;
  }

  function getCarouselPostContent(attachments: AttachmentSlot[]) {
    let mediaContent = null;
    attachments = attachments.filter((x) => x.isPhoto);

    if (attachments.length == 2) {
      const attachment1 = attachments[0];
      const attachment2 = attachments[1];

      mediaContent = (
        <div className="aspect-[5/3] overflow-hidden">
          <div className="grid grid-cols-6 grid-rows-6 gap-0.5 h-full">
            <img
              className="col-span-3 row-span-6 w-full h-full object-cover"
              src={attachment1.preview.url}
            />
            <img
              className="col-span-3 row-span-6 w-full h-full object-cover"
              src={attachment2.preview.url}
            />
          </div>
        </div>
      );
    } else if (attachments.length == 3) {
      const attachment1 = attachments[0];
      const attachment2 = attachments[1];
      const attachment3 = attachments[2];

      mediaContent = (
        <div className="aspect-[5/3] overflow-hidden">
          <div className="grid grid-cols-8 grid-rows-10 gap-0.5 h-full">
            <img
              className="col-span-4 row-span-10 w-full h-full object-cover"
              src={attachment1.preview.url}
            />
            <img
              className="col-span-4 row-span-5 w-full h-full object-cover"
              src={attachment2.preview.url}
            />
            <img
              className="col-span-4 row-span-5 w-full h-full object-cover"
              src={attachment3.preview.url}
            />
          </div>
        </div>
      );
    } else if (attachments.length == 4) {
      const attachment1 = attachments[0];
      const attachment2 = attachments[1];
      const attachment3 = attachments[2];
      const attachment4 = attachments[3];

      mediaContent = (
        <div className="aspect-[5/3] overflow-hidden">
          <div className="grid grid-cols-8 grid-rows-8 gap-0.5 h-full">
            <img
              className="col-span-4 row-span-4 w-full h-full object-cover"
              src={attachment1.preview.url}
            />
            <img
              className="col-span-4 row-span-4 w-full h-full object-cover"
              src={attachment2.preview.url}
            />
            <img
              className="col-span-4 row-span-4 w-full h-full object-cover"
              src={attachment3.preview.url}
            />
            <img
              className="col-span-4 row-span-4 w-full h-full object-cover"
              src={attachment4.preview.url}
            />
          </div>
        </div>
      );
    }

    return mediaContent;
  }

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-6">
            <div>
              <img
                referrerPolicy="no-referrer"
                className="h-6 w-6 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                src={channel.pictureUrl}
              />
            </div>
            <div className="flex items-center gap-2 text-ellipsis overflow-hidden whitespace-nowrap max-w-[250px]">
              <div className="font-semibold text-[15px] leading-[18px] text-[#101318] text-ellipsis overflow-hidden">
                {channel.name}
              </div>
              <div className="font-normal text-[14px] leading-[21px] text-[#636363] text-ellipsis overflow-hidden">
                @{channel.username}
              </div>
            </div>
          </div>
        </div>

        <div className="pl-12">
          {twitterPostConfig.contentOptions.caption?.length ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(textFormatted),
              }}
              className="pb-3 font-normal text-[14px] leading-[21px] text-[#101318] whitespace-pre-wrap break-words"
            ></div>
          ) : null}

          <div className="flex flex-col rounded-xl overflow-hidden w-full">
            {mediaContent}
          </div>
        </div>
      </div>
    </>
  );
}

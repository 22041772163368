import { PostInstance } from "../../models/post-instance";
import AttachmentSlot from "../../models/attachment-slot";
import ThreadInstancePreview from "./ThreadInstancePreview";
import { Fragment } from "react/jsx-runtime";

export interface ThreadsPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function ThreadsPreview({
  postInstance,
  attachments,
}: ThreadsPreviewProps) {
  return (
    <div className="w-full flex flex-col gap-2">
      {postInstance.postConfig.threads.contentOptions.instances.map(
        (instance) => (
          <Fragment key={instance.id}>
            <ThreadInstancePreview
              postInstance={postInstance}
              attachments={attachments}
              threadInstance={instance}
            />
          </Fragment>
        )
      )}
    </div>
  );
}

import {
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import Hashtag from "../../../../models/entities/hashtag";
import DeleteDialog from "../../../../components/dialogs/DeleteDialog";
import CreateHashtagList from "./CreateHashtagList";

export interface OpenCreateHashtagListDialogParams {
  hashtag: Hashtag;
}

export interface CreateHashtagDialogProps {
  onHashtagSubmitted: (hashtag: Hashtag) => void;
  onDeleted: (hashtag: Hashtag) => void;
  onClosed?: () => void;
}

const CreateHashtagListDialog = forwardRef(
  (
    { onHashtagSubmitted, onDeleted, onClosed }: CreateHashtagDialogProps,
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [editingHashtag, setEditingHashtag] = useState<Hashtag | null>(null);

    const cancelButtonRef = useRef(null);
    const discardChangesDialogRef = useRef(null);

    // Expose the openDialog and closeDialog methods to parent components
    useImperativeHandle(ref, () => ({
      openDialog: ({ hashtag }: OpenCreateHashtagListDialogParams) => {
        setEditingHashtag(hashtag);
        setIsOpen(true);
      },
      closeDialog: () => {
        setIsOpen(false);
      },
    }));

    const handleOnClose = async () => {
      discardChangesDialogRef.current.openDialog();
    };

    const onConfirmDiscardChanges = (): void => {
      setIsOpen(false);
      onClosed && onClosed();
    };

    const handleOnSubmitted = (hashtag: Hashtag): void => {
      setIsOpen(false);
      onHashtagSubmitted?.(hashtag);
    };

    const handleOnDeleted = (hashtag: Hashtag): void => {
      setIsOpen(false);
      onDeleted?.(hashtag);
    };

    return (
      <>
        <Transition as={Fragment} show={isOpen}>
          <Dialog
            as="div"
            className="relative z-50"
            initialFocus={cancelButtonRef}
            onClose={handleOnClose}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80 z-50 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 flex w-screen items-center justify-center z-50">
              <DialogPanel className="relative transform overflow-hidden w-auto">
                <CreateHashtagList
                  onSubmitted={handleOnSubmitted}
                  onDeleted={handleOnDeleted}
                  hashtag={editingHashtag}
                  onClose={handleOnClose}
                />
              </DialogPanel>
            </div>
          </Dialog>
        </Transition>

        <DeleteDialog
          title="Discard Changes?"
          message="You'll lose all your changes, this can't be undone."
          confirmLabel="Discard Changes"
          cancelLabel="Keep Editing"
          ref={discardChangesDialogRef}
          onConfirm={onConfirmDiscardChanges}
        />
      </>
    );
  }
);

export default CreateHashtagListDialog;

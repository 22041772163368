export interface InteractionsProps {
  comment: boolean;
  duet: boolean;
  stitch: boolean;

  commentDisabled: boolean;
  duetDisabled: boolean;
  stitchDisabled: boolean;

  onCommentChanged: (comment: boolean) => void;
  onDuetChanged: (comment: boolean) => void;
  onStitchChanged: (comment: boolean) => void;
}

export default function Interactions({
  comment,
  duet,
  stitch,
  commentDisabled,
  duetDisabled,
  stitchDisabled,
  onCommentChanged,
  onDuetChanged,
  onStitchChanged,
}: InteractionsProps) {
  return (
    <div className="flex justify-start items-center gap-4 relative">
      <div className="text-gray-700 font-semibold text-xs w-40">Allow users to</div>
      <div className="flex-1 w-full">
        <div className="flex justify-between">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                checked={comment}
                disabled={commentDisabled}
                onChange={() => onCommentChanged(!comment)}
                id="comment"
                name="comment"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer disabled:bg-gray-50 disabled:opacity-80 disabled:cursor-not-allowed"
              />
            </div>
            <div className="text-sm leading-6">
              <label htmlFor="comment" className="font-medium text-gray-900 text-xs cursor-pointer pl-2">
                Comment
              </label>{" "}
            </div>
          </div>

          <div className="relative flex items-start ml-3">
            <div className="flex h-6 items-center">
              <input
                checked={duet}
                disabled={duetDisabled}
                onChange={() => onDuetChanged(!duet)}
                id="duet"
                name="duet"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer disabled:bg-gray-50 disabled:opacity-80 disabled:cursor-not-allowed"
              />
            </div>
            <div className="text-sm leading-6">
              <label htmlFor="duet" className="font-medium text-gray-900 text-xs cursor-pointer pl-2">
                Duet
              </label>{" "}
            </div>
          </div>

          <div className="relative flex items-start ml-3">
            <div className="flex h-6 items-center">
              <input
                checked={stitch}
                disabled={stitchDisabled}
                onChange={() => onStitchChanged(!stitch)}
                id="stitch"
                name="stitch"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer disabled:bg-gray-50 disabled:opacity-80 disabled:cursor-not-allowed"
              />
            </div>
            <div className="text-sm leading-6">
              <label htmlFor="stitch" className="font-medium text-gray-900 text-xs cursor-pointer pl-2">
                Stitch
              </label>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import ChannelAccountTypes from "../../data/channel-account-types";
import ThreadsProfile from "../../models/threads/profile";
import ThreadsToken from "../../models/threads/token";
import ApiService from "../core/api-service";

class ThreadsService {
  getAuthorizationUrl(state: string): Promise<string> {
    return ApiService.get<string>(
      `external-providers/threads/authorization-url`,
      {
        params: {
          state: state,
        },
      }
    );
  }

  exchangeCodeForLongLivedToken(code: string): Promise<ThreadsToken> {
    return ApiService.post<ThreadsToken>(
      `external-providers/threads/exchange-code`,
      {},
      {
        disableGlobalErrorReporting: true,
        params: {
          code: code,
          longLived: true,
        },
      }
    );
  }

  getThreadAccounts(
    userId: number,
    userAccessToken: string
  ): Promise<ThreadsProfile[]> {
    return ApiService.get<ThreadsProfile[]>(
      `external-providers/threads/accounts`,
      {
        disableGlobalErrorReporting: true,
        params: {
          userId,
          userAccessToken,
        },
      }
    ).then((profiles) => {
      profiles.forEach((x) => {
        x.channelAccountType = ChannelAccountTypes.ThreadsProfile;
      });

      return profiles;
    });
  }
}

export default new ThreadsService();

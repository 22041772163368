import { DayOfWeek } from "../models/entities/channel-slot";

const daysOfWeek: DayOfWeek[] = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const weekDays: DayOfWeek[] = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
];

export const weekEnds: DayOfWeek[] = ["Saturday", "Sunday"];

export default daysOfWeek;

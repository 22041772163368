import { useMemo } from "react";
import { InstagramPostConfig } from "../../../../../models/entities/post";
import Channel from "../../../../../models/entities/channel";

interface InstagramCommentProps {
  instagramPostConfig: InstagramPostConfig;
  channel: Channel;
}

export default function InstagramComment({
  instagramPostConfig,
  channel,
}: InstagramCommentProps) {
  const commentFormatted = useMemo(() => {
    return instagramPostConfig.firstCommentOptions?.comment ?? "";
  }, [instagramPostConfig.firstCommentOptions?.comment]);

  return commentFormatted ? (
    <div className="flex flex-row gap-2 px-4 py-2 overflow-hidden">
      <div>
        <span className="text-sm font-semibold leading-4 text-black">
          {channel.name}
        </span>{" "}
        <span className="font-normal text-sm whitespace-pre-line leading-none text-gray-900">
          {commentFormatted.substring(0, 80)}
          {commentFormatted.length > 80 ? (
            <span className="text-sm font-light text-gray-400">... more</span>
          ) : null}
        </span>
      </div>

      {/* <div
          className="text-xs font-normal text-gray-900 whitespace-pre-wrap overflow-hidden line-clamp-5 break-words"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(commentFormatted),
          }}
        ></div> */}
    </div>
  ) : null;
}

import { useState, useEffect } from "react";

export default function useFragmentParams(): URLSearchParams {
  const [fragmentParams, setFragmentParams] = useState<URLSearchParams>(
    new URLSearchParams()
  );

  useEffect(() => {
    const hash = window.location.hash; // Get the fragment part
    if (hash.startsWith("#")) {
      const fragmentString = hash.substring(1); // Remove the leading #
      const params = new URLSearchParams(fragmentString); // Use URLSearchParams to parse
      setFragmentParams(params);
    }
  }, []); // Runs once on component mount

  return fragmentParams;
}

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import Post from "../../../../models/entities/post";

export interface ErrorsProps {
  post: Post;
}

export default function Errors({ post }: ErrorsProps) {
  const title =
    post?.error?.message ??
    "Something went wrong with this post. Please retry.";

  const description = post?.error?.description;

  // post?.status == "ProcessingFailed"
  //   ? "Post processing failed"
  //   : post?.status == "PublishFailed"
  //   ? "Post publishing failed"
  //   : null;

  return post?.error ? (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex items-center">
        <div className="flex flex-row items-center">
          <ExclamationCircleIcon className="h-5 w-5 text-red-400" />
        </div>
        <div className="ml-4">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          {description && (
            <div className="text-xs mt-1 text-red-700">{description}</div>
          )}
        </div>
      </div>
    </div>
  ) : null;
}

import { useContext, useRef, useState } from "react";
import clsx from "clsx";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import TenantUser from "../../../models/entities/tenant-user";
import actionTypes from "../../../state/global-state/action-types";
import { queryClient } from "../../../queries/query-client";
import SubscriptionPill from "./SubscriptionPill";
import { useNavigate } from "react-router-dom";
import SwitchWorkspaceDialog from "../../../components/dialogs/SwitchWorkspaceDialog";
import { createIDBPersister } from "../../../queries/indexdb-persister";

export default function TenantListPicker() {
  const confirmDialogRef = useRef(null);
  const [nextTenant, setNextTenant] = useState<TenantUser>(null);
  const { state, dispatch } = useContext(GlobalStateContext);
  const navigate = useNavigate();

  const currentTenant = state.currentTenant;
  const tenants = state.tenants;

  const selectTenant = (tenant: TenantUser) => {
    if (tenant.tenant.id == currentTenant.tenant?.id) {
      return;
    }

    setNextTenant(tenant);
    confirmDialogRef.current.openDialog(nextTenant);
  };

  const onConfirm = async () => {
    dispatch({ type: actionTypes.SET_CURRENT_TENANT, payload: nextTenant });
    await queryClient.invalidateQueries();
    await createIDBPersister().removeClient();
    navigate("/scheduler/calendar");
  };

  return (
    <>
      {currentTenant == null ? null : (
        <>
          <div className="flex flex-col gap-1 max-h-[60vh] overflow-auto py-1">
            <div className="text-xs text-[0.65rem] font-normal pl-1 mb-1 uppercase">
              Change Workspace
            </div>
            {tenants.map((tenant) => (
              <div
                onClick={() => selectTenant(tenant)}
                key={tenant.tenant.id}
                className={clsx(
                  tenant.tenant.id == currentTenant.tenant.id
                    ? "bg-stone-100"
                    : " hover:bg-stone-100",
                  "group flex justify-between items-center gap-x-3 rounded-md px-2 py-1.5 cursor-pointer hover:bg-gray-50"
                )}
              >
                <div className="text-sm leading-4 font-normal truncate">
                  {tenant.tenant.name}
                </div>
                <SubscriptionPill subscription={tenant.subscription} />
              </div>
            ))}
          </div>

          <SwitchWorkspaceDialog
            title="Change Workspace "
            message={`Please confirm that you want to change your current workspace to <strong>${nextTenant?.tenant?.name}</strong>.`}
            ref={confirmDialogRef}
            onConfirm={onConfirm}
          />
        </>
      )}
    </>
  );
}

import Timezone from "../../models/entities/timezone";
import ApiService from "../core/api-service";

class TimezoneService {
  list(): Promise<Timezone[]> {
    return ApiService.get<Timezone[]>(`timezones`);
  }
}

export default new TimezoneService();

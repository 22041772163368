import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";
import Spinner from "./Spinner";

export class BreadcrumbsPage {
  name: string;
  to: string;
}

export default function Breadcrumbs({ pages }: { pages: BreadcrumbsPage[] }) {
  return (
    <nav
      className="flex px-2 pb-2 -mb-2 overflow-x-auto"
      aria-label="Breadcrumb"
    >
      <ol role="list" className="flex items-center space-x-2 sm:space-x-4">
        <li>
          <div>
            <NavLink
              to="/scheduler"
              className="text-gray-400 hover:text-gray-500"
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" />
              <span className="sr-only">Home</span>
            </NavLink>
          </div>
        </li>
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" />
              {/* <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg> */}
              {BreadcrumbStep(page, index == pages.length - 1)}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

function BreadcrumbStep(page: BreadcrumbsPage, isLast: boolean) {
  if (!page.name) {
    return (
      <div className="ml-4">
        <Spinner size="5" />
      </div>
    );
  }

  return isLast ? (
    <div className="ml-2 sm:ml-4 text-sm font-medium text-gray-700 truncate text-ellipsis">
      {page.name}
    </div>
  ) : (
    <NavLink
      to={page.to}
      className="ml-2 sm:ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 truncate text-ellipsis"
    >
      {page.name}
    </NavLink>
  );
}

import { CalendarIcon, QueueListIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

export interface SchedulerViewToggleProps {
  compact?: boolean;
}

export default function SchedulerViewToggle({
  compact,
}: SchedulerViewToggleProps) {
  return (
    <div className="w-full flex-1 flex rounded-md  border border-gray-200 gap-2 p-1">
      <NavLink
        to="/scheduler/calendar"
        type="button"
        className={({ isActive }) =>
          clsx(
            isActive
              ? "text-primary-600 bg-primary-100"
              : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
            "flex-1 relative inline-flex items-center rounded px-2 py-2 text-sm font-semibold transition-all"
          )
        }
      >
        <CalendarIcon className="w-4 mr-1" /> {!compact ? "Calendar" : null}
      </NavLink>

      <NavLink
        to="/scheduler/list"
        type="button"
        className={({ isActive }) =>
          clsx(
            isActive
              ? " text-primary-600 bg-primary-100"
              : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
            "flex-1 relative inline-flex items-center rounded px-2 py-2 text-sm font-semibold text-gray-900 transition-all"
          )
        }
      >
        <QueueListIcon className="w-4 mr-1" />
        {!compact ? "List" : null}
      </NavLink>
    </div>
  );
}

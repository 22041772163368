import {
  OptionGroup,
  CropPresetOption,
  PinturaEditorDefaultOptions,
  createDefaultImageWriter,
  createDefaultMediaWriter,
  imageStateToCanvas,
  Sticker,
  StickerGroup,
} from "@pqina/pintura";
import {
  plugin_trim_locale_en_gb,
  createDefaultVideoWriter,
  createMuxerEncoder,
  createMediaStreamEncoder,
} from "@pqina/pintura-video";
import * as Mp4Muxer from "mp4-muxer";
import { ChannelType } from "../models/entities/post";

const videoBitrate: number = 5000000; // 5Mbps
const audioBitrate: number = 128000; // 128 Kbps

const facebookCropOptions: OptionGroup = [
  "Facebook",
  [
    [1 / 1, "Square Post (1:1)"],
    [4 / 5, "Portrait Post (4:5)"],
    [1.91 / 1, "Landscape Post (1.91:1)"],
    [9 / 16, "Facebook Story (9:16)"],
    [9 / 16, "Facebook Reel (9:16)"],
  ],
];

const instagramCropOptions: OptionGroup = [
  "Instagram",
  [
    [1 / 1, "Square Post (1:1)"],
    [4 / 5, "Portrait Post (4:5)"],
    [1.91 / 1, "Landscape Post (1.91:1)"],
    [9 / 16, "Instagram Story (9:16)"],
    [9 / 16, "Instagram Reel (9:16)"],
  ],
];

const pinterestCropOptions: OptionGroup = [
  "Pinterest",
  [
    [1 / 1, "Square Pin (1:1)"],
    [2 / 3, "Portrait Pin (2:3)"],
    [1.91 / 1, "Landscape Pin (1.91:1)"],
  ],
];

const twitterCropOptions: OptionGroup = [
  "X",
  [
    [9 / 16, "Portrait Video (9:16)"],
    [16 / 9, "Landscape Video (16:9)"],
    [1 / 1, "Square Video (1:1)"],
  ],
];

const tiktokCropOptions: OptionGroup = [
  "TikTok",
  [
    [1 / 1, "Square Image (1:1)"],
    [2 / 3, "Portrait Image (2:3)"],
    [9 / 16, "Portrait Video (9:16)"],
    [1 / 1, "Square Video (1:1)"],
    [16 / 9, "Landscape Video (16:9)"],
  ],
];

const youtubeCropOptions: OptionGroup = [
  "YouTube",
  [
    [9 / 16, "YouTube Short (9:16)"],
    [1 / 1, "Square Video (1:1)"],
  ],
];

const cropOptions: OptionGroup[] | CropPresetOption[] = [
  facebookCropOptions,
  instagramCropOptions,
  pinterestCropOptions,
  twitterCropOptions,
  tiktokCropOptions,
  youtubeCropOptions,
];

const stickers: Sticker[] | StickerGroup[] = [
  "😎",
  "😁",
  "😆",
  "😅",
  "🤣",
  "🎃",
];

const defaultPinturaEditorOptions: PinturaEditorDefaultOptions = {
  stickers: stickers,
  stickerForceEnable: true,
  stickerEnableButtonFlipVertical: true,
  stickerEnableDropImagePreset: true,
  stickerEnableSelectImagePreset: true,
  cropEnableSelectPreset: true,
  stickerStickToImage: true,
  cropSelectPresetOptions: cropOptions,
  // src: imageFile,
  // imageState,
  enableTransparencyGrid: true,
  cropActiveTransformTool: "zoom",
  cropEnableButtonFlipVertical: true,
  cropEnableButtonFlipHorizontal: true,
  cropEnableButtonRotateLeft: true,
  cropEnableButtonRotateRight: true,
  cropEnableInfoIndicator: true,
  cropMinimizeToolbar: "auto",
  imageTargetSize: undefined,
  locale: {
    ...plugin_trim_locale_en_gb,
  },
  // trimWillRenderControls: (controls, env, redraw) => {
  //   // we add the video thumbnail button
  //   controls.push([
  //     "Button",
  //     "video-thumbnail",
  //     {
  //       label: "Create thumbnail",
  //       onclick: () => {
  //         // we remember timestamp for this thumbnail so we can create full size thumbnail when done
  //         editor.imageMetadata = {
  //           thumbnail: editor.imageCurrentTime,
  //         };

  //         // we generate a temporary thumbnail for display purposes
  //         createThumbnail(editor).then((newThumbnailURL) => {
  //           // if already generated a thumbnail, revoke the URL so we free up memory
  //           if (currentThumbnailURL) {
  //             URL.revokeObjectURL(currentThumbnailURL);
  //             currentThumbnailURL = undefined;
  //           }

  //           // set new thumbnail
  //           currentThumbnailURL = newThumbnailURL;

  //           // triggers redraw of the trim util
  //           redraw();
  //         });
  //       },
  //     },
  //   ]);

  //   // we add the preview thumbnail if it's set
  //   if (currentThumbnailURL) {
  //     controls.push([
  //       "div",
  //       "thumbnail",
  //       {
  //         innerHTML: `<img src="${currentThumbnailURL}" alt=""/>`,
  //       },
  //     ]);
  //   }

  //   return controls;
  // },
  imageWriter: createDefaultMediaWriter([
    createDefaultImageWriter(),
    createDefaultVideoWriter({
      encoder: createMuxerEncoder({
        imageStateToCanvas,
        muxer: Mp4Muxer,
        mimeType: "video/mp4",
        videoBitrate: videoBitrate,
        audioBitrate: audioBitrate,
        log: true,
      }),
    }),

    // Media stream as fallback
    createDefaultVideoWriter({
      encoder: createMediaStreamEncoder({
        imageStateToCanvas,
        videoBitrate: videoBitrate,
        audioBitrate: audioBitrate,
        log: true,
      }),
    }),
  ]),
};

export const profilePicturePinturaEditorOptions: PinturaEditorDefaultOptions = {
  stickers: stickers,
  stickerForceEnable: false,
  stickerEnableButtonFlipVertical: false,
  stickerEnableDropImagePreset: false,
  stickerEnableSelectImagePreset: false,
  stickerStickToImage: false,
  cropSelectPresetOptions: [["Profile picture", [[1 / 1, "Square (1:1)"]]]],
  cropEnableInfoIndicator: true,
  cropEnableImageSelection: true,
  cropEnableRotationInput: false,
  cropWillRenderTools: () => {
    return [];
  },
  enableTransparencyGrid: true,
  cropActiveTransformTool: "zoom",
  cropEnableButtonFlipVertical: false,
  cropEnableButtonFlipHorizontal: false,
  cropEnableButtonRotateLeft: false,
  cropEnableButtonRotateRight: false,
  enableToolbar: true,
  imageCropAspectRatio: 1,
  cropImageSelectionCornerStyle: "hook",
  cropInteractionFocus: "selection",
  cropMinimizeToolbar: "always",
  willRenderCanvas(shapes, state) {
    const { utilVisibility, selectionRect, lineColor, backgroundColor } = state;

    // Exit if crop utils is not visible
    if (utilVisibility.crop <= 0) return shapes;

    // Get variable shortcuts to the crop selection rect
    const { x, y, width, height } = selectionRect;

    return {
      // Copy all props from current shapes
      ...shapes,

      // Now we add an inverted ellipse shape to the interface shapes array
      interfaceShapes: [
        {
          x: x + width * 0.5,
          y: y + height * 0.5,
          rx: width * 0.5,
          ry: height * 0.5,
          opacity: utilVisibility.crop,
          inverted: true,
          backgroundColor: [...backgroundColor, 0.5],
          strokeWidth: 1,
          strokeColor: [...lineColor],
        },
        ...shapes.interfaceShapes,
      ],
    };
  },
  util: "crop",
  utils: ["crop"],
  imageTargetSize: {
    width: 250,
    height: 250,
  },
  locale: {
    ...plugin_trim_locale_en_gb,
  },
  imageWriter: createDefaultMediaWriter([createDefaultImageWriter()]),
};

const getDefaultEditorOptions = (
  channelTypes: ChannelType[]
): PinturaEditorDefaultOptions => {
  const result = { ...defaultPinturaEditorOptions };

  const cropOptions: OptionGroup[] = [];

  channelTypes.forEach((channelType) => {
    if (cropOptions.find((x) => x[0] === channelType?.toString())) return;

    switch (channelType) {
      case "Facebook":
        cropOptions.push(facebookCropOptions);

        break;
      case "Instagram":
        cropOptions.push(instagramCropOptions);
        break;
      case "Pinterest":
        cropOptions.push(pinterestCropOptions);
        break;
      case "Twitter":
        cropOptions.push(twitterCropOptions);
        break;
      case "TikTok":
        cropOptions.push(tiktokCropOptions);
        break;
      case "YouTube":
        cropOptions.push(youtubeCropOptions);
        break;
    }
  });

  result.cropSelectPresetOptions = cropOptions;

  return result;
};

export default getDefaultEditorOptions;

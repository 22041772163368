import dayjs from "dayjs";
import ApiService from "../core/api-service";

class QuotaService {
  getPostsQuota(timezone: string): Promise<number> {
    const timezoneOffset = dayjs().tz(timezone).utcOffset() / 60;
    const date = dayjs().format("YYYY-MM-DD");

    return ApiService.get<number>(`quotas/posts`, {
      params: {
        timezoneOffset,
        date,
      },
    });
  }

  getTwitterPostQuota(date: Date, timezone: string): Promise<number> {
    const timezoneOffset = dayjs().tz(timezone).utcOffset() / 60;
    const dateFormatted = dayjs(date).format("YYYY-MM-DD");

    return ApiService.get<number>(`quotas/twitter-posts`, {
      params: {
        timezoneOffset,
        date: dateFormatted,
      },
    });
  }
}

export default new QuotaService();

import "@aws-amplify/ui-react/styles.css";
import { Outlet } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";

function RootLayout() {
  return (
    <>
      <Authenticator.Provider>
        <Outlet />
      </Authenticator.Provider>
    </>
  );
}

export default RootLayout;

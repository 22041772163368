/**
 * Fetches data from a URL and creates a File object.
 * @param url The URL of the public image or video.
 * @param filename The name to assign to the resulting File object.
 * @returns Promise<File>
 */
export async function createFileFromUrl(
  url: string,
  filename: string
): Promise<File> {
  try {
    // Fetch the data from the URL
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${response.statusText}`);
    }

    // Convert the response data to a Blob
    const blob = await response.blob();

    // Create a File from the Blob
    const file = new File([blob], filename, { type: blob.type });

    return file;
  } catch (error) {
    console.error("Error creating file from URL:", error);
    throw error;
  }
}

export async function getImageSize(
  dataURL
): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });
}

// Works for any type of url, (http url, dataURL, blobURL, etc...)
export async function urlToFile(
  url: string,
  filename: string,
  mimeType?: string
): Promise<File> {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];

  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => {
      return new File([buf], filename, { type: mimeType });
    });
}

export function replaceFileExtension(
  fileName: string,
  newExtension: string
): string {
  try {
    const parts = fileName.split(".");
    parts.pop();
    const nameWithoutExtension = parts.join(".");
    return `${nameWithoutExtension}.${newExtension}`;
  } catch {
    return `${fileName}.${newExtension}`;
  }
}

export function generateVideoThumbnail(video): Promise<string> {
  return new Promise((resolve) => {
    video.currentTime = 0;

    const height = video.videoHeight;
    const width = video.videoWidth;

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    setTimeout(() => {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      const blob = canvas.toDataURL("image/jpeg");

      canvas.remove();

      resolve(blob);
    }, 150);
  });
}

export async function getVideoDimensions(
  url: string,
  disposeVideo: boolean = false
): Promise<{
  width: number;
  height: number;
  duration: number;
  video: HTMLVideoElement;
}> {
  return new Promise((resolve) => {
    const video = document.createElement("video") as HTMLVideoElement;

    video.addEventListener(
      "loadedmetadata",
      function () {
        const height = this.videoHeight;
        const width = this.videoWidth;
        const duration = Math.round(this.duration);

        if (disposeVideo) {
          video.remove();
        }

        resolve({ width, height, duration, video });
      },
      false
    );

    // start download meta-datas
    video.src = url;
  });
}

export async function getImageDimensions(
  url: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = url;
  });
}

export function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
  return btoa(String.fromCodePoint(...new Uint8Array(arrayBuffer)));
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = (reader.result as string).split(",").pop();
      resolve(result);
    };
    reader.readAsDataURL(file);
  });
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

console.log(import.meta.env);

Sentry.init({
  dsn: "https://a68d454557f5198b0df4befa050c83b6@o4507839002050560.ingest.us.sentry.io/4507839008407552",
  environment: import.meta.env.MODE,
  release: "1.0.0",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      replaysOnErrorSampleRate: 1,
    }),
    // Sentry.feedbackIntegration({
    //   colorScheme: "system",
    //   showName: false,
    //   showEmail: false,
    //   showBranding: false,
    //   useSentryUser: { email: 'email', name: 'name' },
    // }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,
  enabled: import.meta.env.MODE !== "development",
  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/api\.viraly\.io/, /^https:\/\/api\.staging\.viraly\.io/],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
import { Description, Field, Label, Switch } from "@headlessui/react";
import { UserNotificationSetting } from "../../../../models/entities/user";

export interface NotificationToggleProps {
  title: string;
  description: string;
  settings: UserNotificationSetting;
  onChange: (checked: boolean) => void;
}

export default function NotificationToggle({
  title,
  description,
  settings,
  onChange,
}: NotificationToggleProps) {
  return (
    <Field className="py-5 sm:py-6 px-0">
      <Label
        as="h3"
        passive
        className="text-sm font-semibold leading-6 text-gray-900"
      >
        {title}
      </Label>
      <div className="flex items-start justify-between">
        <div className="max-w-xl text-sm text-gray-500">
          <Description>{description}</Description>
        </div>
        <div className="ml-6 mt-0 flex flex-shrink-0 items-center">
          <Switch
            checked={settings.email}
            onChange={onChange}
            className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-primary-600"
          >
            <span
              aria-hidden="true"
              className="inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
            />
          </Switch>
        </div>
      </div>
    </Field>
  );
}

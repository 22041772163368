import planQuotaService from "../../../../services/application/plan-quota-service";
import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../../queries/query-names";
import clsx from "clsx";
import { RocketLaunchIcon } from "@heroicons/react/20/solid";
import quotaService from "../../../../services/api/quota-service";
import useCurrentTenant from "../../../../hooks/useCurrentTenant";
import useCurrentSocialSet from "../../../../hooks/useCurrentSocialSet";

export default function PostsQuota() {
  const tenant = useCurrentTenant();
  const socialSet = useCurrentSocialSet();

  const { data: postQuota } = useQuery({
    queryKey: [queryNames.postQuotas],
    queryFn: () => quotaService.getPostsQuota(socialSet.timezone),
    staleTime: Infinity,
  });

  const subscription = tenant.subscription;
  const { tier, usagePercentage, remainingUsage, tooltipLabel } =
    planQuotaService.calculatePostQuotaInfo(subscription, postQuota);

  return (
    <>
      <div
        className="relative size-8 cursor-pointer group"
        data-tooltip-id="tooltip-placeholder"
        data-tooltip-content={tooltipLabel}
      >
        <svg
          className="size-full -rotate-90 group-hover:scale-150 transition-all"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="stroke-current text-gray-200"
            strokeWidth="2"
          ></circle>
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className={clsx("stroke-current text-gray-400")}
            strokeWidth="2"
            strokeDasharray="100"
            strokeDashoffset={usagePercentage}
            strokeLinecap="round"
          ></circle>
        </svg>

        <div className="flex items-center justify-center absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2 group-hover:scale-125 transition-all">
          <span
            className={clsx(
              "text-center text-[10px] font-medium font-mono text-gray-500 group-hover:text-gray-700 group-hover:text-xs transition-all"
            )}
          >
            {tier != "high" && remainingUsage}
            {tier == "high" && (
              <RocketLaunchIcon className="text-gray-600 size-4" />
            )}
          </span>
        </div>
      </div>
    </>
  );
}

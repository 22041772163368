import CreateTenantRequest from "../../models/api-requests/create-tenant-request";
import UpdateTenantRequest from "../../models/api-requests/update-tenant-request";
import TenantUser from "../../models/entities/tenant-user";
import Tenant from "../../models/entities/tenant";
import ApiService from "../core/api-service";

class TenantService {
  list(): Promise<TenantUser[]> {
    return ApiService.get<TenantUser[]>(`tenants/current`);
  }

  createTenant(model: CreateTenantRequest): Promise<TenantUser> {
    return ApiService.post<TenantUser>(`tenants`, model);
  }

  updateTenant(tenantId: string, model: UpdateTenantRequest): Promise<Tenant> {
    return ApiService.put<Tenant>(`tenants/${tenantId}`, model);
  }
}

export default new TenantService();

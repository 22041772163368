import TenantUser from "../models/entities/tenant-user";
import { UserRole } from "../models/entities/user";
import { PlanTier } from "../models/stripe/subscription";

export function canAccessSocialSet(
  currentTenant?: TenantUser,
  socialSetId?: string
) {
  return (
    currentTenant?.user != null &&
    (currentTenant.user.socialSetIds == null ||
      (currentTenant.user.socialSetIds != null &&
        currentTenant.user.socialSetIds.includes(socialSetId)))
  );
}

export function authorizeScope(
  requiredPermissions: string[],
  requiredRoles: UserRole[],
  requiredTiers: PlanTier[],
  tenant?: TenantUser
): boolean {
  const authorizePermission = () => {
    const currentPermissions = tenant?.user?.permissions;

    if (!requiredPermissions.length) {
      return true;
    }

    if (tenant?.user == null) {
      return false;
    }

    return (
      currentPermissions == null ||
      requiredPermissions.some((x) => currentPermissions.includes(x))
    );
  };

  const authorizeRole = () => {
    const currentRole = tenant?.user?.role;

    if (!requiredRoles.length) {
      return true;
    }

    if (tenant?.user == null) {
      return false;
    }

    return requiredRoles.includes(currentRole);
  };

  const authorizeTier = () => {
    const currentTier = tenant?.subscription?.items?.[0]?.plan?.product
      ?.metadata?.tier as PlanTier;

    if (!requiredTiers.length) {
      return true;
    }

    if (tenant?.subscription == null) {
      return false;
    }

    return requiredTiers.includes(currentTier);
  };

  return authorizePermission() && authorizeRole() && authorizeTier();
}

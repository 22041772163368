import backgroundImage from "./../../assets/images/background-auth.jpg";
import Lottie from "react-lottie-player";
import feathersAnimation from "./../../assets/animations/signup/business-analysis.json";
import { Outlet } from "react-router-dom";

export default function UnauthenticatedLayout({ children }: { children?: React.ReactNode }) {
  return (
    <div className="relative flex h-dvh shrink-0 justify-center md:px-12 lg:px-0">
      <div className="relative z-10 flex flex-1 flex-col bg-white px-4 py-10 shadow-2xl sm:justify-center md:flex-none md:px-28">
        <main className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
          <div className="flex justify-center mb-8">
            <a href="/" aria-label="Home">
              <img className="w-40" src="/logo/viraly-logo-rect.png" alt="Viraly" />
            </a>
          </div>

          <div>{children ?? <Outlet />}</div>
        </main>
      </div>
      <div className="hidden sm:contents lg:relative lg:flex lg:flex-1 items-center justify-center">
        <img className="absolute inset-0 h-full w-full object-cover" src={backgroundImage} alt="" />
        <Lottie loop animationData={feathersAnimation} play style={{ height: 350 }} />
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import Button from "../common/Button";
import Media from "../../modules/scheduler/media/Media";
import { XMarkIcon } from "@heroicons/react/20/solid";
import AttachmentSlot from "../../modules/scheduler/create-post/models/attachment-slot";

const SelectMediaDialog = forwardRef(({ onConfirm, onCancel }: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [selectedAttachments, setSelectedAttachments] = useState<
    AttachmentSlot[]
  >([]);

  // Expose the openDialog and closeDialog methods to parent components
  useImperativeHandle(ref, () => ({
    openDialog: () => setIsOpen(true),
    closeDialog: () => setIsOpen(false),
  }));

  // Handle confirm action
  const handleConfirm = () => {
    setIsOpen(false);

    if (onConfirm) {
      onConfirm(selectedAttachments);
      setSelectedAttachments([]);
    }
  };

  // Handle cancel action
  const handleCancel = () => {
    setIsOpen(false);

    if (onCancel) {
      onCancel();
    }
  };

  const onMediaSelected = (attachments: AttachmentSlot[]) => {
    setSelectedAttachments(attachments);
  };

  return (
    <Transition as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80 z-50 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 z-50">
          <DialogPanel className="flex flex-col relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[1000px] h-[100dvh] max-h-[80dvh] ">
            <div>
              <DialogTitle
                as="h3"
                className="flex items-center justify-between"
              >
                <div className="text-base font-semibold leading-7 text-gray-900">
                  Select media from the Library
                </div>
                <XMarkIcon
                  onClick={handleCancel}
                  className="w-7 h-7 bg-white rounded-full text-gray-800 shadow button-shadow p-0.5 cursor-pointer hover:bg-gray-50/50"
                />
              </DialogTitle>
            </div>
            <div className="flex-1 overflow-y-auto">
              <Media isSelectionMode={true} onSelected={onMediaSelected} />
            </div>
            <div className="mt-2 flex justify-end items-center gap-2">
              <div className="ml-auto mr-4 text-gray-700 font-medium text-sm">
                {selectedAttachments.length} attachment
                {selectedAttachments.length != 1 ? "s" : ""} selected
              </div>

              <Button
                variant="solid"
                color="red"
                disabled={selectedAttachments.length === 0}
                text="Use Selection"
                onClick={handleConfirm}
              />
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  );
});

export default SelectMediaDialog;

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { useEffect, useMemo, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../../../queries/query-names";
import googleService from "../../../../../services/api/google-service";
import { YouTubeCategory } from "../../../../../models/google/youtube-category";

export interface CategorySelectorProps {
  categoryId: string;
  onCategorySelected: (category: string) => void;
}

export default function CategorySelector({
  categoryId,
  onCategorySelected,
}: CategorySelectorProps) {
  const [searchValue, setSearchValue] = useState<string>();
  const [selectedCategory, setSelectedCategory] =
    useState<YouTubeCategory>(null);

  const { data } = useQuery({
    queryKey: [queryNames.youTubeCategories],
    queryFn: () => googleService.getYouTubeCategories(),
    staleTime: Infinity,
  });

  useEffect(() => {
    const category = data?.find((x) => x.id == categoryId);
    setSelectedCategory(category);
  }, [categoryId, data]);

  const filteredCategories = useMemo(() => {
    const filteredCategories =
      (!searchValue
        ? data
        : data?.filter((x) =>
            x.snippet.title.toLocaleLowerCase().includes(searchValue)
          )) ?? [];
    return filteredCategories;
  }, [data, searchValue]);

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const onSelectCategory = (category: YouTubeCategory) => {
    if (category) {
      setSelectedCategory(category);
      onCategorySelected(category?.id);
    }
  };

  return (
    <>
      <Combobox
        as="div"
        value={selectedCategory}
        onChange={(category: YouTubeCategory) => {
          onSearch("");
          onSelectCategory(category);
        }}
      >
        <div className="relative">
          <ComboboxButton className="w-full">
            <ComboboxInput
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-xs md:text-sm sm:leading-6"
              onChange={(event) => onSearch(event.target.value)}
              onBlur={() => onSearch("")}
              displayValue={(category: YouTubeCategory) =>
                category?.snippet.title
              }
            />
            <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-1 focus:outline-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
            </div>
          </ComboboxButton>

          {filteredCategories.length > 0 && (
            <ComboboxOptions
              anchor="top"
              transition
              className="w-[var(--input-width)] [--anchor-gap:var(--spacing-1)] empty:invisible transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 z-50 !max-h-60 rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {filteredCategories.map((category) => (
                <ComboboxOption
                  key={category.id}
                  value={category}
                  className={({ focus }) =>
                    clsx(
                      "relative cursor-default select-none py-1 pl-2 pr-2 text-xs md:text-sm mx-1 md:mx-2 rounded-md",
                      focus ? "bg-primary-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ focus, selected }) => (
                    <>
                      <span
                        className={clsx(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {category.snippet.title}
                      </span>

                      {selected && (
                        <span
                          className={clsx(
                            "hidden md:flex absolute inset-y-0 right-0 items-center pr-2",
                            focus ? "text-white" : "text-primary-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>
    </>
  );
}

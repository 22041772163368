import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../../queries/query-names";
import userService from "../../../../services/api/user-service";
import Spinner from "../../../../components/common/Spinner";
import UsersList from "./components/UsersList";
import Button from "../../../../components/common/Button";
import { useContext, useEffect } from "react";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import actionTypes from "../../../../state/global-state/action-types";

export default function Users() {
  const { data: users, isLoading } = useQuery({
    queryKey: [queryNames.users],
    queryFn: userService.list,
  });
  const { dispatch } = useContext(GlobalStateContext);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SETTINGS_BREADCRUMBS,
      payload: [
        {
          name: "Settings",
          to: "/settings",
        },
        {
          name: "Users",
          to: "/settings/users",
        },
      ],
    });
  }, [dispatch]);

  return (
    <>
      <div className="flex items-center">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Users
          </h2>
          <p className="text-sm leading-6 text-gray-500">
            Manage your team members.
          </p>
        </div>

        <div className="ml-auto">
          <Button
            to="invite"
            variant="solid"
            color="brand"
            text="Invite new user"
          />
        </div>
      </div>

      <div className="my-4 border-t border-gray-200"></div>

      <div className="flex-1 flex flex-col items-center justify-center">
        {isLoading ? <Spinner size="5" /> : <UsersList users={users} />}
      </div>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CreatePostFormInput from "../../../../../models/form-inputs/create-post-input";
import * as yup from "yup";
import { InstagramPostType } from "../../../../../models/entities/post";
import { useEffect } from "react";
import { PostInstance } from "../../models/post-instance";
import clsx from "clsx";
import ChannelAccountTypes from "../../../../../data/channel-account-types";
import PrimaryTextBox from "../../../../../components/common/PrimaryTextBox";
import eventBusService from "../../../../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../../../../services/application/event-bus/event-bus-events";
import TextAreaField from "../../../../../components/form/TextAreaField";
import AttachmentSlot from "../../models/attachment-slot";

interface InstagramFormProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
  children?: React.ReactNode | (({ primaryForm, secondaryForm }) => void);
}

const schema = yup
  .object({
    description: yup.string().required(),
    firstComment: yup.string().max(500),
  })
  .required();

export default function InstagramForm({
  postInstance,
  attachments,
  onPostInstanceChanged,
  children,
}: InstagramFormProps) {
  const instagramConfig = postInstance.postConfig.instagram;
  const isCreatorAccount =
    postInstance.channel.accountType ==
    ChannelAccountTypes.InstagramCreator.key;

  const form = useForm<CreatePostFormInput>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      description: instagramConfig.contentOptions?.caption ?? "",
      firstComment: instagramConfig.firstCommentOptions?.comment ?? "",
    },
  });

  useEffect(() => {
    const insertAiContent = (content) => {
      form.setValue("description", content);
      onTextChanged(content);
    };

    eventBusService.on(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);

    return () => {
      eventBusService.remove(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);
    };
  });

  useEffect(() => {
    const setSystemPostType = () => {
      let systemPostType = instagramConfig.contentOptions.systemPostType;

      const initialSystemPostType = systemPostType;

      if (instagramConfig.contentOptions.postType == "Post") {
        if (attachments.length == 1) {
          if (attachments[0].isPhoto) {
            systemPostType = "ImagePost";
          }
        } else if (attachments.length > 1) {
          systemPostType = "Carousel";
        }
      } else if (instagramConfig.contentOptions.postType == "Reel") {
        systemPostType = "Reel";
      } else if (instagramConfig.contentOptions.postType == "Story") {
        if (attachments.length > 0) {
          if (attachments[0].isPhoto) {
            systemPostType = "PhotoStory";
          } else {
            systemPostType = "VideoStory";
          }
        } else {
          systemPostType = "PhotoStory";
        }
      }

      if (systemPostType != initialSystemPostType) {
        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            instagram: {
              ...postInstance.postConfig.instagram,
              contentOptions: {
                ...instagramConfig.contentOptions,
                systemPostType: systemPostType,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    setSystemPostType();
  }, [instagramConfig.contentOptions, attachments, postInstance]);

  const selectPostType = (postType: InstagramPostType) => {
    if (postType == "Story" && isCreatorAccount) {
      return;
    }

    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        instagram: {
          ...postInstance.postConfig.instagram,
          contentOptions: {
            ...instagramConfig.contentOptions,
            postType: postType,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onTextChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      title: text,
      postConfig: {
        ...postInstance.postConfig,
        instagram: {
          ...postInstance.postConfig.instagram,
          contentOptions: {
            ...instagramConfig.contentOptions,
            caption: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onFirstCommentChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        instagram: {
          ...postInstance.postConfig.instagram,
          firstCommentOptions: {
            ...postInstance.postConfig.instagram.firstCommentOptions,
            comment: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const primaryForm = (
    <>
      <div className="flex flex-row">
        <fieldset className="w-full mt-0.5">
          <div className="flex items-center space-x-10">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => selectPostType("Post")}
            >
              <input
                name="postType"
                type="radio"
                readOnly
                checked={instagramConfig.contentOptions.postType == "Post"}
                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer"
              />
              <label className="ml-3 block text-sm font-medium leading-6 text-gray-600 cursor-pointer">
                Post
              </label>
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => selectPostType("Reel")}
            >
              <input
                name="postType"
                type="radio"
                readOnly
                checked={instagramConfig.contentOptions.postType == "Reel"}
                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer"
              />
              <label className="ml-3 block text-sm font-medium leading-6 text-gray-600 cursor-pointer">
                Reel
              </label>
            </div>
            <div
              className={clsx(
                "flex items-center",
                isCreatorAccount ? "cursor-not-allowed" : "cursor-pointer"
              )}
              onClick={() => selectPostType("Story")}
              data-tooltip-id={isCreatorAccount ? "tooltip-placeholder" : ""}
              data-tooltip-content="Instagram Stories can only be published to Instagram Business accounts"
            >
              <input
                name="postType"
                type="radio"
                readOnly
                checked={instagramConfig.contentOptions.postType == "Story"}
                className={clsx(
                  "h-4 w-4 border-gray-300 text-primary-600 focus:ring-transparent",
                  isCreatorAccount
                    ? "cursor-not-allowed bg-gray-100"
                    : "cursor-pointer"
                )}
              />
              <label
                className={clsx(
                  "ml-3 block text-sm font-medium leading-6 text-gray-600",
                  isCreatorAccount ? "cursor-not-allowed" : "cursor-pointer"
                )}
              >
                Story
              </label>
            </div>
            <div className="flex-1"></div>
          </div>
        </fieldset>
      </div>

      {instagramConfig.contentOptions.postType != "Story" && (
        <div className="mt-2.5">
          <PrimaryTextBox
            onFirstCommentChanged={onFirstCommentChanged}
            onTextChanged={onTextChanged}
            textAreaOptions={{
              hideLabel: true,
              maxLength: 2200,
              className: "h-[124px]",
              name: "description",
              label: "Description",
              formHook: form,
              placeholder: "Start writing your post",
            }}
            firstCommentOptions={{
              hideLabel: true,
              rows: 5,
              maxLength: 500,
              name: "firstComment",
              label: "First Comment",
              formHook: form,
              placeholder: "Write your first comment",
            }}
          />
        </div>
      )}

      {instagramConfig.contentOptions.postType == "Story" && (
        <div className="mt-3">
          <TextAreaField
            hideLabel={true}
            disabled={true}
            className="h-[124px] resize-none"
            name="dummy"
            label="Description"
            formHook={form}
            placeholder="Instagram stories don't support text captions"
          />
        </div>
      )}
    </>
  );

  const secondaryForm = null;

  return (
    <form>
      <>
        {typeof children === "function"
          ? children({ primaryForm, secondaryForm })
          : children}{" "}
      </>
    </form>
  );
}

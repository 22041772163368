import { Field, Label } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

export interface AIContentSettingsProps {
  aiGenerated: boolean;
  onAiGeneratedChanged: (aiGenerated: boolean) => void;
}

export default function AIContentSettings({ aiGenerated, onAiGeneratedChanged }: AIContentSettingsProps) {
  return (
    <Field className="flex items-center">
      <div className="flex flex-grow flex-col">
        <div className="flex items-center gap-4">
          <Label as="span" passive className="w-40 flex gap-2 items-center text-xs font-semibold text-gray-700">
            <span>AI-generated content</span>
            <InformationCircleIcon
              className="h-4 w-4 text-gray-400"
              data-tooltip-id="tooltip-placeholder"
              data-tooltip-content={`TikTok requires users to disclose realistic AI-generated content`}
            />
          </Label>
          <div className="relative flex items-center">
            <div className="flex h-6 items-center">
              <input
                checked={aiGenerated}
                onChange={() => onAiGeneratedChanged(!aiGenerated)}
                id="aiGenerated"
                name="aiGenerated"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer disabled:bg-gray-50 disabled:opacity-80 disabled:cursor-not-allowed"
              />
            </div>
            <div className="text-sm leading-6">
              <label htmlFor="aiGenerated" className="font-medium text-gray-900 text-xs cursor-pointer pl-2">
                Yes
              </label>{" "}
            </div>
            {aiGenerated && (
              <div className="text-xs text-gray-500 ml-2">
                <a
                  href="https://www.tiktok.com/tns-inapp/pages/ai-generated-content"
                  target="_blank"
                  className="underline text-[11px]"
                >
                  TikTok's AI-generated content policy
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </Field>
  );
}

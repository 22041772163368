export default class BrowserStorage<T> {
  private storageKey: string = "viraly-storage";
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  // Load the entire storage object from the underlying storage (localStorage or sessionStorage)
  private loadStorageData(): Partial<T> {
    const storedData = this.storage.getItem(this.storageKey);
    return storedData ? (JSON.parse(storedData) as Partial<T>) : {};
  }

  // Save the entire storage object back to the underlying storage
  private saveStorageData(data: Partial<T>): void {
    this.storage.setItem(this.storageKey, JSON.stringify(data));
  }

  // Set a key-value pair within the common object and save it to the storage
  setItem<K extends keyof T>(key: K, value: T[K]): void {
    const storageData = this.loadStorageData();
    storageData[key] = value;
    this.saveStorageData(storageData);
  }

  // Retrieve a value by key from the common object stored in the underlying storage
  getItem<K extends keyof T>(key: K): T[K] | null {
    const storageData = this.loadStorageData();
    return storageData[key] !== undefined ? storageData[key] : null;
  }

  // Remove an item from the common object and update the storage
  removeItem<K extends keyof T>(key: K): void {
    const storageData = this.loadStorageData();
    delete storageData[key];
    this.saveStorageData(storageData);
  }

  // Clear all data from the common object
  clear(): void {
    this.storage.removeItem(this.storageKey);
  }
}

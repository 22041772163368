import ChannelAccountTypes from "../../data/channel-account-types";
import LinkedInAccount from "../../models/linkedin/linkedin-account";
import LinkedInToken from "../../models/linkedin/linkedin-token";
import ApiService from "../core/api-service";

class LinkedInService {
  getAuthorizationUrl(state: string): Promise<string> {
    return ApiService.get<string>(
      `external-providers/linkedin/authorization-url`,
      {
        params: {
          state: state,
        },
      }
    );
  }

  exchangeCodeForLongLivedToken(code: string): Promise<LinkedInToken> {
    return ApiService.post<LinkedInToken>(
      `external-providers/linkedin/exchange-code`,
      {},
      {
        disableGlobalErrorReporting: true,
        params: {
          code: code,
        },
      }
    );
  }

  async getAccounts(accessToken: string): Promise<LinkedInAccount[]> {
    const profiles = await ApiService.get<LinkedInAccount[]>(
      `external-providers/linkedin/accounts`,
      {
        disableGlobalErrorReporting: true,
        params: {
          accessToken,
        },
      }
    );

    profiles.forEach((x) => {
      x.channelAccountType =
        x.type == "member"
          ? ChannelAccountTypes.LinkedInMember
          : ChannelAccountTypes.LinkedInOrganization;
    });

    console.log(profiles);
    return profiles;
  }
}

export default new LinkedInService();

export interface Permission {
  code: PermissionCode;
  name: string;
  description: string;
  icon: string;
}

export type PermissionCode =
  | "users_write"
  | "billing_write"
  | "social_sets_write"
  | "schedule_posts_write"
  | "schedule_posts_publish";

class PermissionsService {
  static PERMISSIONS: Permission[] = [
    {
      code: "users_write",
      name: "Users",
      description: "View & Manage users",
      icon: "users",
    },
    {
      code: "billing_write",
      name: "Billing",
      description: "View & Manage billing-related settings",
      icon: "settings",
    },
    {
      code: "social_sets_write",
      name: "Social Sets",
      description: "View & Manage social sets",
      icon: "reports",
    },
    {
      code: "schedule_posts_write",
      name: "Scheduler - Manage posts",
      description:
        "View & Manage posts without the ability to schedule or publish",
      icon: "reports",
    },
    {
      code: "schedule_posts_publish",
      name: "Scheduler - Publish posts",
      description:
        "View & Manage posts with the ability to schedule and publish",
      icon: "reports",
    },
  ];

  public getPermissions(): Permission[] {
    return PermissionsService.PERMISSIONS;
  }
}

export default new PermissionsService();

import { ReactNode, useEffect, useState } from "react";
import AttachmentSlot from "../../create-post/models/attachment-slot";
import {
  ArrowPathIcon,
  HeartIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/20/solid";

import {
  DocumentIcon,
  PhotoIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { AttachmentType } from "../../../../models/entities/attachment";
import attachmentService from "../../../../services/api/attachment-service";
import storageService from "../../../../services/storage/storage-service";
import errorReporter from "../../../../utils/error-reporter";
import useCurrentSocialSet from "../../../../hooks/useCurrentSocialSet";

interface MediaItemCallbackProps {
  mediaNode: ReactNode;
  fileTypeNode: ReactNode;
  favoritedNode: ReactNode;
}

interface MediaItemProps {
  attachmentSlot: AttachmentSlot;
  isGridView: boolean;
  onSlotUpdated: (slot: AttachmentSlot) => void;
  children?: (callbackProps: MediaItemCallbackProps) => void | null;
}

export default function MediaItem({
  attachmentSlot,
  isGridView,
  onSlotUpdated,
  children,
}: MediaItemProps) {
  const [progressText, setProgressText] = useState<string>("");
  const currentSocialSet = useCurrentSocialSet();

  useEffect(() => {
    let progressText = "";

    if (attachmentSlot.status == "uploading") {
      progressText = !attachmentSlot.progress
        ? "Starting"
        : ` ${attachmentSlot.progress}%`;
    } else if (attachmentSlot.status == "processing") {
      progressText = "Finalizing";
    } else if (attachmentSlot.status == "error") {
      progressText = "Error";
    }

    setProgressText(progressText);
  }, [attachmentSlot.status, attachmentSlot.progress]);

  useEffect(() => {
    if (attachmentSlot.status == "readyToUpload") {
      uploadFile();
    }
  }, [attachmentSlot.status]);

  const uploadFile = async () => {
    const file: File = attachmentSlot.file;

    attachmentSlot.status = "uploading";
    onSlotUpdated(attachmentSlot);

    if (file) {
      try {
        attachmentSlot.progress = 0;
        onSlotUpdated(attachmentSlot);

        const { url, metadata, attachment } =
          await attachmentService.getUploadUrl({
            socialSetId: currentSocialSet.id,
            fileName: file.name,
            fileSize: file.size,
            mimeType: file.type,
            role: "MediaAttachment",
          });

        await storageService.uploadFileToS3(url, metadata, file, (progress) => {
          attachmentSlot.progress = progress;
          onSlotUpdated(attachmentSlot);
        });

        attachmentSlot.status = "processing";
        onSlotUpdated(attachmentSlot);

        const processedAttachment =
          attachment.type == AttachmentType.Photo
            ? await attachmentService.processPhoto(attachment, file)
            : await attachmentService.processVideo(attachment, file);

        attachmentSlot.attachment = processedAttachment;
        attachmentSlot.status = "ready";
        attachmentSlot.progress = null;

        onSlotUpdated(attachmentSlot);
      } catch (e) {
        attachmentSlot.status = "error";
        attachmentSlot.progress = null;

        onSlotUpdated(attachmentSlot);

        errorReporter.alertErrors(e);
      }
    }
  };

  const fileTypeNode = isGridView ? (
    <div className="bg-black/40 px-1 py-0.5 rounded-lg">
      {attachmentSlot.isPhoto && <PhotoIcon className="w-4 h-4 text-white" />}
      {attachmentSlot.isVideo && (
        <VideoCameraIcon className="w-4 h-4 text-white" />
      )}
      {attachmentSlot.isDocument && (
        <DocumentIcon className="w-4 h-4 text-white" />
      )}
    </div>
  ) : (
    <div>
      {attachmentSlot.isPhoto && (
        <PhotoIcon className="w-5 h-5 text-gray-600" />
      )}
      {attachmentSlot.isVideo && (
        <VideoCameraIcon className="w-5 h-5 text-gray-600" />
      )}
      {attachmentSlot.isDocument && (
        <DocumentIcon className="w-5 h-5 text-gray-600" />
      )}
    </div>
  );

  const favoritedNode = attachmentSlot.attachment?.favoriteAt ? (
    <HeartIcon className="w-5 h-5 text-white" />
  ) : null;

  const mediaContainer = attachmentSlot.isPhoto ? (
    <img
      className="object-cover w-full h-full"
      src={attachmentSlot.thumbnailPreview.url}
    />
  ) : attachmentSlot.isVideo ? (
    <>
      {attachmentSlot.thumbnailPreview?.url && (
        <img
          className="object-cover w-full h-full"
          src={attachmentSlot.thumbnailPreview.url}
        />
      )}
      {!attachmentSlot.thumbnailPreview?.url && attachmentSlot.preview?.url && (
        <video
          className="object-cover w-full h-full"
          src={attachmentSlot.preview.url}
        />
      )}
    </>
  ) : (
    <span>Documents not implemented</span>
  );

  const mediaNode = (
    <div className="relative w-full h-full">
      {mediaContainer}

      {attachmentSlot.status == "uploading" ? (
        <div className="absolute left-0 right-0 top-0 bottom-0 rounded-md bg-slate-800/60 z-10">
          <div className="rounded-md w-full h-full flex flex-col items-center justify-center">
            <div className="w-10">
              <CircularProgressbar
                value={attachmentSlot.progress}
                text={progressText}
                strokeWidth={4}
                styles={buildStyles({
                  textColor: "#ffffff",
                  pathColor: "#d1d5db",
                  trailColor: "rgb(58, 9, 20)",
                  strokeLinecap: 22,
                })}
              />
            </div>
          </div>
        </div>
      ) : null}

      {attachmentSlot.status == "processing" ? (
        <>
          <div
            data-tooltip-id="tooltip-placeholder"
            data-tooltip-content="Processing"
            className="absolute left-0 right-0 top-0 bottom-0 rounded-md bg-slate-800/60 z-10"
          >
            <div className="rounded-md w-full h-full flex flex-col items-center justify-center">
              <div className="w-full h-full flex flex-col items-center justify-center">
                <div className="w-5">
                  <WrenchScrewdriverIcon className="animate-pulse text-white" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {attachmentSlot.status == "error" ? (
        <div className="absolute left-0 right-0 top-0 bottom-0 rounded-md bg-slate-800/60 z-10">
          <div className="rounded-md w-full h-full flex flex-col items-center justify-center">
            <div className="font-medium text-xs leading-3 text-red-200">
              Upload failed
            </div>
            <ArrowPathIcon
              onClick={uploadFile}
              className="w-5 mt-2 text-white hover:text-white/60 cursor-pointer focus:outline-none"
              data-tooltip-id="tooltip-placeholder"
              data-tooltip-content="Retry"
            />
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      {children &&
        children({
          mediaNode,
          fileTypeNode,
          favoritedNode,
        })}
    </>
  );
}

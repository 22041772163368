import { useContext, useEffect, useRef } from "react";
import GlobalStateContext from "../../state/global-state/GlobalStateContext";
import onboardingService from "../../services/application/onboarding-service";
import { NavLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import Button from "../../components/common/Button";
import CouponAppliedDialog from "../../components/dialogs/CouponAppliedDialog";
import SchedulerViewToggle from "./components/SchedulerViewToggle";
import PostActions from "../shared/post-actions/PostActions";
import { OpenComposerParams } from "../../components/dialogs/CreatePostDialog";
import useCurrentChannel from "../../hooks/useCurrentChannel";
import useCurrentTenant from "../../hooks/useCurrentTenant";

const navigation = [
  { name: "Calendar", href: "/scheduler/calendar/view" },
  { name: "My Posts", href: "/scheduler/calendar/posts" },
  { name: "Media Library", href: "/scheduler/calendar/media-library" },
  { name: "Hashtag Lists", href: "/scheduler/calendar/hashtag-lists" },
];

export interface SchedulerLayoutProps {
  children: React.ReactNode;
}

export default function SchedulerLayout({ children }: SchedulerLayoutProps) {
  const navigate = useNavigate();
  const couponAppliedDialogRef = useRef(null);
  const postComposerRef = useRef(null);
  const { state } = useContext(GlobalStateContext);
  const channel = useCurrentChannel();
  const currentTenant = useCurrentTenant();
  const subscription = currentTenant.subscription;
  const socialSets = state.socialSets;

  useEffect(() => {
    if (!currentTenant?.tenant?.id || !socialSets) return;

    const noChannels = socialSets.flatMap((x) => x.channels).length == 0;
    const onboardingCompleted = onboardingService.isOnboardingCompleted(
      currentTenant.tenant.id
    );

    if (noChannels && !onboardingCompleted) {
      // Onboarding not completed. Show onboarding dialog
      if (subscription.items?.[0]?.discounts?.length > 0) {
        couponAppliedDialogRef.current.openDialog();
      } else {
        goToSocialSets();
      }

      onCompleted();
    }
  }, [currentTenant?.tenant?.id, socialSets]);

  const goToSocialSets = () => {
    navigate(`/social-sets/${socialSets[0].id}`);
  };

  const onCompleted = () => {
    onboardingService.setOnboardingCompleted(currentTenant.tenant.id);
  };

  const openComposer = (params: OpenComposerParams) => {
    postComposerRef.current?.openDialog(params);
  };

  return (
    <>
      <div className="flex flex-col xl:flex-row gap-6">
        {/* Vertical navigation on large screens */}
        <div className="w-56 bg-white shadow rounded-2xl p-4 hidden xl:block sticky top-24 max-h-dvh">
          <ul role="list" className="flex flex-1 flex-col gap-y-2 h-full">
            {/* <li className="text-gray-500 font-medium text-sm leading-6 text-center">Your scheduling journey</li> */}

            <li>
              <SchedulerViewToggle />
            </li>

            <li className="border-b -mx-4 my-2"></li>

            <li className="mb-4">
              <Button
                className="w-full text-left"
                variant="solid"
                color="brand"
                text=""
                onClick={() =>
                  openComposer({
                    channelIds: [channel?.id].filter((x) => !!x),
                  })
                }
              >
                <div className="flex-1 flex justify-center">Create Post</div>
              </Button>
            </li>

            <li>
              <ul role="list" className="space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        clsx(
                          isActive
                            ? " text-primary-600 bg-gray-50"
                            : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                          "group flex justify-between items-center gap-x-1 rounded-lg p-2 px-4 text-sm leading-6 font-semibold"
                        )
                      }
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>

        {/* Horizontal navigation on small screens */}
        <div className="bg-white shadow rounded-xl p-4 flex xl:hidden">
          <ul
            role="list"
            className="flex flex-1 overflow-x-auto whitespace-nowrap items-center"
          >
            <li>
              <ul
                role="list"
                className="flex items-center justify-center gap-4"
              >
                <li>
                  <SchedulerViewToggle compact={true} />
                </li>

                {navigation.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        clsx(
                          isActive
                            ? " text-primary-600 bg-gray-50"
                            : "text-gray-700 hover:text-primary-600 hover:bg-gray-50 ",
                          "group flex gap-2 items-center rounded-lg py-2 px-3 text-sm leading-6 font-semibold"
                        )
                      }
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
            <li className="sticky right-0 pl-2 ml-auto bg-white">
              <Button
                className="w-full text-left"
                variant="solid"
                color="brand"
                text="Create Post"
                onClick={() =>
                  openComposer({
                    channelIds: [channel?.id].filter((x) => !!x),
                  })
                }
              >
                <div className="flex-1 flex justify-center">
                  <span className="block sm:hidden">+</span>
                  <span className="hidden sm:block">Create Post</span>
                </div>
              </Button>
            </li>
          </ul>
        </div>

        <div className="flex-1 w-auto">{children}</div>
      </div>

      <CouponAppliedDialog
        ref={couponAppliedDialogRef}
        onClose={goToSocialSets}
      ></CouponAppliedDialog>

      <PostActions composerRef={postComposerRef} />
    </>
  );
}

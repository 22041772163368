export default interface User {
  id: string;
  name: string;
  email: string;
  identityId?: string;
  picture?: string;
  createdAt: Date;
  permissions?: string[];
  socialSetIds?: string[];
  status: "Invited" | "Active" | "Inactive";
  role: UserRole;
  notificationSettings: UserNotificationSetting[];
}

export type UserRole = "Owner" | "Member";

export interface UserNotificationSetting {
  code: string;
  email: boolean;
  push: boolean;
  sms: boolean;
}

export class UserNotificationCodes {
  static readonly PublishedPost = "published_post";
  static readonly FailedPost = "failed_post";
  static readonly ChannelError = "channel_error";
  static readonly Newsletter = "newsletter";
}

import createPostHelper, {
  AttachmentsChangedData,
} from "../../../../services/application/create-post-helper";
import Attachments from "./Attachments";
import Errors from "./Errors";
import FormWrapper from "./FormWrapper";
import { PostInstance } from "../models/post-instance";
import Validation from "./Validation";
import AttachmentSlot from "../models/attachment-slot";

export interface PostContainerProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  isCurrent: boolean;
  isLg: boolean;
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
  onAttachmentsChanged: (
    postInstance: PostInstance,
    data: AttachmentsChangedData
  ) => void;
}

export default function PostContainer({
  isCurrent,
  postInstance,
  attachments,
  isLg,
  onPostInstanceChanged,
  onAttachmentsChanged,
}: PostContainerProps) {
  return (
    <>
      {isCurrent && (
        <>
          {isLg && (
            <>
              <div className="flex-1 flex flex-col p-4">
                <div className="flex-1">
                  <div className="flex-1 pb-4 flex flex-row gap-4">
                    <div className="">
                      <div className="w-40">
                        <div className="mb-0.5 max-w-40 inline-block rounded-md bg-gray-50 px-2 py-1 text-sm font-normal text-gray-600 ring-1 ring-inset ring-gray-500/10 text-ellipsis overflow-hidden text-nowrap">
                          {postInstance?.channel?.name}
                        </div>

                        <Attachments
                          postInstance={postInstance}
                          attachmentSlots={attachments}
                          validationErrors={postInstance.validation.errors}
                          acceptedTypes={createPostHelper.getAttachmentFileType(
                            postInstance,
                            attachments
                          )}
                          onAttachmentsChanged={(data) =>
                            onAttachmentsChanged(postInstance, data)
                          }
                        >
                          {({ uploadSection, attachmentsSection }) => {
                            return (
                              <div className="flex flex-col gap-3">
                                <div className="aspect-1 w-40 h-40">
                                  {uploadSection}
                                </div>
                                <div className="grid grid-cols-2 gap-2">
                                  {attachmentsSection}
                                </div>
                              </div>
                            );
                          }}
                        </Attachments>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div>
                        <FormWrapper
                          attachments={attachments}
                          postInstance={postInstance}
                          onPostInstanceChanged={onPostInstanceChanged}
                        >
                          {({ primaryForm, secondaryForm }) => {
                            return (
                              <>
                                <div>{primaryForm}</div>
                                <div className="mt-4">{secondaryForm}</div>
                              </>
                            );
                          }}
                        </FormWrapper>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sticky -bottom-px left-0 right-0 py-2 bg-white z-10 -mx-2 px-2">
                  <Validation
                    validationResult={postInstance?.validation}
                    // errorOwner="form"
                  />
                  <Errors post={postInstance.post} />
                </div>
              </div>
            </>
          )}

          {!isLg && (
            <>
              <div className="flex-1 flex flex-col px-3 md:px-4 py-3 pb-0">
                <div className="flex-1">
                  <FormWrapper
                    attachments={attachments}
                    postInstance={postInstance}
                    onPostInstanceChanged={onPostInstanceChanged}
                  >
                    {({ primaryForm, secondaryForm }) => {
                      return (
                        <>
                          <div className="flex-1 pb-4 flex flex-col">
                            <div>
                              <div className="inline-block text-sm font-normal text-gray-600 text-ellipsis overflow-hidden text-nowrap">
                                {postInstance?.channel?.name}
                              </div>
                            </div>

                            <div>{primaryForm}</div>

                            <div className="mt-4">
                              <Attachments
                                postInstance={postInstance}
                                attachmentSlots={attachments}
                                validationErrors={
                                  postInstance.validation.errors
                                }
                                acceptedTypes={createPostHelper.getAttachmentFileType(
                                  postInstance,
                                  attachments
                                )}
                                onAttachmentsChanged={(data) =>
                                  onAttachmentsChanged(postInstance, data)
                                }
                              >
                                {({ uploadSection, attachmentsSection }) => {
                                  return (
                                    <div className="grid grid-cols-4 grid-rows-3 gap-2 sm:grid-cols-6">
                                      <div className="col-span-2 row-span-3 aspect-1">
                                        {uploadSection}
                                      </div>
                                      {attachmentsSection}
                                    </div>
                                  );
                                }}
                              </Attachments>
                            </div>

                            <div className="mt-4">{secondaryForm}</div>
                          </div>
                        </>
                      );
                    }}
                  </FormWrapper>
                </div>

                <div className="sticky -bottom-px left-0 right-0 py-2 bg-white z-10 -mx-2 px-2">
                  <Validation
                    validationResult={postInstance?.validation}
                    errorOwner="form"
                  />
                  <Errors post={postInstance.post} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

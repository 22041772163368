import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useContext } from "react";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export default function SubscriptionCanceledBanner() {
  const {
    state: {
      currentTenant: { subscription },
    },
  } = useContext(GlobalStateContext);

  return (
    <div className="flex flex-col sm:flex-row items-end sm:items-center justify-between gap-4 w-full">
      <div className="flex gap-2 items-center ">
        <div className="w-6 h-6">
          <ExclamationTriangleIcon className="w-6 h-6 text-red-200" />
        </div>

        <p className="text-sm text-red-200">
          Your{" "}
          <span className="font-medium">
            {subscription?.items?.[0]?.plan?.product?.name}
          </span>{" "}
          {subscription?.items?.[0]?.plan?.interval == "year"
            ? "yearly"
            : "monthly"}{" "}
          plan was canceled on{" "}
          <span className="font-medium">
            {" "}
            {dayjs(subscription.canceledAt).format("MMM DD, YYYY")}
          </span>
          . To continue using Viraly you have to subscribe again.
        </p>
      </div>
      <Link
        to="/settings/plan"
        className="group inline-flex items-center justify-center rounded-md py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white hover:bg-white-50 active:bg-white-200 active:text-slate-600 focus-visible:outline-white w-40 text-red-600"
      >
        <div className="flex items-center gap-2">View Plans</div>
      </Link>
    </div>
  );
}

import { QueryKey } from "@tanstack/react-query";
import { queryClient } from "./query-client";

export function invalidateQuery(queryKey: QueryKey): void {
  queryClient.invalidateQueries({ queryKey: queryKey });
}

export function refetchQuery(queryKey: QueryKey): Promise<void> {
  return queryClient.refetchQueries({ queryKey: queryKey });
}

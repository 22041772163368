import config from "./../amplifyconfiguration.json";
import { Amplify } from "aws-amplify";
import { ConsoleLogger, Hub } from "aws-amplify/utils";
import { createIDBPersister } from "../queries/indexdb-persister";
import { queryClient } from "../queries/query-client";
import intercomService from "../services/application/intercom-service";
import localBrowserStorage from "../services/browser-storage/local-browser-storage";
import sessionBrowserStorage from "../services/browser-storage/session-browser-storage";

const authHostname = import.meta.env.VITE_AUTH_HOSTNAME;
config.oauth.domain = authHostname;

ConsoleLogger.LOG_LEVEL = "WARNING";
window.LOG_LEVEL = "WARNING";

config.oauth.redirectSignIn = `${window.location.origin}/oauth/cognito-redirect/`;
config.oauth.redirectSignOut = `${window.location.origin}/oauth/cognito-redirect/`;

Amplify.configure(config);

Hub.listen("auth", ({ payload }) => {
  const clearCaches = () => {
    queryClient.clear();
    createIDBPersister().removeClient();
    localBrowserStorage.clear();
    sessionBrowserStorage.clear();
  };

  console.log("A new auth event has happened: ", payload);

  switch (payload.event) {
    case "signedIn":
      clearCaches();
      break;

    case "signedOut":
      clearCaches();
      intercomService.shutdown();
      break;

    case "tokenRefresh":
      break;
  }
});

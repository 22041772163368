import {
  BookmarkIcon,
  ChatBubbleOvalLeftIcon,
  HeartIcon,
  MagnifyingGlassIcon,
  MusicalNoteIcon,
  PaperAirplaneIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import FacebookVideoFrame from "../facebook/FacebookVideoFrame";
import { PostInstance } from "../../models/post-instance";
import AttachmentSlot from "../../models/attachment-slot";

export interface TikTokVideoPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function TikTokVideoPreview({
  postInstance,
  attachments,
}: TikTokVideoPreviewProps) {
  const channel = postInstance.channel;
  const title = postInstance?.postConfig?.tikTok?.contentOptions?.title;

  if (!attachments.length) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col relative rounded-lg w-full overflow-hidden aspect-[9/16]">
        <FacebookVideoFrame slot={attachments[0]} />

        <div className="absolute top-4 right-0 z-10 w-full">
          <div className="flex flex-row justify-center gap-6 px-3 py-4">
            <div className="flex gap-4 justify-center">
              <div className="text-white text-xs font-light">Following</div>
              <div className="text-white text-xs border-b-2 border-white pb-2">
                For You
              </div>
            </div>
            <div className="absolute top-4 right-3 rounded-full">
              <MagnifyingGlassIcon className="h-5 w-5 text-white" />
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 right-0 z-10">
          <div className="flex flex-col items-center gap-6 px-3 py-4">
            <div className="relative flex flex-col">
              <img
                referrerPolicy="no-referrer"
                className="h-9 w-9 flex-none rounded-full bg-white outline outline-1 outline-white"
                src={channel.pictureUrl}
              />
              <div className="absolute -bottom-2 left-0 right-0 flex justify-center">
                <div className="bg-white w-3 h-3 rounded-full">
                  <PlusCircleIcon className="h-5 w-5 -mt-1 -ml-1 text-red-600 " />
                </div>
              </div>
            </div>

            <div className="rounded-full">
              <HeartIcon className="h-5 w-5 text-white" />
            </div>
            <div className="rounded-full">
              <ChatBubbleOvalLeftIcon className="w-5 h-5 scale-x-[-1] text-white" />
            </div>
            <div className="rounded-full">
              <BookmarkIcon className="w-5 h-5 text-white" />
            </div>
            <div className="rounded-full">
              <PaperAirplaneIcon className="w-5 h-5 text-white" />
            </div>
            <div className="rounded-full mt-2">
              <div
                className="w-8 h-8 rounded-full flex items-center justify-center"
                style={{
                  background:
                    "linear-gradient(310.03deg, rgb(54, 54, 53) 13.36%, rgb(117, 117, 117) 49.18%, rgb(16, 16, 16) 88.11%)",
                }}
              >
                <img
                  referrerPolicy="no-referrer"
                  className="h-4 w-4 flex-none rounded-full"
                  src={channel.pictureUrl}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 right-0 left-0 z-10 mr-8 pr-2 mb-2">
          <div className="flex flex-col items-start px-4 py-3">
            <div className="text-white text-xs font-semibold">
              {channel.username}
            </div>

            {title ? (
              <div className="text-white text-[10px] leading-[14px] font-light line-clamp-[18] whitespace-break-spaces">
                {title}
              </div>
            ) : null}

            <div className="flex items-center gap-2 mt-3">
              <MusicalNoteIcon className="w-5 h-5 text-white" />
              <span className="text-[10px] font-light leading-3 text-white">
                original sound - {channel.username}
              </span>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { SpeakerXMarkIcon } from "@heroicons/react/20/solid";
import FacebookVideoFrame from "../facebook/FacebookVideoFrame";
import { PostInstance } from "../../models/post-instance";
import { HeartIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import AttachmentSlot from "../../models/attachment-slot";

export interface InstagramStoryPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function InstagramStoryPreview({
  postInstance,
  attachments,
}: InstagramStoryPreviewProps) {
  const channel = postInstance.channel;

  if (!attachments.length) {
    return null;
  }

  const slot = attachments[0];

  return (
    <>
      <div className="flex flex-col relative rounded-lg w-full overflow-hidden">
        <div className="absolute top-0 left-0 right-0 z-10">
          <div className="bg-white/60 w-11/12 h-[2px] mx-auto mt-2 rounded-sm"></div>
          <div className="flex flex-row items-center gap-2 px-2 py-2">
            <div>
              <img
                referrerPolicy="no-referrer"
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
                src={channel.pictureUrl}
              />
            </div>
            <div className="flex-1 flex flex-row items-center gap-2">
              <div className="text-[10px] leading-3 font-semibold text-white">
                {channel.name}
              </div>
              <span className="text-[10px] leading-3 text-white font-extralight">
                {" "}
                1m
              </span>
            </div>
            <div className="text-white flex gap-2">
              <SpeakerXMarkIcon className="h-5 text-white" />
            </div>
          </div>
        </div>

        {slot.isPhoto ? (
          <img
            className="w-full h-full aspect-[9/16] object-cover"
            src={slot.preview.url}
          />
        ) : (
          <div className="aspect-[9/16]">
            <FacebookVideoFrame slot={slot} />
          </div>
        )}

        <div className="flex flex-row justify-between gap-6 bg-black w-full text-white px-3 py-8">
          <div className="flex-1 px-3 py-2 rounded-full border border-gray-500 text-[11px] font-normal leading-[13px]">
            Send message
          </div>
          <div className="flex flex-row gap-5 items-center justify-around">
            <HeartIcon className="w-5 text-white" />
            <PaperAirplaneIcon className="w-5 text-white" />
          </div>
        </div>
      </div>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import CharacterCounter from "./CharacterCounter";
import { TextareaAutosize } from "@mui/material";

export interface TextAreaFieldProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  hideLabel?: boolean;
  horizontalLayout?: boolean;
  id?: string;
  formHook: UseFormReturn<any, any, any>;
  disabled?: boolean;
  autosize?: boolean;
}

export default function TextAreaField({
  name,
  label,
  id,
  formHook,
  hideLabel,
  horizontalLayout,
  disabled,
  autosize = false,
  ...rest
}: TextAreaFieldProps) {
  const [length, setLenght] = useState<number>(
    formHook?.getValues()?.description?.length ?? 0
  );

  const {
    register,
    formState: { errors },
  } = formHook;

  const characterCounterComponent = !rest.maxLength ? null : (
    <CharacterCounter counter={rest.maxLength - length} />
  );

  const onTextChanged = (event) => {
    setLenght(event.target.value.length);
    rest?.onChange?.(event);
  };

  return (
    <>
      <div
        className={clsx(
          horizontalLayout
            ? "flex flex-row justify-start items-center gap-1"
            : "flex flex-col justify-center items-start gap-1"
        )}
      >
        {!hideLabel ? (
          <label
            htmlFor={name}
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            {label}
          </label>
        ) : null}

        <div className={clsx("relative", horizontalLayout ? null : "w-full")}>
          {autosize ? (
            <TextareaAutosize
              id={id}
              {...register(name)}
              {...rest}
              onChange={rest.maxLength ? onTextChanged : rest.onChange}
              name={name}
              autoComplete={label}
              disabled={disabled}
              maxRows={rest.rows}
              className={clsx(
                "block w-full rounded-md border-0 py-1.5 text-gray-600 text-sm leading-5 shadow-sm placeholder:text-gray-400 ring-1 ring-inset ring-gray-200 focus:ring-1 focus:ring-inset focus:ring-gray-400",
                rest.className ?? "",
                errors[name] ? "ring-red-700 focus:ring-red-700" : "",
                rest.maxLength ? "pr-10" : null,
                disabled ? "bg-gray-50 cursor-not-allowed" : ""
              )}
            />
          ) : (
            <textarea
              id={id}
              {...register(name)}
              {...rest}
              onChange={rest.maxLength ? onTextChanged : rest.onChange}
              name={name}
              autoComplete={label}
              disabled={disabled}
              className={clsx(
                "block w-full rounded-md border-0 py-1.5 text-gray-600 text-sm leading-5 shadow-sm placeholder:text-gray-400 ring-1 ring-inset ring-gray-200 focus:ring-1 focus:ring-inset focus:ring-gray-400",
                rest.className ?? "",
                errors[name] ? "ring-red-700 focus:ring-red-700" : "",
                rest.maxLength ? "pr-10" : null,
                disabled ? "bg-gray-50 cursor-not-allowed" : ""
              )}
            />
          )}

          {characterCounterComponent}

          <p role="alert" className="text-sm text-red-700">
            {errors[name]?.message?.toString()}
          </p>
        </div>
      </div>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import InputField from "../../../../../components/form/InputField";
import { PostInstance } from "../../models/post-instance";
import BoardSelector from "./BoardSelector";
import PinterestBoard from "../../../../../models/pinterest/pinterest-board";
import PrimaryTextBox from "../../../../../components/common/PrimaryTextBox";
import eventBusService from "../../../../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../../../../services/application/event-bus/event-bus-events";
import AttachmentSlot from "../../models/attachment-slot";

interface PinterestFormProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
  children?: React.ReactNode | (({ primaryForm, secondaryForm }) => void);
}

interface PinterestPostFormInput {
  title?: string;
  description?: string;
  link?: string;
  channelId: string;
}

const schema = yup
  .object({
    description: yup.string(),
    title: yup.string(),
    link: yup.string().notRequired(),
  })
  .required();

export default function PinterestForm({
  postInstance,
  attachments,
  onPostInstanceChanged,
  children,
}: PinterestFormProps) {
  const pinterestConfig = postInstance.postConfig.pinterest;

  const form = useForm<PinterestPostFormInput>({
    mode: "onChange",
    resolver: yupResolver(schema) as any,
    reValidateMode: "onChange",
    defaultValues: {
      description: pinterestConfig.contentOptions?.description ?? "",
      title: pinterestConfig.contentOptions?.title ?? "",
      link: pinterestConfig.contentOptions?.link ?? "",
    },
  });

  useEffect(() => {
    const insertAiContent = (content) => {
      form.setValue("description", content);
      onDescriptionChanged(content);
    };

    eventBusService.on(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);

    return () => {
      eventBusService.remove(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);
    };
  });

  useEffect(() => {
    const setSystemPostType = () => {
      let systemPostType = pinterestConfig.contentOptions.systemPostType;

      const initialSystemPostType = systemPostType;

      if (attachments.length == 1) {
        systemPostType = attachments[0].isPhoto ? "ImagePin" : "VideoPin";
      } else if (attachments.length > 1) {
        systemPostType = "Carousel";
      }

      if (systemPostType != initialSystemPostType) {
        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            pinterest: {
              contentOptions: {
                ...pinterestConfig.contentOptions,
                systemPostType: systemPostType,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    setSystemPostType();
  }, [pinterestConfig.contentOptions, postInstance, attachments]);

  const onDescriptionChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      title: text,
      postConfig: {
        ...postInstance.postConfig,
        pinterest: {
          contentOptions: {
            ...pinterestConfig.contentOptions,
            description: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onLinkChanged = (event) => {
    const text = event.target.value;

    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        pinterest: {
          contentOptions: {
            ...pinterestConfig.contentOptions,
            link: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onTitleChanged = (event) => {
    const text = event.target.value;

    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        pinterest: {
          contentOptions: {
            ...pinterestConfig.contentOptions,
            title: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onBoardSelected = (board: PinterestBoard) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        pinterest: {
          ...postInstance.postConfig.pinterest,
          contentOptions: {
            ...pinterestConfig.contentOptions,
            boardId: board.id,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const primaryForm = (
    <>
      <div className="flex flex-row">
        <fieldset className="w-full h-6 mt-3 block"></fieldset>
      </div>

      {/* Description */}
      <div>
        <PrimaryTextBox
          onTextChanged={onDescriptionChanged}
          textAreaOptions={{
            hideLabel: true,
            maxLength: 500,
            className: "h-[124px]",
            name: "description",
            label: "Description",
            formHook: form,
            placeholder: "Your pin description",
          }}
        />
      </div>
    </>
  );

  const secondaryForm = (
    <>
      <div className="md:shadow custom-shadow rounded-md py-3 md:p-3 flex flex-col gap-4">
        {/* <div className="leading-8 font-medium text-md text-gray-700">More Options</div> */}

        <div className="flex justify-between items-center gap-4 relative">
          <div className="text-gray-700 font-semibold text-xs">Pin Title</div>
          <div className="flex-1 w-full max-w-64">
            <InputField
              label="Title"
              placeholder="Your pin title"
              name="title"
              hideLabel={true}
              formReturn={form}
              onChange={onTitleChanged}
              maxLength={100}
            />
          </div>
        </div>

        <div className="flex justify-between items-center gap-4 relative">
          <div className="text-gray-700 font-semibold text-xs">Link</div>
          <div className="flex-1 w-full max-w-64">
            <InputField
              label="Link"
              placeholder="Add a Link"
              name="link"
              hideLabel={true}
              formReturn={form}
              onChange={onLinkChanged}
              maxLength={500}
            />
          </div>
        </div>

        <div className="flex justify-between items-center gap-4 relative">
          <div className="text-gray-700 font-semibold text-xs">Pinning to</div>
          <div className="flex-1 w-full max-w-64">
            <BoardSelector
              channelId={postInstance.channel.id}
              boardId={
                postInstance?.postConfig?.pinterest?.contentOptions?.boardId
              }
              onBoardSelected={onBoardSelected}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <form>
      <>
        {typeof children === "function"
          ? children({ primaryForm, secondaryForm })
          : children}{" "}
      </>
    </form>
  );
}

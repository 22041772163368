import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../../queries/query-names";
import stripeService from "../../../../services/api/stripe-service";
import Spinner from "../../../../components/common/Spinner";
import dayjs from "dayjs";
import Button from "../../../../components/common/Button";
import {
  ArrowTopRightOnSquareIcon,
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useMemo } from "react";

export default function Invoices() {
  const { data: invoices, isLoading } = useQuery({
    queryKey: [queryNames.stripeInvoices],
    queryFn: stripeService.getInvoices,
  });

  const currencyFormatter = useMemo(
    () =>
      Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
      }),
    []
  );

  const content = isLoading ? (
    <div className="flex-1 h-full flex items-center justify-center">
      <Spinner size="5" />
    </div>
  ) : (
    <>
      <div className="flex-1 mt-6">
        {!invoices.length ? (
          <>
            <div className="text-sm text-gray-700">No invoices</div>
          </>
        ) : (
          <table className="w-full">
            <tbody>
              {invoices.map((payment, index) => (
                <tr key={index}>
                  <td className="text-sm font-medium text-gray-900 min-w-24">
                    <time>{dayjs(payment.created).format("MMM DD, YYYY")}</time>
                  </td>

                  <td className="px-4">
                    <div
                      data-tooltip-id={
                        payment.status != "paid" && payment.attemptCount
                          ? "tooltip-placeholder"
                          : null
                      }
                      data-tooltip-content={`Failed charge attempts: ${payment.attemptCount}`}
                      className={clsx(
                        "capitalize inline-flex justify-center gap-2 items-center rounded-full  pl-2 pr-3 py-1 text-xs font-medium  ring-1 ring-inset ",
                        payment.status == "paid" &&
                          "ring-green-600/20 text-green-700 bg-green-50",
                        payment.status == "open" &&
                          "ring-red-600/20 text-red-700 bg-red-50",
                        payment.status != "paid" &&
                          payment.status != "open" &&
                          "ring-gray-600/20 text-gray-700 bg-gray-50"
                      )}
                    >
                      {payment.status != "paid" && payment.attemptCount > 0 && (
                        <ExclamationTriangleIcon className="size-4" />
                      )}
                      {payment.status == "paid" && (
                        <CheckIcon className="size-4" />
                      )}
                      {payment.status == "open" &&
                      payment.nextPaymentAttempt ? (
                        <>Retrying</>
                      ) : payment.status == "open" &&
                        !payment.nextPaymentAttempt ? (
                        <>Failed</>
                      ) : (
                        <>{payment.status}</>
                      )}
                    </div>
                  </td>
                  <td className="px-8 hidden sm:table-cell">
                    <div className="flex items-center gap-6 flex-wrap text-sm text-gray-700">
                      <div className="text-ellipsis leading-1">
                        {payment.lines[0].description}
                      </div>
                    </div>
                  </td>
                  <td className="text-sm font-normal">
                    <time>{currencyFormatter.format(payment.total / 100)}</time>
                  </td>
                  <td className="">
                    {payment.status == "paid" && (
                      <Button
                        target="_blank"
                        to={payment.hostedInvoiceUrl}
                        variant="text"
                        color="brand"
                        text=""
                      >
                        <div className="flex items-center gap-2">
                          View
                          <ArrowTopRightOnSquareIcon className="w-4" />
                        </div>
                      </Button>
                    )}
                    {payment.status == "open" && (
                      <Button
                        target="_blank"
                        to={payment.hostedInvoiceUrl}
                        variant="text"
                        color="brand"
                        text=""
                      >
                        <div className="flex items-center gap-2">
                          Pay now
                          <ArrowTopRightOnSquareIcon className="w-4" />
                        </div>
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className="divide-y divide-gray-200">
        <div className="space-y-1">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Invoices History
          </h2>

          <p className="max-w-2xl text-sm text-gray-500">View your invoices</p>
        </div>
        <div className="mt-4 min-h-20 flex flex-col">{content}</div>
      </div>
    </>
  );
}

import type { Config } from "tailwindcss";
import formsPlugin from "@tailwindcss/forms";
import typographyPlugin from "@tailwindcss/typography";
import aspectRatioPlugin from "@tailwindcss/aspect-ratio";

const config: Config = {
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    extend: {
      colors: {
        primary: {
          50: "#FCEDF1",
          100: "#FADCE3",
          200: "#F4B8C6",
          300: "#F099AD",
          400: "#EA7691",
          500: "#E55274",
          600: "#E03058",
          700: "#B11B3E",
          800: "#78122A",
          900: "#3A0914",
          950: "#1F050B",
        },
        "gray-50": "#F7F6F7",
        secondary: {
          50: "#efeeff",
          100: "#e2e0ff",
          200: "#cac7fe",
          300: "#aaa5fc",
          400: "#8881f8",
          500: "#6b63f1",
          600: "#4f46e5",
          700: "#4038ca",
          800: "#3730a3",
          900: "#332e81",
          950: "#1e1b4b",
        },
        tertiary: {
          50: "#eafff8",
          100: "#cdfeec",
          200: "#a0fade",
          300: "#63f2cd",
          400: "#25e2b8",
          500: "#25e2b8",
          600: "#00a485",
          700: "#00836e",
          800: "#006758",
          900: "#00554a",
          950: "#00302b",
        },
      },
      borderRadius: {
        "2xl": "1.250rem",
      },
      padding: {
        "52": "13rem",
      },
      zIndex: {
        "60": "60",
        "70": "70",
        "80": "80",
        "90": "90",
        "100": "100",
      },
    },
  },
  plugins: [formsPlugin, typographyPlugin, aspectRatioPlugin],
};

export default config;

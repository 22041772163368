import { useEffect } from "react";
import AuthService from "../../../services/auth/auth-service";
import Splash from "../../../layouts/MainLayout/components/Splash";
import { useNavigate } from "react-router-dom";

export default function Signout() {
  const nagivate = useNavigate();

  useEffect(() => {
    const signout = async () => {
      await AuthService.logout();

      const timeout = setTimeout(() => {
        nagivate("/scheduler");
      }, 2500);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    };

    signout();
  }, [nagivate]);

  return (
    <>
      <Splash message="Time for a break. Catch you later, friend!" />
    </>
  );
}

import { Field, Label } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

export interface MusicSettingsProps {
  autoAddMusic: boolean;
  onAutoAddMusicChanged: (autoAddMusic: boolean) => void;
}

export default function MusicSettings({ autoAddMusic, onAutoAddMusicChanged }: MusicSettingsProps) {
  return (
    <Field className="flex items-center">
      <div className="flex flex-grow flex-col">
        <div className="flex items-center gap-4">
          <Label as="span" passive className="w-40 flex gap-2 items-center text-xs font-semibold text-gray-700">
            <span> Auto-add music </span>
            <InformationCircleIcon
              className="h-4 w-4 text-gray-400"
              data-tooltip-id="tooltip-placeholder"
              data-tooltip-content={`If enabled, recommended music will be automatically\n added to photos, and you can later choose to change\n the post's music in TikTok if you prefer other music.`}
            />
          </Label>
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                checked={autoAddMusic}
                onChange={() => onAutoAddMusicChanged(!autoAddMusic)}
                id="autoAddMusic"
                name="autoAddMusic"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer disabled:bg-gray-50 disabled:opacity-80 disabled:cursor-not-allowed"
              />
            </div>
            <div className="text-sm leading-6">
              <label htmlFor="autoAddMusic" className="font-medium text-gray-900 text-xs cursor-pointer pl-2">
                Yes
              </label>{" "}
            </div>
          </div>
        </div>
      </div>
    </Field>
  );
}

import ApiService from "../core/api-service";
import ChannelSlot from "../../models/entities/channel-slot";

class ChannelSlotService {
  update(channelId: string, slots: ChannelSlot[]): Promise<ChannelSlot[]> {
    return ApiService.put<ChannelSlot[]>(`channels/${channelId}/slots`, {
      slots,
    });
  }
}

export default new ChannelSlotService();

const socialSets = "social-sets";
const tenants = "tenants";
const socialSetById = `social-set-by-id`;
const hashtagsBySocialSetId = `hashtags-by-social-set-id`;
const pinterestBoardsByChannelId = `pinterest-boards-by-channel-id`;
const postsByDateRange = `calendar-posts-by-date-range`;
const tikTokCreatorInfo = "tiktok-creator-info";

const queuedPosts = "queued-posts";
const postsByStatus = "posts-by-status";
const getSingleQueueCounters = "single-queue-counters";
const listAllQueuedCount = "queue-counters/list-queued-count";

const currentSubscription = "current-subscription";
const stripeProducts = "stripe-products";
const stripePrices = "stripe-prices";
const stripePaymentMethods = "stripe-payment-methods";
const stripeInvoices = "stripe-invoices";
const youTubeCategories = "youtube-categories";
const users = "users";
const postQuotas = "quotas-posts";
const timezones = "timezones";
const mediaAttachments = "media-attachments";

const queryNames = {
  socialSetById,
  postsByDateRange,
  pinterestBoardsByChannelId,
  tikTokCreatorInfo,
  hashtagsBySocialSetId,
  queuedPosts,
  getSingleQueueCounters,
  listAllQueuedCount,
  postsByStatus,
  youTubeCategories,
  postQuotas,
  stripePaymentMethods,
  currentSubscription,
  socialSets,
  tenants,
  stripeProducts,
  stripePrices,
  stripeInvoices,
  users,
  timezones,
  mediaAttachments,
};

export default queryNames;

import AttachmentSlot from "../models/attachment-slot";
import { PostInstance } from "../models/post-instance";
import FacebookForm from "./facebook/FacebookForm";
import InstagramForm from "./instagram/InstagramForm";
import LinkedInForm from "./linkedin/LinkedInForm";
import PinterestForm from "./pinterest/PinterestForm";
import ThreadsForm from "./threads/ThreadsForm";
import TikTokForm from "./tikTok/TikTokForm";
import TwitterForm from "./twitter/TwitterForm";
import YouTubeForm from "./youTube/YouTubeForm";

export interface FormWrapperProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
  children?: React.ReactNode | (({ primaryForm, secondaryForm }) => void);
}

export default function FormWrapper({
  postInstance,
  attachments,
  onPostInstanceChanged,
  children,
}: FormWrapperProps) {
  const formRenderer = ({ primaryForm, secondaryForm }) => (
    <>
      {" "}
      {typeof children === "function"
        ? children({ primaryForm, secondaryForm })
        : children}{" "}
    </>
  );

  switch (postInstance?.channel.type) {
    case "Facebook":
      return (
        <FacebookForm
          attachments={attachments}
          key={postInstance.channel.id}
          postInstance={postInstance}
          onPostInstanceChanged={onPostInstanceChanged}
        >
          {formRenderer}
        </FacebookForm>
      );

    case "Instagram":
      return (
        <InstagramForm
          attachments={attachments}
          key={postInstance.channel.id}
          postInstance={postInstance}
          onPostInstanceChanged={onPostInstanceChanged}
        >
          {formRenderer}
        </InstagramForm>
      );

    case "Pinterest":
      return (
        <PinterestForm
          attachments={attachments}
          key={postInstance.channel.id}
          postInstance={postInstance}
          onPostInstanceChanged={onPostInstanceChanged}
        >
          {formRenderer}
        </PinterestForm>
      );

    case "Twitter":
      return (
        <TwitterForm
          attachments={attachments}
          key={postInstance.channel.id}
          postInstance={postInstance}
          onPostInstanceChanged={onPostInstanceChanged}
        >
          {formRenderer}
        </TwitterForm>
      );

    case "YouTube":
      return (
        <YouTubeForm
          attachments={attachments}
          key={postInstance.channel.id}
          postInstance={postInstance}
          onPostInstanceChanged={onPostInstanceChanged}
        >
          {formRenderer}
        </YouTubeForm>
      );

    case "TikTok":
      return (
        <TikTokForm
          attachments={attachments}
          key={postInstance.channel.id}
          postInstance={postInstance}
          onPostInstanceChanged={onPostInstanceChanged}
        >
          {formRenderer}
        </TikTokForm>
      );

    case "Threads":
      return (
        <ThreadsForm
          attachments={attachments}
          key={postInstance.channel.id}
          postInstance={postInstance}
          onPostInstanceChanged={onPostInstanceChanged}
        >
          {formRenderer}
        </ThreadsForm>
      );
    case "LinkedIn":
      return (
        <LinkedInForm
          attachments={attachments}
          key={postInstance.channel.id}
          postInstance={postInstance}
          onPostInstanceChanged={onPostInstanceChanged}
        >
          {formRenderer}
        </LinkedInForm>
      );

    default:
      return null;
  }
}

export default class OAuthPopupHandler {
  initiate(url: string, channelName: string, listenOnClose: boolean = true): Promise<AuthorizationCodeResponse> {
    return new Promise((resolve, reject) => {
      const popup = window.open(url, "_blank", "width=600,height=600");

      const bc = new BroadcastChannel(channelName);
      bc.onmessage = handleMessage;

      let checkPopup = null;

      if (listenOnClose) {
        checkPopup = setInterval(() => {
          if (popup && popup.closed) {
            clearInterval(checkPopup);

            console.warn("Popup closed");
            reject();
          }
        }, 1000);
      }

      function handleMessage(event) {
        console.log(event);
        if (event.currentTarget?.name != channelName) return;

        const { code, state } = event.data;

        if (!code) {
          reject("Authorization canceled");
        }

        if (checkPopup) {
          clearInterval(checkPopup);
          checkPopup = null;
        }

        bc.close();
        popup.close();

        resolve({ code, state });
      }
    });
  }

  initiateOAuth1(url: string, channelName: string, listenOnClose: boolean = true): Promise<OAuth1TokenResponse> {
    return new Promise((resolve, reject) => {
      const popup = window.open(url, "_blank", "width=600,height=600");

      const bc = new BroadcastChannel(channelName);
      bc.onmessage = handleMessage;

      let checkPopup = null;

      if (listenOnClose) {
        checkPopup = setInterval(() => {
          if (popup && popup.closed) {
            clearInterval(checkPopup);

            console.warn("Popup closed");
            reject();
          }
        }, 1000);
      }

      function handleMessage(event) {
        console.log(event);
        if (event.currentTarget?.name != channelName) return;

        const { token, verifier } = event.data as OAuth1TokenResponse;

        if (!token) {
          reject("Authentication canceled");
        }

        if (checkPopup) {
          clearInterval(checkPopup);
          checkPopup = null;
        }

        bc.close();
        popup.close();

        resolve({ token, verifier });
      }
    });
  }
}

export interface AuthorizationCodeResponse {
  code: string;
  state: string;
}

export interface OAuth1TokenResponse {
  token: string;
  verifier: string;
}

import { Description, Field, Label, Switch } from "@headlessui/react";
import clsx from "clsx";
import { useState } from "react";

export interface ToggleControlProps {
  initial: boolean;
  onChange?: (enabled: boolean) => void;
  title: string;
  description?: string;
}

export default function ToggleControl({ initial, title, description, onChange }: ToggleControlProps) {
  const [enabled, setEnabled] = useState(initial);

  const onToggle = (value: boolean) => {
    setEnabled(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Field as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Label as="span" className="text-gray-700 font-semibold text-xs leading-4" passive>
          {title}
        </Label>
        {description && (
          <Description as="span" className=" text-gray-500 text-xs leading-2">
            {description}
          </Description>
        )}
      </span>
      <Switch
        checked={enabled}
        onChange={onToggle}
        className={clsx(
          enabled ? "bg-primary-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
        )}
      >
        <span
          className={clsx(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </Field>
  );
}

import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import {
  BookmarkIcon,
  ChatBubbleOvalLeftIcon,
  HeartIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import { PostInstance } from "../../models/post-instance";
import clsx from "clsx";
import AttachmentSlot from "../../models/attachment-slot";
import InstagramComment from "./InstagramComment";

export interface InstagramPostPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function InstagramPostPreview({
  postInstance,
  attachments,
}: InstagramPostPreviewProps) {
  const instagramPostConfig = postInstance.postConfig.instagram;
  const channel = postInstance.channel;

  const mediaContent = getPhotoPostContent();

  function getPhotoPostContent() {
    let mediaContent = null;

    if (attachments.length > 0) {
      const attachment1 = attachments[0];
      const aspectRatio =
        attachment1.preview.width > attachment1.preview.height
          ? "aspect-[1.91/1]"
          : "aspect-[4/5]";

      mediaContent = (
        <div
          className={clsx(
            "grid grid-cols-3 grid-rows-2 max-h-[40vh]",
            aspectRatio
          )}
        >
          <img
            className="col-span-3 row-span-2 w-full h-full object-cover"
            src={attachments[0].preview.url}
          />
        </div>
      );
    }

    return mediaContent;
  }

  return (
    <>
      <div className="flex flex-col border-gray-600 ring-1 ring-gray-200 rounded-sm w-full">
        <div className="flex flex-row items-center gap-3 px-4 py-3">
          <div>
            <img
              referrerPolicy="no-referrer"
              className="h-10 w-10 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
              src={channel.pictureUrl}
            />
          </div>
          <div className="flex-1 flex flex-col">
            <div className="font-semibold text-[13px] leading-4 text-gray-900">
              {channel.name}
            </div>
          </div>
          <div>
            <EllipsisHorizontalIcon className="w-6 text-slate-800" />
          </div>
        </div>

        {mediaContent}

        <div className="relative flex flex-row gap-3 justify-between px-4 py-2">
          <div className="flex items-center justify-center gap-3">
            <div className="flex flex-row gap-1 text-gray-700">
              <HeartIcon className="w-5" />
            </div>
            <div className="flex flex-row gap-1 text-gray-700">
              <ChatBubbleOvalLeftIcon className="w-5 scale-x-[-1]" />
            </div>
            <div className="flex flex-row gap-1 text-gray-700">
              <PaperAirplaneIcon className="w-5" />
            </div>
          </div>
          <div className="flex flex-row gap-1 text-gray-700">
            <BookmarkIcon className="w-5" />
          </div>

          {attachments.length > 1 ? (
            <div className="absolute left-0 top-0 right-0 px-4 py-2">
              {
                <div className="flex items-center justify-center gap-1 h-5">
                  {attachments
                    .map((_, index) => index)
                    .filter((x) => x < 5)
                    .map((index) => (
                      <div
                        key={index}
                        className={clsx(
                          "rounded-full w-1.5 h-1.5",
                          index == 0 ? "bg-black" : "bg-gray-300",
                          index == 3 ? "scale-75" : null,
                          index == 4 ? "scale-50" : null
                        )}
                      ></div>
                    ))}
                </div>
              }
            </div>
          ) : null}
        </div>

        {instagramPostConfig.contentOptions.caption ? (
          <div className="px-4">
            <span className="text-sm font-semibold leading-4 text-black">
              {channel.name}
            </span>{" "}
            <span className="font-normal text-sm whitespace-pre-line leading-[21px] text-gray-900">
              {instagramPostConfig.contentOptions.caption.substring(0, 100)}
              {instagramPostConfig.contentOptions.caption.length > 100 ? (
                <span className="text-sm font-light text-gray-400">
                  ... more
                </span>
              ) : null}
            </span>
          </div>
        ) : null}

        <InstagramComment
          instagramPostConfig={instagramPostConfig}
          channel={channel}
        />

        <div className="mt-2 text-left px-4 pb-3 text-[9px] font-light text-gray-400">
          JUST NOW
        </div>
      </div>
    </>
  );
}

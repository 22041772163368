import SchedulerLayout from "../SchedulerLayout";
import Hashtags from "./Hashtags";

export default function HashtagsLayout() {
  return (
    <SchedulerLayout>
      <div className="flex h-full flex-col overflow-hidden rounded-md shadow overflow-y-auto bg-white min-h-[calc(100dvh-15.5rem)] lg:h-[calc(100dvh-14rem)] xl:h-[calc(100dvh-8rem)]">
        <Hashtags />
      </div>
    </SchedulerLayout>
  );
}

export default function PinterestInfoDialog() {
  return (
    <div className="text-sm text-gray-800 font-light">
      <p>
        You can connect multiple{" "}
        <span className="font-medium">Pinterest business and personal</span>{" "}
        accounts to Viraly.
      </p>

      <p className="mt-5">
        When connecting Pinterest accounts, Viraly will connect to the account{" "}
        <span className="font-medium">
          currently logged in on your browser.
        </span>{" "}
        We recommend logging into the specific account you want to connect on
        Pinterest.com first, then accessing Viraly to complete the connection
        process.
      </p>
    </div>
  );
}

import { XCircleIcon } from "@heroicons/react/20/solid";

export default function ErrorMessage({ title = "An error ocurred", error, onRetry = null }) {
  const errors =
    (error?.response?.data?.errors ? error?.response?.data?.errors : null) ??
    error?.errors ??
    error?.data?.errors ??
    (error.message ? [error.message] : null) ??
    (error.length ? [error] : null) ??
    [];

  return (
    <div className="flex-1 h-full w-full flex flex-row justify-center items-center">
      <div className="rounded-md bg-red-50 p-4 flex-1 max-w-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" />
          </div>
          <div className="ml-3 flex-1">
            <h3 className="text-sm font-medium text-red-800">{title}</h3>
            <div className="mt-2 text-sm text-red-700 ">
              <ul role="list" className="space-y-1">
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>

            {onRetry == null ? null : (
              <div className="mt-4">
                <div className="-my-1.5 flex justify-end">
                  <button
                    onClick={onRetry}
                    type="button"
                    className="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                  >
                    Retry
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";

const breakKeyCodes = ["Enter", "Space", "Comma"];

export interface ChipFieldProps {
  name: string;
  label: string;
  prefix?: string;
  form: UseFormReturn<any, any, any>;
}

export default function ChipField({
  name,
  label,
  form,
  prefix,
}: ChipFieldProps) {
  const [currentValue, setCurrentValue] = useState<string>();
  const tagsInputRef = useRef(null);

  const {
    register,
    formState: { errors },
    getValues,
    trigger,
  } = form;

  const tagList = useMemo(
    () =>
      (currentValue ?? "")
        .split(" ")
        .map((tag) => tag.trim())
        .filter((tag) => !!tag),
    [currentValue]
  );

  useEffect(() => {
    if (!tagList.length) return;
    trigger(name);
  }, [tagList, trigger, name]);

  useEffect(() => {
    setCurrentValue(getValues()[name]);
  }, [getValues, name]);

  const onTextChanged = (e) => {
    const text = e.target.value;

    if (breakKeyCodes.includes(e.code)) {
      const newTags = text
        .split(" ")
        .map((tag) => tag.trim())
        .filter((tag) => !!tag)
        .map((tag) =>
          !prefix ? tag : tag.startsWith(prefix) ? tag : `${prefix}${tag}`
        )
        .filter((tag) => tag != prefix)
        .filter((tag) => !tagList.includes(tag));

      if (newTags.length) {
        const newValue = [currentValue ?? "", ...newTags].join(" ");
        tagsInputRef.current.value = "";

        setCurrentValue(newValue);
        form.setValue(name, newValue);
      }

      e.preventDefault();
    }
  };

  const removeTag = (tag: string): void => {
    const newValue = tagList.filter((t) => t !== tag).join(" ");

    setCurrentValue(newValue);
    form.setValue(name, newValue);
    trigger(name);
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          {label}
        </label>
        <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 whitespace-nowrap">
          {tagList.length}
        </span>
      </div>
      <div className="mt-2">
        <input {...register(name)} type="hidden" name={name} />

        <input
          ref={tagsInputRef}
          disabled={errors?.[name]?.type == "tagsMaxLength"}
          type="text"
          onKeyDown={onTextChanged}
          placeholder="Enter tags separated by Space, Enter or Comma"
          className={clsx(
            "block w-full rounded-md border-0 py-1.5 text-gray-600 text-sm leading-5 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 read-only:bg-gray-50/80",
            errors[name] ? "ring-red-700 focus:ring-red-700" : ""
          )}
        />

        <div className="flex items-center gap-2 flex-wrap mt-4">
          {tagList.map((tag) => (
            <span
              key={tag}
              onClick={() => removeTag(tag)}
              className="inline-flex gap-2 items-center rounded-md bg-secondary-50 px-2 py-1 text-sm font-medium text-secondary-700 ring-1 ring-inset ring-secondary-700/10 hover:opacity-75 cursor-pointer"
            >
              {tag}
              <XMarkIcon className="w-4 h-4" />
            </span>
          ))}
        </div>
        <p role="alert" className="text-sm text-red-700">
          {errors[name]?.message?.toString()}
        </p>
      </div>
    </>
  );
}

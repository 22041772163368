const generatingPhrases = [
  "Unleashing AI",
  "Crafting your content",
  "Brewing your perfect post",
  "Conjuring up creativity",
  "Building your masterpiece",
  "Fueling your feed",
  "Sparkling content coming up",
  "Shaping your vision",
  "Formulating brilliance",
  "Curating your message",
  "Whipping up content",
  "Igniting creativity",
  "Designing your content",
  "Cultivating your feed",
  "Producing your post",
  "Molding your message",
  "Constructing your content",
  "Piecing together brilliance",
  "Forging your feed",
  "Honing your post",
  "Finessing your content",
  "Crafting your message",
  "Fashioning your story",
];

export default generatingPhrases;

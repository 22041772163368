/* eslint-disable @typescript-eslint/no-explicit-any */
class EventBusService {
  private listeners: { [key: string]: ((data: object) => void)[] } = {};

  on(event: string, callback: (data: object) => void) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }

    this.listeners[event].push(callback);
  }

  dispatch(event: string, data?: any) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((callback) => {
        callback(data);
      });
    }
  }

  remove(event: string, callback: (data: object) => void) {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter((listener) => listener !== callback);
    }
  }
}

export default new EventBusService();

import Button from "../../../components/common/Button";
import Breadcrumbs from "../../../components/common/BreadCrumbs";
import List from "./components/List";
import useAuthorizedSocialSets from "../../../hooks/useAuthorizedSocialSets";

export default function ListSocialSets() {
  const socialSets = useAuthorizedSocialSets();

  return (
    <>
      <div className="flex flex-col gap-5 mx-auto">
        <Breadcrumbs
          pages={[
            {
              name: "Social Sets",
              to: "/social-sets",
            },
          ]}
        />

        <div className="flex flex-col overflow-hidden bg-white shadow sm:rounded-lg min-h-[465px]">
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  My Social Sets
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Add, remove and manage your social sets and channels
                </p>
              </div>
              <div className="ml-4 mt-4 flex-shrink-0">
                <Button
                  to="create"
                  variant="solid"
                  color="brand"
                  text="+ Create new social set"
                />
              </div>
            </div>
          </div>
          <List socialSets={socialSets} />
        </div>
      </div>
    </>
  );
}

import PostingTimesSettings from "./components/posting-times/PostingTimesSettings";
import useCurrentChannel from "../../../../hooks/useCurrentChannel";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function ChannelListSettings() {
  const channel = useCurrentChannel();
  const navigate = useNavigate();

  useEffect(() => {
    if (!channel) {
      navigate("/scheduler/list/queue");
    }
  }, [channel]);

  return channel == null ? null : (
    <>
      <div className="shadow rounded-md bg-white lg:flex lg:flex-auto lg:flex-col p-4 h-full">
        {/* <SettingsHeader /> */}

        <main className="divide-y divide-gray-200 space-y-8">
          {/* Posting Slots */}
          <div className="">
            {channel && <PostingTimesSettings channel={channel} />}
            {!channel && (
              <div>
                <p className="text-sm text-gray-500">
                  Please select a channel to update settings.
                </p>
              </div>
            )}
          </div>
        </main>
      </div>
    </>
  );
}

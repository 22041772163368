export function convertUrlsToLinks(text: string, styles?: string): string {
  if (!text) return text;

  try {
    // Regular expression to match URLs starting with http/https or with a space before them
    const urlRegex =
      /(?:^|\s)(https?:\/\/[^\s;:,!"'()]+|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}[^\s;:,!"'()]*)/gi;

    // Replace the URLs in the caption with <a> tags
    const result = text.replace(urlRegex, (match, url) => {
      // Trim leading spaces from the match
      const trimmedUrl = url.trim();

      // Add http:// if the URL doesn't already contain http or https
      const fullUrl =
        trimmedUrl.startsWith("http://") || trimmedUrl.startsWith("https://")
          ? trimmedUrl
          : `https://${trimmedUrl}`;

      // Return the <a> tag with the original match including the space if necessary
      return match.startsWith(" ")
        ? ` <a style="${styles}" href="${fullUrl}" target="_blank" rel="nofollow">${trimmedUrl}</a>`
        : `<a style="${styles}" href="${fullUrl}" target="_blank" rel="nofollow">${trimmedUrl}</a>`;
    });

    return result;
  } catch (e) {
    console.error("Error converting URLs to links", e);
    return text;
  }
}

export function padNumber(num: number): string {
  return num < 10 ? "0" + num : num.toString();
}

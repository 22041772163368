import ChannelAccountTypes from "../../data/channel-account-types";
import MetaAccount from "../../models/meta/account";
import InstagramAccount from "../../models/meta/instagram-account";
import ApiService from "../core/api-service";
import facebookService from "./facebook-service";

class InstagramService {
  getAuthorizationUrl(state: string): Promise<string> {
    return ApiService.get<string>(
      `external-providers/instagram/authorization-url`,
      {
        params: {
          state: state,
        },
      }
    );
  }

  exchangeCodeForLongLivedToken(code: string): Promise<string> {
    return ApiService.post<string>(
      `external-providers/instagram/exchange-code`,
      {},
      {
        disableGlobalErrorReporting: true,
        params: {
          code: code,
          longLived: true,
        },
      }
    );
  }

  getLongLivedAccessToken(accessToken: string): Promise<string> {
    return ApiService.post<string>(
      `external-providers/instagram/long-lived-token`,
      {},
      {
        disableGlobalErrorReporting: true,
        params: {
          accessToken: accessToken,
        },
      }
    );
  }

  async getInstagramAccounts(
    userId: string,
    userAccessToken: string
  ): Promise<InstagramAccount[]> {
    const facebookAccounts = await facebookService.getFacebookAccounts(
      userId,
      userAccessToken
    );

    const promises = facebookAccounts
      .filter((x) => x.instagram_business_account?.id)
      .map((x) => this.getInstagramAccount(x, userAccessToken));

    return Promise.all(promises);
  }

  private async getInstagramAccount(
    facebookAccount: MetaAccount,
    userAccessToken: string
  ): Promise<InstagramAccount> {
    const instagramAccount = await ApiService.get<InstagramAccount>(
      `external-providers/instagram/account`,
      {
        params: {
          accountId: facebookAccount.instagram_business_account.id,
          userAccessToken,
        },
      }
    );

    instagramAccount.metaAccount = facebookAccount;
    instagramAccount.channelAccountType =
      instagramAccount.channelAccountTypeId ==
      ChannelAccountTypes.InstagramBusiness.key
        ? ChannelAccountTypes.InstagramBusiness
        : ChannelAccountTypes.InstagramCreator;

    return instagramAccount;
  }
}

export default new InstagramService();

export default function groupBy<T>(
  list: T[],
  key: keyof T
): Record<string, T[]> {
  return list.reduce((result, currentItem) => {
    const groupKey = String(currentItem[key]); // Get the key for grouping
    if (!result[groupKey]) {
      result[groupKey] = []; // Initialize the group if it doesn't exist
    }
    result[groupKey].push(currentItem); // Add the current item to the group
    return result;
  }, {} as Record<string, T[]>);
}

export function groupByKeySelector<T, K extends string | number>(
  list: T[],
  keySelector: (item: T) => K
): Record<K, T[]> {
  return list.reduce((result, currentItem) => {
    const groupKey = keySelector(currentItem); // Use the selector function to get the key
    if (!result[groupKey]) {
      result[groupKey] = []; // Initialize the group if it doesn't exist
    }
    result[groupKey].push(currentItem); // Add the current item to the group
    return result;
  }, {} as Record<K, T[]>);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from "react";
import Hashtag from "../../../../models/entities/hashtag";
import hashtagService from "../../../../services/api/hashtag-service";
import useCurrentSocialSet from "../../../../hooks/useCurrentSocialSet";
import { DialogTitle } from "@headlessui/react";
import Button from "../../../../components/common/Button";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../../../components/form/InputField";
import ChipField from "../../../../components/form/ChipField";
import { TrashIcon } from "@heroicons/react/24/outline";
import DeleteDialog from "../../../../components/dialogs/DeleteDialog";
import maxTagsCount from "../../../../utils/yup-validators/max-tags-count";

const schema = yup
  .object({
    topic: yup.string().required(),
    hashtags: yup.string().required().concat(maxTagsCount(30)),
  })
  .required();

export interface HashtagListUpdateFormInput {
  topic: string;
  hashtags: string;
}

export interface CreateHashtagListProps {
  onSubmitted: (hashtag: Hashtag) => void;
  onDeleted: (hashtag: Hashtag) => void;
  hashtag: Hashtag;
  onClose: () => void;
}
export default function CreateHashtagList({
  onSubmitted,
  onDeleted,
  hashtag,
  onClose,
}: CreateHashtagListProps) {
  const deleteDialogRef = useRef(null);
  const socialSet = useCurrentSocialSet();
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const isEdit = !!hashtag;

  const form = useForm<HashtagListUpdateFormInput>({
    resolver: yupResolver(schema) as any,
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      topic: hashtag?.topic,
      hashtags: hashtag?.hashtags,
    },
  });

  const submitForm = () => {
    console.log(form.formState);
    form.handleSubmit(submitHashtag)();
  };

  const submitHashtag = async () => {
    setSubmitting(true);

    try {
      const input = {
        topic: form.getValues().topic,
        hashtags: form.getValues().hashtags,
        socialSetId: socialSet.id,
      };

      const result = isEdit
        ? await hashtagService.update(hashtag.id, input)
        : await hashtagService.create(input);

      onSubmitted(result);
    } finally {
      setSubmitting(false);
    }
  };

  const onConfirmDelete = async () => {
    setDeleting(true);

    try {
      await hashtagService.delete(hashtag.id);
      onDeleted(hashtag);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <>
      <div className="flex flex-row justify-center gap-0 lg:gap-3 relative mx-auto max-h-[80dvh]">
        <div className="w-screen lg:max-w-lg relative flex-1 flex flex-col overflow-hidden bg-white lg:rounded-lg p-3 md:p-6 shadow-xl transition-all">
          <DialogTitle
            as="h3"
            className="flex flex-col justify-start mb-1 text-lg font-semibold leading-6 text-gray-900"
          >
            <div className="flex justify-between items-center">
              <div>{!isEdit ? "Create Hashtag List" : "Edit Hashtag List"}</div>
            </div>
          </DialogTitle>

          <div className="flex-1 overflow-y-auto">
            <form
              onSubmit={form.handleSubmit(submitHashtag)}
              className="flex-1"
            >
              <div className="py-6">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <InputField
                      label="List name"
                      name="topic"
                      placeholder="Enter a name for the list"
                      formReturn={form}
                    />
                  </div>

                  <div className="sm:col-span-6">
                    <ChipField
                      label="Hashtags"
                      name="hashtags"
                      prefix="#"
                      form={form}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="flex-shrink-0 gap-2 flex justify-end mt-5">
            {isEdit && (
              <Button
                variant="outline"
                color="red"
                className="w-24"
                text="Delete"
                loadingText="Deleting"
                disabled={deleting || submitting}
                loading={deleting}
                icon={<TrashIcon className="h-5 w-5 mr-2" />}
                onClick={() => deleteDialogRef.current?.openDialog()}
              />
            )}

            <Button
              variant="text"
              color="brand"
              className="w-24 ml-auto"
              text="Cancel"
              disabled={submitting || deleting}
              onClick={onClose}
            />

            <Button
              variant="solid"
              color="brand"
              loading={submitting}
              disabled={submitting || deleting}
              loadingText={isEdit ? "Saving" : "Creating"}
              text={isEdit ? "Save" : "Create"}
              onClick={submitForm}
            />
          </div>
        </div>
      </div>

      <DeleteDialog
        title="Delete Hashtag List?"
        message={`Are you sure you want to delete the hashtag list "${hashtag?.topic}"?`}
        confirmLabel="Delete"
        ref={deleteDialogRef}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}

import { useMemo } from "react";
import { FacebookPostConfig } from "../../../../../models/entities/post";
import { convertUrlsToLinks } from "../../../../../utils/text-utils";
import DOMPurify from "dompurify";
import Channel from "../../../../../models/entities/channel";

interface FacebookCommentProps {
  facebookPostConfig: FacebookPostConfig;
  channel: Channel;
}

export default function FacebookComment({
  facebookPostConfig,
  channel,
}: FacebookCommentProps) {
  const commentFormatted = useMemo(() => {
    return facebookPostConfig.firstCommentOptions?.comment
      ? convertUrlsToLinks(
          facebookPostConfig.firstCommentOptions?.comment,
          "color:#5AA7FF;font-weight:500;"
        )
      : "";
  }, [facebookPostConfig.firstCommentOptions?.comment]);

  return commentFormatted ? (
    <div className="flex flex-row gap-2 px-4 py-2 border-t">
      <div>
        <img
          referrerPolicy="no-referrer"
          className="h-7 w-7 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
          src={channel.pictureUrl}
        />
      </div>
      <div className="flex-1 flex flex-col bg-gray-100 rounded-lg p-3">
        <div className="font-medium text-xs text-gray-950">{channel.name}</div>
        <div
          className="text-xs font-normal text-gray-900 whitespace-pre-wrap overflow-hidden line-clamp-5 break-words"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(commentFormatted),
          }}
        ></div>
      </div>
    </div>
  ) : null;
}

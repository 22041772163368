import dayjs from "dayjs";
import CalendarDay from "../modules/scheduler/calendar/models/calendar-day";
import {
  CalendarEvent,
  CalendarEventSlot,
} from "../modules/scheduler/calendar/models/calendar-event";
import DayHour from "../modules/scheduler/calendar/models/day-hour";

export function generateDayHours(days: CalendarDay[]): DayHour[] {
  const hourEventCounts = new Array(24).fill(0); // To count events for each hour

  days.forEach((day) => {
    for (let hour = 0; hour <= 23; hour++) {
      const hourEvents = day.events.filter(
        (event) => +dayjs(event.time, "HH:mm A").hour() == hour
      );
      hourEventCounts[hour] = Math.max(
        hourEvents.length,
        hourEventCounts[hour]
      );
    }
  });

  const maxSlotsPerHour = hourEventCounts.map((count) => count + 1); // Calculate slots

  // Generate objects with hour labels and slots
  const dayHoursWithSlots = maxSlotsPerHour.map((slots, hour) => {
    const hourLabel = `${hour === 0 || hour === 12 ? 12 : hour % 12}${
      hour < 12 ? " AM" : " PM"
    }`;
    return { hourLabel, slots, hourIndex: hour };
  });

  return dayHoursWithSlots;
}

export function sumMaxTotalSlotsInOneDay(dayHoursWithSlots: DayHour[]): number {
  return dayHoursWithSlots.reduce(
    (acc, currentHour) => acc + currentHour.slots,
    0
  );
}

export function flattenDayEvents(days: CalendarDay[]): CalendarEvent[] {
  const events = days.reduce(
    (acc, day) => acc.concat(day.events),
    [] as CalendarEvent[]
  );
  return events.sort(
    (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
  );
}

export function decimalHoursToTimeString(decimalHours: number): string {
  // Extract hours and decimal part
  const hours = Math.floor(decimalHours);
  const decimalPart = decimalHours - hours;
  const minutes = Math.round(decimalPart * 60);

  // Create a Day.js object and set the hours and minutes
  const time = dayjs().hour(hours).minute(minutes).second(0);

  // Format the time to a 12-hour format with AM/PM
  return time.format("hh:mm A");
}

export function generateSlotsMap(
  events: CalendarEvent[],
  dayHours: DayHour[],
  timezone: string
): CalendarEventSlot[] {
  const slots: CalendarEventSlot[] = [];

  // Iterate through each day of the week
  for (let day = 1; day <= 7; day++) {
    // Track the last hourSlot processed to identify when we move to a new hour
    let currentHour = 0;
    let currentSlot = -1;

    // Filter events by daySlot, then sort by hourSlot
    const dayEvents = events
      .filter((event) => dayjs(event.datetime).tz(timezone).isoWeekday() == day)
      .sort(
        (a, b) =>
          dayjs(a.time, "HH:mm A").hour() - dayjs(b.time, "HH:mm A").hour()
      );

    while (currentHour <= 23) {
      let currentHourSlotIndex = 1;
      const dayHour = dayHours.find((x) => x.hourIndex == currentHour);
      currentSlot++;

      const hourEvents = dayEvents.filter(
        (event) => +dayjs(event.time, "HH:mm A").hour() == currentHour
      );

      hourEvents.forEach((event) => {
        slots.push({
          daySlot: day,
          event: event,
          hourSlot: currentSlot,
          slotTime: event.time,
          slotTimeDecimal: 0,
        });

        currentSlot++;
        currentHourSlotIndex++;
      });

      const slotTimeDecimal =
        currentHour + (currentHourSlotIndex - 1) / dayHour.slots;

      slots.push({
        daySlot: day,
        event: null,
        hourSlot: currentSlot,
        slotTime: decimalHoursToTimeString(slotTimeDecimal),
        slotTimeDecimal: slotTimeDecimal,
      });

      const fillInSlots = dayHour.slots - 1 - hourEvents.length;

      for (let i = 0; i < fillInSlots; i++) {
        currentSlot++;
        currentHourSlotIndex++;

        const slotTimeDecimal =
          currentHour + (currentHourSlotIndex - 1) / dayHour.slots;

        slots.push({
          daySlot: day,
          event: null,
          hourSlot: currentSlot,
          slotTime: decimalHoursToTimeString(slotTimeDecimal),
          slotTimeDecimal: slotTimeDecimal,
        });
      }

      currentHour++;

      // currentSlot += dayHour.slots - 1 - hourEvents.length;
    }
  }

  return slots;
}

export function generateWeekData(
  year: number,
  weekNumber: number,
  timezone: string
): CalendarDay[] {
  const days: CalendarDay[] = [];

  const startOfWeek = dayjs()
    .tz(timezone)
    .year(year)
    .isoWeek(weekNumber)
    .startOf("isoWeek");
  const today = dayjs().tz(timezone); // Get today's date for comparison

  for (let i = 0; i < 7; i++) {
    const day = startOfWeek.add(i, "day");
    const isToday = day.isSame(today, "day"); // Check if the day is today
    const hours = [];
    const isPast = day.add(1, "day").isBefore(today);

    for (let h = 0; h < 24; h++) {
      hours.push({
        hour: day.hour(h).format("ha"),
        events: [], // Populate with events as necessary
      });
    }

    days.push({
      date: day.format("YYYY-MM-DD"),
      dayOfWeek: day.format("ddd"),
      isCurrentMonth: true,
      isSelected: false,
      isPast: isPast,
      events: [],
      isToday: isToday,
    });
  }

  return days;
}

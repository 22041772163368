export default interface Attachment {
  id: string;
  role: string;
  createdAt: Date;
  updatedAt: Date | null;
  status: AttachmentStatus;
  type: AttachmentType;
  info: AttachmentInfo;
  favoriteAt?: Date | null;
  description?: string;
  socialSetId?: string;
  tags: string[];
  uploadedBy: {
    id: string;
    name: string;
    email: string;
    identityId?: string;
    picture?: string;
  };
  thumbnails: {
    [key: string]: AttachmentInfo;
  };
}

export interface AttachmentInfo {
  fileName: string;
  fileSize: number;
  width: number;
  height: number;
  duration: number;
  resolution: number;
  aspectRatio: string;
  mimeType: string;
  s3Bucket: string;
  s3Key: string;
  url: string;
}

export enum AttachmentStatus {
  Pending,
  Completed,
  Failed,
}

export enum AttachmentType {
  Photo = "Photo",
  Video = "Video",
  Document = "Document",
}

import { Tab, TabGroup, TabList } from "@headlessui/react";
import clsx from "clsx";
import { PostInstance } from "../models/post-instance";
import ErrorsAlertContainer from "./ErrorsAlertContainer";
import { findChannelConfig } from "../../../../data/channels-config";

interface ChannelsSidenavProps {
  postInstances: PostInstance[];
  selected?: PostInstance;
  hideValidation: boolean;
  onSelected: (postInstance: PostInstance) => void;
}

export default function ChannelsSidenav({
  postInstances,
  selected,
  hideValidation,
  onSelected,
}: ChannelsSidenavProps) {
  const select = (postInstance: PostInstance) => {
    onSelected(postInstance);
  };

  return (
    <TabGroup vertical>
      <TabList className="flex flex-col gap-0.5 pr-0.5 py-2">
        {postInstances.map((postInstance) => (
          <Tab
            key={postInstance.channel.id}
            className="outline-0 focus:outline-0 relative"
          >
            <ErrorsAlertContainer
              showTooltip={false}
              disabled={hideValidation}
              validation={postInstance.validation}
              size={2.5}
            >
              {(icon) => {
                return (
                  <>
                    {selected?.channel?.id == postInstance.channel.id && (
                      <div className="absolute top-0 -right-3 z-10 left-0 bottom-0 bg-white rounded-l-xl">
                        <div className="relative rounded-l-xl px-2 md:px-3 py-3 text-sm font-medium">
                          <img
                            referrerPolicy="no-referrer"
                            className="h-6 w-6 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                            src={
                              findChannelConfig(postInstance.channel.type)
                                .imageUrl
                            }
                          />
                          <div className="absolute -top-px -left-2">{icon}</div>
                        </div>
                      </div>
                    )}
                    <div
                      onClick={() => select(postInstance)}
                      className={clsx(
                        "relative rounded-l-xl px-2 md:px-3 py-3 text-sm font-medium shadow tab-shadow transition-all",
                        selected?.channel.id == postInstance.channel.id
                          ? "border-primary-600"
                          : "text-gray-500"
                      )}
                    >
                      <img
                        referrerPolicy="no-referrer"
                        className="h-6 w-6 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                        src={
                          findChannelConfig(postInstance.channel.type).imageUrl
                        }
                      />
                      <div className="absolute -top-px -left-2">{icon}</div>
                    </div>
                  </>
                );
              }}
            </ErrorsAlertContainer>
            <div className="relative"></div>
          </Tab>
        ))}
      </TabList>
    </TabGroup>
  );
}

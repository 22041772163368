/* eslint-disable @typescript-eslint/no-explicit-any */
import ChannelConfig from "../models/channel-config";
import Button from "../../../../components/common/Button";
import {
  ArrowsRightLeftIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import RedirectChannelConnector from "./RedirectChannelConnector";

export interface RefreshChannelErrorDialogProps {
  channelConfig: ChannelConfig;
  socialSetId: string;
  channelId?: string;
  externalChannelId?: string;
  onCancel: () => void;
}

export default function RefreshChannelErrorDialog({
  channelConfig,
  socialSetId,
  channelId,
  externalChannelId,
  onCancel,
}: RefreshChannelErrorDialogProps) {
  return (
    <>
      <div className="fixed inset-0 flex flex-col w-screen items-center justify-center p-4 z-50">
        <div className="flex justify-center gap-6">
          <img
            src="/logo/viraly-logo-square-dense.png"
            className="h-12 w-auto"
          />
          <ArrowsRightLeftIcon className="w-5" />
          <img src={channelConfig.imageUrl} />
        </div>

        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div className="sm:flex sm:flex-1 sm:items-start flex">
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex-1">
              <h3 className="flex items-center gap-2 text-base font-semibold leading-7 text-gray-900">
                <ExclamationTriangleIcon className="w-8 text-red-500" />
                Refreshing your {channelConfig.name} channel connection failed
              </h3>
              <div className="mt-4 mb-2">
                <p className="text-sm text-gray-600">
                  Make sure you are selecting the same channel you are trying to
                  refresh. Please try again and if the issue persists, please
                  contact support.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <RedirectChannelConnector
              showInfoPopup={false}
              channelConfig={channelConfig}
              socialSetId={socialSetId}
              channelId={channelId}
              externalChannelId={externalChannelId}
            >
              {({ isLoading, onClick }) => {
                return (
                  <Button
                    variant="solid"
                    color="brand"
                    text="Try again"
                    loadingText="Redirecting"
                    loading={isLoading}
                    onClick={onClick}
                  />
                );
              }}
            </RedirectChannelConnector>
            <Button
              variant="outline"
              color="slate"
              text="Go back"
              onClick={onCancel}
              className="mr-2"
            />
          </div>
        </div>
      </div>
    </>
  );
}

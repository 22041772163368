import dayjs from "dayjs";
import { useState, useRef, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../../components/common/Button";
import User from "../../../../../models/entities/user";
import alertService from "../../../../../services/alert/alert-service";
import userService from "../../../../../services/api/user-service";
import DeleteDialog from "../../../../../components/dialogs/DeleteDialog";
import { MenuItems, MenuItem, Menu, MenuButton } from "@headlessui/react";
import clsx from "clsx";
import { ChevronDownIcon, NoSymbolIcon } from "@heroicons/react/20/solid";
import {
  PaperAirplaneIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Spinner from "../../../../../components/common/Spinner";
import ConfirmDialog from "../../../../../components/dialogs/ConfirmDialog";

interface ProfileTabProps {
  user: User;
}

export default function ProfileTab({ user }: ProfileTabProps) {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const navigate = useNavigate();
  const isOwner = useMemo(() => user.role == "Owner", [user.role]);

  const deleteDialogRef = useRef(null);

  const onDelete = async () => {
    deleteDialogRef.current.openDialog();
  };

  const deleteUserConfirmed = async () => {
    setIsProcessing(true);

    try {
      await userService.delete(user.id.toString());

      alertService.success("User successfully deleted");
      navigate(-1);
    } finally {
      setIsProcessing(false);
    }
  };

  const deactivateDialogRef = useRef(null);

  const onDeactivate = async () => {
    deactivateDialogRef.current.openDialog();
  };

  const deactivateUserConfirmed = async () => {
    setIsProcessing(true);

    try {
      await userService.deactivate(user.id.toString());

      alertService.success("User successfully deactivated");
      navigate(-1);
    } finally {
      setIsProcessing(false);
    }
  };

  const activateDialogRef = useRef(null);

  const onActivate = async () => {
    activateDialogRef.current.openDialog();
  };

  const activateUserConfirmed = async () => {
    setIsProcessing(true);

    try {
      await userService.activate(user.id.toString());

      alertService.success("User successfully activated");
      navigate(-1);
    } finally {
      setIsProcessing(false);
    }
  };

  const onResendInvite = async () => {
    setIsProcessing(true);

    try {
      await userService.reinvite(user.id.toString());

      alertService.success("User invitation e-mail was sent");
      navigate(-1);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <div className="relative">
        <dt className="flex justify-between">
          <div className="text-base font-semibold leading-7 text-gray-900">
            Personal Details
          </div>

          <div className="flex-shrink-0 gap-2 flex">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton as="div">
                  <Button
                    type="button"
                    variant="outline"
                    color="slate"
                    disabled={isProcessing}
                    text=""
                  >
                    {isProcessing && (
                      <>
                        <Spinner size="4" />
                      </>
                    )}
                    {!isProcessing && (
                      <>
                        <span>User Actions</span>
                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" />
                      </>
                    )}
                  </Button>
                </MenuButton>
              </div>

              <MenuItems
                transition
                anchor="bottom end"
                className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
              >
                <div className="py-1">
                  <MenuItem>
                    {({ focus }) => (
                      <Link
                        to="edit"
                        className={clsx(
                          focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm"
                        )}
                      >
                        <PencilIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                        Edit Profile
                      </Link>
                    )}
                  </MenuItem>
                  {user.status == "Invited" && (
                    <MenuItem>
                      {({ focus }) => (
                        <button
                          onClick={onResendInvite}
                          type="button"
                          className={clsx(
                            focus
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm w-full"
                          )}
                        >
                          <PaperAirplaneIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                          Resend Invite
                        </button>
                      )}
                    </MenuItem>
                  )}
                  {user.status == "Active" && (
                    <MenuItem>
                      {({ focus }) => (
                        <button
                          onClick={onDeactivate}
                          type="button"
                          disabled={isOwner}
                          data-tooltip-id="tooltip-placeholder"
                          data-tooltip-content={
                            isOwner ? "Can't deactivate owner" : null
                          }
                          className={clsx(
                            isOwner ? "bg-white cursor-not-allowed" : null,
                            focus
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm w-full"
                          )}
                        >
                          <NoSymbolIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                          Deactivate
                        </button>
                      )}
                    </MenuItem>
                  )}
                  {user.status == "Inactive" && (
                    <MenuItem>
                      {({ focus }) => (
                        <button
                          onClick={onActivate}
                          type="button"
                          className={clsx(
                            focus
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm w-full"
                          )}
                        >
                          <NoSymbolIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                          Activate
                        </button>
                      )}
                    </MenuItem>
                  )}
                </div>
                <div className="py-1">
                  <MenuItem>
                    {({ focus }) => (
                      <button
                        onClick={onDelete}
                        disabled={isOwner}
                        data-tooltip-id="tooltip-placeholder"
                        data-tooltip-content={
                          isOwner ? "Can't delete owner" : null
                        }
                        type="button"
                        className={clsx(
                          isOwner ? "bg-white cursor-not-allowed" : null,
                          focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm w-full"
                        )}
                      >
                        <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                        Delete
                      </button>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
        </dt>
        <dl className="grid grid-cols-1 sm:grid-cols-2">
          <div className="px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Full name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              {user.name}
            </dd>
          </div>
          <div className="px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Email address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              {user.email}
            </dd>
          </div>
          <div className="px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Role
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              {user.role}
            </dd>
          </div>
          <div className="px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Created
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              {dayjs(user.createdAt).format("D MMM YYYY, hh:mm A")}
            </dd>
          </div>
        </dl>
      </div>

      <DeleteDialog
        title="Delete user"
        ref={deleteDialogRef}
        onConfirm={deleteUserConfirmed}
      >
        <div>
          <div>
            <div className="text-sm mb-4">
              The user will be permanently deleted from this account.
            </div>
            <ul className="space-y-0"></ul>
          </div>
        </div>
      </DeleteDialog>

      <ConfirmDialog
        title="Deactivate user"
        message="The user will lose access to this account."
        ref={deactivateDialogRef}
        onConfirm={deactivateUserConfirmed}
      />

      <ConfirmDialog
        title="Activate user"
        message="The user will gain access to this account according to the assigned permissions."
        ref={activateDialogRef}
        onConfirm={activateUserConfirmed}
      />
    </>
  );
}

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  EllipsisHorizontalIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import ViewRange from "../../models/view-range";
import { ViewType } from "../../models/view-type";
import Spinner from "../../../../../components/common/Spinner";
import Post from "../../../../../models/entities/post";
import TimezoneLabel from "../../../../../components/common/TimezoneLabel";
import FilterChannelsSelector from "../../../posts/components/FilterChannelsSelector";
import useCurrentSocialSet from "../../../../../hooks/useCurrentSocialSet";
import Channel from "../../../../../models/entities/channel";

export interface CalendarHeaderProps {
  currentView: string;
  viewRange: ViewRange;
  loading: boolean;
  showChannelFilters: boolean;
  posts: Post[];
  selectedChannels: Channel[];
  onChannelsFiltered: (channels: Channel[]) => void;
  onNewPost: (date?: Date) => void;
  onPrevious: () => void;
  onToday: () => void;
  onNext: () => void;
  onSetView: (view: ViewType) => void;
}

const views: { id: ViewType; label: string }[] = [
  { id: "week", label: "Week view" },
  { id: "month", label: "Month view" },
];

export default function CalendarHeader({
  currentView,
  viewRange,
  loading,
  showChannelFilters,
  posts,
  selectedChannels,
  onChannelsFiltered,
  onNewPost,
  onNext,
  onPrevious,
  onToday,
  onSetView,
}: CalendarHeaderProps) {
  const currentSocialSet = useCurrentSocialSet();

  const view = views.find((x) => x?.id == currentView) ?? views[1];
  return (
    <>
      <header className="flex flex-none items-center justify-between sticky top-0 z-10 shadow ring-1 ring-black ring-opacity-5 px-6 py-4 bg-white rounded-t-md">
        <div className="flex-1 flex flex-col items-start sm:flex-row sm:items-center truncate whitespace-nowrap">
          <h1 className="text-base font-semibold leading-7 text-gray-900">
            <time>
              {currentView == "month"
                ? `${viewRange.monthly.headerLabel}`
                : `${viewRange.weekly.headerLabel}`}
            </time>
          </h1>
          <div className="sm:ml-2 text-sm leading-7 text-slate-500">
            {!loading ? (
              <>
                <span className="hidden sm:block ">
                  ({posts?.length} posts)
                </span>
                <div className="sm:hidden">
                  <TimezoneLabel showLocalTime={false} />
                </div>
              </>
            ) : (
              <Spinner size="5" />
            )}
          </div>
        </div>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white md:items-stretch shadow-sm">
            <button
              onClick={onPrevious}
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-200 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
            >
              <span className="sr-only">
                Previous {view.label.toLowerCase()}
              </span>
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <button
              onClick={onToday}
              type="button"
              className="hidden border-y border-gray-200 px-3.5 text-sm font-normal text-gray-900 hover:bg-gray-50 focus:relative md:block"
            >
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              onClick={onNext}
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-200 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next {view.label.toLowerCase()}</span>
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="hidden md:ml-4 md:flex gap-2 md:items-center">
            {/* View Picker */}
            <div>
              <Menu as="div" className="relative">
                {({ open }) => (
                  <>
                    <MenuButton
                      type="button"
                      className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                    >
                      <span>{view.label}</span>
                      {open ? (
                        <ChevronUpIcon className="-mr-1 h-5 w-5 text-gray-400" />
                      ) : (
                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" />
                      )}
                    </MenuButton>

                    <MenuItems
                      transition
                      anchor="bottom start"
                      className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                    >
                      <div className="py-1">
                        {views.map((view) => (
                          <MenuItem key={view.id}>
                            {() => (
                              <a
                                onClick={() => onSetView(view.id)}
                                className={clsx(
                                  view.id == currentView
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                              >
                                {view.label}
                              </a>
                            )}
                          </MenuItem>
                        ))}
                      </div>
                    </MenuItems>
                  </>
                )}
              </Menu>
            </div>

            {/* Channels Filter */}
            {showChannelFilters && (
              <div>
                <FilterChannelsSelector
                  posts={posts}
                  channels={currentSocialSet?.channels ?? []}
                  selected={selectedChannels}
                  onSelected={onChannelsFiltered}
                />
              </div>
            )}

            {/* <Button variant="solid" color="brand" text="Schedule new post" onClick={() => onNewPost(null)} /> */}
          </div>

          {/* Timezone Picker */}
          <div className="ml-4 hidden sm:block">
            <TimezoneLabel />
          </div>

          <Menu as="div" className="relative ml-6 md:hidden">
            <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
              <EllipsisHorizontalIcon className="h-5 w-5" />
            </MenuButton>

            <MenuItems
              transition
              anchor="bottom end"
              className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
            >
              <div className="py-1">
                <MenuItem>
                  {({ focus }) => (
                    <a
                      onClick={() => onNewPost(null)}
                      className={clsx(
                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Schedule post
                    </a>
                  )}
                </MenuItem>
              </div>
              <div className="py-1">
                <MenuItem>
                  {({ focus }) => (
                    <a
                      onClick={onToday}
                      className={clsx(
                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Go to today
                    </a>
                  )}
                </MenuItem>
              </div>
              <div className="py-1">
                <MenuItem>
                  {({ focus }) => (
                    <a
                      onClick={() => onSetView("week")}
                      className={clsx(
                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Week view
                    </a>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }) => (
                    <a
                      onClick={() => onSetView("month")}
                      className={clsx(
                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Month view
                    </a>
                  )}
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        </div>
      </header>
    </>
  );
}

import { InformationCircleIcon } from "@heroicons/react/20/solid";

export interface DiscloseInfoBoxProps {
  text: string;
}

export default function DiscloseInfoBox({ text }: DiscloseInfoBoxProps) {
  return (
    <div className="rounded-md bg-blue-50 p-4 mt-2">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-blue-700 text-xs">{text}</p>
        </div>
      </div>
    </div>
  );
}

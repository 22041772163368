export default function ThreadsInfoDialog() {
  return (
    <div className="text-sm text-gray-800 font-light">
      <p>
        You can connect multiple <span className="font-medium">Threads</span>{" "}
        profiles to Viraly.
      </p>

      <p className="mt-5">
        When connecting Threads profiles, Viraly will try to connect to the
        profile{" "}
        <span className="font-medium">
          currently logged in on your browser.
        </span>{" "}
        If you are not currently logged in, you will be prompted to log in to
        the profile you want to connect.
      </p>
    </div>
  );
}

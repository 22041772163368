import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import socialSetsService from "../../../services/api/social-sets-service";
import Breadcrumbs from "../../../components/common/BreadCrumbs";
import EditForm from "../edit/components/EditForm";
import SocialSetUpdateFormInput from "../../../models/form-inputs/social-set-update-input";
import queryNames from "../../../queries/query-names";
import { refetchQuery } from "../../../queries/query-utils";
import { useQuery } from "@tanstack/react-query";
import { socialSetColors } from "../../../utils/social-set-utils";
import SocialSet from "../../../models/entities/social-set";
import actionTypes from "../../../state/global-state/action-types";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import { delay } from "../../../utils/shared-utils";
import dayjs from "dayjs";
import timezoneService from "../../../services/api/timezone-service";
import { normalizeTimezone } from "../../../utils/date-time-utils";

export default function CreateSocialSet() {
  const navigate = useNavigate();
  const { dispatch } = useContext(GlobalStateContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data } = useQuery<SocialSet[]>({
    queryKey: [queryNames.socialSets],
    queryFn: socialSetsService.list,
  });

  const { data: timezones } = useQuery({
    queryKey: [queryNames.timezones],
    queryFn: timezoneService.list,
    refetchOnMount: false,
    staleTime: Infinity,
  });

  const onSubmit = async (input: SocialSetUpdateFormInput): Promise<void> => {
    setIsSubmitting(true);

    try {
      const socialSet = await socialSetsService.create(input);
      await refetchQuery([queryNames.socialSets]);

      delay(100);

      dispatch({
        type: actionTypes.SET_CURRENT_SOCIAL_SET,
        payload: socialSet,
      });

      navigate(`/social-sets/${socialSet.id}`, { replace: true });
    } finally {
      setIsSubmitting(false);
    }
  };

  const freeColors = socialSetColors
    .map((x) => x.hex)
    .filter((x) => !data.map((y) => y.colorHex).includes(x));

  const input: SocialSetUpdateFormInput = {
    name: null,
    code: null,
    timezone: timezones?.find(
      (x) => x.id == normalizeTimezone(dayjs.tz.guess())
    )?.id,
    colorHex: freeColors.length ? freeColors[0] : socialSetColors[0].hex,
  };

  return timezones == null ? null : (
    <>
      <div className="flex flex-col gap-5 mx-auto">
        <Breadcrumbs
          pages={[
            {
              name: "Social Sets",
              to: "/social-sets",
            },
            {
              name: `Create`,
              to: `/social-sets/create`,
            },
          ]}
        />

        <div className="flex flex-col bg-white shadow sm:rounded-lg min-h-[465px] px-4 py-5 sm:px-6">
          <EditForm
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            input={input}
          />
        </div>
      </div>
    </>
  );
}

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import UnauthenticatedLayout from "../../../layouts/UnauthenticatedLayout/UnauthenticatedLayout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { authenticatorFormFields, authenticatorSignupAttributes, getComponents } from "../signup/authenticator-config";

export default function Signin() {
  const navigate = useNavigate();
  const { authStatus, route, toSignUp, toSignIn } = useAuthenticator((context) => [
    context.authStatus,
    context.route,
    context.toSignIn,
  ]);

  useEffect(() => {
    if (authStatus == "authenticated") {
      navigate("/");
    }
  }, [authStatus, navigate, route, toSignUp]);

  const components = getComponents(toSignIn, toSignUp);

  return (
    <UnauthenticatedLayout>
      <Authenticator
        initialState="signIn"
        signUpAttributes={authenticatorSignupAttributes}
        formFields={authenticatorFormFields}
        components={components}
      >
        {() => {
          window.location.href = "/scheduler";
          return <div></div>;
        }}
      </Authenticator>
    </UnauthenticatedLayout>
  );
}

import SocialSet from "../models/entities/social-set";

export const socialSetColors: { name: string; hex: string }[] = [
  { name: "fill-orange-600", hex: "#ea580c" },
  { name: "fill-emerald-600", hex: "#059669" },
  { name: "fill-pink-600", hex: "#db2777" },
  { name: "fill-indigo-600", hex: "#4f46e5" },
  { name: "fill-teal-600", hex: "#0d9488" },
  { name: "fill-amber-600", hex: "#d97706" },
  { name: "fill-yellow-600", hex: "#ca8a04" },
  { name: "fill-purple-600", hex: "#9333ea" },
  { name: "fill-rose-600", hex: "#e11d48" },
  { name: "fill-blue-600", hex: "#2563eb" },
  { name: "fill-red-600", hex: "#dc2626" },
  { name: "fill-lime-600", hex: "#65a30d" },
  { name: "fill-violet-600", hex: "#7c3aed" },
];

export function getDescription(socialSet: SocialSet): string {
  return `${socialSet.channels.length} ${socialSet.channels.length == 1 ? "channel" : "channels"}`;
}

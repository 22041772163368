import ComingSoon from "../../../../components/common/ComingSoon";
import TimezoneLabel from "../../../../components/common/TimezoneLabel";

export default function ListApprovals() {
  const description = `Post approvals is a new feature which will allow users on the
                "Team" plan to collaborate by having team members approve the
                Posts before Publishing.`;

  return (
    <>
      <div className="flex flex-col overflow-hidden rounded-md shadow overflow-y-auto bg-white h-full">
        <div className="flex-1 h-full lg:flex lg:flex-col">
          <div className="flex flex-col sm:flex-row gap-2 bg-white border-b sticky top-0 z-20 py-4 px-6">
            <div className="flex flex-col flex-1">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Approval Posts
              </h2>
              <p className="mt-1 text-sm text-gray-500 leading-4 max-w-2xl">
                Posts which require approval before being scheduled for
                publishing.
              </p>
            </div>
            <TimezoneLabel />
          </div>

          <div className="flex-1 flex items-center justify-center px-8 py-6 h-full">
            <ComingSoon description={description} />
          </div>
        </div>
      </div>
    </>
  );
}

import clsx from "clsx";

export interface UserAvatarProps {
  picture?: string | null;
  size: "xs" | "sm" | "md" | "lg" | "xl";
}

export default function UserAvatar({ picture, size }: UserAvatarProps) {
  return (
    <>
      <div
        className={clsx(
          "flex-shrink-0",
          size === "xs" && "h-6 w-6",
          size === "sm" && "h-8 w-8",
          size === "md" && "h-11 w-11",
          size === "lg" && "h-16 w-16",
          size === "xl" && "h-20 w-20"
        )}
      >
        {picture && (
          <img
            className={clsx(
              "rounded-full shadow",
              size === "xs" && "h-6 w-6",
              size === "sm" && "h-8 w-8",
              size === "md" && "h-11 w-11",
              size === "lg" && "h-16 w-16",
              size === "xl" && "h-20 w-20"
            )}
            referrerPolicy="no-referrer"
            src={picture}
            alt="User picture"
          />
        )}
        {!picture && (
          <span
            className={clsx(
              "inline-block overflow-hidden rounded-full bg-slate-200 shadow-sm",
              size === "xs" && "h-6 w-6",
              size === "sm" && "h-8 w-8",
              size === "md" && "h-11 w-11",
              size === "lg" && "h-16 w-16",
              size === "xl" && "h-20 w-20"
            )}
          >
            <svg
              className="h-full w-full text-slate-400"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        )}
      </div>
    </>
  );
}

import Hashtag from "../../../../models/entities/hashtag";
import HashtagCard from "./HashtagCard";

export interface HashtagListProps {
  hashtags: Hashtag[];
  loading: boolean;
  selectedHashtag?: Hashtag;
  isSelectionMode: boolean;
  onEdit: (hashtag: Hashtag) => void;
}
export default function HashtagList({
  hashtags,
  loading,
  selectedHashtag,
  isSelectionMode,
  onEdit,
}: HashtagListProps) {
  return (
    <>
      {hashtags.length == 0 && !loading && (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="text-sm text-center leading-6 text-gray-600">
            <span className="font-medium text-base">No hashtags found.</span>{" "}
            <br /> Click on "Generate" to create a new one.
          </div>
        </div>
      )}
      {hashtags.length > 0 && (
        <div className="grid grid-cols-6 gap-6">
          {hashtags.map((hashtag) => (
            <div key={hashtag.id} className="col-span-6 md:col-span-3">
              <div className="relative">
                {isSelectionMode && (
                  <input
                    type="checkbox"
                    readOnly={true}
                    checked={selectedHashtag?.id == hashtag.id}
                    style={{
                      boxShadow:
                        "rgb(255, 255, 255) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
                    }}
                    className="absolute top-1.5 right-1.5 border-gray-300 rounded h-5 w-5 text-primary-600 border focus:ring-transparent cursor-pointer"
                  />
                )}

                <HashtagCard
                  hashtag={hashtag}
                  selected={selectedHashtag?.id == hashtag.id}
                  onEdit={onEdit}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

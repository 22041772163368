import UpdateChannelRequest from "../../models/api-requests/update-channel-request";
import GetChannelStatsResponse from "../../models/api-responses/get-channel-stats";
import { ChannelsAvailabilityResponse } from "../../models/api-responses/get-channels-availability";
import Channel from "../../models/entities/channel";
import { ChannelType } from "../../models/entities/post";
import ApiService from "../core/api-service";

class ChannelService {
  list(): Promise<Channel[]> {
    return ApiService.get<Channel[]>(`channels`);
  }

  get(id: string): Promise<Channel> {
    return ApiService.get<Channel>(`channels/${id}`);
  }

  getAvailabilities(
    channelType: ChannelType,
    externalIds: string[]
  ): Promise<ChannelsAvailabilityResponse> {
    return ApiService.post<ChannelsAvailabilityResponse>(
      `channels/availabilities`,
      {
        channelType,
        externalIds,
      }
    );
  }

  create(model: UpdateChannelRequest): Promise<Channel> {
    return ApiService.post<Channel>(`channels`, model);
  }

  update(id: string, model: UpdateChannelRequest): Promise<Channel> {
    return ApiService.put<Channel>(`channels/${id}`, model);
  }

  delete(id: string): Promise<void> {
    return ApiService.delete<void>(`channels/${id}`);
  }

  getStats(id: string): Promise<GetChannelStatsResponse> {
    return ApiService.get<GetChannelStatsResponse>(`channels/${id}/stats`);
  }
}

export default new ChannelService();

import { GenerateCaptionCommand } from "../../models/api-requests/generate-post-caption-request";
import { ChannelType } from "../../models/entities/post";
import ApiService from "../core/api-service";

class GenerativeAiService {
  generatePostCaption(
    command: GenerateCaptionCommand,
    tone: string,
    content?: string,
    chanelType?: ChannelType
  ): Promise<string> {
    return ApiService.post<string>(`generative-ai/generate-post-caption`, {
      command,
      tone,
      content,
      chanelType,
    });
  }

  generateHashtags(content: string): Promise<string> {
    return ApiService.post<string>(`generative-ai/generate-hashtags`, {
      content,
    });
  }
}

export default new GenerativeAiService();

import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useContext, useState } from "react";
import stripeService from "../../../services/api/stripe-service";
import Spinner from "../../../components/common/Spinner";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import dayjs from "dayjs";

export default function PastDueBanner() {
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);
  const {
    state: {
      currentTenant: { subscription },
    },
  } = useContext(GlobalStateContext);

  const openBillingPortal = async () => {
    setIsLoadingPortal(true);

    try {
      const returnUrl = `${window.location.origin}/settings/billing`;
      const customerPortalSession = await stripeService.getCustomerPortal(
        returnUrl
      );

      window.location.href = customerPortalSession.url;
    } catch {
      setIsLoadingPortal(false);
    }
  };

  return (
    <div className="flex flex-col sm:flex-row items-end sm:items-center justify-between gap-4 w-full">
      <div className="flex gap-2 items-center ">
        <div className="w-6 h-6">
          <ExclamationTriangleIcon className="w-6 h-6 text-red-200" />
        </div>

        <p className="text-sm text-red-200">
          We were unable to renew your subscription. Please update your payment
          method before{" "}
          {dayjs(subscription.currentPeriodStart)
            .add(15, "days")
            .format("MMM DD, YYYY")}{" "}
          to avoid any service interruptions.
        </p>
      </div>
      <button
        onClick={openBillingPortal}
        className="group inline-flex items-center justify-center rounded-md py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white hover:bg-white-50 active:bg-white-200 active:text-slate-600 focus-visible:outline-white w-40 text-red-600"
      >
        {!isLoadingPortal && (
          <>
            <div className="flex items-center gap-2">Fix issue</div>
          </>
        )}
        {isLoadingPortal && (
          <>
            <div className="flex items-center gap-2">
              Redirecting
              <Spinner size="5" />
            </div>
          </>
        )}
      </button>
    </div>
  );
}

import clsx from "clsx";
import GetPostQueueCountersResponse from "../../../../models/api-responses/get-single-queue-counters";

interface QueueCounterProps {
  counterKey: string;
  counters: GetPostQueueCountersResponse;
}

export default function QueueCounter({
  counterKey,
  counters,
}: QueueCounterProps) {
  return (
    <span
      className={clsx(
        "inline-flex items-center rounded-full max-h-6 px-1.5 py-0.5 text-xs font-medium",
        counterKey === "queued" && "bg-indigo-100 text-indigo-700",
        counterKey === "approvals" && "bg-purple-100 text-purple-700",
        counterKey === "drafts" && "bg-orange-100 text-orange-700",
        counterKey === "published" && "bg-emerald-100 text-emerald-700"
      )}
    >
      {counters?.[counterKey] ?? "0"}
    </span>
  );
}

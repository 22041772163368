import { ReactNode } from "react";

interface NoPostsProps {
  title?: string;
  description?: string;
  icon?: ReactNode;
  button?: ReactNode;
}
export default function NoPosts({
  title,
  description,
  icon,
  button,
}: NoPostsProps) {
  return (
    <>
      <div className="flex flex-col items-center gap-3 text-center mt-28">
        {icon && <div>{icon}</div>}
        <div className="text-gray-800 text-md font-medium">
          {title ?? "No posts were found."}
        </div>

        {description && (
          <div className="text-gray-500 text-sm">{description}</div>
        )}

        {button && <div>{button}</div>}
      </div>
    </>
  );
}

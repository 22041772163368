/* eslint-disable @typescript-eslint/no-explicit-any */
import { HeartIcon, PencilIcon, XMarkIcon } from "@heroicons/react/20/solid";
import AttachmentSlot from "../../create-post/models/attachment-slot";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import dayjs from "dayjs";
import Button from "../../../../components/common/Button";
import DeleteDialog from "../../../../components/dialogs/DeleteDialog";
import mediaLibraryService from "../../../../services/api/media-library-service";
import UserAvatar from "../../../../components/common/UserAvatar";
import { formatBytes } from "../../../../utils/file-utils";
import axios from "axios";
import { saveAs } from "file-saver";
import Spinner from "../../../../components/common/Spinner";
import clsx from "clsx";

export interface ViewAttachmentProps {
  attachmentSlot: AttachmentSlot;
  sidebarOpen: boolean;
  closeSidebar: () => void;
  onSlotDeleted: (slot: AttachmentSlot) => void;
  onAttachmentUpdated: (slot: AttachmentSlot) => void;
}

export default function ViewAttachment({
  attachmentSlot,
  sidebarOpen,
  closeSidebar,
  onSlotDeleted,
  onAttachmentUpdated,
}: ViewAttachmentProps) {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isEditingDescr, setIsEditingDescr] = useState<boolean>(false);
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const deleteDialogRef = useRef(null);

  const onDownload = async () => {
    setIsDownloading(true);
    setDownloadProgress(0);

    try {
      const response = await axios({
        url: attachmentSlot.attachment.info.url,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setDownloadProgress(percentCompleted);
        },
      });

      saveAs(response.data, attachmentSlot.preview.fileName);
    } finally {
      setIsDownloading(false);
    }
  };

  const onDelete = async () => {
    deleteDialogRef.current?.openDialog();
  };

  const onConfirmDelete = async () => {
    setIsDeleting(true);

    try {
      if (attachmentSlot.attachment) {
        await mediaLibraryService.deleteAttachment(
          attachmentSlot.attachment.id
        );
      }

      onSlotDeleted(attachmentSlot);
    } finally {
      setIsDeleting(false);
    }
  };

  const toggleFavorite = async () => {
    const attachmentId = attachmentSlot.attachment?.id;

    if (attachmentId) {
      const shouldAddToFavorites = !attachmentSlot.attachment.favoriteAt;

      attachmentSlot.attachment.favoriteAt = shouldAddToFavorites
        ? new Date()
        : null;

      onAttachmentUpdated(attachmentSlot);

      shouldAddToFavorites
        ? await mediaLibraryService.addToFavorites(attachmentId)
        : await mediaLibraryService.removeFromFavorites(attachmentId);
    }
  };

  const saveDescription = async () => {
    setIsEditingDescr(false);

    const description = (document.getElementById("media-description") as any)
      ?.value;

    attachmentSlot.attachment.description = description;
    onAttachmentUpdated(attachmentSlot);

    await mediaLibraryService.updateAttachment(attachmentSlot.attachment.id, {
      description,
      name: attachmentSlot.attachment.info.fileName,
      tags: attachmentSlot.attachment.tags,
    });
  };

  return (
    <>
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeSidebar}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-40" />
          </TransitionChild>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full sm:my-8 sm:max-w-lg z-50">
                  {attachmentSlot && (
                    <>
                      <div className="bg-white p-8">
                        <div className="space-y-6">
                          <div>
                            <div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg">
                              {attachmentSlot.isPhoto && (
                                <img
                                  src={attachmentSlot.preview.url}
                                  className="object-cover"
                                />
                              )}
                              {attachmentSlot.isVideo && (
                                <video
                                  controls={true}
                                  src={attachmentSlot.preview.url}
                                  className="object-cover"
                                />
                              )}
                            </div>
                            <div className="mt-4 flex items-start justify-between">
                              <div className="truncate">
                                <h2 className="text-lg font-medium text-gray-900 truncate">
                                  <span className="sr-only">Details for </span>
                                  {attachmentSlot.preview.fileName}
                                </h2>
                                <p className="text-sm font-medium text-gray-500">
                                  {formatBytes(attachmentSlot.preview.fileSize)}
                                </p>
                              </div>

                              <button
                                type="button"
                                data-tooltip-id="tooltip-placeholder"
                                onClick={toggleFavorite}
                                data-tooltip-content={
                                  attachmentSlot.attachment.favoriteAt
                                    ? "Remove from favorites"
                                    : "Add to favorites"
                                }
                                className={clsx(
                                  attachmentSlot.attachment.favoriteAt
                                    ? "text-primary-600 hover:text-primary-600"
                                    : "text-gray-400 hover:text-gray-500",
                                  "flex h-8 w-8 items-center justify-center rounded-full bg-white hover:bg-gray-100 focus:outline-none"
                                )}
                              >
                                <HeartIcon
                                  aria-hidden="true"
                                  className="h-6 w-6"
                                />
                              </button>
                            </div>
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-900">
                              Information
                            </h3>
                            <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                              {attachmentSlot.attachment?.uploadedBy && (
                                <div className="flex justify-between py-2.5 text-sm font-medium">
                                  <dt className="text-gray-500">Uploaded by</dt>
                                  <dd className="flex gap-2 items-center whitespace-nowrap text-gray-900">
                                    <UserAvatar
                                      picture={
                                        attachmentSlot.attachment.uploadedBy
                                          .picture
                                      }
                                      size="xs"
                                    />

                                    {attachmentSlot.attachment.uploadedBy.name}
                                  </dd>
                                </div>
                              )}

                              {attachmentSlot.attachment?.createdAt && (
                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">Upload date</dt>
                                  <dd className="whitespace-nowrap text-gray-900">
                                    {dayjs(
                                      attachmentSlot.attachment.createdAt
                                    ).format("DD MMM YYYY, hh:mm A")}
                                  </dd>
                                </div>
                              )}

                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Dimensions</dt>
                                <dd className="whitespace-nowrap text-gray-900">
                                  {attachmentSlot.preview.width} x{" "}
                                  {attachmentSlot.preview.height}
                                </dd>
                              </div>
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">File type</dt>
                                <dd className="whitespace-nowrap text-gray-900">
                                  {attachmentSlot.preview.mimeType}
                                </dd>
                              </div>
                              {attachmentSlot.isVideo && (
                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">Duration</dt>
                                  <dd className="whitespace-nowrap text-gray-900">
                                    {attachmentSlot.preview.duration}
                                  </dd>
                                </div>
                              )}
                            </dl>
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-900">
                              Description
                            </h3>
                            <div className="mt-2">
                              {!isEditingDescr ? (
                                <>
                                  <div className="flex items-center justify-between">
                                    {attachmentSlot.attachment?.description ? (
                                      <p className="text-sm text-gray-500">
                                        {attachmentSlot.attachment.description}
                                      </p>
                                    ) : (
                                      <p className="text-sm italic text-gray-500">
                                        Add a description to this{" "}
                                        {attachmentSlot.attachment.type}.
                                      </p>
                                    )}
                                    <button
                                      onClick={() => setIsEditingDescr(true)}
                                      type="button"
                                      className="relative flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                                    >
                                      <span className="absolute -inset-1.5" />
                                      <PencilIcon
                                        aria-hidden="true"
                                        className="h-5 w-5"
                                      />
                                      <span className="sr-only">
                                        Add description
                                      </span>
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="mt-2">
                                    <textarea
                                      id="media-description"
                                      rows={2}
                                      defaultValue={
                                        attachmentSlot.attachment?.description
                                      }
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 resize-none ring-gray-200 focus:ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                    />

                                    <div className="mt-2 flex justify-end gap-x-3">
                                      <Button
                                        variant="text"
                                        color="gray"
                                        text="Cancel"
                                        onClick={() => setIsEditingDescr(false)}
                                      />
                                      <Button
                                        variant="text"
                                        color="brand"
                                        text="Save"
                                        onClick={saveDescription}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="flex gap-x-3">
                            <Button
                              className="flex-1"
                              variant="solid"
                              color="brand"
                              text="Download"
                              loadingText="Downloading"
                              loading={isDownloading}
                              onClick={onDownload}
                            >
                              {isDownloading && (
                                <div className="flex items-center justify-between gap-2">
                                  <span className="text-xs">
                                    {downloadProgress}%
                                  </span>
                                  <Spinner size="4" />
                                </div>
                              )}

                              {!isDownloading && "Download"}
                            </Button>

                            <Button
                              className="flex-1"
                              variant="outline"
                              color="gray"
                              text="Delete"
                              loadingText="Deleting"
                              loading={isDeleting}
                              onClick={onDelete}
                            />
                          </div>
                        </div>
                        <div className="absolute pt-4 pr-4 -top-2 -right-2">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={closeSidebar}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <DeleteDialog
        title={`Delete ${attachmentSlot.isPhoto ? "photo" : "video"}`}
        message={`Are you sure you want to delete this ${
          attachmentSlot.isPhoto ? "photo" : "video"
        } from your media library?`}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        ref={deleteDialogRef}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}

import { useContext, useMemo } from "react";
import Button from "../../../../../components/common/Button";
import User from "../../../../../models/entities/user";
import GlobalStateContext from "../../../../../state/global-state/GlobalStateContext";
import { getDescription } from "../../../../../utils/social-set-utils";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

interface SocialSetsTabProps {
  user: User;
}
export default function SocialSetsTab({ user }: SocialSetsTabProps) {
  const { state } = useContext(GlobalStateContext);
  const socialSets = state.socialSets;

  const userSocialSets = useMemo(() => {
    return user.socialSetIds?.length
      ? socialSets.filter((x) => user.socialSetIds.includes(x.id))
      : socialSets;
  }, [socialSets, user.socialSetIds]);

  return (
    <div>
      <dt className="flex justify-between">
        <div className="flex flex-col justify-center">
          <div className="text-base font-semibold leading-7 text-gray-900">
            Social sets
          </div>
          <div className="text-sm text-gray-500">
            {user.name} has access to the following social sets:
          </div>
        </div>

        <div>
          <div className="flex-shrink-0 gap-0 flex">
            <Button
              to="edit"
              variant="outline"
              color="slate"
              text="Edit social sets"
            />
          </div>
        </div>
      </dt>

      <dl className="flex flex-col">
        <div className="py-6">
          <ul
            role="list"
            className="divide-y divide-gray-100 border border-gray-100 rounded-md"
          >
            {userSocialSets.map((socialSet) => (
              <li
                key={socialSet.id}
                className="col-span-1 flex rounded-md px-4 py-1"
              >
                <div className="flex flex-1 ">
                  <div className="p-3">
                    <div
                      style={{ backgroundColor: socialSet.colorHex }}
                      className="flex flex-shrink-0 items-center justify-center text-sm font-medium text-white w-11 h-11 rounded-full"
                    >
                      {socialSet.code}
                    </div>
                  </div>
                  <div className="flex flex-1 items-center justify-between truncate rounded-r-md bg-white">
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                      <div className="font-medium text-gray-900 truncate">
                        {socialSet.name}
                      </div>
                      <p className="text-gray-500">
                        {getDescription(socialSet)}
                      </p>
                    </div>
                    <div className="mr-4 flex-shrink-0">
                      <CheckCircleIcon className="h-5 w-5 text-primary-600" />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </dl>
    </div>
  );
}

import ChannelAccountTypes from "../../data/channel-account-types";
import TwitterAccount from "../../models/twitter/account";
import TwitterOAuthToken from "../../models/twitter/oauth-token";
import ApiService from "../core/api-service";

class TwitterV1Service {
  getAuthorizationUrl(state: string): Promise<string> {
    return ApiService.get<string>(`external-providers/twitter/v1/authorization-url`, {
      disableGlobalErrorReporting: true,
      params: {
        state: state,
      },
    });
  }

  exchangeTokens(token: string, verifier: string): Promise<TwitterOAuthToken> {
    return ApiService.post<TwitterOAuthToken>(
      `external-providers/twitter/v1/tokens`,
      {},
      {
        disableGlobalErrorReporting: true,
        params: {
          token,
          verifier,
        },
      }
    );
  }

  async getUserAccount(token: string, tokenSecret: string): Promise<TwitterAccount> {
    const account = await ApiService.get<TwitterAccount>(`external-providers/twitter/v1/account`, {
      disableGlobalErrorReporting: true,
      params: {
        accessToken: token,
        accessTokenSecret: tokenSecret,
      },
    });

    account.channelAccountType = ChannelAccountTypes.TwitterProfile;

    return account;
  }
}

export default new TwitterV1Service();

/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import ViewRange from "../../models/view-range";
import { useEffect, useState } from "react";
import Post from "../../../../../models/entities/post";
import CalendarDay from "../../models/calendar-day";
import dayjs from "dayjs";
import Button from "../../../../../components/common/Button";
import {
  attachCalendarEvents,
  generateCalendarMonth,
} from "../../../../../utils/monthly-calendar-utils";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { findChannelConfig } from "../../../../../data/channels-config";
import useCurrentSocialSet from "../../../../../hooks/useCurrentSocialSet";
import CalendarTileV2 from "./CalendarTileV2";

export interface CalendarMonthViewProps {
  viewRange: ViewRange;
  posts: Post[];
  deletingPost?: Post;
  isSubmitting: boolean;
  onDelete: (post: Post) => void;
  onEdit: (post: Post) => void;
  onClone: (post: Post) => void;
  onCreate: (datetime?: Date) => void;
  onPublishNow: (post: Post) => void;
  onMoveToDraft: (post: Post) => void;
  onMoveToScheduled: (post: Post) => void;
}

export default function CalendarMonthView({
  viewRange,
  posts,
  deletingPost,
  isSubmitting,
  onDelete,
  onEdit,
  onClone,
  onCreate,
  onPublishNow,
  onMoveToDraft,
  onMoveToScheduled,
}: CalendarMonthViewProps) {
  const [days, setDays] = useState<CalendarDay[]>();
  const [selectedDay, setSelectedDay] = useState<any>();
  const socialSet = useCurrentSocialSet();

  useEffect(() => {
    const days = generateCalendarMonth(
      viewRange.monthly.year,
      viewRange.monthly.month,
      socialSet.timezone
    );

    attachCalendarEvents(days, posts, socialSet.timezone);

    setDays(days);
  }, [
    viewRange.monthly.month,
    viewRange.monthly.year,
    posts,
    socialSet.timezone,
  ]);

  const clickOnCreate = (day: CalendarDay) => {
    const date = dayjs.tz(day.date, socialSet.timezone).hour(9).toDate();
    onCreate(date);
  };

  return (
    <>
      {!days ? null : (
        <div className="lg:flex lg:h-full lg:flex-col">
          <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
            <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
              <div className="bg-white py-2">
                M<span className="sr-only sm:not-sr-only">on</span>
              </div>
              <div className="bg-white py-2">
                T<span className="sr-only sm:not-sr-only">ue</span>
              </div>
              <div className="bg-white py-2">
                W<span className="sr-only sm:not-sr-only">ed</span>
              </div>
              <div className="bg-white py-2">
                T<span className="sr-only sm:not-sr-only">hu</span>
              </div>
              <div className="bg-white py-2">
                F<span className="sr-only sm:not-sr-only">ri</span>
              </div>
              <div className="bg-white py-2">
                S<span className="sr-only sm:not-sr-only">at</span>
              </div>
              <div className="bg-white py-2">
                S<span className="sr-only sm:not-sr-only">un</span>
              </div>
            </div>
            <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
              <div
                className={clsx(
                  `lg:grid-rows-${Math.ceil(days.length / 7)}`,
                  "hidden w-full lg:grid lg:grid-cols-7 lg:gap-px"
                )}
              >
                {(days ?? []).map((day) => (
                  <div
                    key={day.date}
                    className={clsx(
                      day.isCurrentMonth
                        ? day.isPast
                          ? "bg-slate-50"
                          : "bg-white"
                        : "bg-gray-50 text-gray-500",
                      "group relative px-3 py-2 min-h-24"
                    )}
                  >
                    <div className="flex flex-row justify-between">
                      <time
                        dateTime={day.date}
                        className={
                          day.isToday
                            ? "flex h-6 w-6 items-center justify-center rounded-full bg-primary-600 font-semibold text-white"
                            : undefined
                        }
                      >
                        {day.date.split("-").pop().replace(/^0/, "")}
                      </time>
                      <div
                        className={clsx(
                          "hidden",
                          !day.isPast && day.isCurrentMonth
                            ? "group-hover:block"
                            : null
                        )}
                      >
                        <PlusCircleIcon
                          className="w-5 h-5 cursor-pointer text-gray-600 active:text-gray-200"
                          onClick={() => clickOnCreate(day)}
                        />
                      </div>
                    </div>
                    {day.events.length > 0 && (
                      <ol className="mt-2 flex flex-col gap-2 group">
                        {day.events.map((event) => (
                          <li key={event.id}>
                            <CalendarTileV2
                              event={event}
                              showTitle={false}
                              isSubmitting={isSubmitting}
                              onDelete={() => onDelete(event.post)}
                              onEdit={() => onEdit(event.post)}
                              onClone={() => onClone(event.post)}
                              onMoveToDraft={() => onMoveToDraft(event.post)}
                              onMoveToScheduled={() =>
                                onMoveToScheduled(event.post)
                              }
                              onPublishNow={() => onPublishNow(event.post)}
                              loading={deletingPost?.id == event.id}
                            />
                          </li>
                        ))}
                      </ol>
                    )}
                  </div>
                ))}
              </div>
              <div
                className={clsx(
                  "isolate grid w-full grid-cols-7 gap-px lg:hidden"
                )}
              >
                {days.map((day) => (
                  <button
                    key={day.date}
                    type="button"
                    onClick={() => setSelectedDay(day)}
                    className={clsx(
                      day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                      (day.isSelected || day.isToday) && "font-semibold",
                      day.isSelected && "text-white",
                      !day.isSelected && day.isToday && "text-primary-600",
                      !day.isSelected &&
                        day.isCurrentMonth &&
                        !day.isToday &&
                        "text-gray-900",
                      !day.isSelected &&
                        !day.isCurrentMonth &&
                        !day.isToday &&
                        "text-gray-500",
                      "flex h-20 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10"
                    )}
                  >
                    <time
                      dateTime={day.date}
                      className={clsx(
                        day.isSelected &&
                          "flex h-6 w-6 items-center justify-center rounded-full",
                        day.isSelected && day.isToday && "bg-primary-600",
                        day.isSelected && !day.isToday && "bg-gray-900",
                        "ml-auto"
                      )}
                    >
                      {day.date.split("-").pop().replace(/^0/, "")}
                    </time>
                    <span className="sr-only">{day.events.length} events</span>
                    {day.events.length > 0 && (
                      <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                        {day.events.map((event) => (
                          <span
                            key={event.id}
                            className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"
                          />
                        ))}
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
          {selectedDay?.events.length > 0 && (
            <div className="px-0 py-10 sm:px-6 lg:hidden">
              <ol className=" divide-gray-100 grid grid-cols-6 gap-2 text-sm">
                {selectedDay.events.map((event) => (
                  <li
                    key={event.id}
                    className="group col-span-6 sm:col-span-3 flex flex-col p-4 pr-6  bg-white rounded-none sm:rounded-lg ring-1 ring-gray-800 ring-opacity-5"
                  >
                    <div className="flex-1">
                      <div className="px-4 py-3 sm:px-4">
                        <div className="flex space-x-5 items-center">
                          <div className="relative">
                            <img
                              referrerPolicy="no-referrer"
                              className="h-8 w-8 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                              src={
                                event.post.channel.pictureUrl ??
                                findChannelConfig(event.post.channel.type)
                                  .imageUrl
                              }
                            />
                            <img
                              className="h-4 w-4 flex-none rounded-full border border-white border-3 bg-gray-50 absolute top-6 right-0 -mr-2"
                              src={
                                findChannelConfig(event.post.channel.type)
                                  .imageUrl
                              }
                            />
                          </div>
                          <div className="min-w-0 flex-1">
                            <p className="text-sm font-semibold text-gray-900">
                              <a href="#" className="hover:underline">
                                {event.post.channel.name}
                              </a>
                            </p>
                            <p className="text-xs text-gray-500">
                              <a href="#" className="font-light leading-3">
                                {dayjs(event.post.scheduledAt)
                                  .tz(socialSet.timezone)
                                  .format("MMM DD, YYYY, hh:mm A")}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="px-4 py-3 sm:px-4">
                        {event.post.title?.length > 0 && (
                          <div className="font-normal text-sm text-gray-900">
                            {event.post.title}
                          </div>
                        )}
                        {event.post.postAttachments?.length > 0 && (
                          <div className="flex justify-start">
                            <img
                              className="max-h-40 max-w-sm object-cover"
                              src={event.post.postAttachments[0].attachment.url}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex relative self-center rounded-md px-3 py-2 font-semibold text-gray-900">
                      <div className=" w-[160px] h-[52px] block focus:hidden group-hover:hidden"></div>
                      <div className="flex-row hidden focus:flex group-hover:flex">
                        <div className="flex flex-1 w-full">
                          <Button
                            variant="text"
                            text="Delete"
                            color="red"
                            onClick={() => onDelete(event)}
                            className="w-full"
                          />
                        </div>
                        <div className="flex flex-1">
                          <Button
                            variant="text"
                            text="Edit"
                            onClick={() => onEdit(event)}
                            className="w-full"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>
      )}
    </>
  );
}
